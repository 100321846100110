/* ---------- VISIBLE FUNCTION */
@mixin responsive-visibility($parent) {
	#{$parent} { display: block !important; }
	table#{$parent} { display: table !important; }
	tr#{$parent} { display: table-row !important; }
	th#{$parent},
	td#{$parent} { display: table-cell !important; }
}

/* ---------- INVISIBLE FUNCTION */
@mixin responsive-invisibility($parent) {
	#{$parent} { display: none !important; }
}

/* ---------- GRID FUNCTION */
@mixin make-grid($class) {
	@include float-grid-columns($class);
	@include loop-grid-columns($grid-columns, $class, width);
	@include loop-grid-columns($grid-columns, $class, pull);
	@include loop-grid-columns($grid-columns, $class, push);
	@include loop-grid-columns($grid-columns, $class, offset);
}
@mixin float-grid-columns($class, $i: 1, $list: ".col-#{$class}-#{$i}") {
	@for $i from (1 + 1) through $grid-columns {
		$list: "#{$list}, .col-#{$class}-#{$i}";
	}

	#{$list} { float: left; }
}
@mixin loop-grid-columns($columns, $class, $type) {
	@for $i from 0 through $columns {
		@include calc-grid-column($i, $class, $type);
	}
}
@mixin calc-grid-column($index, $class, $type) {
	@if ($type == width) and ($index > 0) {
		.col-#{$class}-#{$index} { width: percentage(($index / $grid-columns)); }
	}
	@if ($type == push) and ($index > 0) {
		.col-#{$class}-push-#{$index} { left: percentage(($index / $grid-columns)); }
	}
	@if ($type == push) and ($index == 0) {
		.col-#{$class}-push-0 { left: auto; }
	}
	@if ($type == pull) and ($index > 0) {
		.col-#{$class}-pull-#{$index} { right: percentage(($index / $grid-columns)); }
	}
	@if ($type == pull) and ($index == 0) {
		.col-#{$class}-pull-0 { right: auto; }
	}
	@if ($type == offset) {
		.col-#{$class}-offset-#{$index} { margin-left: percentage(($index / $grid-columns)); }
	}
}

/* ---------- HOVER & FOCUS */
@mixin hover-focus {
	&:hover,
	&:focus {
		@content;
	}
}

/* ---------- COLOR LEVEL */
@function theme-color-level($color-name, $level) {
	$color: map-get($theme-colors, $color-name);
	$color-base: if($level > 0, black, white);
	$level: abs($level);

	@return mix($color-base, $color, $level * $theme-color-interval);
}

/* ---------- COLOR CONTRAST */
@function color-yiq($color) {
	$r: red($color);
	$g: green($color);
	$b: blue($color);

	$yiq: calc((($r * 299) + ($g * 587) + ($b * 114)) / 1000);

	@if ($yiq >= $yiq-contrasted-threshold) {
		@return $text;
	} @else {
		@return white;
	}
}

/* ---------- BUTRON GENERATOR */
@mixin button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%)) {
	color: color-yiq($background);
	background-color: $background;
	border-color: $border;

	&.hover,
	&:hover,
	&.focus,
	&:focus,
	&.active,
	&:active,
	&:active:focus {
		color: color-yiq($hover-background);
		background-color: $hover-background;
		border-color: $hover-border;

		.badge {
			color: $hover-background;
			background-color: color-yiq($hover-background);
		}
	}

	&.disabled,
	&:disabled {
		color: color-yiq($background);
		background-color: $background;
		border-color: $border;
	}

	&:not(:disabled):not(.disabled):active,
	&:not(:disabled):not(.disabled).active,
	.show > &.dropdown-toggle,
	.open > &.dropdown-toggle {
		color: color-yiq($active-background);
		background-color: $active-background;
		border-color: $active-border;

		&:focus {
			color: color-yiq( darken($active-background, 7.5%) );
			background-color: darken($active-background, 7.5%);
			border-color: darken($active-background, 10%);
		}
	}

	.badge {
		color: $background;
		background-color: color-yiq($background);
	}
}

/* ---------- ANIMATION FUNCTION */
@mixin property-animation($property, $duration) {
	-webkit-transition-property: $property;
	-ms-transition-property: $property;
	-moz-transition-property: $property;
	-o-transition-property: $property;
	transition-property: $property;

	-webkit-transition-duration: $duration;
	-ms-transition-duration: $duration;
	-moz-transition-duration: $duration;
	-o-transition-duration: $duration;
	transition-duration: $duration;
}

/* ---------- AVATAR */
@mixin avatar($size) {
	$p: $size * 0.25;
	$lh: calc($size / 2);
	$fs: $size * 0.35;

	&.avatar#{$size} {
		span {
			height: #{$size}px;
			width: #{$size}px;

			&.avatar-no-image {
				font-size: #{$fs}px;
				line-height: #{$lh}px;
			}
		}

		@if ($size > 30) {
			$s: $size * 0.7;
			$p: $s * 0.25;
			$lh: calc($s / 2);
			$fs: $s * 0.35;

			@media #{$max-xs} {
				span {
					height: #{$s}px;
					width: #{$s}px;

					&.avatar-no-image {
						font-size: #{$fs}px;
						line-height: #{$lh}px;
					}
				}
			}
		}
	}
}

/* ---------- EFFECT */
@mixin effect($name) {
	.#{$name} {
		animation-duration: 1s;
		animation-fill-mode: both;
		animation-name: #{$name};
	}
}