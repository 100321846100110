.sorting_asc,
.sorting_desc,
.sorting,
.sorting_asc_disabled,
.sorting_desc_disabled {
	&:after {
		content: "";
		font-family: "FontAwesome";
		margin-left: 10px;
		font-size: 0.8em;
	}
}

.sorting_asc,
.sorting_desc,
.sorting { cursor: pointer; }

.sorting_asc:after{
	content: "\f0de";
	vertical-align: bottom;
	line-height: 13px;
}

.sorting_desc:after {
	content: "\f0dd";
	vertical-align: top;
	line-height: 20px;
}

.sorting:after { content: "\f0dc"; }

.sorting_asc_disabled:after{
	content: "\f0de";
	opacity:0.7;
	vertical-align: bottom;
	line-height: 12px;
}

.sorting_desc_disabled:after {
	content: "\f0dd";
	opacity:0.7;
	vertical-align: top;
	line-height: 18px;
}

.dataTables_empty { text-align: center; }

.table-nosort {
	.sorting_asc,
	.sorting_desc,
	.sorting,
	.sorting_asc_disabled,
	.sorting_desc_disabled {
		cursor: initial;

		&:after { content: ""; }
	}

	.col-index { display: none; }

	tbody tr:hover .col-index + td { border-left: 1px solid $blue-2; }
}