@-webkit-keyframes spinner {
	0% { -webkit-transform: rotate(0deg); }
	100% { -webkit-transform: rotate(360deg); }
}
@keyframes spinner {
	0% { transform: rotate(0deg); }
	100% { transform: rotate(360deg); }
}

/* ----- EFFECTS */
@include effect('slideRight');
@include effect('slideLeft');
@include effect('puffOut');
@include effect('boingOutDown');
@include effect('tinRightOut');
@include effect('rotateLeft');
@include effect('rotateDown');
@include effect('vanishOut');
@include effect('spaceOutDown');
@include effect('spaceInUp');

@keyframes slideRight {
	0% {
		transform-origin: 0 0;
		transform: translateX(0%);
	}
	100% {
		transform-origin: 0 0;
		transform: translateX(100%);
	}
}
@keyframes slideLeft {
	0% {
		transform-origin: 0 0;
		transform: translateX(0%);
	}
	100% {
		transform-origin: 0 0;
		transform: translateX(-100%);
	}
}
@keyframes puffOut {
	0% {
		opacity: 1;
		transform-origin: 50% 50%;
		transform: scale(1, 1);
		filter: blur(0px);
	}
	100% {
		opacity: 0;
		transform-origin: 50% 50%;
		transform: scale(2, 2);
		filter: blur(2px);
	}
}
@keyframes boingOutDown {
	0% {
		opacity: 1;
		transform-origin: 100% 100%;
		transform: perspective(800px) rotateX(0deg) rotateY(0deg);
	}
	20% {
		opacity: 1;
		transform-origin: 100% 100%;
		transform: perspective(800px) rotateX(0deg) rotateY(10deg);
	}
	30% {
		opacity: 1;
		transform-origin: 0% 100%;
		transform: perspective(800px) rotateX(0deg) rotateY(0deg);
	}
	40% {
		opacity: 1;
		transform-origin: 0% 100%;
		transform: perspective(800px) rotateX(10deg) rotateY(10deg);
	}
	100% {
		opacity: 0;
		transform-origin: 100% 100%;
		transform: perspective(800px) rotateX(90deg) rotateY(0deg);
	}
}
@keyframes tinRightOut {
	0%, 20%, 40%, 50% {
		opacity: 1;
		transform: scale(1, 1) translateX(0);
	}
	10%, 30% {
		opacity: 1;
		transform: scale(1.1, 1.1) translateX(0);
	}
	100% {
		opacity: 0;
		transform: scale(1, 1) translateX(900%);
	}
}
@keyframes rotateLeft {
	0% {
		opacity: 1;
		transform-origin: 0 0;
		transform: perspective(800px) rotateY(0deg) translateZ(0px);
	}
	100% {
		opacity: 0;
		transform-origin: 50% 0;
		transform: perspective(800px) rotateY(180deg) translateZ(300px);
	}
}
@keyframes rotateDown {
	0% {
		opacity: 1;
		transform-origin: 0 0;
		transform: perspective(800px) rotateX(0deg) translateZ(0px);
	}
	100% {
		opacity: 0;
		transform-origin: 50% 100%;
		transform: perspective(800px) rotateX(-180deg) translateZ(300px);
	}
}
@keyframes vanishOut {
	0% {
		opacity: 1;
		transform-origin: 50% 50%;
		transform: scale(1, 1);
		filter: blur(0px);
	}
	100% {
		opacity: 0;
		transform-origin: 50% 50%;
		transform: scale(2, 2);
		filter: blur(20px);
	}
}
@keyframes spaceOutDown {
	0% {
		opacity: 1;
		transform-origin: 50% 100%;
		transform: scale(1) translate(0%, 0%);
	}
	100% {
		opacity: 0;
		transform-origin: 50% 100%;
		transform: scale(.2) translate(0%, 200%);
	}
}
@keyframes spaceInUp {
	0% {
		opacity: 0;
		transform-origin: 50% 0%;
		transform: scale(.2) translate(0%, -200%);
	}
	100% {
		opacity: 1;
		transform-origin: 50% 0%;
		transform: scale(1) translate(0%, 0%);
	}
}
@keyframes progressBar {
	0% {
		width: 0;
		margin-left: 0;
	}
	50% {
		width: 100%;
		margin-left: 0;
	}
	100% {
		width: 0;
		margin-left: 100%;
	}
}