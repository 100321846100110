.ocr-manager {
	.ocr-wrapper {
		display: flex;
		flex-direction: column;
		height: calc(100vh - 124px);
		overflow: hidden;

		.ocr-img {
			height: 45%;
			overflow-x: hidden;
			overflow-y: auto;
			text-align: center;
			scrollbar-width: thin;
			scrollbar-color: $grey-1 $grey-3; // 1 barra 2 fondo (firefox)			

			img { max-width: 80%; }
		}

		.ocr-form {
			height: 55%;
			min-height: 255px;
			padding: 0 15px;
			background-color: white;
			text-align: center;
		}
	}

	@media #{$min-sm} {
		.ocr-wrapper {
			height: calc(100vh - 300px);

			.ocr-img { height: 55%; }

			.ocr-form { height: 45%; }
		}
	}
}