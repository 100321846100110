//	VIEW CUENTA
.cuenta-info {
	color: $text-grey;

	.cuenta-nombre {
		margin-top: 0;
		color: $text;
	}

	p { margin-bottom: 5px; }

	.tag { margin-bottom: 5px; }
}
		
#viewAccount {
	.tab-container-wrapper {
		margin-bottom: 30px;
	}

	.block-group {
		margin-bottom: 50px;


		.caja { background: $primary-50; }

		.empty-box img { padding: 20px 0; }
	}

	.stadistic .stat-total {
		margin-bottom: 5px;
		margin-top: 10px;
	}

	.contacts-list {
		.contact {
			background-color: $primary-50;
		}
	}
	@media #{$max-md} {
		.block-group {
			display: block;

			.block{
				margin-left: 0;
			}
		}

	}
	@media #{$max-xs} {
		.tab-container-wrapper {
			.cuenta-nombre,
			.cuenta-cif { padding-right: 60px; }

			.map-wrapper { margin-top: 20px; }
		}

		.block-group { margin-bottom: 30px; }

		.contacts-list { max-height: 326px; }
	}
}

//	RESUM CUENTA, des d'activitats
#viewCuenta {
	.contacts-list {
		max-height: 100%;
		overflow-y: auto;
		scrollbar-width: thin;
		scrollbar-color: $grey-1 $grey-3; // 1 barra 2 fondo (firefox)
		
		@media #{$max-md} { max-height: 326px; }

		.contact {
			background-color: $primary-50;

			&:last-child { margin-bottom: 0; }
		}
	}

	@media #{$max-xs} {
		.bloque-mapa {
			padding-left: 0;
			padding-right: 0;
		}

		.div-comment .form-group { margin-bottom: 10px; }
	}
}



#divNewAccount {
	width: 95%;
	max-width: 1200px;
	
	#cifValidation {
		margin-top: -5px;
		padding-top: 0;
	}

	#checksActua { padding-top: 15px; }

	.checkbox-inline {
		padding-left: 0;
		margin-top: 10px; 

		@media #{$max-sm} {
			display: block;
			width: 100%;
			margin-left:0;
		}
	}
	
	.sin-campos { padding-top: 30px;}
	
	.tab-pane { min-height: 300px; }
	
	#condicionesFace {
		min-height: 500px;
				
		.sector-head{
			margin-top: 30px;
		}		
		
		.sector-titulo{
			display: flex;
			align-items: center;
			justify-content: space-between;
			font-weight: 700;
			font-size: 22px;
		}
				
		li.bloque-sector-item{
			position: relative;
			margin-bottom: 25px;
			border: 1px solid $primary-100;
			background: $primary-50;
			padding: 15px;
			
			.btn-remove-icon{
				position: absolute;
				right: 7px;
				top: 12px;
			}
		}
		
		.input-group-addon{
			background: white;
			width: 40%;
			text-align: left;
			font-weight: 600;
		}
		
		.panel{
			margin-bottom: 5px;
		}
		
		.panel, .panel-body{
			border-color: $form-input-border;
		}
		
		.panel-body{
			//background-color: $grey-5;
		}
		
		.panel-title{
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding-top: 5px;
			padding-bottom: 5px;
			
			.input-group{
				width: calc(100% - 30px);
			}
			
			.input-group-addon{
				border: 0;
			}
					
			a{
				width: 30px;
				background-size: 15px;
				background-image: url(/saas/img/icons/icons/grey/caret_down.svg);
				background-repeat: no-repeat;
				background-position: calc(100% - 10px) 50%;
			}
		}
	}
	
	#camposPersonalizados { padding-top: 25px; }
	
	#infoAdicional { overflow: hidden; }
}

.page-facturae {
	.form-facturae { 
		max-width: 600px;
		margin-top: 40px;
	}

	#submitUpload { margin-top: 40px; }
	#submitDelete { margin-top: 40px; margin-left: 10px; }
	i { margin-right: 3px; }
}
