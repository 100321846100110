$bgCaretSelect: url(/saas/img/icons/icons/blue/caret_down.svg) no-repeat calc(100% - 5px) 50% / 17px; 
$bgCaretSelectDisabled: url(/saas/img/icons/icons/grey/caret_down.svg) no-repeat calc(100% - 5px) 50% / 17px;


// Hide carets input numbers
input[type=number]:not(.carets)::-webkit-outer-spin-button,
input[type=number]:not(.carets)::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
}
input[type=number]:not(.carets) { -moz-appearance: textfield; }

input[type=number].carets { padding-right: 25px; }


::-webkit-input-placeholder { color: $form-placeholder-color; opacity: 0.6;}
::-moz-placeholder { color: $form-placeholder-color; opacity: 0.6;}
:-ms-input-placeholder { color: $form-placeholder-color; opacity: 0.6;}
:-moz-placeholder { color: $form-placeholder-color; opacity: 0.6;}
::placeholder { color: $form-placeholder-color !important; opacity: 0.6;}
.form-control::placeholder { opacity: 0.6;}

// Autocompleter
.autocompleter-content {
	position: relative;

	.caret {
		position: absolute;
		top: 50%;
		right: 0;
		width: 20px;
		height: 20px;
		border: 0;
		margin-top: -9px;
		margin-bottom: 0;
		margin-right: 8px;
		cursor: pointer;
		background: transparent $bgCaretSelect;
		z-index: 3;
	}

	.divAutocomplete {
		resize: both;
		position: absolute;
		top: 100%;
		width: 100%;
		max-height: 300px;
		border: 1px solid $autocomplete-box-color;
		margin: 0;
		text-align: left;
		z-index: 79;
		overflow-y: auto;
		scrollbar-width: thin;
		scrollbar-color: $grey-1 $grey-3; // 1 barra 2 fondo (firefox)
		overflow-x: hidden;
		background-color: $autocomplete-box-background;

		&::-webkit-scrollbar { width: 0.4em; }
	}
	
	.ulAutocomplete {
		padding: 5px 0;
		overflow-x: auto;
		scrollbar-width: thin;
		scrollbar-color: $grey-1 $grey-3; // 1 barra 2 fondo (firefox)

		.completer-header {
			display: block;
			padding: 3px 20px;
			font-size: 12px;
			line-height: 1.42857143;
			color: $autocomplete-header-color;
			white-space: nowrap;
			opacity: 0.6;
		}

		li:not(.completer-header) {
			padding: 5px 20px;
			cursor: pointer;
			z-index: 10;

			.addNew {
				float: right;
				height: inherit;
				text-align: right;
				color: $autocomplete-add-color;
			}

			//&:hover,
			
			&.hover {
				background-color: $autocomplete-row-background-hover;
				color: $autocomplete-row-color-hover;

				.addNew {
					color: inherit;
					text-decoration: underline;
				}
			}
		}
	}

	&.input-group {
		input ~ .caret { margin-right: 35px; }

		.spinner { margin-bottom: 0; }
	}

	&.popover-wrap.input-group.no-adder{
		input ~ .caret { margin-right: 7px; }
		.spinner{ margin-right: 7px !important;}
	}
}

.input-group-addon{
	font-size: 16px;
}

.tagit {
	padding: 2.5px 12px 2.5px 35px;
	border: 1px solid $form-input-border;
	background: $form-input-background url(/saas/img/icons/icons/blue/tag.svg) no-repeat 4px;

	&.disabled { background-color: $form-input-border !important; }	
	
	&.focus { border-color: $form-input-border-focus; }

	.tagit-new {
		.divAutocomplete { display: block !important; }

		.ulAutocomplete {
			top: 66px !important;
			padding: 0;
			border: 1px solid $autocomplete-box-color;
			background-color: $autocomplete-box-background;
			z-index: 10;

			.ui-menu-item {
				padding: 2px 15px;
				color: inherit;
				cursor: pointer;
				z-index: 10;

				* { color: inherit; }

				&:hover {
					background-color: $autocomplete-row-background-hover;
					color: $autocomplete-row-color-hover;
				}
			}
		}
	}
}

ul.tagit {
	padding: 5px 35px 5px 12px;
	border: 1px solid $form-input-border;
	background: $form-input-background url(/saas/img/icons/icons/blue/tag.svg) no-repeat;
	background-size: auto 20px;
	background-position: calc(100% - 8px) 8px;
	min-height: 40px;
	margin: 0;
	scrollbar-width: thin;
	scrollbar-color: $grey-1 $grey-3; // 1 barra 2 fondo (firefox)

	&.disabled {background-color: $form-input-border !important; }	
	
	&.focus {border-color: $form-input-border-focus;}
	
	li {
		&.tagit-new {
			width: initial;
			clear: none;
			
			> ul.tagit-choice {
				margin: 0px;
				margin-right: 5px;
			}

			> .ui-widget-content {
				border: 0;
				width: 200px;
				padding: 0;
				line-height: 23px;
			}			
		}
		&.tagit-choice-editable { padding: 1px 20px 1px 5px; }
	}
}


// Forms
.form-inline {
	margin-bottom: 15px;

	.input-group-wrapper {
		display: inline-block;
		vertical-align: middle;
	}

	.form-group {
		margin-left: 10px;

		label { display: inline-block; }

		.inpFecha { 
			width: 120px;  

			@media #{$max-xs} { width: 100%; }
		}

		&:first-of-type { margin-left: 0; }
	}
}

.inline-radios{
	display: flex;
	
	.form-group{
		margin-right: 15px;
	}
}

.form-label-normal{
	font-weight: normal !important;
}

.form-label-help{
	padding-right: 35px;
	position: relative;
	
	.badgeHelp{
		position: absolute;
		right: 0;
		top: calc(50% - 12px);	
	}
}

.form-group {
	position: relative;

	@media #{$min-xs} { margin-bottom: 20px; }

	label {
		display: block;
		font-weight: normal;
		line-height: 20px;
		transition: all 1s ease;

		&.form-label { font-weight: 600; }
	}

	&.form-disabled {
		cursor: not-allowed;

		::-webkit-input-placeholder {color: transparent;}
		::-moz-placeholder {color: transparent;}
		:-ms-input-placeholder {color: transparent;}
		:-moz-placeholder {color: transparent;}

		* { cursor: not-allowed; }

		.form-control:disabled,
		*:disabled {
			background: none !important;
			background-color: $form-inputDisabled-background !important;
			cursor: not-allowed;
			color: $form-inputDisabled-color !important;
			border-color: $form-input-border !important;
		}

		label, label * {
			cursor: not-allowed;
			color: $form-labelDisabled-color !important;
		}

		.input-group .addon-back,
		.input-group .addon-add {
			background-color: $form-inputDisabled-background;

			label { visibility: hidden; }
		}

		.input-group.inputImg > .input-group-addon:last-of-type,
		.input-group-addon {
			//border:0;
			color: $form-labelDisabled-color !important;
			cursor: not-allowed;
		}

		.btn-group .btn {
			border: 1px solid $form-input-border !important;
			color: $form-labelDisabled-color !important;
			background-color: $form-inputDisabled-background !important;

			&.active {
				color: white !important;
				background-color: darken($form-inputDisabled-background, 15%) !important;
			}
		}
	}

	+ .subinfo {
		margin-top: -13px;
		margin-bottom: 15px;
	}
/*
	.btn {
		padding-top: 10px;
		padding-bottom: 10px;
	}
	*/
	label.btn {
		padding-top: 9px;
		padding-bottom: 9px;
	}
	
	.text-text{
		min-height: 40px;
	}
}


.inpTextarea {
	position: relative;
	display: block;
	width: 100%;

	.infoTextarea {
		position: absolute;
		right: 5px;
		bottom: 0;
		padding: 0;
		margin: 0;
		border: 0;
		font-size: 11px;
		color: $form-subinfo-color;
		z-index: 3;
		background-color: transparent;
	}

	+ .help-block { margin-top: 5px; }

	&.inp-attach {
		textarea { padding-bottom: 25px; }

		.infoTextarea {
			left: 5px;
			width: 30px;
		}

		.btn-attach {
			position: absolute;
			bottom: 5px;
			right: 10px;
		}
	}
}

.input-group {
	width: 100%;
	
	&[class*=col-]{
		float: initial;
		padding-left: 15px;
		padding-right: 15px;
	}
	
	
	& > .addonSelect{ 
		padding: 0;
		-webkit-border-radius: 2px;
		-moz-border-radius: 2px;
		border-radius: 2px;
	    border: 0;
	    width: 75px;
	    background-color: transparent;
	    
	    .form-control { margin-left: -1px; }
	}	

	&.inputImg {
		& > .form-control, .fake-select{
			border-right: 0;

			&:focus {
				+ .input-group-addon,
				+ .input-group-addon + .input-group-addon { border-color: $form-input-border-focus; }
			}
		}
		
		& > .btn-group.open{
			+ .input-group-addon,
			+ .input-group-addon + .input-group-addon { border-color: $form-input-border-focus; }
		}		

		.input-group-addon {
			border-color: $form-input-border;
			border-left: 0;
			background-color: $form-input-background;

			&:not(:first-of-type):not(:last-of-type) { border-right: 0; }
			&:last-child { border-right: 1px solid $form-input-border; }

			&.addon-remove,
			&.addon-back,
			&.addon-add {
				padding: 0;
				border-radius: 0 2px 2px 0;
				cursor: pointer;

				label {
					padding: 6px 4px;

					&:before {
						content: "";
						display: block;
						width: 20px;
						height: 20px;
						cursor: pointer;
						background-size: 20px;
						background-repeat: no-repeat;
						background-color: transparent;
					}
				}
			}
			
			&.addon-select.addon-add label { padding-left: 0; }

			&.addon-add label {
				&:before { background-image: url('/saas/img/icons/icons/blue/add.svg'); }
				&:hover:before { opacity:0.7 }
			}
			
			&.addon-remove label {
				&:before { 
					background-image: url('/saas/img/icons/icons/grey/close.svg'); 
					background-size: 10px auto;
					background-position: 90% 50%;
				}

				&:hover:before {  background-image: url('/saas/img/icons/icons/red/close.svg'); }
			}

			&.addon-back label {
				&:before { background-image: url('/saas/img/icons/icons/grey/caret_left.svg'); }
				&:hover:before { opacity:0.7 }
			}

			label { margin-bottom: 0; }
		}
		
		&.input-group-autocomplete{
			border-top-right-radius: 2px !important;
			border-bottom-right-radius: 2px !important;
			
			&.inputImg > .form-control{
				border-right: 1px solid $form-input-border !important;
				&:focus{
					border-right: 1px solid $blue!important;
				}
			}
			
			input ~ .caret{
				margin-right: 15px !important;
			}
			
			.add-clear-x{
				margin-right: 40px !important;
			}
		}
	}

	&.input-group-bank {
		.addon-separador {
			background-color: white;
			border: 0;
			padding: 4px;
		}

		.form-control {
			&[maxlength='2'] { min-width: 41px; }
			&[maxlength='4'] { min-width: 57px; }
			&[maxlength='10'] { min-width: 115px; }
		}
	}

	.form-control + .input-group-addon {
		&.addonInput {
			padding: 0;
			margin: 0;
			border: 0;
			background-color: transparent;

			select {
				&.form-control {
					width: 60px;
					padding-left: 5px;
				}
			}
		}
	}

	+ .help-block { margin-top: 5px; }
}

.dropdown {
	&.dropdown-img {
		display: block;

		.btn {
			padding: 2.5px;
			text-align: left;
			background-color: transparent;

			&.avatar { padding: 0; }

			&.form-control {
				@extend .substring;

				padding-left: 12px;
				border: 1px solid $form-input-border;
				background: $form-input-background $bgCaretSelect;

				&:focus {
					border-color: $form-input-border-focus;
					box-shadow: 0px 0px 7px 0px $form-input-shadow-focus;
				}
			}
		}

		ul.dropdown-menu {
			width: 100%;
			max-height: 400px;
			margin-top: 0;
			border-radius: 1px;
			font-size: inherit;
			background-color: $form-input-background;

			li {
				padding: 5px 15px;

				.avatar span,
				img {
					margin-right: 5px;
					color: white;
				}

				abbr { display: none; }
				
				&:hover{
					.avatar span,
					img { opacity: 0.8; }
				}
			}
		}
	}
	
	&.dropdown-box{
		@extend .fake-form-control;
		background: white $bgCaretSelect; 
		padding: 4px 25px 4px 7px !important;
		display: flex;
		align-items: center;
		
		&.dropdown-img{
		
			.btn{
				width: 100%;
				padding: 0;
				display: flex;
				align-items: center;
				
				> .avatar{
					margin-right: 5px;
				}
			}
		}
		
		&.dropdown-box-disabled{
			background: white; 
			padding: 4px 7px !important;
		}
	}
}

.dropdown-color {
	display: block;
	height: 40px;
	
	.btn {
		padding-left: 0 !important;
		background-color: transparent;
	}

	.color {
		display: block;
		width: 25px;
		height: 25px;
		border: 3px solid white;
		border-radius: 50%;
		margin-right: 5px;
		float: left;

		&.color-none {
			position: relative;
			background-image: url(/saas/img/icons/icons/red/close.svg);
			background-repeat: no-repeat;
			background-position: 50% 50%;

			&.active { border: 3px solid rgba($blue, 0.5); }
		}
		
		&.color-all {
			position: relative;
			background-image: url(/saas/img/icons/color.svg);

			&.active { border: 3px solid $blue-3; }
		}

		&.active { border: 3px solid rgba(white, 0.50); }
	}

	.dropdown-menu {
		min-width: 202px;
		padding: 10px;

		.color {
			padding: 0;

			&:last-of-type { margin-right: 0; }
			&:hover { border: 3px solid rgba(white, 0.50); }

			&.color-none:hover { border: 3px solid rgba($grey, 0.5); }
		}
	}
	
	.dropdown-toggle .caret{ height: 25px; }
	
	&.tb-color .dropdown-menu {
		right: -75px;
		margin-top:5px;
	}

	.form-group & { padding-top: 5px; }
}

.radio,
.checkbox {
	input[type="radio"],
	input[type="checkbox"] {
		margin: 0;
		width: 16px;
		height: 16px;
		opacity: 0;

		&:hover + label:after { color: $grey-2; }
		&:checked + label:after { color: white; } // $grey
		&:checked + label:before { 
			background-color: white; 
			border: 1px solid $blue; //$grey-2
		} 
	}
	
	input[type="checkbox"] {
		&:checked + label:before { 
			background-color: $blue;
		} 
	}	

	label {
		position: relative;
		padding-left: 25px;
		user-select: none; 
		min-height: 16px;
		
		&:before,
		&:after {
			content: "";
			position: absolute;
			left: 0;
			top: 50%;
			transform: translateY(-50%);
			width: 16px; 
			height: 16px;
			font-family: $f-icons;
			text-align: center;
			vertical-align: middle;
			color: $grey-2;
		}

		&:before {
			border: 1px solid $grey-2;
			background: rgba($grey-3, 0.48);
		}

		&:empty { 
			padding-left: 0; 
			width: 16px;
		}
		
		.form-group &{
			font-weight: 600;
		}
	}
	
	.form-group.inline-check &{
		height: 65px;
		display: flex;
		align-items: center;
	}

	input[type="radio"] {
		&:hover + label:after { background-color: $grey-2; }
		&:checked + label:after { background-color: $blue; }

		+ label {
			&:before,
			&:after { border-radius: 50%; }

			&:after {
				left: 4px;
				width: 8px;
				height: 8px;
			}
		}
	}

	input[type="checkbox"] {
		&:hover,
		&:checked {
			+ label:after { 
				content: "\e90a"; 
				font-size: 13px;
				line-height: 18px;
			}
		}

		+ label:before { border-radius: 4px; }
		
		&:disabled + label:before { background-color: $grey-3; }

		&:disabled:checked + label:before { 
			background-color: $blue-2; 
			border-color: $blue-2; 
		}

	}

	&.checkbox {
		.radio {
			&:checked + label:after {
				width: 1.516em;
				height: 1.516em;
				font-size: 12px;
				line-height: 1.516em;
			}

			+ label:before { border-radius: 50%; }
		}
	}

	&.help-block {
		input[type="radio"],
		input[type="checkbox"] {
			width: 15px;
			height: 15px;

			&:checked + label:after { line-height: 15px; }
		}

		label {
			font-size: inherit;
			line-height: 15px;

			&:before,
			&:after {
				width: 15px;
				height: 15px;
			}
		}
	}
}

.row-checks{
	display: flex;
	align-items: center;
	
	.checkbox{
		margin-top: 10px;
		margin-right: 10px;
	}

	&.justify-center{
		justify-content: center;
	}
}

.active:not(.tab-pane) .radio label{
	&:before{border-color: $grey-1;}
	&:after{background-color: $grey;}
}

.btn-group {
	.btn {
		&.btn-check-none,
		&.btn-check,
		&.btn-radio {
			//border: 1px solid $blue;
			color: $blue;
			background-color: $primary-50;
			font-weight: 600;
			font-size: 15px;
			text-align: center;
			text-transform: uppercase;
			padding-left: 5px;
			padding-right: 5px;

			&:not(:first-child) { border-left: 0px; }

			&.active {
				//border-color: $blue;
				color: white;
				background-color: $blue;
				box-shadow: none;

				&:focus {
					box-shadow: none;
					outline: none;
				}
			}

			&:hover {
				//border-color: $primary-300;
				color: white;
				background-color: $primary-300;
			}
		}
	}
}

.fake-form-control{
	border: 1px solid $form-input-border;
	border-radius: 2px;
	color: $form-input-color;
	margin: 0;
	display: block;
	height: 34px;
	padding: 6px 12px;
	font-size: 14px;

	@media #{$min-xs} {
		height: 40px;
		padding-top: 8px;
		padding-bottom: 8px;
		font-size: 15px;
	}
}

.form-control {
	height: 40px;
	padding-top: 8px;
	padding-bottom: 8px;
	border-color: $form-input-border;
	border-radius: 2px;
	font-size: 15px;
	color: $form-input-color;
	transition: all 0.5s ease;
	outline: none;
	box-shadow: none;

	&:hover,
	&.hover,
	&:hover + .input-group-addon.addon-add,
	&:hover + .input-group-addon.addon-back {
		border-color: $form-input-border-hover;
		outline: none;
		box-shadow: 0px 0px 7px 0px $form-input-shadow-hover;
	}

	&:focus,
	&.focus,
	&:focus + .input-group-addon.addon-add,
	&:focus + .input-group-addon.addon-back {
		border-color: $form-input-border-focus;
		outline: none;
		box-shadow: 0px 0px 7px 0px $form-input-shadow-focus;
	}

	&.input-xs {
		height: 25px;
		padding-top: 0;
		padding-bottom: 0;

		+ .input-group-addon.addon-add label { padding: 5px 8px; }
	}

	&.input-sm {
		height: 34px;
		padding-top: 0;
		padding-bottom: 0;

		+ .input-group-addon.addon-add label { padding: 5px 8px; }
	}

	&.input-lg {
		height: 40px;
		padding: 10px 16px;
		font-size: 16px;
	}

	&.inpHora {
		padding-right: 5px;
		border-right: 0;
		text-align: right;
	}

	&.inpMinuts {
		border-left: 0;
		padding-left: 5px;
	}

	// ICON INPUTS
	&.inpMail				{ background: $form-input-background url(/saas/img/icons/icons/grey/mail.svg) no-repeat 4px / 30px; }
	&.inpDuracion			{ background: $form-input-background url(/saas/img/icons/icons/grey/duracion.svg) no-repeat 4px; }
	&.inpHora				{ background: $form-input-background url(/saas/img/icons/icons/grey/clock.svg) no-repeat 4px; }
	&.inpHora.inpDuracion	{ background: $form-input-background url(/saas/img/icons/icons/grey/duracion.svg) no-repeat 4px; }
	
	
	&.inpFecha				{ background: $form-input-background url(/saas/img/icons/icons/blue/calendar.svg) no-repeat; background-size: 20px; background-position: calc(100% - 7px); cursor: pointer; }
	
	 
	&.inpTareaCRM			{ background: $form-input-background url(/saas/img/icons/icons/grey/tareas.svg) no-repeat 4px; }
	&.inpTask				{ background: $form-input-background url(/saas/img/icons/icons/grey/tareas.svg) no-repeat 4px; }
	&.inpProducto			{ background: $form-input-background url(/saas/img/icons/icons/grey/producto.svg) no-repeat 4px / 30px; background-size:20px auto; }
	&.inpServicio			{ background: $form-input-background url(/saas/img/icons/icons/grey/servicio.svg) no-repeat 4px / 30px; background-size:20px auto; }
	&.inpContrato			{ background: $form-input-background url(/saas/img/icons/icons/grey/contrato.svg) no-repeat 4px / 30px; background-size:20px auto; }
	
	&.inpAviso				{ 
		background: $form-input-background url(/saas/img/icons/icons/yellow/exclamation.svg) no-repeat; 
		background-size: 17px; 
		background-position: calc(100% - 5px); 
		cursor: pointer; 
		border-color: $yellow;
	}

	&.inpProductServei,
	&.inpProducto,
	&.inpServicio,
	&.inpContrato,
	&.inpPersonas,
	&.inpPass,
	&.inpMail,
	&.inpDuracion,
	&.inpHora,
	&.inpTareaCRM,
	&.inpTask
	{
		padding-left: 35px;

		&:disabled { background: $form-inputDisabled-background; }
	}
	
	&.inpFecha, &.inpAviso {
		padding-right: 35px;

		&:disabled, &.disabled { background-color: $form-inputDisabled-background; cursor:initial;}
	}	
	
	&.inpPersonas {		
		background-image: url(/saas/img/icons/icons/grey/users.svg); 
		background-repeat: no-repeat;
		background-position: 8px 8px;
		background-size: 24px auto; 
		padding-left: 40px;
	}	

	// VIEW INPUTS
	&.inp-view{
		display: inline-block;
		width: 100%;
		border: 1px dashed $form-input-border;
		overflow: hidden;
		text-overflow: ellipsis;	
		white-space: nowrap;
	}

	&.txt-view{
		height: initial;
		min-height: 50px;
		border: 1px dashed $form-input-border;
	}
	
	&.form-control-o{
		border:0;
		padding:0;
		border-radius:0;
	}

	+ .help-block {
		margin-top: 5px;
		margin-bottom: 0;
	}
	
	&[type='text']:not(.disable-clear):not(.inpHora):not(.inpMinuts):not(.inpFecha):not([maxlength='2']):not([maxlength='4']):not([maxlength='10']):not(.input-xs):not(.input-sm)
	{
		padding-right: 30px;
	}
/*
	.bg-light &{
		border-color: $grey-2;
	}
*/
}


select.form-control {
	padding-right: 20px;
	appearance: none;
	-moz-appearance: none;
	-webkit-appearance: none;
	background: $form-input-background $bgCaretSelect; 
	cursor: pointer;
	
	
	&.inpCuenta {
		background: $bgCaretSelect,
					url(/saas/img/icons/icons/grey/cuenta.svg) no-repeat 4px / 30px,
					$form-input-background;
	}

	&.inpTareaCRM {
		background: $bgCaretSelect,
					url(/saas/img/icons/icons/grey/tareas.svg) no-repeat 4px / 30px,
					$form-input-background;
	}
	&.inpDuracion {
		background: $bgCaretSelect,
					url(/saas/img/icons/icons/grey/duracion.svg) no-repeat 4px / 30px,
					$form-input-background;
	}

	&.inpMail {
		background:$bgCaretSelect,
					url(/saas/img/icons/icons/grey/mail.svg) no-repeat 4px / 30px,
					$form-input-background;
	}
	&.inpTask {
		background: $bgCaretSelect,
					url(/saas/img/icons/icons/grey/tareas.svg) no-repeat 4px / 30px,
					$form-input-background;
	}

	&:invalid {
		color: $grey;

		option { color: $form-input-color; }
	}

	option.separador {
		font-size: 1pt;
		height: 1px;
		margin: 0 15px;
		background-color: $form-input-color;
	}

	option[selected][default] { color: $grey; }
	
	&:disabled{
		background: $form-inputDisabled-background $bgCaretSelectDisabled; 
	}
}

a.form-control {
	display: block;
	color: $blue;

	&:focus,
	&:hover {
		color: $blue1;
		border: 1px solid $form-input-border;
	}
}

textarea.expanding {
	height: 40px;
	max-height: 250px;
	padding-top: 7px;
	padding-bottom: 7px;

	&.input-sm { height: 32px; }

	&.expanding-grey{
		border: 0;
		background-color: $bg-grey;

		&:placeholder-shown {
			background-color: $bg-grey;
			border: none;
		}
	}
}

.expanding-wrapper{
	.expanding-clone{
		word-break: break-word !important;
	}
}

.fake-select{
	@extend .form-control;
	padding-right: 20px;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	background: $form-input-background $bgCaretSelect;
	width: 100%;
	
	&.btn{
		border: 1px solid $form-input-border;
		border-radius: 2px;
		color: $form-input-color;
		
		.inputImg &{
			border-right: 0; 
		}
		
		&:focus { border-color: $form-input-border-focus; }
	}
}


.badgeHelp {
	width: 20px;
	height: 20px;
	float: right;
	cursor: pointer;
	background: transparent url(/saas/img/icons/icons/grey/ayuda.svg) no-repeat 50% 50%;
	background-size: 18px;
	&:empty{
		display: block;
	}
}

.input-group-addon {
	background: $grey-3;
	
	label{
		margin-bottom: 0;
	}
	
	&:last-child {
		border-top-right-radius: 2px;
		border-bottom-right-radius: 2px;
		border-color: $form-input-border;
	}	
}

.input-group-btn .btn { height: 100%; }

.footerTextarea {
	margin-bottom: 15px;

	[class^="col-"] {
		border: 1px solid $form-input-border;
		border-top: 0;

		&:not(:first-child) {
			border-left: 0;

			@media #{$max-xs} { border-left: 1px solid $form-input-border; }
		}
	}

	.form-group { margin-top: 15px; }
}

span.required, label.required  {
	font-size: 14px;
	line-height: 1em;
	font-style: italic;
	color: $form-requiredStar; 
	margin-left: 2px;  
}


.form-error {
	> label { color: $form-error-label !important; }

	> input[type="checkbox"] + label:before,
	> .form-control,
	> .add-clear-span .form-control,
	> .form-control + .input-group-addon.addon-add,
	> .input-group.inputImg > .input-group-addon,
	> .input-group.inputImg > .form-control,
	> .tagit { border-color: $form-error-input-border !important; }

	> .inlineMultiple label.btn-check,
	> .inlineMultiple label.btn-radio

	{ border-color: $form-error-input-border; }
}

.validation-error{
	> label { color: $form-error-label !important; }

	> input[type="checkbox"] + label:before
	{ border-color: $form-error-input-border; }
}

.errorMessage {
	font-weight: 700;
	color: $color-error;
}

.validacion-error,
.validacion {
	display: none;
	padding: 10px 0;
	color: $form-error-label;
	text-align: center;
	
	&.validacion-show{
		display: block;
	}
}

.input-group .unidades { padding-right:5px !important; }
.input-group-addon{
	border: 1px solid $form-input-border;

	&.addon-white { background-color: $form-input-background; }
	&.addon-transparent{
		border:none !important;
		background:transparent !important;
		padding: 0 !important;
	}
}

.tdLabelFormFalta { text-align: left; }

.duration-group,
.hora-group {
	&:before {
		content: ":";
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		width: 4px;
		height: 100%;
		padding: 8px 0;
		z-index: 12;
	}

	&.hover,
	&:hover {
		.form-control {
			border-color: $form-input-border-hover;
			outline: none;

			&.inpHora {
				box-shadow:	7px 0px 0px -1px $form-input-background,
							0px 0px 7px 0px $form-input-shadow-hover;
			}

			&.inpMinuts {
				box-shadow:	-7px 0px 0px -1px $form-input-background,
							0px 0px 7px 0px $form-input-shadow-hover;
			}
		}
	}

	&.focus,
	&:focus {
		.form-control {
			border-color: $form-input-border-focus;
			outline: none;

			&.inpHora {
				box-shadow:	7px 0px 0px -1px $form-input-background,
							0px 0px 7px 0px $form-input-shadow-focus;
			}

			&.inpMinuts {
				box-shadow:	-7px 0px 0px -1px $form-input-background,
							0px 0px 7px 0px $form-input-shadow-focus;
			}
		}
	}

	.inpHora {
		width: 50%;
		border-right: 0;
		text-align: right;
	}

	.inpMinuts {
		position: absolute;
		right: 0;
		width: 50%;
		border-left: 0;
		text-align: left;
	}
}		

.btn-toggle{
	color: $grey-bold;
	background: transparent;
	
	.iconCaret{
		height: 20px;
		margin-left: 5px;
	}
	
	.btn-toggle-text { line-height:35px; }
}

.combo-advanced {
	.btn-group { width:100%; }
	
	img {
		height:18px;
		width: auto;
		margin-right:5px;
	}
				
	.fake-select{
		text-align:left;
		padding-left:15px;
		border-radius: 2px;
		
		span {
			display:none;
			
			&.active {
				display:inline-block;
				
				.propietario,
				.price { display:inline-block; }
			}
			
			&.option-image{
				padding-left:30px;
				background-repeat: no-repeat;
				background-position: 0 center;
				background-size: auto 18px;
				height: 18px;
				//margin-left: -5px;
			}
		}		
	}
	
	.dropdown-menu, .combo-menu {
		width: 100%;
		border-radius: 0;
		box-shadow: none;
		-webkit-box-shadow: none;
		margin-top: 0;
		border-color: $form-input-border;
		border-top: 0;
		padding: 0;
		
		> li {
			padding: 15px;
			overflow: hidden;
			position: relative;
			cursor: pointer;
			border-bottom: 1px solid $form-input-border;
			background-color: $form-input-background;
			color: $text;

			&:last-of-type { border-bottom: 0; }
			
			&.option-image {
				padding: 15px 40px 15px 15px;
				background-repeat: no-repeat;
				background-position: calc(100% - 15px) center;
				background-size: auto 20px;
			}

			&.disabled {
				cursor: initial;
				border-bottom: 0;
				border-top: 1px solid $form-input-border;
			}

			strong { font-weight: 600; }

			&.active,
			&:not(.disabled):hover { 
				background-color: $grey-4; 
				color: black;
				
				.form-control{
					background-color: $form-input-background;
				}
			}
			 
			.bg-light &{
				&.active { 
					background-color: $blue-3; 
					color: black;
				}
				&:not(.disabled):hover { 
					background-color: $blue-4; 
					color: black;
				}				
			}

			.combo-texto {
				display: block;
				margin-left: 25px;
			}
		}
	}
	
	
	&.combo-advanced-radio{
		.dropdown-menu{
			> li {
				display: flex;
				align-items: center;
				
				.radio{
					width: 20px;
				}
				
				.combo-texto {
					margin-left: 10px;
				}	
			}
		}
	}
	
	&.combo-advanced-select{
		.dropdown-menu{
			> li {
				border-bottom: 0;
			}	
			.combo-texto {
				margin-left: 0;
			}	
		}
	}
}

.combo-multiselect{
	.dropdown-menu{
		> li {
			padding: 7px 15px !important;

			&:after{
				content: "";
				width: 20px;
				height: 20px;
				border: 1px solid $grey-2;
				background: white;
				border-radius: 2px;
				position: absolute;
				top: 7px;
				left: 10px;
				color: $grey;
			}
			
			&.active:after{
				content: "\e90a";
				font-family: "icomoon";
				text-align: center;
				vertical-align: middle;
				line-height: 1.3em;
			}
		}
	}
}

.breadcrumb-form {
	display: flex;
	padding-left: 0;
	padding-right: 0;
	background-color: transparent !important;

	li {
		display: block;
		float: left;

		&:not(.bc-edit) {
			@extend .substring;
		}

		&.bc-edit {
			width: 45px;

			&:before { content: none; }

			.btn-edit {
				opacity: 0.7;
				background-size: 18px;
				background-position: 100% 50%;

				&:hover { opacity: 1; }
			}
		}

		p {
			display: initial;
			margin: 0;
			cursor: auto;
			font-size: 14px;

			&:not(.bc-empty) {
				@extend .a;
				cursor: auto;
			}

			&.bc-empty {
				font-style: italic;
				padding-right:1px;
			}
		}

		a { font-size: 14px; }
	}
}

// form helper
$helper-size: 20px;
.helper {
	background-color: $grey-1;
	width: $helper-size;
	height: $helper-size;
	line-height: $helper-size;
	border-radius: 20px;
	text-align:center;
	color: $blue;
	display:block;
	position:absolute;
	right: $helper-size - 5px ;
	top: 10px;

	&::after {
		content: "?";
		position: absolute;
		width: $helper-size;
		font-size: calc($helper-size / 2);
		height: $helper-size;
		margin-left: calc(-1 * $helper-size / 2);
		left: 50%;
		top: 50%;
		margin-top: calc(-1 * $helper-size / 2);
	}
}

// sobreescribe bootstrap
.has-error .popover .help-block { color: $text; }

.has-error .form-control,
.has-error .form-control:focus { border-color: $red; }

.has-success .form-control,
.has-success .form-control:focus { border-color: $green; }

.form-error:not(.form-group){
	display: none;
}

.has-error{
	.form-error{
		font-size: 90%;
		color: $red;
		display: block;
		margin-top: 3px;
	}
}

// time picker default
.form-time-text{
	border: none;
	background: transparent;
}

.form-time-control{

}

input[type="time"] *{
	color: $red;
	background: $red;
}

input[type="time"]::-moz-clear, input[type="time"]::-ms-clear { display: none; }
input[type="time"]::-moz-outer-spin-button,
input[type="time"]::-moz-inner-spin-button,
input[type="time"]::-moz-clear-button,
input[type="time"]::-moz-clear { 
  -moz-appearance: textfield;
  display: none;
  margin: 0;
}

input[type="time"]::-webkit-outer-spin-button,
input[type="time"]::-webkit-inner-spin-button,
input[type="time"]::-webkit-clear-button { 
  -webkit-appearance: none;
  margin: 0;
}

.time-container{
	width:44px;
	display: inline-block;
	overflow: hidden;
	margin-left: 3px;
	margin-right: 5px;

	.form-time-text{
		cursor: pointer;
		&:focus{
			background: $blue-4;

		}
	}
}

.form-time-container{
	@extend .fake-form-control;
	background: white url(/saas/img/icons/icons/blue/clock.svg) no-repeat; background-size: 17px; background-position: calc(100% - 10px); cursor: pointer;
	display: flex;
	align-items: center;
	
	input[type="time"]{
		border-bottom: 1px dotted $blue;
		color: $blue;
	}
}



// aviso en formulario

.form-aviso{
	padding: 5px 10px;
	font-size: 12px;
	margin-top: 4px;
}