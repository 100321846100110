// sobreescribe trumbowyg 
.trumbowyg-box {
	border-color: $form-input-border;
	min-height: 200px;
	
	.trumbowyg-button-pane {
		border-color: $form-input-border;
		background: $grey-4;

		&:after { display: none; }
		
		.trumbowyg-button-group {
			margin-bottom: 0;

			&:after{ background: $form-input-border; }
		}

		button { margin-bottom: 0; }
	}

	.trumbowyg-editor {
		padding: 15px 12px;
		cursor: text;
		min-height: 200px;
		
		.editor-cols{
			display: flex;
			
			.col{
				border: 1px solid $form-input-border;
				width: 100%;
			}
		}
	}

	.form-group & { margin: 0; }
	.form-error & { border-color: $form-error-label; }
}