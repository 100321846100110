/* Colors corporatius Billage */
$corporative-color: #2C64FF !default;
$navbar: $corporative-color !default;  //todo borrar
$navy: #09244C !default;

// SOLUBLE COLORS
// primary
$primary-900: #09244C;
$primary-800: #071E5F; // titols etc navy
$primary-700: #0A2D8F;
$primary-600: #0D3CBF;
$primary-500: #104AEB;
$primary-400: #2C64FF; // blue / corpo
$primary-300: #85A5FF;
$primary-200: #B3C7FF;
$primary-100: #E6ECFF;
$primary-50: #F5F8FF;

// basic
$basic-900: #171A1C;
$basic-800: #363E44;
$basic-700: #57646F;
$basic-600: #7B8B98;
$basic-500: #98A4AE;
$basic-400: #DFE3E6;
$basic-300: #EEF0F1;
$basic-200: #F3F5F6;
$basic-100: #FFFFFF;

// support
$green-700: #1BA74F;
$green-600: #21D363;
$green-500: #48E281;
$green-100: #E9FCF0;

$yellow-700: #F5D200;
$yellow-600: #FFDF1F;
$yellow-500: #FFE650;
$yellow-100: #FFFBE0;

$orange-700: #FA8400;
$orange-600: #FF9824;
$orange-500: #FFB057;
$orange-100: #FFF3E5;

$red-700: #E02A00;
$red-600: #FF4114;
$red-500: #FF6845;
$red-100: #FFEAE6;


//	color-blue
$blue: $corporative-color !default;					// #2C64FF
$blue1: $primary-800 !default;					// #071E5F
$blue2: darken($blue, 15%) !default;				// #003bde
$blue-1: lighten($blue, 10%) !default;			//* #5f89ff
$blue-2: lighten($blue, 20%) !default;				// #92afff
$blue-3: lighten($blue, 35%) !default;				// #dee7ff
$blue-4: lighten($blue, 39%) !default;				// #f3f6ff
$blue-5: lighten($blue, 40%) !default;				// #f8faff

//	color4 --> NAVY
$color4: #09244C !default;

//  color-grey
$grey: #98A4AE !default;							// #98A4AE
$grey1: darken($grey, 10%) !default;				// #7B8B98
$grey2: darken($grey, 25%) !default;				// #57646f
$grey3: darken($grey, 33%) !default;
$grey4: darken($grey, 40%) !default;				// #363e44
$grey-bold: darken($grey, 30%) !default;	//* #666666
$grey-1: lighten($grey, 15%) !default;		//* #c3cad0
$grey-2: lighten($grey, 25%) !default;				// #dfe3e6
$grey-3: #e1e6ea !default;                         // abans: lighten($grey, 30%) !default; // #eef0f1
$grey-4: lighten($grey, 32%) !default;				// #f3f5f6
$grey-5: lighten($grey, 34%) !default;				// #
$grey-s1: saturate($grey, 10%) !default;			// #8fa5b7

$grey-label: #888;

//	color-red
$red: #FF6845 !default;								//	#FF6845
$red2: darken($red, 20%) !default;					//	#DE2A00
$red3: darken($red, 25%) !default;					//	#C42500

//	color-black
$color-black: $grey4 !default;

//	color-postIt
$color-postIt: #fffdd4 !default;

//	color-orange
$color-orange: #FF931E !default;

//	color-yellow
$yellow: #FFE650 !default;

//	color-green
$green: #48E281 !default;
$green2: darken(#48E281, 30%) !default;				// #147D3B

$color-dark: #333 !default;

// color santander (red)
$color-santander: #EA1D25;
$color-santander-light:#ed7b80;


/* ---------- COLORS BASE */
$background: $primary-50  !default; // $grey-4
$text: $grey4 !default;
$text-grey: #686868 !default;
$bg-grey: lighten($grey, 34%) !default; //  gris bloques, textareas, etc
$filter-border: $primary-100 !default; // old: #ebebeb

$color-border: $primary-100 !default; //#d6dadb
$color-input: $grey-bold !default; 
$border-gray-billage: $grey !default;
$color-input-border: $color-border !default;
$color-input-border-hover:$primary-200 !default;

$color-vender: #EB7C6B !default;
$color-vender-active: #c65f4f !default;
$color-venderDark: #d45e4c !default;
$color-organizar: #EDBF29 !default;
$color-organizar-active: #b79116 !default;
$color-cobrar: #68C168 !default;
$color-cobrar-active: #408e40 !default;
$orange: #f7931e !default;
$orange-dark: #ea7e00 !default;


$color-error: #fe4f42 !default;
$color-error-light: lighten($color-error, 15%) !default;
$color-alert: #eb9a14 !default;

/* colors calendari */
$color-tareas: #de52ef !default;
$color-avui: $grey-3 !default; 
$color-golden: #c9c310 !default;
$color-vermell-crm: #eb7c6b !default;
$color-blau-tarea: #66a4c7 !default;
$color-mid-gray: $grey !default;


/* ---------- PAGE COLORS */
$title-color: $primary-800 !default; //$grey4
$title-border: $primary-100 !default;
$subtitle-color: $primary-800 !default;
$subtitle-border: $primary-100 !default;
$summary-background: $blue1 !default;

/* ---------- TABLE COLORS */
//$table-border: $grey-3 !default;
$table-border: $primary-100 !default;
$noResult-title: $blue !default;
$noResult-subtitle: #828282 !default;

/* ---------- TABLE COLORS */
$hover-background: $blue-4 !default;
$hover-border: $blue-2 !default;


/* ---------- BUTTONS COLORS */
//	default
$btn-def-color: $grey2 !default;
$btn-def-background: transparent !default;
$btn-def-hover-color: $grey1 !default;
$btn-def-hover-background: $grey-3 !default;
$btn-def-disabled-color: $grey !default;
$btn-def-disabled-background: $grey-3 !default;
//	primary
$btn-primary-color: white !default;
$btn-primary-background: $blue !default;
$btn-primary-hover-color: white !default;
$btn-primary-hover-background: $blue1 !default;
$btn-primary-disabled-color: $btn-primary-color !default;
$btn-primary-disabled-background: $btn-primary-background !default;
//	secondary
$btn-secondary-color: $blue !default;
$btn-secondary-background: transparent !default;
$btn-secondary-hover-color: lighten($blue, 15%) !default;
$btn-secondary-hover-background: transparent !default;
$btn-secondary-disabled-color: $btn-secondary-color !default;
$btn-secondary-disabled-background: $btn-secondary-background !default;
//	third
$btn-third-color: white !default;
$btn-third-background: black !default;
$btn-third-hover-color: white !default;
$btn-third-hover-background: $grey4 !default;
$btn-third-disabled-color: $grey4 !default;
$btn-third-disabled-background: #54636d !default;
//	fourth
$btn-fourth-color: $grey !default;
$btn-fourth-background: white !default;
$btn-fourth-border-color: $grey-3 !default;
$btn-fourth-hover-color: $grey2 !default;
$btn-fourth-hover-background: $grey-3 !default;
$btn-fourth-disabled-color: $grey-1 !default;
$btn-fourth-disabled-background: $grey-3 !default;

//	cta (rojo)
$btn-cta-color: white !default;
$btn-cta-background: $red !default;
$btn-cta-hover-color: white !default;
$btn-cta-hover-background: darken($btn-cta-background, 20% ) !default;
$btn-cta-disabled-color: $btn-cta-color !default;
$btn-cta-disabled-background: $btn-cta-background !default;

//	gris
$btn-gris-color: $grey-bold !default;
$btn-gris-background: $grey-2 !default;
$btn-gris-hover-color: $grey !default;
$btn-gris-hover-background: $grey-3 !default;
$btn-gris-disabled-color: $grey-bold !default;
$btn-gris-disabled-background: $grey-2 !default;
//	listFilters
$btn-listFilters-color: #b3b3b3 !default;
$btn-listFilters-background:$grey-2 !default;
$btn-listFilters-hover-color: white !default;
$btn-listFilters-hover-background: #3ab5cc !default;
$btn-listFilters-disabled-color: white !default;
$btn-listFilters-disabled-background: $blue !default;
//	listFiltersDeshacer
$btn-listFiltersDeshacer-color: #3ab5cc !default;
$btn-listFiltersDeshacer-background:$grey-2 !default;
$btn-listFiltersDeshacer-hover-color: white !default;
$btn-listFiltersDeshacer-hover-background: #3ab5cc !default;
$btn-listFiltersDeshacer-disabled-color: white !default;
$btn-listFiltersDeshacer-disabled-background: $blue !default;
//	upLoad
$btn-upLoad-color: #D6DADB !default;
$btn-upLoad-border: #D6DADB !default;
$btn-upLoad-background: white !default;
$btn-upLoad-hover-color: #b3b3b3 !default;
$btn-upLoad-hover-border: #b3b3b3 !default;
$btn-upLoad-hover-background: white !default;
$btn-upLoad-disabled-color: $grey-bold !default;
$btn-upLoad-disabled-border: $grey-bold !default;
$btn-upLoad-disabled-background: $grey-2 !default;
//	register
$btn-register-color: white !default;
$btn-register-background: $blue !default;
$btn-register-hover-color: white !default;
$btn-register-hover-background: $blue1 !default;

//	cancel
$btn-cancel-color: white !default;
$btn-cancel-background: $red !default;
$btn-cancel-hover-color: white !default;
$btn-cancel-hover-background: #B74133 !default;

//	defaultDropdown
$dropDown-menu-border: $grey-2 !default;
$dropDown-menu-background: white !default;
$dropDown-line-color: $grey2 !default;
$dropDown-line-background: transparent !default;
$dropDown-line-hover-color: $blue !default;
$dropDown-line-hover-background: transparent !default;
$dropDown-divider: $grey-3 !default;

/* ---------- MENU COLORS */
$menu-border: $grey-3 !default; // anterior $grey-4
$menu-color: $grey1 !default;
$menu-color-hover: $blue !default;
$menu-background: white !default;
$menu-title-color: $grey1 !default;
$menu-tree: $grey-2 !default;
$menu-separador: $grey-3 !default;
$menu-more: $grey1 !default;
$menu-more-hover: $grey2 !default;

/* ---------- FORM COLORS */
$form-input-color: $grey4 !default;
$form-placeholder-color: $grey1 !default;
$form-input-border: $primary-100 !default;
$form-input-border-hover: $primary-200 !default;
$form-input-shadow-hover: rgba($grey, 0.11) !default;
$form-input-border-focus: $blue-2 !default;
$form-input-shadow-focus: rgba($blue, 0.11) !default;
$form-input-background: white !default;
$form-labelDisabled-color: $grey-2 !default;
$form-inputDisabled-color: #828282 !default;
$form-inputDisabled-background: $grey-3 !default;
$form-check-border: #b3b3b3 !default;
$form-checkChecked-border: $blue1 !default;
$form-checkChecked-background: $blue !default;
$form-checkChecked-color: white !default;
$form-checkCheckedDisabled-background: $grey-2 !default;
$form-textareaDark-background: #f1f3f4 !default;
$form-subinfo-color: $grey !default;
$radioButton: #E2E2E2 !default;
$radioButtonHover: $grey !default;
$radioButtonChecked: #828282 !default;
$form-requiredStar: $color-error !default;
$form-error-label: $color-error !default;
$form-error-input-border: $color-error !default;

/* ---------- AUTOCOMPLETE */
$autocomplete-box-color: #CACFD3 !default;
$autocomplete-box-background: #f9f9f9 !default;
$autocomplete-header-color: #777 !default;
$autocomplete-row-color-hover: white !default;
$autocomplete-row-background-hover: #b3b3b3 !default;
$autocomplete-add-color: $blue1 !default;

/* ---------- CALENDAR COLORS */
$calendar-toolbar-dateColor: #3ab5cc !default;
$calendar-headerBackground: $grey-2 !default;
$calendar-headerColor: #595959 !default;
$calendar-borders: $grey-2 !default;
$calendar-dayColor: #595959 !default;
$calendar-todayBackground: $grey-3 !default;

/* ---------- TIMELINE */
$timeline-line: $grey-2 !default;
$timeline-line-icon-border: $grey-1 !default;
$timeline-line-icon-background: white !default;
$timeline-block-color: $grey2 !default;
$timeline-block-border: transparent !default;
$timeline-block-background: $grey-3 !default;

$timeline-block2-color: $timeline-block-color !default;
$timeline-block2-border: $grey-3 !default;
$timeline-block2-background: white !default;

/* ---------- DATE RANGE PICKER */
$daterangepicker-color: $grey2;
$daterangepicker-bg-color: white;
$daterangepicker-ranges-color: $grey2;
$daterangepicker-ranges-bg-color: transparent;
$daterangepicker-ranges-hover-color: $blue;
$daterangepicker-ranges-hover-bg-color: transparent;
$daterangepicker-cell-hover-bg-color: $grey-1;
$daterangepicker-cell-hover-color: white;
$daterangepicker-unselected-color: $grey;
$daterangepicker-in-range-bg-color: $grey-2;
$daterangepicker-in-range-color: $grey2;
$daterangepicker-active-bg-color: $blue-2;