.project-container{
	//margin-bottom: 30px;
	padding: 15px 0 5px;
}

.tasks-list {
	.list-ordered {
		ul{
			background: white;
		}
	}
	
	.icon-drag {
		display: none;
		position: absolute;
		top: 0;
		left: -32px;
		width: 15px;
		height: 100%;
		margin-left: 7.5px;
		background: transparent url(/saas/img/icons/icons/grey/burger.svg);
		background-repeat: no-repeat;
		background-position: center;
		background-size: 100%;
		cursor: move;
	}

	.row-project-title {
		padding: 7px 0 0 0;
		overflow: hidden;
		color: $grey4;
		display: flex;
		align-items: center;
		margin-bottom: 10px;
		
		.link-plus {
			color: $text;
			text-transform: uppercase;
			opacity: 0.6;
			display: flex;
			align-items: center;
			font-size: 15px;
			background: url(/saas/img/icons/icons/blue/add.svg) no-repeat 5px 50%;
			background-size: 15px auto;
			padding-left:25px;
		}

		&:hover{
			.link-plus{
				opacity: 1;
				color: $blue;
				background-image: url(/saas/img/icons/icons/grey/add.svg);
			}
		}
	}

	.project-title {
		width: auto;
		margin-top: 0;
		margin-bottom: 0;
		float: left;
		display: flex;
		align-items: center;
		font-weight: 700;
		font-size: 20px;
		color: $blue1;

		& > * { color: inherit; }
	}

	.project-task-list {
		margin-bottom: 10px;
	}

	.project-task-list-container {
		//margin-bottom: 25px;
		
		.container-fluid {
			padding-right: 30px;
			padding-left: 30px;
		}

		.list-line-placeholder {
			border: 2px dashed $blue;
			background-color: $blue-4; 
		}

		.ui-draggable-helper-list {
			background-color: white;
		}
	}
	
	.project-task-list-title {
		position: relative;
		padding: 5px 0;
		border: 1px solid transparent;
		display: flex;
		justify-content: space-between;

		&:before{
			content: '';
			width: 30px;
			height: 37px;
			background: transparent;
			display: block;
			position: absolute;
			left:-30px;
			top: 0;
		}

		.project-task-list-title-text { 
			margin: 0; 
			display: flex;
			justify-content: space-between;
			align-items: center;
			text-transform: uppercase;
			font-weight: 400;
			font-size: 16px;
			color:$grey4;
		}

		.dropdown {
			z-index: 5;

			.btn-more{
				opacity: 0.7;
				line-height: 1em;
				height: 20px;
			}

			.menu-desplegable{
				top:15px;
				left: -30px;
			}
		}

		&:hover,
		&:focus {
			.dropdown {
				//display: block;
				.btn-more{
					opacity: 1;
				}
			}
			&:not(.unsortable){
				.icon-drag { display: block; }
			}
		}
	}
	
	.listado-progress{
		margin-right:0;
		font-size: 13px;
		color: $color-black;
	}
	
	.popover-usuarios{
		right: 0 !important;
		left: auto !important;
		
		.arrow{
			right: 45px;
			left: auto !important;
		}
	}
}


.ul-project-task {
	&.sortable {
		min-height: 5px;
		margin-bottom: 0;
		z-index: 0;

		.line-placeholder {
			width: 100%;
			min-height: 45px;
			border: 2px dashed $blue;
			background-color: $blue-4; 
			transition: 0.3s;
		}
	}
}	 

.li-project-task {
	position: relative;
	padding: 6px 30px 6px 10px;
	border: 1px solid transparent;
	box-shadow: 0 2px 2px 0 rgba(13,10,44,.05);
	color: $text;
	list-style: none;
	background: white;
	margin-bottom:4px;

	&:before{
		content: '';
		width: 30px;
		height: 37px;
		background: transparent;
		display: block;
		position: absolute;
		left:-30px;
		top: 0;
	}

	&:first-child{
		margin-top: 0;
	}

	&:hover,
	&:focus {
		//border-top: 1px solid;
		border-color: $hover-border;
		background-color: $hover-background;
		z-index: 2;

		.icon-drag { display: block; }

		.li-project-container {
			border-top: 0;

			.dropdown { display: block; }
		}
	}

	&.ui-sortable-helper {
		width: 100% !important;
		height: initial !important;
		background: white;
		border: 1px solid $grey-3;
	}
	.popover-horas-content { width: 150px; }
	
	&.time-order { 
		padding-left: 15px; 
		background: white;
	}
	
	.task-name {
		display: flex;
		align-items: center;			
	}
	
	.btn-menu-usuario{
		margin-left: 10px;
		
		.avatar-image{
			display: flex;
		}
	}
	
	.user-select .avatar { 
		margin-right: 5px;
	}
	
	.project-name {
		cursor: pointer;
		font-weight: 600;
	}
	.project-task {
		position: relative;
		width: calc(100% - 130px);
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	
	.project-alias{
		margin-left: 15px;
		
		.img-icon{
			margin-right: 2px;
		}
	}

	.fecha-fin { 
		min-width: 80px;
		margin-left:15px;
		line-height: 1.2em;
		
		.fecha-fin-label{
			display: block;
			font-size: 13px;
			color: $grey2;
		}
		
		.data-time-trigger{
			cursor: pointer; 
			color: $blue;
			border-bottom: 1px solid $blue;
			
			&:hover{
				opacity: 0.8;
			}
		}
	}
		
	.task-progress-container{
		width: 100px;
		margin-left: 15px;
	}
	
	.state-container{
		display: flex;
		align-items: center;
		margin-left: 15px;
		min-width: 95px;
		
		.state{
			width: 100%;
			height: 25px;
		}
	}
}



.li-project-container {
	display: flex;
	align-items: center;
	justify-content: space-between;
	
	.dropdown {
		display: none;
		position: absolute;
		right: 0;
		top: 50%;
		transform: translateY(-50%);
		z-index: 1;

		.dropdown-menu { overflow: inherit; }
	}
}

.ul-project-recurring-task{
	margin-bottom: 30px;
	
	.li-project-task {
		padding-left: 0;
		
		.project-name{
			padding-left: 15px;
		}		
		
		.status{
			min-width: 65px;
			display: inline-block;
		}
		
		&:first-of-type{
			border-top: 1px solid $grey-3;
			&:hover,
			&:focus {
				border-color: $blue-2;
			}
		}
	}
	
	.project-task{
		display: flex;
		justify-content: space-between;
		
		span{
			text-align: center;
			padding-left:10px;
		}
		
		@media #{$max-md} {
			flex-direction: column;
			
			span{
				text-align: left;
				&:first-child{
					padding-left:0;
				}
			}
		}		
		@media #{$max-xs} {		
			span{
				display: block;
				padding-left:0;
			}
		}		
	}	
	
	.btn-menu-usuario{
		margin-left: 10px;
	}

}		


.task-add-quick {
	width: 100%;
	padding: 5px 15px 5px 20px;
	border: 0;
	font-size:15px;
	color: black; //$text
	background: transparent url(/saas/img/icons/icons/blue/add.svg) no-repeat 0 center;
	background-size: auto 15px;

	&::-webkit-input-placeholder,
	&::-moz-placeholder,
	&:-moz-placeholder,
	&:-ms-input-placeholder,
	&::placeholder { color: $grey2 !important; }	
}

.task-add-quick::placeholder, .task-add-quick::-webkit-input-placeholder  { opacity: 0.9;}

.task-list-mobil {
	.tasks-list { width: 100%; }
}



#divtasks{
	padding-top: 75px;

	.table-no-list{
		padding-top: 30px;
	}
}

#datosTareaProyecto{
	.tab-pane{
		position: relative;
	}
	
	.tab-header{
		position: absolute;
		right: 0;	
		margin-top: 25px;
		
		.btn-add{
			padding-right: 0;
		}
	}

	.tab-content{
		padding-left: 25px;
		margin-left: -25px;
	}

	#divtasks{
		padding-top: 0;
	}

	.li-project-task {
		background: $grey-4;
		box-shadow: none;
	}
}

.tab-pane {	
	.tasks-list {
		width: 100%;

		.page-content {
			padding: 0;
		}
	}

}

.ul-project-task{
	margin-bottom: 0;
}


.project-task{
	.list-tags{
		margin-right: 10px;

		.tag-more { padding: 0; }
	}
	
	.project-priority{
		position:relative;
		display: inline-block;
		margin-left:5px;
	}
	.project-traking{
		display: inline-block;

		img{ height: 26px; }
	}
	
	.project-details{
		padding-top:5px;
		float:left;
		width: calc(100% - 30px);
	}
	
	.project-comments{
		font-size: 13px;
		color: $grey1;
		padding-right: 5px;
	}
	
	.task-details{
		display: flex;
		align-items: center;
		justify-content: flex-end;
	}
	
	.project-time{
		display: flex;
		align-items: center;
		justify-content: flex-end;
		margin-left:5px;
		position:relative;
		color: $grey1;
		font-size: 14px;
		
		.img-icon{
			position:relative;
			margin-bottom: 2px;
		}
		
		.img-icon-calendar { margin-right: 2px; }
	}
}



#divNuevaTarea {
	width: 600px;

	.row-bloques{
		display: block;
	}
}

#divEditTarea {
	width: 95%;
	max-width: 1000px;

	.bloque.bg-light, .tab-pane { min-height: 400px; }

	.nav-vertical-tabs .vertical-content .tab-content { overflow: initial; }

	.addComment { margin-bottom:0 !important; }

	.registerTable {
		.reg-titulo {
			margin-top: 0;
			margin-bottom: 15px;
			font-weight: 700;
			color: $primary-800;
		}

		.reg { 
			border-bottom: 1px solid $grey-2;
			padding: 10px 0;

			&:first-of-type { border-top: 1px solid $grey-2; }
		}
		
		p { margin: 0; }

		pre {
			padding: 0;
			border: 0;
			margin: 0;
			background-color: transparent;
			white-space: pre-wrap;
		}
	}
	
	.tareas-edit-estados .estado {
		margin-right: 7px;

		&:last-child { margin-right: 0; }
	}
	
	.tareas-edit-etiquetas{
		.ui-autocomplete-input { height:auto !important; }
	}
}

#divTareaRepetitiva{
	width: 95%;
	max-width: 900px;
	
	.bloque.bg-light { min-height: 330px; }
}


#verTodosProyectos{
	padding: 10px 15px;
	display: block;
}


/* selección de usuario */
.btn-menu-usuario {
	background: none;
	border: none;
	padding:0;
}

.avatar.updating{
	&:after{
		content:"";
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		border-radius: 50%;
		background-color: rgba($grey, 0.7);		
	}

	.spinner {
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);

		&:before {
			top: 0;
			left: 0;
			transform: unset;
		}
	}
}




.page.list > .tasks-list{
	.list-workspace{
		padding-top: 70px;
	}

}