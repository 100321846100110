.block-header {
	margin: 0;
	margin-top: 15px;
	padding-bottom: 15px;
}

.title {
	display: inline-block;
	max-width: 560px;
	font-weight: 600;
	color: $primary-800;
	
	.estado {
		margin-left: 10px;
		vertical-align: middle;
		cursor: initial;

		&.pointer { cursor: pointer; }
	}
}

.bg-full{
	position: relative;

	&:before {
		content: '';
		position: absolute;
		top: 0;
		left: 50%;
		transform: translateX( -50%);
		height: 100%;
		width: 100vw;
		background: $primary-50;
		z-index: -1;
	}
}

.page-header {
	@extend .clearfix;

	padding-top: 30px;
	min-height: 64px;
	border-bottom: 0;
	margin-top: 0;
	margin-bottom: 10px;

	@media #{$max-xs} {
		&:not(&.page-header-mobile){
			padding-top: 15px;
			padding-bottom: 10px;
		}
	}

	.group-title{
		display: flex;

		.title{
			margin-right: 5px;
		}

		.title-mobile{
			display: none;

			@media #{$max-sm} {
				display: block;
			}
		}
		.estado {
			margin-left: 10px;
			display: flex;
			align-items: center;
			cursor: initial;

			&.pointer { cursor: pointer; }
		}
	}
		
	h1, .group-title {
		max-width: 800px;
		margin: 0;
		font-size: 24px;
		font-weight: 600;
		line-height: 1.3em; 

		.dropdown.open {
			position: relative;
			
			.arrow {
				position: absolute;
				display: block;
				width: 0;
				height: 0;
				border-color: transparent;
				border-style: solid;
				top: 12px;
				border-width:11px;
				left: 8px;
				margin-left: -11px;
				border-top-width: 0;
				border-bottom-color: #d9d9d9;
				z-index: 76;
				
				&:after {
					position: absolute;
					color: transparent;
					display: block;
					width: 0;
					height: 0;
					border-style: solid;
					border-width: 10px;
					top: 1px;
					margin-left: -10px;
					content: "";
					border-top-width: 0;
					border-bottom-color: #fff;
				}
			}
			
			.dropdown-menu {
				margin-top: 10px;
				margin-left: -25px;
			}
		}
		
		.dropdown-menu .active {
			color: $blue;
			cursor: initial;
		}

		.btn-select-tabs { padding: 0 5px; }
	}

	.main-row{ // nuevo, sustituto first-line
		display: flex;
		justify-content: space-between;

		> * { align-self: center; } //flex-end

		.filters-list { margin-right: auto; }
	}
	
	.head-col{
		display: flex;
		justify-content: flex-start;
		align-self: center; 
	}
	
	.head-summary{
		color: $grey;
		margin-left: 15px;
		margin-right: 15px;
		font-size: 24px;
		font-weight: 400;	
		line-height: 1.3em;	
	}
	
	.first-line {
		display: flex;
		justify-content: space-between;
		margin-bottom: 10px;

		> * { align-self: center; } //flex-end

		h1 {
			margin-top: 0;
			margin-bottom: 0;
		}

		@media #{$max-xs} { margin-bottom: 15px; }
	}

	.second-line {
		display: flex;
		justify-content: space-between;

		.filters-list { margin-right: auto; }
	}

	.view-mode {
		margin-bottom: 6px;
		margin-right: 7px;
		min-width: 66px;

		.btn {
			display: flex;
			height: 34px;
			max-width: 36px;
			align-items: center;
			justify-content: center;
			background-color: white;
			z-index: initial;
			border-radius: 2px;

			&.active{
				background-color: $primary-50;
				outline: 4px solid #fff !important;
				outline-offset: -5px;
			}
		}
		
		.btn-icon{
			width: 34px;
			border:1px solid $grey-3;
			margin-right:-1px;
			padding: 0;

			&:last-child{
				margin-right: 0;
			}

			img{
				width: 14px;
				height: auto;
			}

			&.active{
				img{
					width: 12px;
				}
			}
		}
	}

	.page-actions {
		align-self: flex-start;
		text-align: right;
		white-space: nowrap;

		.btn {
			margin-left: 5px;
			height: 34px;
			padding-top: 4px;
			padding-bottom: 4px;

			&.btn-dropmore{
				margin-left: 0;
			}
		}

		@media #{$max-xs} {
			min-width: auto;
			&.actions-sm,
			&.actions-lg { min-width: 35px; }
		}
	}

	.headerButtons {
		@extend .clearfix;

		.dropdown,
		.btn {
			margin-right: 5px;

			&:last-child { margin-right: 0; }
		}

		.dropdown .btn { margin-right: 0; }

		.btn-link {
			+ .dropdown {
				margin-left: 20px;

				&:before {
					content: "";
					position: absolute;
					top: 50%;
					left: -13px;
					width: 1px;
					height: 60%;
					transform: translateY(-50%);
					background-color: $grey-bold;
				}
			}
		}

		.btn-icon {
			padding: 16px;
			background-size: 60%;
			background-color: white;

			&.btn-list { background-image: url('/saas/img/icons/filters/list.svg'); }
			&.btn-funnel { background-image: url('/saas/img/icons/filters/kanban.svg'); }
		}
	}

	&.page-header-bordered{
		border-bottom: 1px solid $title-border !important;
		margin-bottom: 20px;	
	}

	.title-mobile{
		visibility: hidden;

		@media #{$max-sm} {
			visibility: visible;
		}
	}

	&.page-header-fixed{
		background: white;
		position: fixed;
		width: 100%;
		z-index: 76; //10
		//box-shadow: 0 4px 4px 0 rgba(13,10,44,.04);
		border-bottom: 1px solid $grey-3;
		padding-top: 15px;
		margin-left: -30px;
		padding-left: 30px;
		padding-right: 30px;
		transition: width 0.1s linear;
		top: 50px;

		.user-trial &{
			top: 95px;
		}

		@media #{$max-sm} {
			margin-left: -15px;
			padding-left: 15px;
			padding-right: 15px;

			.main-menu.toggled.ajustes &{
				width: calc(100% - 500px);
				left: 265px;
			}
		}
		@media #{$min-sm} {
			.main-menu.ajustes:not(.toggled) &{
				width: calc(100% - 250px); // $menu-width no lo coge
				left: 0;
			}
			.main-menu.toggled.ajustes &{
				width: calc(100% - 500px);
				left: 280px;
			}
		}
		@media #{$min-xs} {
			top: 65px;

			.user-trial &{
				top: 106px;
			}

			.main-menu.toggled:not(.ajustes) &{
				width: calc(100% - 250px);
				right: 0;
			}
		}
	}

	&.page-header-mobile {
		visibility: hidden;
		margin-top: 15px;


		@media #{$max-sm} {
			visibility: visible;
			margin-top: 45px;

			.user-trial &{
				margin-top: 65px;
			}
		}
	}

	&.page-header-hidden {
		.title-mobile{
			display: none;

			@media #{$max-sm} {
				display: block;
			}
		}

		@media #{$min-sm} {
			min-height: auto;
			padding-top: 15px;
		}
	}
}

.page {
	height: 100%;

	.page-content {
		&.page-content-divided{
			padding: 0;

			.row-bloques { margin: 0; }
			.bloque { padding:40px 30px; }
		}

		.nav-vertical-tabs { margin: -30px; }
		
		.top-actions{
			text-align: right;
			margin-top: -10px;
			margin-bottom: 30px;
		}

		&.page-content-flex-between{
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			min-height: calc(100vh - 120px);
		}
	}

	.caja {
		background: white;
		padding: 30px;
		margin-top: 15px;

		@media #{$max-xs} {
			padding: 10px;
			padding-left: 10px;
			padding-right: 10px;
			margin-top: 5px;
		}

		h2 { margin-top: 0; }

		p { margin: 0 0 20px 0; }
		
		@media #{$max-xs} { 
			padding-left: 15px;	
			padding-right: 15px;
		}		
	}
}

.page-narrow {
	max-width: 800px;
	margin: 0 auto;
}
