#dgris {
    position: fixed;
    top: 0px;
    left: 0px;
    display: none;
    z-index: 103;
    height: 100%;
	width: 100%;
    background-color: #999; //#505e6c
    opacity: 0.40;
    filter: alpha(opacity =   40);
}

#popup-xxs {
    width: 25%;
    min-width: 250px;
    max-width: 300px;
}

#popup-xs {
	width: 30%;
	max-width: 400px;
	min-width: 300px;
}

#popup-sm {
	width: 45%;
	max-width: 600px;
	min-width: 300px;
}

#popup-md {
	width: 55%;
	max-width: 800px;
	min-width: 400px;
}

#popup-lg {
	width: 60%;
	max-width: 1050px;
	min-width: 400px;
}

#popup-xl {
	width: 90%;
	max-width: 1200px;
	min-width: 400px;
}

.imgPerfilPopup {
	padding: 3px;
	border: 1px solid #D7D5D5;
	border-bottom: 1px solid #cacaca;
	border-right: 1px solid #cacaca;
	background-color: #FFFFFF;
}

#divResetPassword { width: 600px; }
#tableDireccionesEntrega {
	display: none;
	margin-bottom: 10px;
}

.tdLabelFormPopup{
	color: #6c6c6c;
	text-align: left;
	padding: 4px;
	width: 130px;   
	font-size: 14px; 
	font-weight: 700;
}

.divErrorUnsubscribe {
	color: #A42738;
	font-weight: 700;
	text-align: center;
	margin-top: 30px;
}

.divErrorPopup {
	color: #A42738;
	font-weight: 700;
	text-align: center;
	margin-top: 30px;
}

.popup {
	position: absolute;
	display: none;
	cursor: default;
	background-color: white;
	border-radius: 0;
	width: 850px;
	max-width: calc(100% - 10px);
	z-index: 110; 
	box-shadow: rgba(10, 10, 10, 0.2) 0px 2px 5px 0px;
	//top: 10px !important; 
	
	.popup-header {
		margin-bottom: 15px;
		position:relative; 
		cursor: move;

		.popup-title {
			padding: 20px 50px 15px 30px;
			//border-bottom: 1px solid $grey-3;
			font-size: 26px;
			color: $title-color;
			line-height: 1em;
			
		 	@media #{$max-xs} { 
		 		padding: 15px 50px 15px 15px;
		 		font-size: 24px;
		 	}
		}

		.pickColor-wrap.bucket-picker {
			float: right;
			margin-right: 50px;
			margin-top:8px;
		}

		.popup-btn-close {
			position: absolute;
			top: 15px;
			right: 25px;
			padding: 0;
			z-index: 120;
			img {
				height: 15px;
				width: auto;
			}

			@media #{$max-xs} { right: 15px; }
		}
	}

	.popup-content {
		position: relative;
		padding: 0 30px;
		
		@media #{$max-xs} { padding: 0 15px; }
		
		&.popup-content-divided{ margin-top:-15px; }
		
		&.popup-content-tabs { padding: 0; }

		.title{
			font-size: 20px;
			font-weight: 700;
			margin-top: 0;
			margin-bottom: 7px;
		}

		.subtitle {
			border-bottom: 1px solid $table-border;
			margin: 10px 0 20px;
			color: $title-color;
			padding-bottom: 7px;
			font-weight:600;
			
			&.subtitle-sm {
				font-size: 20px;
			}

			+ .help-block { margin-top: 0; }
		}
		
		.subtitle2{
			color: $subtitle-color;
			font-weight: 600;
			font-size: 15px;
			margin-bottom: 0;
		}
		
		.alert-header { margin-bottom:20px; }
	}		
	
	.popup-footer,
	.fake-footer {
		padding: 0 30px;
		margin: 25px 0;
		text-align: left;

		.btn-submit {
			margin-bottom: 0;

			@media #{$max-xxs} { padding: 10px 15px; }
		}

		&.acenter { text-align: center; }
		
		.btn .fa-angle-right{
			margin-left: 5px;
		}

		@media #{$max-xs} { padding: 0 15px; }
	}

	.fake-footer {
		padding: 0;

		&:not(.fake-footer-visible) {
			@media #{$max-sm} { display: none; }
		}
	}

	.popup-footer-link { padding:5px 15px; }

	.popup-alert {
		position: absolute;
		top: 0;
		left: 50%;
		transform: translateX(-50%);
		width: 30%;
		min-width: 230px;
		max-width: 100%;
		border: 0;
		border-bottom: 5px solid grey;
		border-radius: 0;
		background-color: #eee;
		box-shadow: 0px 2px 4px 0px #eeeeee;
		z-index: 4;
		
		.btn-secondary { color: $grey; }
	}

	.row-bloques {
		margin-left: -30px;
		margin-right: -30px;
		display: flex;

		.bloque {
			padding-left: 30px;
			padding-right: 30px;
			
			&:first-of-type, &:nth-of-type(2n){
				padding-top: 15px;
			}

			.subtitle {
				padding-bottom: 0;
				border-bottom: 0;
				margin-top: 0;
				color: $primary-800;
			} 
		}

		.row-bloques {
			display: block;

			.bloque { padding-top: 0; }
		}

		@media #{$max-xs} {
			margin-left: -15px;
			margin-right: -15px;

			.bloque {
				padding-left: 15px;
				padding-right: 15px;
			}
		}
	}

	.form-group {
		margin-bottom: 20px;
		 
		&.form-disabled label{ color: #ccc !important; }

		@media #{$max-xs} { margin-bottom: 15px; }
	} 
			
	.form-group-descripcion { padding-top: 10px; }

	.ui-autocomplete{ 
		top: 66px;
		
		> li {  border: 0; }
	}
	
	.bloque-ayuda{
		color: #888;
		margin-bottom:15px;
	}

	.table-lst{
		background:#fff;
		
		&.table > thead > tr > th, &.table > thead > tr > td, &.table > tbody > tr > td{
			border:0;
			padding:6px 8px;
		}
		 
		&.table > thead > tr > th { border-bottom: 1px solid $primary-100; }

		th {
			background:#fff; 
			text-transform:initial;
			border-radius:0;
			font-size:15px;
		}

		td { border:0; }

		&>tbody>tr:nth-child(even)>td,
		&>tbody>tr:nth-child(even)>th { background-color: #fff; }		
	}	
	
	.nav-vertical-tabs:not(.smallTabs){ 
		.vertical-content{
			width: calc(100% - 120px);
			float: left;
			
			> .tab-content{
				border:none;
				padding: 0 30px;
			}
		}
		
		.vertical-tabs{
			width: 120px;
			float:left;
			
			.nav-tabs li:last-child { margin-bottom: 0;}
		}
	}

	ul.tagit {
		padding-top:5px;
		padding-bottom:5px;
	}
	
	.popup-footer-flex{
		width: 100%;
		display: flex;
		justify-content: space-between;
	}	
	
	.divHelp{
		text-align:left;
		margin-top:20px;
		margin-bottom:20px;
	}
	@media #{$max-sm} { 
		left: 5px !important;
		width: calc(100% - 10px);
	}	
	@media #{$max-xs} { 
		height: 100%;
	}
}


.popup-opened {
	overflow: hidden;

	.popup {
		@media screen and (max-width: 320px) { max-width: 100%; }
	}
}

@media #{$max-xs} { 
	.popup:not(#divConfirmacionAccion) {
	    position: fixed;
	    top: 5px !important;
	    left: 5px;
	    width: calc(100% - 10px)!important;
	    min-width: calc(100% - 10px)!important;
	    max-width: calc(100% - 10px)!important;
	    max-height: calc(100% - 10px);
	    border-radius:0;
	    border: none;
		overflow: auto;
		scrollbar-width: thin;
		scrollbar-color: $grey-1 $grey-3; // 1 barra 2 fondo (firefox)
	}
}

.curtain {
	display: none;

	&.open {
		display: block;
		position: absolute;
		top: 57px;
		left: 0;
		height: calc(100% - 57px);
		width: 100%;
		overflow: hidden;
		background-color: transparent;

		.curtain-wrapper { box-shadow: 10px 0px 20px 8px rgba(0, 0, 0, 0.3); }
	}

	.curtain-wrapper {
		position: absolute;
		top: 0;
		left: 100%;
		max-width: 100%;
		min-width: 25%;
		width: 350px;
		height: 100%;
		padding: 30px;
		background-color: $bg-grey;
		transition: all 2s;

		&.slideRight {
			right: 0;
			left: auto;
		}

		.curtain-container {
			max-height: calc(100% - 32px);
			overflow-y: scroll;
			scrollbar-width: thin;
			scrollbar-color: $grey-1 $grey-3; // 1 barra 2 fondo (firefox)			
		}

		.curtain-footer {
			position: absolute;
			bottom: 25px;
			width: 100%;
			padding: 0 30px;
			margin: 0 -30px;

			.btn { float: left; }
			.validacion { padding: 5px 0; }
		}
	}
}

#divNewCampaign {
	width: 30%;
	width: 440px;
}



/* POPUP MENSAJE CONFIRMACION */
.popup-mensaje{
	opacity:0;
	z-index: 115;
	border-radius: 2px;
	box-shadow: rgba(10, 10, 10, 0.2) 0 2px 6px 4px;
	top: 0px;
	width: 400px;
	padding: 15px 20px 15px 65px;
	min-height: 60px;
	left: calc(50% - 200px);	
	background: #363E44;
	
	@media #{$max-xs} { 
		left: 5px;
		width: calc(100% - 10px); 
	}
		
	.popup-mensaje-texto{
		color: white;
		position: relative;
		font-size: 17px;
		min-height: 40px;
		display: flex;
		align-items: center;
			
		&:before{
			position: absolute;
			left: -50px;
			width: 40px;
			height: 40px;
			content: "\e900";
			font-family: "icomoon";
			background: $green;
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 50%;
			font-size: 24px;
		}
	}		
	
	&.popup-mensaje-confirmacion{
		.popup-mensaje-texto{
			&:before{
				content: "\e900";
				background: $green;
			}	
		}	
		
		@media #{$max-xs} { 
			height: auto !important;
		}
	}
	
	&.popup-mensaje-alerta{
		.popup-mensaje-texto{
			&:before{
				content: "\e90d";
				background: $red;
			}
		}
	}
}

#divNuevoET {
	width: 900px;
	
	#tableMiembrosProyecto {
		.checkbox {
			width: auto;

			label { padding-left: 0; }
		} 		
	}
	
	.combo-advanced .combo-texto .input-group { margin-top: 10px; }
	
	.error-tarifa {
		margin-top:10px;
		color: #828284;
		font-size:85%;
	}	
}

#divNuevoGasto { width: 800px; }
#divNuevoGastoSwitch {width: 600px;}  

#divNewDoc {
	width: 860px;

	.combo-advanced {
		.item1 { background-image: url("/saas/img/icons/icons/blue/documentos.svg"); }
		.item2 { background-image: url("/saas/img/icons/icons/blue/cuenta.svg"); }
		.item3 { background-image: url("/saas/img/icons/icons/blue/oportunidad.svg"); }
		.item4 { background-image: url("/saas/img/icons/icons/blue/producto.svg"); }
		.item5 { background-image: url("/saas/img/icons/icons/blue/servicio.svg"); }
		.item6 { background-image: url("/saas/img/icons/icons/blue/contrato.svg"); }
		.item7 { background-image: url("/saas/img/icons/icons/blue/proyecto.svg"); }	
	}

	.vinculado {
		display: none;

		&.active { display:block; }
	}
	
	.multi-seleccionable{
		max-height: 450px;
		overflow-y: auto;
		margin-right: -30px;
		padding-right: 30px;
		margin-bottom: 30px;
		scrollbar-width: thin;
		scrollbar-color: $grey-1 $grey-3; // 1 barra 2 fondo (firefox)	
	}
}

#popup-tracking .popover-addET .divAutocomplete { max-height: 175px; }

#divTimeReport {
	width: 650px;
	
	.form-inline .radio {
		margin-left: -17px;
		margin-right: 5px;
		
		//.label { padding-left: 30px; }
	}
	
	.form-inline .label-inline:first-of-type { padding-left: 5px; }
	
	.btn-estado { font-size: 14px; }
}

#divUserPassword,
#divProjectsReport { width:450px; }

#divPlantillaEmail .ayuda-envios-content { display: none; }

#divContratarDatos,
#divNewContract {
	width: 940px;
	min-height: 450px;


	.curtain .dropzone.dz-docs { background-color: white; }
}

#divEditEmpresa,
#divServidorEmail,
#divQuickET,
#divGrupoCuentas { width: 900px; }

#divServidorEmail{
	.bg-light{
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		padding-bottom: 25px;
	}
}

#divNuevoContacto,
#divNuevoUsuario {
	width: 950px;

	.user-avatar {
		display: inline-block;
		width: 100%;

		.avatar { float: left; }

		.avatar-remove {
			display: block;
			padding-top: 25px;
			padding-left: 150px;
			text-align: center;
		}
	}

	.media { margin-top: 0; }

	.fake-footer { margin-top:15px; }

	@media #{$min-sm} {
		.col-activo{
			margin-top: -45px;

			.form-group{
				margin-bottom:0;
			}
		}
	}

}

#exportXLS,
#div-generar-factura { width: 500px; }

#dpopup_imagen_perfil,
.dpopupsubirimagen { width: 520px; }

#divEditEmpresa {
	.company-logo {
		position: relative;

		img {
			max-height: 124px;
			max-width: 100%;
			margin-bottom: 6px;
			padding-right: 30px;
		}

		.btn-remove {
			position: absolute;
			top: 0;
			right: 0;
		}
	}
	.media { margin-top: 0; }
	
	.lista-multiempresa{
		margin-top: 20px;
		li{
			margin-bottom: 10px;
			
			a{
				display: block;
				&:hover{
					color: $grey-1;
				}
			}
			
			.empresa-nombre{
				display: block;
			}
		}
	}
}

#divNuevoNegocio{
	width: 500px;
}

#divTimeTrackingAuto{
	width: 650px;
	
	.popup-content{
		.icon{
			margin-right: 10px;
		}
	}
	
	.btn{
		height: 34px;
		min-width: 100px;
	}
	
	#btn_timetracking-stop, #btn_timetracking-continue{
		.i-icon{
			padding-top: 5px;
			padding-bottom: 5px;
		}
	}
	
	#btn_timetracking-discard{
		.i-icon{
			height: 20px;
		}
	}
	
	.btn .i-icon{
		margin-right: 4px;
	}
}

#divNuevoTiempo {
	width: 950px;
	min-height: 425px;
	
	.form-group.form-disabled .addon-add { display: none; }

	.btn-hora-fin {
		&.active {
			.remove-fin{ display: block; }
			.add-fin{ display: none; }
		}

		.remove-fin { display: none; }
	}
}

#divNotas {
	width: 600px;

	#usuarioNota{
		padding: 0;

		.avatar span { margin-right: 10px; }
	}

	.nota-info {
		border-top: 1px solid $table-border;
		border-bottom: 1px solid $table-border;
		padding: 20px 0;

		.avatar { margin-right: 5px; }

		a { font-weight: 600; }

		aside {
			display: flex;
			margin-top: 12px;
			justify-content: space-between;
			color: $grey1;
		}

		.documento-fecha { font-size: 14px; }

		.documento-nota {
			text-align: right;

			a {
				color: $grey2;
				font-weight: normal;
			}
		}
	}
}

#div-generar-rectificativa { width: 380px; }

#divConceptosAlbaranes { width: 870px; }

#destinationPopup,
#gotoPopup { width: 500px; }


#divLostReason { width: 600px; }

#div-popup-cambiar-estado { width: 485px; }

#divNuevoClientePopup,
#divNuevaCampanaPopup { width: 480px; }

#popupImportHelp,
#divFirstLogin { width: 1095px; }

.dpopupsubirimagen,
.dpopupsubirlogo,
.dpopupsubirgasto { width: 520px;}

#divEditGrupo {
	width: 455px;
	height: 250px;
}

#divNuevoMiembro { width: 370px; }

#divEditMilestone {
	width: 680px;
	min-height: 270px;
}

#divClonarProyecto{
	width: 820px;
	
	.combo-advanced{
		li{		
			.combo-texto{
				margin-left: 0;
			}
		}
	}
	
	.row-fechas{
		margin-bottom:5px;
		.col1{
			padding-right:5px;
		}
		.col2{
			padding-left:5px;
		}		
	}
}
#dEliminarElemento{
	width: 500px;

	.popup-content{
		background: url("/saas/img/icons/icons/blue/delete.svg") no-repeat 30px 50%;
		background-size: 40px auto;
		padding: 10px 30px 15px 100px;
		display: flex;
		//align-items: center;
		justify-content: center;
		flex-direction: column;

		h4{
			font-weight: 600;
			font-size: 18px;
			margin-bottom: 5px;
		}
	}
}



.divAlertPopup,
#div-popup-exportar,
#div-error,
#divRenovarPassword,  
#editfolderPopup,
#removePopup,	 
#newFolderPopup,
#dEliminarPhase,
#divNuevoAlmacenProducto,
#divBajaUsuario,
#divBajaEmpresa { width: 450px; }

#popupPhase{  
	width: 450px;
	
	.group-colors{
		overflow: hidden;
	}
}

#divNuevoBancoCuenta { width: 750px; }

#divWidgetFiltersPopup { display: none; }

#idTableCuentasContables th:first-child { width: 250px; }

#linkCobroPago,
#newCobroPago { 
	width: 650px; 
	
	.row-total{
		padding-right: 9px;
		margin: 10px 0 15px 0;
		display: flex;
		justify-content: space-between;
		font-weight: 600;
	}
}

#linkCobroPago {
	.row-importe { display: none; }

	.combo-advanced-radio {
		.dropdown-menu > li { padding: 10px 15px; }	

		.combo-texto {
			display: flex !important;
			justify-content: space-between;
			width: 100%;
		}	

		.combo-texto-barra{
			padding-left: 10px;
			padding-top: 2px;
			width: 50%;
		}
	}
}

#editCobroPago {
	width: 75%;

	.table-list tbody > tr:not(:hover):last-child > td { border-bottom-color: $table-border; }

	.bloque.bg-light {
		padding-bottom: 15px;

		.import-vinculado,
		.import-pendiente {
			font-weight: 600;

			span { float: right; }
		}
	}
	
	@media #{$min-xl} { width: 50%; min-width: 950px; }
}

#popupSerie{
	width: 530px;
}

#divMateriales{
	width: 500px;
}

#seleccionaCliente{
	width: 500px;
}

#divInvitarUsuario{
	width: 530px;
}

.popup-switch{
	
	.texto-intro{
		margin-top: 25px;
	}
	
	.listbox{
		border-top: 0;
		margin-top: 30px;
	}
	
	.popup-footer{
		margin-top: 10px;
	}
	
	.list-element, .item-titulo{
		text-align: center;
		justify-content: center;
	}
	
	.list-element{
		background: white;
		border: 1px solid $grey-3;
		padding:15px;
		margin-bottom: 30px;
		display: block;
		&:hover, &:focus, &:active, &.active{
			outline: 2px solid $blue;
			background: white;
			padding: 15px !important; 
		}		
	}
		
	.item-titulo{
		font-weight: 600;
		font-size: 17px;
		display: flex;
		align-items: center;
	}
	
	.icono{
		display: flex;
		background: $blue;
		color: white;
		align-items: center;
		justify-content: center;
		width: 26px;
		min-width: 26px;
		height: 26px;
		border-radius: 50%;
		margin-left: 5px;
		font-size: 17px;
		margin: 10px auto 30px auto;
		
		&:before{
			content: '';
			font-family: 'fontAwesome';
			font-weight: normal;
			font-style: normal;
		}
		
		&.icono-compra{
			&:before{
				content: '\f07a';
			}
		}
		
		&.icono-gasto{
			&:before{
				content: '\f07c';
			}
		}		
		
		&.icono-lg{
			background: $blue;
			width: 50px;
			height: 50px;
			font-size: 30px;
		}
	}
}

// first login register fields
#firstLoginForm{
	width: 500px;
}

// apply massive colours
#divMassiveColours{
	width: 420px;

	.radio{
		label{
			&:before{
				background: none;
			}
			&.color-none{
				background-image: url(/saas/img/icons/icons/red/close.svg);
				background-repeat: no-repeat;
				background-size: cover;
			}
		}

		input[type="radio"]:checked + label::before{
			background: none;
			border:none;
		}

		input[type="radio"]:checked + label{
			opacity:0.8;
			border:2px solid $blue;
		}
	}
}