/*
.state,
.estado,
.estado-editable,
.state-edit
 {
	padding: 4px 10px;
	border-radius: 4px;
	font-weight: 400;
	color: white;
	background-color: $grey;
	line-height: 1em;
	border: 1px solid transparent; // $grey;
	white-space:nowrap;
	font-size: 14px;
	opacity: 1;
	border-radius: 30px;

	&.active,
	&:hover,
	&:focus {
		text-decoration: none; 
		color: white !important;
		opacity: 0.7;
	}
}

.state {
	border: 1px solid $grey;

	&.state-Green,
	&.state-green {
		border-color: $green;
		background-color: $green;
	}

	&.state-Yellow,
	&.state-yellow {
		border-color: $yellow;
		background-color: $yellow;
	}

	&.state-Orange,
	&.state-orange {
		border-color: $orange;
		background-color: $orange;
	}

	&.state-Red,
	&.state-red {
		border-color: $red;
		background-color: $red;
	}
}


.estado-Lima, .estado-lima, .stateLima
{
	&.active, &:not(.state-text):hover, &:focus, &.state-o, &.estado{
		background-color: #c9d805;
	}
}

.estado-Green, .stateGreen, .estado-verde, .verde, .Green
{
	&.active, &:not(.state-text):hover, &:focus, &.state-o, &.estado, &.state-edit{
		background-color: $green;
	}
}

.estado-Orange, .stateOrange, .estado-amarillo, .Orange, .amarillo
{
	&.active, &:not(.state-text):hover, &:focus, &.state-o, &.estado, &.state-edit{
		background-color: $color-orange;
	}
}

.estado-Yellow, .stateYellow, .Yellow
{
	&.active, &:not(.state-text):hover, &:focus, &.state-o, &.estado, &.state-edit{
		background-color: $yellow;
	}
}

.estado-Red, .stateRed, .estado-rojo, .rojo, .Red
{
	&.active, &:not(.state-text):hover, &:focus, &.state-o, &.estado, &.state-edit{
		background-color: $red;
	}
}

.estado-Grey, .stateGrey, .estado-gris, .Grey
{
	&.active, &:not(.state-text):hover, &:focus, &.state-o, &.estado, &.state-edit{
		background-color: $grey;
	}
}



.estado-editable {
	cursor:pointer;
	background: white;

	&.stateGreen, &.estado-verde, &.estado-Green{
		border-color: $green;
		color: $green;
	}

	&.stateLima, &.estado-lima, &.estado-Lima{
		border-color: #c9d805;
		color: #c9d805;
	}

	&.stateOrange, &.estado-amarillo, &.estado-Orange {
		border-color: $color-orange;
		color: $color-orange;
	}

	&.stateYellow, &.estado-Yellow{
		border-color: $yellow;
		color: $yellow;
	}

	&.stateRed, &.estado-rojo, &.estado-Red{
		border-color: $red;
		color: $red;
	}

	&.stateGrey, &.estado-gris, &.estado-Grey{
		border-color: $grey;
		color: $grey;

		&.active, &.estado-editable:hover, &.estado-editable:hover{
			background: $grey;
		}
	}

	&.estado-amarillo:hover,
	&.estado-Orange:hover { background: lighten($color-orange, 30%); }
	&.estado-rojo:hover,
	&.estado-Red:hover { background: lighten($red, 30%); }
	&.estado-verde:hover,
	&.estado-Green:hover { background: lighten($green, 30%); }
	&.estado-Yellow:hover { background: lighten($yellow, 30%); }

	&.updating{
		.spinner {
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);

			&:before {
				top: 0;
				left: 0;
				transform: unset;
			}
		}
	}
}

 */


.estado,
.estado-editable,
.state-edit
{
	//line-height: 1em;
	white-space:nowrap;
	opacity: 1;
	color: $basic-700;
	display: flex;
	align-items: center;
	background: transparent;
	border: none;
	font-weight: normal;
	font-size: 16px;

	&.active,
	&:hover,
	&:focus {
		text-decoration: none;
		opacity: 0.7;
	}

	&:before{
		width: 10px;
		height: 10px;
		border-radius: 50%;
		background: $grey;
		margin-right: 7px;
		content:'';
	}

	&.stateYellow {
		&:before {
			background-color: $yellow;
		}
	}

	&.stateLima
	{
		&:before{
			background-color: #c9d805;
		}
	}

	&.stateGreen,
	&.estado-verde
	{
		&:before{
			background-color: $green;
		}
	}

	&.stateOrange,
	&.estado-amarillo
	{
		&:before{
			background-color: $color-orange;
		}
	}

	&.stateRed,
	&.estado-rojo
	{
		&:before{
			background-color: $red;
		}
	}

	&.stateGrey,
	&.estado-gris,
	{
		&:before{
			background-color: $grey;
		}
	}

	.fa-angle-down{
		margin-left: 4px;
		//color: $primary-400;
	}
}


.estado-editable {
	cursor:pointer;

	&.updating{
		.spinner {
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);

			&:before {
				top: 0;
				left: 0;
				transform: unset;
			}
		}
	}
}

.estado-texto {
	color: $grey1;

	&.estado-verde {
		color: $green;
	}
	&.estado-rojo {
		color: $red;
	}
}

.state-o{
    width:8px;
    height:8px;
    border-radius:4px;
    display: inline-block;
    margin-right:5px;

	&.stateYellow{
		background-color: $yellow;
	}

	&.stateGreen,
	&.estado-verde
	{
		background-color: $green;
	}

	&.stateOrange,
	&.estado-amarillo
	{
		background-color: $color-orange;
	}

	&.stateRed,
	&.estado-rojo
	{
		background-color: $red;
	}

	&.stateGrey,
	{
		background-color: $grey;
	}
}

.btn-estado {
	padding-left: 0;
	padding-right: 0;
	&.estado-texto {
		cursor: pointer;
		background: transparent $bgCaretSelect;
		background-position: 100% 50%;
		padding-right: 20px;
	}
}

.estado-no-editable{
	cursor: initial;
	pointer-events: none;
}