

.contact{
	position: relative;
	background-color: white;
	display: flex;

	.contact-name{
		font-size: 16px;
		font-weight: 600;
		margin-bottom: 3px;
		margin-top: 0;

		a{
			color: $primary-800 !important;
		}
	}

	.contact-account{
		color: $primary-800 !important;
		font-weight: 600;
	}

	a:not(.social) {
		@extend .a;
		color: $grey2;
	}

	.i-icon {
		height: 21px;
		margin-right: 3px;
	}

	.hoverAction {
		opacity: 0;

		@media #{$max-xs} {
			opacity: 0.25;
		}
	}

	&:hover {
		.list-actions .hoverAction {
			opacity: 1;
		}
	}

	.media {
		p {
			margin-bottom: 0;
			color: $grey2;
		}
	}

	.media-body {
		vertical-align: middle;
	}

	&.list-actions {
		.dropdown {
			.dropdown-menu {
				overflow: initial;

				a {
					color: inherit;
					font-weight: inherit;
				}
			}
		}
	}

	.contacts-bloc2, .contacts-bloc3, .contacts-bloc5 {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.contacts-bloc5{
		flex-direction: column;
	}

	.contact-tags{
		display: flex;
		align-items: center;
	}
}

.contacts-list {
	.view-grid { display: none !important; }
	.contact {
		width: 100%;
		padding: 15px 10px;
		margin-bottom: 10px;
		align-items: center;
		flex-wrap: wrap;

		@media #{$max-xs} { padding: 10px 15px; }

		&.row-selected{
			background-color: $blue-5;
			outline: 1px solid $blue;
			margin-left: 1px;
			width: calc(100% - 2px);
		}
		
		&.list-bc {
			border-left-width: 4px;
			border-left-style: solid;
			border-left-color: transparent;
		}		
		
		.select-contact{
			padding-right: 10px;
		}

		.avatar {
			@extend .avatar50;
		}

		@media #{$max-sm} {
			display: block;
			float: left;
		}

		.contacts-bloc1 {
			width: 40%;
			padding-right: 5px;

			@media #{$max-md} {
				width: 100%;
				margin-bottom: 7px;
			}
		}

		.contacts-bloc2 {
			max-width: 35%;
			padding-left: 5px;
			padding-right: 5px;

			a{
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}

			@media #{$max-md} {
				justify-content: start;
			}

			@media #{$max-sm} {
				max-width: 50%;
			}

			@media #{$max-xs} { display: none; }
		}

		.contacts-bloc3 {
			//width: 20%;
			padding-left: 5px;
			padding-right: 5px;

			a{
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}

			@media #{$max-md} {
				//width: 100%;
				justify-content: start;
			}
		}

		.contacts-bloc5 {
			//width: 25%;
			padding-left: 5px;
			padding-right: 5px;

			@media #{$max-md} {
				justify-content: start;
			}

			@media #{$max-sm} { display: none; }
		}

		.list-actions {
			width: 5%;
			text-align: right;
			margin-left: auto;

			@media #{$max-sm} {
				position: absolute;
				right: 5px;
				top: 50%;
				transform: translateY(-50%);
				width: 30px;
				z-index: 1;
			}
		}
	}

	&.no-contacts {
		.avatar span,
		h4,
		p { background-color: rgba(0, 0, 0, 0.1); }

		h4 {
			height: 15px;
			margin-top: 10px;
			margin-bottom: 10px;
		}

		p { height: 10px; }

		.contacts-bloc1 {
			p { width: 90%; }
		}

		.contacts-bloc2,
		.contacts-bloc3 {
			h4 {
				width: 40%;
				margin: 10px auto;
			}

			p {
				width: 80%;
				margin: 0 auto;
			}
		}
	}
}

.contacts-grid {
	flex-wrap: wrap;
	display: flex;
	margin-left: -30px;
	margin-bottom: -30px;

	@media #{$max-sm} { margin-left: -15px; }

	.view-list { display: none !important; }

	.contact {
		width: calc(25% - 30px);
		padding: 25px;
		margin-left: 30px;
		margin-bottom: 30px;
		text-align: center;
		flex-direction: column;

		.select-contact{
			display: none;
		}

		@media #{$max-sm} {
			width: calc(25% - 15px);
			padding: 10px;
			margin-left: 15px;
			margin-bottom: 15px;
		}

		@media #{$max-xs} { width: calc(50% - 15px); }
		@media #{$max-xxs} { width: 100%; }

		.media-left {
			display: block;
			width: 100%;
			padding-right: 0;
			margin-bottom: 15px;

			.avatar {
				@extend .avatar90;

				* { margin: 0 auto; }
			}

			@media #{$max-xs} { margin-bottom: 10px; }
		}

		.media-body {
			display: block;
			width: 100%;
			margin-bottom: 0;
		}

		[class^="contacts-bloc"] {
			margin-bottom: 15px;

			&:empty { display: none; }

			&.contacts-bloc2{
				@media #{$max-xs} { display: none; }
			}

			&.contacts-bloc5 {
				margin-bottom: 0;
			}

			@media #{$max-xs} {
				margin-bottom: 0;

				&.contacts-bloc3 { margin-top: 10px; }

				.social { margin-top: 10px; }
			}
		}

		.list-actions {
			position: absolute;
			top: 10px;
			right: 10px;

			@media #{$max-sm} {
				top: 10px;
				right: 10px;
			}

			.dropdown {
				.btn-more { transform: rotate(90deg); }
			}
		}
	}
	
	.contact-social{
		margin-bottom: 10px;
	}
}



#viewPersona {
	.contact {
		margin-bottom: 30px;
		text-align: center;
		flex-direction: column;

		h2{
			color: $primary-800;
			font-weight: 600;
		}

		.contact-info {
			margin-bottom: 15px;

			.info {
				margin-left: 10px;

				&:first-of-type { margin-left: 0; }
			}
			
			.observaciones{
				margin-top: 10px;
				margin-bottom: 0;
				color: $grey1;
			}
		}

		@media #{$max-xs} {
			margin-bottom: 25px;

			h3 { margin-top: 10px; }
		}
		
		.contact-social{
			margin-bottom: 10px;
		}
	}
}



.select-all{
	padding: 0 15px;
	display: inline-block;
	background:white;
	margin-bottom: 10px;
}


.contact-social{
	margin-bottom: 5px;
	white-space: nowrap;

	.social{
		margin: 0 2px;
	}
}