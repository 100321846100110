.view {
	.page{
		position: relative;
		height: calc(100% - 30px);
		max-width: 1300px;
		margin: auto;
	}

	.texto-intro {
		margin: 0 0 30px 0;
		font-size: 16px;
		color: $grey2;
	}

	.page-header {
		@extend .clearfix;

		.btn-backlink:before { margin-right: 5px; }
	}

	.page-content {
		background: white;
		padding: 30px;
		margin-bottom:30px;
	}

	.btn-edit {
		padding: 15px;
		border-radius: 0;

		> img { 
			height: 20px; 
			width: 20px;
		}
	}

	.subtitle {
		margin-top: 30px;
		margin-bottom: 20px;
		+ .form-inline { margin-bottom: 15px; }
		color: $primary-800;
		font-weight: 400;
		font-size: 24px;
	}

	p.form-control {
		height: initial;
		color: inherit;
		background-color: transparent;
	}

	@media #{$max-xs} {
		.page-content { padding: 20px 15px; }
	}
}