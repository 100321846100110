#calendari {
	.addEvent {
		opacity: 0;
		padding: 5px;
	}

	.fc-toolbar {
		> * > h2 {
			padding: 7px;
			margin: 0;
			font-size: 18px;
			line-height: 1;
			font-weight: 600;
			color: $grey4;
		}

		.filters-list {
			margin-bottom: 0;
			margin-top: 0;

			.filtersLeft > * { margin-bottom: 0; }

			@media #{$max-xs} {
				.filter-search input:focus { width: calc(100% - 32px); }
			}
		}

		.fc-prev-button,
		.fc-next-button {
			padding: 5px 12px;
			margin: 0;
			background-color: transparent;

			.fc-icon { font-size: 14px; }
		}

		.btn-group {
			.btn {
				font-size: 14px;
				font-weight: 600;
				text-transform: capitalize;
				color: $grey;
				background-color: white;

				&:hover,
				&.fc-state-active {
					color: $primary-400;
				}
			}
		}
	}

	.fc-view {
		background-color: white;

		.fc-head {
			.fc-head-container {
				border-top: 0;
				border-left: 0;
				border-right: 0;
			}

			.fc-day-header abbr { display: none; }
		}

		.fc-body {
			> * > * { border: 0; }

			.fc-day-top {
				color: $grey4;

				.month {
					float: right;
					color: inherit;
					margin: 2px;
					padding: 2px;
				}
			}

			.fc-content-skeleton {
				padding-bottom: 0;

				table tr:last-of-type td .fc-event { margin-bottom: 0; }
			}

			.fc-highlight-skeleton {
				.fc-highlight {
					background-color: $blue-3;
				}
			}

			.fc-other-month {
				opacity: 1;
				color: $grey;
			}

			.fc-content-skeleton:hover,
			.fc-day-top:hover,
			.fc-day:hover { cursor: pointer; }

			.fc-more-cell > div { display: initial; }

			hr.fc-divider {
				padding: 0;
				border-top: 0;
			}
		}

		.fc-day-number {
			display: inline-block;
			width: 25px;
			height: 25px;
			margin: 2px;
			box-sizing: border-box;
			text-align: center;
		}

		.fc-today {
			background-color: transparent;

			.fc-day-number {
				color: white;
				background-color: $red;
				border-radius: 50%;
			}
		}

		&.fc-agendaWeek-view,
		&.fc-agendaDay-view {
			.fc-head .fc-row {
				border-right-width: 0px !important;
				margin-right: 0px !important;
			}

			.fc-body .fc-row { margin-right: 0 !important; }

			.fc-body {
				.fc-bg .fc-axis { width: 40px !important; }

				.fc-slats {
					tr:first-of-type .fc-axis > span { display: none; }

					.fc-axis {
						position: relative;
						width: 40px !important;

						> span {
							position: absolute;
							top: -50%;
							right: 0;
							width: calc(100% - 5px);
							padding: 0 2px;
							margin-right: 5px;
							box-sizing: border-box;
							font-size: 13px;
							background-color: white;
						}
					}
				}
			}
		}
	}

	&.fc-unthemed {
		thead,
		td { border-color: $grey-3; }
	}

	tr:first-child > td > .fc-day-grid-event { margin-top: 0; }

	.fc-event {
		min-height: 35px;
		padding: 5px;
		border: 0;
		margin-left: 0;
		margin-right: 0;
		margin-bottom: 4px;
		border-left: 4px solid $blue-5;
		border-radius: 0;
		outline: 1px solid $blue-3;
		color: $grey4;
		background-color: $blue-5;

		.media {
			.media-left { padding-right: 5px; }
			.media-body {
				.media-heading {
					@extend .substring;

					margin-bottom: 2px;
					font-size: 13px;
				}

				p {
					@extend .substring;

					position: relative;
					display: block;
					padding-left: 30px;
					margin-bottom: 0;
					font-size: 13px;
					text-align: right;
					color: $grey1;
					box-sizing: border-box;

					span {
						position: absolute;
						left: 0;
					}
				}
			}
		}
	}

	.fc-day-grid-event { margin-top: 0; }

	.fc-scroller  { 
		position: relative; 
		scrollbar-width: thin;
		scrollbar-color: $grey-1 $grey-3; // 1 barra 2 fondo (firefox)
	}

	.fc-agendaDay-view {
		float: left;
		display: inline-block;
		width: 65%;

		.fc-time-grid-container {
			border-right-width: 1px;
			border-right-style: solid;
			border-right-color: $grey-3;
		}
	}

	.fc-time-grid .current-time{
		position: absolute;
		left: 0;
		width: 48px;
		padding-right: 7px;
		border: 0;
		margin-top: -10px;
		transform: none;
		box-sizing: border-box;
		font-size: 13px;
		text-align: right;
		color: $red;
	}

	+ .little-calendar {
		.little-container {
			display: inline-block;
			width: 35%;
			padding: 0 30px;
			border-width: 50px 0;
			border-style: solid;
			border-color: transparent;
			background-color: white;

			.fc-header-toolbar {
				margin-bottom: 0;
				background-color: $blue-5;

				.fc-button {
					padding: 12px 20px;
					margin: 0;
					background-color: transparent;
				}

				h2 {
					padding: 14px;
					font-size: 16px;
					font-weight: 600;
					text-transform: capitalize;
				}
			}

			.fc-view-container {
				padding: 0 20px 20px;
				background-color: $blue-5;

				td { border: 0; }

				.fc-head {
					th { height: 35px; }
				}

				.fc-body {
					.fc-row {
						min-height: 35px;

						.fc-bg {
							.fc-day { background-color: transparent; }
						}

						.fc-content-skeleton {
							padding-bottom: 0;

							.fc-day-top {
								cursor: pointer;

								span {
									display: block;
									float: none;
									height: 25px;
									width: 25px;
									border-radius: 50%;
									margin: 3px auto;
									line-height: 25px;
									text-align: center;
									color: inherit;
								}

								&:hover {
									color: white;
									opacity: 0.4;

									span { background-color: $grey1; }									
								}

								&.fc-other-month { opacity: 0.8; }

								&.selected-day {
									color: white;

									span { background-color: $grey1; }
								}

								&.fc-today {
									color: $red;

									&:hover {
										color: white;
										opacity: 0.4;

										span { background-color: $red; }
									}

									&.selected-day {
										color: white;

										span { background-color: $red; }

									}
								}
							}
						}
					}
				}
			}
		}
	}

	@media #{$max-xs} {
		.fc-toolbar {
			margin-bottom: 10px;

			.fc-left { width: 75%; }

			.fc-center { margin-top: 15px; }

			.fc-agendaWeek-button { display: none; }

			.btn-group {
				.btn {
					line-height: 21px;
					padding: 6px 8px;
					font-size: 13px;
				}
			}
		}

		.fc-view-container {
			.fc-view {
				&.fc-month-view {
					thead,
					td { border-color: transparent; }

					.fc-head {
						.fc-head-container {
							border-bottom-color: $grey-3;

							.fc-day-header {
								span { display: none; }
								abbr {
									display: inline;
									text-transform: capitalize;
								}
							}
						}
					}

					.fc-body {
						.fc-row {
							min-height: 50px;
							max-height: 50px;
						}

						.fc-scroller { height: 100% !important; }

						.fc-content-skeleton {
							.month,
							table tbody tr:not(:first-of-type) { display: none; }

							.fc-day-top {
								text-align: center;

								.fc-day-number { float: none; }
							}
						}

						.fc-event {
							width: 0;
							height: 0;
							min-height: 0;
							padding: 2.5px;
							border: 0;
							border-radius: 50%;
							margin: 3px auto;
							background-color: $grey1;
							outline: none;
						}

						.fc-more-cell { display: none; }
					}
				}

				&.fc-agendaDay-view {
					width: 100%;

					td {
						border-left: 0;
						border-right: 0;
					}

					.fc-head { display: none; }

					.fc-body {
						.fc-day-grid {
							.fc-row { border-right-width: 0 !important; }

							.fc-axis {
								width: 25px !important;

								span { display: none; }
							}
						}

						.fc-scroller {
							border-right: 0;

							.fc-axis {
								width: 25px !important;
								border-top: 0;

								> span { position: initial; }
							}

							.fc-time-grid {
								.fc-now-indicator-line { left: -5px; }
								.fc-now-indicator-arrow { display: none; }
							}
						}
						.fc-divider { margin-left: 33px; }
					}
				}
			}

			.fc-head .fc-day-header { padding: 15px 5px 5px; }
		}
	}

	@media #{$max-xxs} {
		.fc-toolbar {
			.fc-left { width: 100%; }
		}
	}
}

.fc {
	table {
		font-size: 14px;

		th {
			padding: 15px 5px;
			border: 0;
			vertical-align: middle;
			text-transform: capitalize;
		}
	}

	th,
	td,
	thead,
	tbody,
	.fc-divider,
	.fc-row,
	.fc-content,
	.fc-popover,
	.fc-list-view,
	.fc-list-heading td { border-color: $grey-3; }

	.fc-button,
	button {
		height: initial;
		padding: 6px 12px;
		background-image: none;
		border: 0;
		box-shadow: none;

		@media #{$max-xs} {
			padding: 6px;
		}

		.fc-icon {
			top: initial;
			margin: 0;
			font-family: $f-icons;

			&.fc-icon-left-single-arrow:after {
				content: "\f053";
				top: 0;
				font-size: inherit;
				font-weight: normal;
			}

			&.fc-icon-right-single-arrow:after {
				content: "\f054";
				top: 0;
				font-size: inherit;
				font-weight: normal;
			}
		}
	}

	.fc-now-indicator { border-color: $red; }

	.fc-time-grid .fc-now-indicator-arrow {
		left: 49px;
		border-width: 5px;
		border-top-color: $red;
		border-bottom-color: $red;
		transform: translateX(-50%);
		border-radius: 50%;
	}
}

.fc-open-popover { cursor: pointer; }
.popover-fc {
	position: absolute;
	width: 250px;
	padding-top: 10px;
	padding-bottom: 10px;
	border: 1px solid $grey-2;
	border-radius: 5px;
	margin: 5px;
	background-color: white;
	box-shadow: -6px 0px 18px 0px rgba($grey4, 0.14); 
	z-index: 3;

	@media #{$max-xs} {
	    left: 0 !important;
	}

	&:before {
		content: "";
		position: absolute;
		height: 12px;
		width: 12px;
		transform: rotate(45deg);
		border-width: 0px;
		border-style: solid;
		border-color: $grey-2;
		background-color: white;
	}

	&.left {
		&:before {
			top: 40%;
			left: -6px;
			border-left-width: 1px;
			border-bottom-width: 1px;
		}
	}

	&.top-left {
		&:before {
			bottom: -6px;
			left: 19px;
			border-bottom-width: 1px;
			border-right-width: 1px;
		}
	}

	&.top-right {
		&:before {
			bottom: -6px;
			right: 19px;
			border-bottom-width: 1px;
			border-right-width: 1px;
		}
	}

	&.bottom-left {
		&:before {
			top: -6px;
			left: 19px;
			border-top-width: 1px;
			border-left-width: 1px;
		}
	}

	&.bottom-right {
		&:before {
			top: -6px;
			right: 19px;
			border-top-width: 1px;
			border-left-width: 1px;
		}
	}

	&.auto-left {
		&:before {
			top: 50%;
			right: -6px;
			border-top-width: 1px;
			border-right-width: 1px;
		}
	}

	&.auto-right {
		&:before {
			top: 50%;
			left: -6px;
			border-left-width: 1px;
			border-bottom-width: 1px;
		}
	}

	.fc-popover-header {
		padding: 0 15px;

		.media {
			padding-left: 10px;
			padding-right: 15px;
			border-left: 5px solid transparent;
			margin: 0 -15px;

			.media-body .media-heading {
				margin-bottom: 0;
				line-height: 30px;
				font-size: 16px;
				font-weight: 600;
			}
		}
	}

	.fc-popover-content {
		padding: 0 15px;
		margin-bottom: 5px;

		.list-info {
			margin-bottom: 0;
			font-size: 14px;
			color: $grey;

			> li {
				> span:not(.estado) { color: $grey4; }

				.estado { margin-top: 5px; }

				&.divider {
					@include divider;
				}

				.btn {
					float: right;
					margin-top: -5px;
				}
			}
		}
	}
}

// google calendar
.gCalendar{

	.logo{
		height: 60px;
		width: auto;
	}
	
	.title{
		color: $color4;
		font-size: 34px;
		font-weight: 700;
		display: block;
		margin-top: 20px;
	}
	
	.titulo{
		padding: 5px 0 10px 0;
		font-weight: 400;
		font-size: 29px;
		color: #494A4A;	
		margin:0;
		line-height:1em;
	}
	
	.text-small{
		font-size:13px;
		margin-bottom:15px;
	}
	
	.text-conectado{
		margin-bottom: 25px;
		
		strong{
			font-weight:700;
			color: #494A4A;	
		}
	}
	
	#buttons-google{
		padding-top: 5px;
	}
	
	.checkbox label{
		color: $grey2;
	}
	
	.lista-actividades{
		line-height: 2em;
		
		.checkbox{
			margin-top: 0;
			margin-bottom: 0;
			
			label{
				padding-left: 30px;
			}
		}
	}

	.btn-submit{
		margin-top:10px;
	}
}


#carregant{
	display: flex; 
	justify-content:center;
	align-items:center; 
	height: 300px;
}



// pickDiary 

#divEditActividad{
	.popover.popover-pickDiary{
	
		.arrow:after{
			border-bottom-color: $grey-3;
		}
		
		@media #{$min-sm} { 
			left: 70px !important;
			.arrow{
				left: 20% !important;
			}
		}
	} 
}

.popover.popover-pickDiary {
	width: 700px;
	background: white;

	.row-main > .bloque{
		min-height:383px;
	}

	@media #{$max-sm} { 
		min-width: calc(100% - 10px); 
		width: calc(100% - 10px); 
		left: 5px !important;
	}

	.bg-light { 
		padding-bottom: 0;
		display: flex;
		justify-content: space-between;
		flex-direction: column; 
	 }

	.row-detalle {
		padding-top: 20px;
		padding-bottom: 20px;
		background-color: white;
	}	

	.avatar {
		img {
			height: 34px;
			width: 34px;
		}

		+ abbr {
			border-bottom: 0;
			cursor: auto;
		}
	}

	.dyary-div {
		padding-bottom: 0;
		border-left: 1px solid $grey-1;
	}
}

#calendarContainer {
	width: 100%;
	
	.pika-title {
		.pika-label {
			font-weight: 600;
			background-color: transparent;
		}
		.pika-prev { background-image: url(/saas/img/icons/icons/grey/caret_left.svg); }
		.pika-next { background-image: url(/saas/img/icons/icons/grey/caret_right.svg); }
	}
	
	.pika-button {
		width: 25px;
		margin: 0 auto;
		text-align: center;
		background-color: transparent;

		&:hover {
			border-radius: 50%;
			background-color: $blue-1;
		}
	}		

	.pika-table {
		thead th {
			padding: 4px;
			font-weight: 400;
			font-size: 0.8em;
			color: $color-black;
		}

		tbody {
			td {
				padding: 4px;

				&.is-selected {
					.pika-button {
						border-radius: 50%;
						background: $blue;
						opacity: 1;
						box-shadow: none;
					}
				}
			}
		}
	}		
			
	.pika-single {
		border: 0;
		background-color: transparent;
	}

	.pika-lendar {
		width: 100%;
		margin: 0;
	}		
}


#dyaryContent {
	margin-right: -30px;
	margin-left: -15px;

	.fc-toolbar {
		padding-left: 15px;
		padding-right: 15px;
	
		.fc-center {
			h2 {
				line-height: 20px;
				padding: 5px;
				font-size: 14px;
				font-weight: 600;
				text-transform: capitalize;
			}
		}

		.fc-button {
			background-color: transparent;
			opacity: .5;
			width: 20px;
			height: 30px;
			padding: 0;
			background-size: 75% 75%;
			background-position: center center;

			&:hover { opacity: 1; }

			.fc-icon { display: none; }

			&.fc-state-hover {
				color: inherit;
				background-color: inherit;
				transition: none;
			}

			&.fc-state-down { box-shadow: none; }
		}

		.fc-left .fc-button { background-image: url(/saas/img/icons/icons/grey/caret_left.svg); }
		.fc-right .fc-button { background-image: url(/saas/img/icons/icons/grey/caret_right.svg); }
	}

	.fc-view-container {
		.fc-head { display: none; }
		
		.fc-scroller{
			scrollbar-width: thin;
			scrollbar-color: $grey-1 $grey-3; // 1 barra 2 fondo (firefox)
			min-height: 320px;
		}
		
		.fc-widget-content {
			border: 0;

			.fc-today { background-color: transparent; } 
			.fc-slats {
				table tr:first-of-type .fc-axis span { display: none; }

				.fc-widget-content {
					border-top: 1px solid $grey-2;
					cursor: pointer;
				}

				.fc-axis {
					width: 50px !important;
					border-color: transparent;
					background-color: transparent;
					cursor: default;

					span {
						display: block;
						margin-top: -20px;
						margin-right: 15px;
					}
				}

				.fc-minor .fc-widget-content:not(.fc-axis) { border-bottom: 1px solid $grey-1; }
			}

			.fc-content-skeleton {
				.fc-event-container {
					.new-event,
					.old-event {
						font-size: 1em;
						cursor: auto;

						.fc-content { padding: 5px; }

						&.new-event {
							border-color: white;
							color: white;
							background-color: $blue;
							cursor: pointer;
						}

						&.old-event {
							border-color: white;
							color: $grey2;
							background-color: $grey-2;
						}
					}
				}
			}
		}
	}
}
