.landings {
	min-height: 100%;
	padding-bottom: 60px;
	
	.default-panel{
		display: flex;
		align-items: center;
		
		span{
			margin-right: 10px;
		}
	}
	
	.switch{
		input:checked + .slider{
			background: $green;
		}
	}
	
	.head-col{
		h1{
			line-height: 1.7em; 
		}
	}
	
	
	.head-right{
		display: flex;
		align-items: center;
		
		.filters-list {
			> * {
				margin-bottom: 0;
			}

			.btn-filter{
				background-color: white !important;
			}
		}
		
		.default-panel{
			margin-left: 15px;
		}
	}
	
	
	@media #{$max-sm} {
		.main-row{
			display: block;
		}
	}

	.first-line {	
		margin-bottom: 0;

		.help-block {
			max-width: 500px;
			text-align: right;
		}

		.row-switch > * { margin-bottom: 8px; }

		@media #{$max-xs} {
			display: block;
		}
	}

	.tab-content .page-header{padding-top:0}
	
	.kpi-main {
		color: $color-dark;
		font-weight: 600;
		font-size: 2.7em;
		
		&.kpi-main-label{
			overflow: hidden;
			
			.kpi-main-number{
				width: 95px;
				overflow:hidden;
				text-overflow: clip;
				display: block; 
				margin-right:5px;
				float: left;
			}
		
			.kpi-main-text{
				text-overflow: ".";
				width: calc(100% - 100px);
				display: block;
				overflow: hidden;
				white-space: nowrap;
				float: left;
			}
		}
	}
				

	.row-resumenes{	
		.caja h3 { margin-bottom: 3px; }

		.box-total{
			color:#5E6062;

			&.box-total-comercial .ntotal {
				color:$color-dark;
				font-weight: bold;
			}
		}
		
		.empty-kpi { margin-top: 30px; }
		
		.btn-resumen{
			display: inline-block;
			margin-top: 20px;
		}
	}

	.caja {
		border:none; 
		position: relative;	

		.caja-head-link {
			float: right;
			font-size: 16px;
		}
		
		h2{
			margin-bottom: 5px;
		}
		
		h2.titulo-alt {
			margin-bottom: 10px;
			&:not(:first-of-type) { margin-top: 25px; }
		}

		h3{
			font-size: 16px;
			font-weight: 400;
			color: #888;
			margin-top: 15px;
		}
		
		.table{
			margin-bottom: 0; 
			width: 100% !important;
			
			tbody{
				[data-toggle=popover]{
					cursor: pointer;
				}
			}
		}
	}
	
	.caja-listado{
		li{
			
			&:last-child{
				border-bottom: none;
			}

			&.otros{
				display: block !important;
				
				.otros-contenido{
					cursor: pointer;
				}					

				ul{
					display: none;
					
					.col-main{
						padding-left:10px; 
					}
				}
			}
			
			.state-o{
				margin-right: 10px;
				margin-top: 7px;
				background-color: #dedede; // defecto
			}
		}
		
		&.caja-listado-flex{
			li, .otros-contenido{
				display: flex;
				
				.col-main{
					flex: 2;
				}				
				.col{
					flex: 1;
					min-width: 60px;
					text-align:right;
				}
			}		
		}
	}	
	
	.dataTables_empty{
		border-bottom: 0 !important;
	}
	 
	.empty-box {		
		&.empty-graph1 {
			img {
				margin-right:30px;
				margin-bottom:20px;
				max-width: 600px;
			}
			
			.empty-btns {
				display: inline-block;
				white-space: nowrap; 

				.btn { margin-right: 10px; }
				
				@media #{$max-xs} { 
					white-space: initial; 
					
					.btn {
						width: 100%;
						margin-bottom: 10px;
						margin-right: 0;
					}					
				}
			}
		}
		&.empty-graph2 {
			img {
				max-height: 150px;
			}
		}		
		&.empty-kpi img {
			display: block;
			max-width: 250px;
			margin-bottom: 0;
		}

		&.empty-list img {
			display: block;
			max-width: 1000px;
			margin-bottom: 0;
		}

		&.empty-sum img {
			display: block;
			max-width: 300px;
			margin-top: 15px;
			margin-bottom: 30px;
		}
	}

	.title-summary {
		float: right;
		font-size: 14px;

		.state-o { margin-left: 15px; }
	}

	.list-summary {
		.media {
			.media-right {
				min-width: 100px;

				@media #{$max-xs} {
					min-width: 85px;
				}
			}

			.media-object {
				font-size: 12px;
				text-align: right;
			}

			.media-body {
				.help-block{
					color: $color-dark;

					.since { color: $grey; }
				}
			}

			p { margin-bottom: 0;}
		}

		li {
			margin-bottom: 25px;

			&:last-of-type { margin-bottom: 0; }
		}
	}
	
	.usuario-actividades-content { display: none; }

	.usuario-popover-trigger {
		display: block;
		width: 100%;
		cursor: pointer;

		.usuario-actividades-lista { display: none; }
	}

	.popover-usuario-actividades {
		width: 500px;
		max-width: 500px;

		.popover-content {
			padding: 20px;

			h2 { margin: 0; }

			h3 {
				margin: 10px 0;
				font-size: 17px;
			}
		}
	}

	.caja-actividades {
		min-height: 460px;

		.img-cargando {
			display: block;
			margin: 150px auto 0 auto;
		}
	}

	.caja-horas {
		.kpi-horas {
			display: block;
			margin-bottom: 20px;
			font-size: 32px;
			font-weight: 600;
		}

		.lista-horas {
			margin-top: 40px;

			li {
				display:flex;
				justify-content: space-between;
				line-height: 2em;
			}
		}

		.horas-lista {
			@media (min-width: 1180px) and (max-width: 1320px) {
				width: 100%;	
			}	
			@media (min-width: 1321px) and (max-width: 1500px) {
				width: 50%;	
			}			
				
			.caja-listado{
				display: inline-block;
				
				li{
					display:flex;
					align-items: center;
					text-align: left;	
					justify-content: flex-start;
				}
				
				.state-o{
					margin-top: 0;
					margin-right: 5px;
				}
			}		
		}

		.horas-grafico{
			@media (min-width: 1180px) and (max-width: 1320px) {
				width: 100%;	
			}	
			@media (min-width: 1321px) and (max-width: 1500px) {
				width: 50%;	
			}			
			text-align: center;
		}		
		
		@media #{$max-sm} {
			.lista-horas { margin-top: 10px; }
		}
	}

	// highcharts
	.highcharts-tooltip{
		background-color: rgba(255,255,255,1);
		
		.tt-label { font-weight: bold; }

		.tt-tooltip {
			display: block;
			width: 100%;
			padding: 10px;
			position: relative;
		    background-color: white;
		    opacity: 1;
		    z-index: 9999 !important;
		    text-align:left;
		}
	}
	
	.highcharts-title { text-align: center; }

	.tt-label {
		font-size: 14px;
		color: #888;
	}

	.tt-total {
		font-size: 17px;
		color: $color-dark;
		line-height: 1em;
	}
	
	/* landing proyectos */
	.filtro-fecha-plus{
		white-space: nowrap;
		
		.btn{
			padding: 5px 12px;
			border: 1px solid $grey-3;
			margin-bottom: 8px;
			border-radius: 2px;
			
			@media #{$max-sm} { 
				font-size: 12px; 
			}
			
			@media #{$max-xs} { 
				padding: 6px 5px; 
			}
		}
		
		.prev-results, .next-results{
			width: 20px;
		}
	}
}

#ingresosGastosChart {
	height: 400px;
	min-width: 250px;
	max-width: 100%;
	margin: 0 auto
}

.selector-paneles{
	margin-bottom: 0;

	.btn-group{
		width: auto;
	}
	
	.dropdown-menu{
		min-width: 150px;
		
		li:hover{
			background-color: white !important;
			color: $blue !important;
		}
	}
	
	.fake-select{
		padding-right: 30px;
		padding-left: 30px;
		color: $blue1;
		border: none;
		font-size: 24px;
		font-weight: normal;
		line-height: 1em; 
		background: url(/saas/img/icons/icons/blue/caret_down.svg) no-repeat calc(100% - 5px) 50%/17px, url(/saas/img/icons/icons/blue/analisis.svg) no-repeat 0 50%/20px;
	}
}

// landing facturacion
.row-resumenes{
	.btn-santander{
		position: absolute;
		right: 15px;
		top: 15px;
	}
}

// landing tesoreria

.caja-chart{
	min-height: 177px;
}

.caja-flujo-caja{
	min-height: 380px;
	margin-bottom: 0;
}

.caja-alertas{
	height: calc(100% - 10px);
	margin-bottom: 0;
	
	.caja-sin-alertas{
		margin-top: 50px;
		text-align: center;
	}
	
	.alerta-container{
		overflow-y: auto;
		scrollbar-width: thin;
		scrollbar-color: $grey-1 $grey-3; // 1 barra 2 fondo (firefox)
		height: calc(100% - 30px);
		margin-right: -15px;
		padding-right: 15px;
		padding-top: 7px;
	}	
}



.alerta{
	box-shadow: 1px 2px 4px 0 rgba(0, 0, 0, 0.12);
	border: solid 1px #eeeeee;
	padding: 25px 15px 20px 15px;
	position: relative;
	margin-bottom: 15px;
	
	.alerta-btn-close{
		position: absolute;
		top: 5px;
		right: 10px;
		height: 15px;
		width: 15px;
		padding: 0;
		
		img{
			height:auto;
			width: 10px;
		}
	}
	p{
		margin-bottom: 0 !important;
	}
	
	.btn-alerta{
		margin-top: 10px;
	}
	
	.alerta-icono{
		position: absolute;
		bottom: 15px;
		right: 10px;
		height: 20px;
		width: 20px;
	}
}


// apañazo
.landing-proyectos{
	.ranges li:nth-child(even){
		padding-bottom: 15px;
		padding-top: 2px;
	}
}