// ICOMOON
// --------------------------

@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon.eot?7shaep');
  src:  url('../fonts/icomoon.eot?7shaep#iefix') format('embedded-opentype'),
    url('../fonts/icomoon.ttf?7shaep') format('truetype'),
    url('../fonts/icomoon.woff?7shaep') format('woff'),
    url('../fonts/icomoon.svg?7shaep#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-bucket:before {
  content: "\e904";
}
.icon-check:before {
  content: "\e900";
}
.icon-slash:before {
  content: "\e901";
}
.icon-watch:before {
  content: "\e902";
}
.icon-zap:before {
  content: "\e903";
} 

.icon-file-plus:before {
  content: "\e905"; 
}

.icon-traffic:before {
  content: "\e906";
}

.icon-user-plus:before {
  content: "\e973";
}

.icon-users:before {
  content: "\e972";
}

.icon-demo:before {
  content: "\e907";
}

.icon-arrow-up:before {
  content: "\ea3a";
}
.icon-arrow-right:before {
  content: "\ea3c";
}
.icon-arrow-down:before {
  content: "\ea3e";
}
.icon-arrow-left:before {
  content: "\ea40";
}

.icon-camera:before {
  content: "\e909";
}

.icon-tick:before {
  content: "\e90a";
}

.icon-file-text:before {
  content: "\e90b";
}

.icon-print:before {
  content: "\e90c";
}

.icon-download:before {
  content: "\e908";
}

.icon-list:before {
  content: "\e9bb";
}

.icon-coin-euro:before {
  content: "\e93c";
}

.icon-alert:before {
  content: "\e90d";
}