html {
	//height:100%;  //100vh
	min-height: 100%;

	body {
		position: relative; 
		//height: 100%; //100vh
		min-height: 100%;
		margin: 0px;
		font-family: $f1;
		font-size: 16px;
		-webkit-font-smoothing: antialiased;
		color: $text;
		background-color: $background;

		//@media #{$max-xs} { font-size: 15px; }

		&.user-trial {
			.main-menu {
			 	padding-top: 89px;
			 	
				@media #{$max-xs} { padding-top: 89px; }
				

				.sidebar-wrapper {
					top: 107px;
					height: calc(100% - 107px);
					@media #{$max-sm} { top: 107px; }
					@media #{$max-xs} { top: 95px; }
				}

				.page-content-wrapper {
					top: 10px;
					position: relative;
					
					@media #{$max-sm} { top: 0; }
					
				}
				
				#sidebarAjustes{
					padding-top: 140px;
				}
			}
		}

		&.bg { background-color: white; }
	}
}

h1 {
	font-size: 2em;
	font-weight: 600;

	@media #{$max-xs} { font-size: 1.7em; }
}

h2 {
	font-size: 1.5em;
	font-weight: 400;
	margin-bottom: 15px;
}

h3 {
	font-size: 1.2em;
	font-weight: 400;

	@media #{$max-xs} { font-size: 1em; }
}

h5{
	font-size: 15px;
}

a,
.a {
	color: $blue;
	text-decoration: none;
	cursor: pointer;

	&:focus,
	&:hover {
		color: $blue1;
		text-decoration: none;
		outline: none;
	}

	&[href^="tel:"] {
		color: inherit;

		&:hover { color: $blue1; }
	}
}

b, strong { font-weight: 600; }

abbr {
	cursor: inherit;
	border-bottom: 0;
	text-decoration: none;
}

pre {
	font-family: inherit;
	font-size: inherit;
	color: inherit;
}

sub { vertical-align: text-bottom; }

hr {
	margin-top: 10px;
	margin-bottom: 10px;
	border-color: $grey-3;
}

label{
	font-weight: 600;
}

ul {
	padding-left: 0;
	list-style: none;
	list-style-type: none;
}

*:focus, *:hover, * { outline: none; }

#divCentral {
	position: relative;
	min-height: calc(100vh - 67px);

	> .container-fluid { 
		height: inherit; 
		padding-left: 30px;
		padding-right: 30px;
		
		@media #{$max-sm} {
			padding-left: 15px;
			padding-right: 15px;
		}		
	}


	.container { 
		width: 100%; 
		
		@media #{$min-lg} { 
			.container { max-width: 970px; }
		}
	}

}

main{
	height: 100%;
	/*
	&.mask{
		-webkit-filter: blur(7px);
		-moz-filter: blur(7px);
		-o-filter: blur(7px);
		-ms-filter: blur(7px);
		filter: blur(7px);
		height: 100%;
		width: 100%;
		//position: relative; 
	}
	*/
}

/* SCROLL **********/

::-webkit-scrollbar {
	width: 0.5em;
	height: 0.5em;

	@media screen and (max-width: 425px) {
		width: 0.3em;
		height: 0.3em;
	}
}
::-webkit-scrollbar-button { display: none; }
::-webkit-scrollbar-thumb {
	border: 0px;
	background: #b3b3b3;
}
::-webkit-scrollbar-thumb:hover {background: #828284;} 
::-webkit-scrollbar-thumb:active {background: #59595b;}
::-webkit-scrollbar-track {
	border: 0px;
	background: transparent;
}
::-webkit-scrollbar-track:hover {background: rgba(211,211,211,0.5);} 
::-webkit-scrollbar-track:active {background: rgba(211,211,211,0.5);}
::-webkit-scrollbar-corner { display: none; }