.view.user {

	.user-avatar-container{
		text-align: right;
		
	 	@media #{$max-sm} { 
			text-align: center;
	 	}
	}

	.user-avatar {
		position: relative;
		display: inline-block;
		max-height: 150px;
		max-width: 150px;
		border: 3px solid white;
		border-radius: 50%;
		overflow: hidden;
		cursor: pointer; 

		.user-img,
		.dz-UploadImage .dz-preview .dz-image img{
			height: 150px;
			max-height: 150px;
			max-width: 100%;
			border: 1px solid white;
			border-radius: 50%;
		}
		.dz-UploadImage{
			.dz-preview {
				.dz-remove {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					opacity: 0;
				}
			}
		}

		.edit-avatar {
			position: absolute;
			left: 0;
			bottom: -27px;
			width: 100%;
			padding: 5px;
			text-align: center;
			background-color: $grey-3;
			opacity: 0;
			transition: all 1s;

			> img { height: 17px; }
		}

		&:hover {
			.edit-avatar {
				bottom: 0;
				opacity: 1;
			}
		}
	}

	.user-name {
		margin-top: 15px;
		margin-bottom: 5px;
		font-size: 22px;
		font-weight: 600;
		color: $color-black;
		text-transform: capitalize;
		
	 	@media #{$max-sm} { 
			text-align: center;
	 	}
	}

	.user-cargo {
		margin-top: 0;
		color: $grey2;
		font-size: 1.2em;
		margin-bottom: 20px;
		
		@media #{$max-sm} { 
			text-align: center;
	 	}
	}

	.user-contact {
		margin-bottom: 25px;

		> span {
			margin-right: 30px;
			white-space: nowrap;
			display: inline-block;
			margin-bottom: 7px;			

			&:last-of-type { margin-right: 0; }

			@media #{$max-xs} {
				display: block;
				margin-right: 0;
				margin-bottom: 5px;
			}

			.i-icon { 
				height: 20px; 
				margin-right: 3px;
			}
		}
		
		@media #{$max-sm} { 
			text-align: center;
	 	}
	}
	
	.user-buttons{
		margin-top: 40px;
		
		.btn {
			margin-right: 5px;
			
			@media #{$max-sm} { 
				margin-bottom: 10px;
		 	}			
		}
	}

	hr {
		margin-top: 25px;
		margin-bottom: 25px;
	}

	.textLabel { color: $grey; }

	h5 {
		font-size: 18px;
		font-weight: 600;
		margin-top: 0;
	}
	
	
	
	.tab-switches{
	
		ul{
			width: 100%;
			max-width: 450px;
		}
	
		li{
			display: flex;
			align-items: center;
			height: 50px;
			justify-content: space-between;
		}
		
		.switch{
			min-width: 36px;
			
			 input:checked + .slider{
			 	background: $green;
			 }
		}
	}

	.tab-switches-proyectos{

		ul{
			width: 100%;
			max-width: 600px;

			li{
				margin-bottom: 25px;
			}
		}

		.li-content{
			display: flex;
			align-items: center;
			height: 50px;
			justify-content: space-between;
		}

		.grid-hours-content{
			display: flex;
			align-items: center;
			.form-group{
				margin-right: 12px;
				&:last-of-type{
					margin-right: 0;
				}

				.form-control{
					padding-right: 8px !important;
					width: 45px;
					padding-left: 8px;
				}
			}
			@media #{$max-md} {
				flex-wrap: wrap;
			}

			@media #{$max-sm} {
				display: block;
				.form-control{
					width: auto;
				}
				.input-group-addon{
					width: 100%;
				}
			}
		}

		.form-group{
			display: flex;
			align-items: center;
			.email-label{
				display: block;
				margin-right: 10px;
				width: 100%;
				color: $grey-label;
			}
		}

		.switch{
			min-width: 36px;

			input:checked + .slider{
				background: $green;
			}
		}
	}
	
	#tabAlertasTesoreria{

		ul{
			max-width: 600px;
		}

			
		.form-group{
			margin-bottom: 0;
			max-width: 130px;
			margin-left: 20px;
		}
		/*
		.first-span{
			width: 230px;
			
			@media #{$max-sm} { 
				width: 100%;
		 	}
		}*/
	}
}