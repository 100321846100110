.fast-call {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	box-shadow: -6px 0px 18px 0px rgba($grey4, 0.14); 
	background-color: white;
	z-index: 101;

	header {
		.title {
			padding-top: 60px;
			padding-bottom: 40px;
			margin-top: 0;
			margin-bottom: 0;
			max-width: 100%;
		}

		.btn-close {
			position: absolute;
			top: 30px;
			right: 30px;
			padding: 15px;
		}
	}

	section {
		display: flex;

		.media {
			margin-top: 0;
			margin-bottom: 60px;

			.media-left {
				.media-object {
					width: 32px;
					height: 32px;
					margin-top: 5px;
					img {
						height: 100%;
						max-width: 100%;
					}
				}
			}

			.media-body {
				.media-heading {
					margin: 10px 0 25px;
				}
			}
		}

		.fast-call-caller { margin-bottom: 20px; }

		a,
		.a {
			color: $color4;

			&:hover,
			&:focus { color: $blue; }
		}
	}
}

@media #{$max-xs} {
	.fast-call {
		margin-top: 51px;

		header {
			h1 {
				padding-top: 30px;
				padding-bottom: 30px;
			}

			.btn-close {
				top: 15px;
				right: 20px;
			}
		}

		section {
			flex-wrap: wrap;

			.media {
				width: 50%;
				margin-bottom: 20px;
				margin-bottom: 20px;
				margin-bottom: 20px;
			}
		}
	}
}