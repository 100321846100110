.dropzone {
	position: relative;

	.dz-preview {
		display: inline-block;
		position: relative;
		
		&.dz-error .dz-error-message,
		&.dz-error .dz-error-mark { display: block; }

		&.dz-success .dz-success-mark { display: inline-block; }

 		.dz-progress {
			display: block;
			height: 15px;
			
			.dz-upload{
				display: block; 
				height: 100%;
				background: green;
			}
		}

		.dz-details{
			display: inline-block;
			
			.dz-filename { margin-right: 5px; }

			.btn-remove {
				min-width: unset;
				min-height: unset;
				vertical-align: middle;
			}
		}

		.dz-error-message {
			display: none;
			color: red;
		}

		.dz-error-mark,
		.dz-success-mark { display: none; }

		.dz-success-mark { color: #228B22; }
		.dz-error-mark { color: #B22222; }		
	}

	&.dz-mensajes {
		position: absolute;
		bottom: 0;
		width: 100%;
		padding: 0 40px;
		text-align: right;

		.dz-default { display: none; }

		.dz-progress { height: 2px; }

		.btn {
			padding: 0 5px;
			opacity: 0.7;

			&:hover { opacity: 0.2; }
		}
	}

	&.dz-adjuntos .dz-preview {
		display: inline-block;
		margin-top: 3px;
		margin-left: 3px;

		&:first-child { margin-left: 0; }

		.dz-details {
			display: block;
			padding: 2px 5px;
			border: 1px solid $form-input-border;
			background: #f2f2f2;
		}

		.dz-progress {
			position: absolute;
			right: -110px;
			bottom: 3px;
			height: 20px;
		}

		&.dz-complete {
			.dz-progress { display: none; }
		}

		a.dz-remove { display: none; }

		span.dz-remove {
			display: inline-block;
			float: right;
			width: 15px;
			margin-left: 10px;
			background: url(/saas/img/icons/icons/grey/delete.svg) 50% 50% no-repeat;
			background-size: auto 15px;
			cursor: pointer;

			&:hover {
				background: url(/saas/img/icons/icons/blue/delete.svg) 50% 50% no-repeat;
				background-size: auto 15px;
			}
		}
	}

	&.dz-docs {
		width: 100%;
		min-height: 40px;
		padding: 8px 12px;
		border: 1px solid $form-input-border;
		border-radius: 2px;
		background: url(/saas/img/icons/icons/blue/clip.svg) 98% 50% no-repeat;
		background-size: 20px auto;
		cursor: pointer;

		&:hover { border-color: $form-input-border-hover; }

		.dz-default { color: $form-placeholder-color; }

		&.dz-started {
			background: $bg-grey;
			cursor: default;

			.dz-default { display: none; }
		}

		.dz-preview {
			position: initial;
			display: block;
			padding-left: 30px;

			.dz-details {
				display: block;

				.btn-remove { float: right; }
			}

			.dz-progress {
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 2px;
			}		
		}			
	}

	&.dz-gasto, &.dz-notas {
		.dz-default {
			padding: 50px 20px 20px 20px;
			border: 2px dashed #d6dadb;
			border-radius: 4px;
			margin-top: 35px;
			text-align: center;
			background-image: url("../img/icons/icons/grey/upload.svg");
			background-size: 20px auto;
			background-repeat: no-repeat;
			background-position: 50% 20px;

			&:hover { cursor: pointer; }
		}

		.dz-preview {
			width: 100%;
			padding: 10px 15px 10px 45px;
			border: 1px solid #d6dadb;
			border-radius: 4px;
			margin-top: 20px;
			background-image: url("../img/icons/icons/grey/documentos.svg");
			background-size: 20px auto;
			background-repeat: no-repeat;
			background-position: 15px 50%;

			.dz-progress { display: none; }

			.dz-details {
				display: block;

				.dz-filename,
				.dz-size,
				.btn-remove { float: left; }

				.dz-name-size {
					width: calc(100% - 50px);
					overflow: hidden;
				}
			}

			span.dz-remove {
				display: inline-block;
				float: right;
				width: 15px;
				margin-left: 10px;
				cursor: pointer;
				background: url(/saas/img/icons/icons/grey/delete.svg) 50% 50% no-repeat;
				background-size: auto 15px;

				&:hover {
					background: url(/saas/img/icons/icons/blue/delete.svg) 50% 50% no-repeat;
					background-size: auto 15px;
				}
			}

			@media #{$max-sm} { 
				padding-left: 15px;
				background-image: none;
			}
		}
	}
	
	&.dz-notas {
		.dz-default{
			margin-top: 20px;
		}
	}

	&.dz-UploadImage {
		.dz-preview {
			width: 100%;

			.dz-image {
				@extend .avatar;
				@extend .avatar150;

				float: left;

				img {
					height: 150px;
					width: auto;
					max-width: 100%;
				}
			}

			.dz-details {
				@extend .help-block;

				display: block;
				padding-top: 25px;
				padding-left: 150px;
				text-align: center;

				.dz-filename:after {
					content: "-";
					margin-left: 10px;
				}

				.dz-size {
					font-size: 0.8em;
					font-style: italic;

					strong { font-weight: normal; }
				}

				.dz-remove {
					display: block;
					margin-top: 15px;
				}
			}
		}
	}
}

.form-error .dz-docs {
	border-color: #a42738 ;

	&:hover { border-color: #a42738; }
}

.my-dz-template { display: none; }

.dropzone-fake {
	min-height: 40px;
	width: 100%;
	padding: 8px 12px;
	padding-left: 35px;
	border: 1px solid $form-input-border;
	border-radius: 2px;
	overflow: hidden;
	background-color: $bg-grey;
	background-image: url("../img/icons/icons/grey/documentos.svg");
	background-size: 20px auto;
	background-repeat: no-repeat;
	background-position: 5px 50%;

	.dz-filename {
		width: calc(100% - 30px);
		margin-right: 5px;
		float: left;
	}

	.btn-remove {
		min-width: unset;
		float: right;
	}
}

.dropzone-gasto .dropzone-fake .btn-remove {
	top: calc(50% - 10px);
	right: 10px;
}