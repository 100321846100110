#divNuevaActividad { 
	width: 960px;

	.cheks-div {
		display: inline-block;
		width: calc(100% - 200px);
		margin-left: 15px;

		.checkbox { margin-top: 0; }

		@media screen and (max-width: 768px) {
			width: 100%;
			margin-left: 0;
		}

		@media screen and (min-width: 770px) { float: right; }
	}
}

#divEditActividad {
	width: 960px;
 
	#formTarea {
		.log-container {
			min-height: 280px;
			max-height: 500px;
			overflow-y: auto;
			scrollbar-width: thin;
			scrollbar-color: $grey-1 $grey-3; // 1 barra 2 fondo (firefox)			
		}

		.bucket-picker { padding: 0; }

		.cheks-div {
			display: inline-block;
			width: calc(100% - 200px);
			margin-left: 15px;

			.checkbox { margin-top: 0; }

			@media screen and (max-width: 768px) {
				width: 100%;
				margin-left: 0;
			}

			@media screen and (min-width: 770px) { float: right; }
		}
	}

	
	#mapCanvas, #mapCanvasPopup {
		width: 100%;
		min-height: 300px;
		margin-bottom: 20px;
		overflow: hidden;
	}

	.contacts-list {
		padding-top: 15px;
		max-height: 335px;
		margin-bottom: 20px;
		overflow-y: auto;
		scrollbar-width: thin;
		scrollbar-color: $grey-1 $grey-3; // 1 barra 2 fondo (firefox)			

		.media {
			.media-left {
				display: block;
				width: 40px;
				float: left;
			}

			.media-body {
				display: block;
				max-width: calc(100% - 40px);

				p { margin-bottom: 5px; }
			}
		}
	}

	#viewRecientes {
		padding-bottom: 40px;
	}
}

.btn-icon-comment{
	img{
		height: 20px !important;
		margin-right: 3px;
	}
	
	span{
		font-size: 13px;
	}
}