.pre-login {
	width: 100%;
	height: 100%;
	font-size: 15px;

	.pl-block {
		width: 50%;
		height: inherit;
		float: left;

		&:first-of-type { position: fixed; }
		&:last-of-type { margin-left: 50%; }
		
		@media #{$max-sm} { 
			width: 100%;
			
			&:first-of-type { display: none; }
			&:last-of-type { margin-left: 0; }
		}
	}

	.pl-image {
		position: relative;
		width: 100%;
		height: inherit;
		background-image: url('/saas/img/icons/backgrounds/pc_billage.jpg');
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;

		&.pl-image-bg:before {
			content: "";
			position: absolute;
			width: 100%;
			height: 100%;
			background-color: $corporative-color;
			opacity: 0.6;
		}

		> * { position: relative; }
	}

	.pl-logo {
		display: inline-block;
		height: 80px;
		margin: 40px;

		img { height: 100%; }
	}

	.pl-header {
		padding: 40px 80px 15px;

		@media #{$max-sm} { padding: 20px 50px 15px; }
		@media #{$max-xs} { padding: 15px 35px; }
		
		a {
			display: block;
			width: 100%;
			text-align: right;
		}

		+ .pl-container {
			padding: 25px 80px 15px;

			@media #{$max-sm} { padding: 20px 50px 15px; }
			@media #{$max-xs} { padding: 15px 30px; }
		}
	}

	.pl-container {
		padding: 135px 80px 15px;

		@media #{$max-sm} { padding: 40px 50px 15px; }
		@media #{$max-xs} { padding: 20px 35px 15px; }
	}
	
	.logo {  
		@media #{$min-sm} {
			display: none; 
		}
		@media #{$max-sm} {
			display: block;
			margin-bottom: 30px;
			text-align: center;

			img { 
				width: 100%; 
				max-width: 300px;
			}
		}

		@media #{$max-xs} {
			margin-bottom: 20px;

			img { width: 50%; } 
		}
	}

	h1 {
		margin-top: 0;
		margin-bottom: 10px;
		font-size: 32px;
		color: $color4;
		font-weight: 700;
		
		@media #{$max-sm} { 
			font-size: 28px;
			text-align: center;	
		}			

		@media #{$max-xs} { 
			margin-bottom: 10px; 
		}
	}

	h2 {
		margin-top: 0;
		margin-bottom: 25px;
		font-weight: normal;
		font-size: 16px;
		line-height: 24px;
		
		@media #{$max-sm} { 
			margin-bottom: 25px;
			text-align: center;	
		}
	}
	
	hr {
		margin: 25px 0;
	}		

	.form-group {
		@media #{$min-xs} { margin-bottom: 25px; }

		label { margin-bottom: 0; }
	}
	
	.form-footer{
		overflow: hidden;

		#preguntaCodigoDescuento{
			text-align: center;
			margin-bottom: 0;
		}
		
		.form-footer-aside{
			display: none;
		}
		
		label{
			font-weight: 400;
		}
		
		@media #{$max-sm} {
			text-align: center;
			
			a{
				display:block;
			}
		
			.form-footer-aside{
				display: block;
				margin-top: 5px;
			}
		}			
	}

	.pl-buttons {
		.btn {
			min-width: 250px;
			padding: 12px 20px;
			height: auto;

			@media #{$max-sm} { 
				margin: 0 auto; 
				display: block;
			}
			@media #{$max-xs} { 
				width: 100%; 
			}

			&.btn-register { 
				text-transform: uppercase;
				width: 100%;
				height: 48px;
			}
		}

		.validacion { margin-top: 5px; }			
		
		hr {
			@media #{$max-sm} { display: none; }
		}	
	}
	
	
	@media #{$max-sm} { 
		.bloque-faq{
			display: none;
		} 
	}
	
}



.login, .register{
	.pre-login {
		.pl-header {	
			@media #{$max-sm} { display: none; }
			
			a {
				@media #{$max-sm} { 
					display: none;
				}
			}
		}
	}

	.pl-container, .pl-header{
		max-width: 800px;
		margin: 0 auto;
	}

	.inner-container{
		max-width: 360px;
		margin: 0 auto;
	}

	.btn-google{
		width: 100%;
		background-position-x: calc(50% - 85px) !important;
		text-align: center !important;
		box-shadow: 0 1px 2px 1px rgba(0,0,0,.12) !important;
		height: 48px;
	}
	
	.help-block{
		margin-bottom: 20px;
		display: block;
	}	

	@media #{$max-sm} { 
		h1{
			margin-bottom: 20px;
			text-align: center;
		}
		
		h2{
			text-align: center;
		}
		
		.help-block{
			text-align: center;
			margin: 10px auto 15px auto;
		}		
	}	
	
	@media #{$max-xs} { 
		.help-block{
			font-size: .80em;
			
			&.checkbox label{
				padding-left: 20px;
			}
		}	
	}	
	
	.form-group {
		@media #{$min-xs} { margin-bottom: 25px; }
		position: relative;
		
		@media #{$max-sm} { 
			margin-left: auto;
			margin-right: auto;		
		}

		label { 
			margin-bottom: 0; 
			position: absolute;
			transition: all 0.2s;
			color: $grey;
			top: 15px;
			left: 10px;
		}
		
		.form-control{
			height: 48px;
			padding-top: 16px;
			padding-bottom: 4px;
			box-shadow:none;
			
			&:focus ~ label, &.not-empty ~ label{
				top: 4px;
				font-size: 10px;
			}
		}
	}	
}

.login{
	h1 {
		@media #{$max-xs} { 
			margin-top: 30px; 
			margin-bottom: 15px;
		}	
		
		@media #{$max-sm} { 
			text-align: center; 
		}
	}

	.pre-login{
		.pl-header{
			text-align: center;

			.btn-primary-outline{
				display: inline;
			}
		}
	}
}

#register{
	.pl-image-billage {
		background-color: #F4F3F5;
		background-image: linear-gradient(to top, rgba(54, 62, 68, 0.6) 20%, rgba(9, 36, 76, 0) 55%), url('/saas/img/register/renata.png');
		background-position-y: 40%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	} 

	.pl-container{
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		height: calc(100% - 76px);
	}
	
	blockquote{
		background: rgba(255,255,255,0.12);
		width: 100%;
		text-align: center;
		color: white;
		padding: 25px 70px;
		font-size: 17px;
		margin: 0;
		border: 0;
		
		q, cite{
			max-width: 500px;
			margin: 0 auto;
			text-align: center;
			line-height: 24px;
		}
		
		q{
			font-style: italic;
			display: block;
			font-size: 20px;
			margin-bottom: 10px;
		}

		cite{
			font-size: inherit;
			margin-top: 10px;
			font-size: 18px;
			font-style: normal;

			span{
				font-size: 16px;
				display: block;
			}
		}
	}

	.register-testimonials{
		padding: 30px 80px;

		.testimonial-card{
			background: white;
			border-radius: 8px;
			box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.24);
			padding: 15px;
			margin-bottom: 15px;
		}

		.testimonial-header{
			display: flex;
			align-items: center;
			margin-bottom: 8px;

			.testimonial-name{
				font-size: 15px;
				font-weight: 600;
				margin: 0;
			}

			.testimonial-role{
				font-size: 13px;
				color: $grey-label;
			}
		}

		.testimonial-profile{
			width: 32px;
			height: 32px;
			object-fit: fill;
			border-radius: 16px;
			margin-right: 8px;
		}

		.testimonial-rating{
			display: flex;

			li{
				margin-right: 2px;
			}
		}
	}
	
	h1 {
		@media #{$max-xs} { 
			margin-bottom: 5px; 
			font-size: 22px;
			text-align: center;		
		}
		
		.titulo-normal{
			@media #{$max-xs} { display: none }
		}
		.titulo-mobile{
			@media #{$min-xs} { display: none }
		}
	}

	.bloque-importacion{
		border-top: 1px solid $grey-3;
		border-bottom: 1px solid $grey-3;
		padding-top: 30px;
		padding-bottom: 30px;
		margin: 30px auto 0 auto;

		.img-register-importacion{
			width: 100%;
			height: auto;
		}

		h3{
			margin-top: 0;
			font-size: 18px;
			font-weight: 600;
		}

		.col-main{

		}
		.col-image{
			padding-right: 0;
		}
	}

	.form-group-idioma{
		display: flex;
		align-items: center;
		justify-content: end;
		margin-top: 15px;
		margin-bottom: 15px;

		label{
			margin-right: 10px;
			margin-bottom: 0;
			font-weight: 400;
		}

		.form-control{
			border: none;
			padding:0;
			height: auto;
			width: auto;
			background-image: url(/saas/img/icons/icons/grey/caret_down.svg);
			background-size: 12px 12px;
			font-weight: 600;
		}
	}

}

.hr-o{
	color: $grey-label;
	display: inline-flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	margin-top: 20px;
	margin-bottom: 20px;

	&:before, &:after{
		content:'';
		height: 1px;
		width: 42%;
		background: $grey-3;
	}
}


.preLogin-div {
	max-width: 960px;
	margin: 0 auto 50px auto;
	
	@media #{$max-xs} { padding: 0 15px; }

	h1 {
		font-size: 32px;
		font-weight: 700;
		margin-bottom: 10px;
		color: $color4;
	}
	h2 {
		font-size: 16px;
		font-weight: normal;
		margin-bottom: 30px;
	}
	
	.subtitle{
		margin-bottom: 30px;
	}
	
	.preLogin-icon {
		height: 50px;
		margin-bottom: 20px;
	}
		
	.preLogin-header {
		padding-top: 40px;
		padding-bottom: 15px;
		
		.logo{
			display: block;
			height: 60px;
			text-align: center;
			margin-bottom: 30px;
			
			img{
				height:100%;
				width: auto;
			}
		}
	}

	.header-links{
		display: flex;
		justify-content: space-between;
		margin-bottom: -5px;
	}
	
	.preLogin-container {
		padding: 40px 30px;
		background-color: white;
		position: relative;

		@media #{$max-xs} { padding: 15px 20px; }

		.title { display: block; }
		
		.header-links{
			position: absolute;
			top: -33px;
			width: 100%;
			left: 0;
		}		
	}

	.caja {
		padding: 30px;
		border: 0;
		background-color: $background;
		@media #{$max-xs} { padding: 20px; }
	}
	
	.form-group {
		margin-bottom: 30px;
	}	
	
	hr {
		margin: 25px;
		border-color: $grey-1;
	}
			
	.form-condiciones{
		color: $grey1;
		padding-top: 30px;
		margin-top: 30px;
		border-top: 1px solid $grey-2;
		
		.datos-seguros{
			margin-top: 20px;
			
			i{
				margin-right: 5px;
			}
		}
	}
	
	.server-error {
		font-size: 12px;
		color: $red;
	}
	
	&.invitation{
		.preLogin-header{
			margin-top: 70px;
			padding-bottom: 0;
		}
		.preLogin-container{
			padding-top: 20px;
			margin: 0 auto;
			max-width: 600px;
		}
		
		&.invitation-confirm{
			max-width: 450px;
		}
	}
	
	&.multiempresa{
		.preLogin-header{
			margin-top: 70px;
			padding-bottom: 0;
		}	
		.preLogin-container{
			padding-top: 20px;
			margin: 0 auto;
			max-width: 600px;
		}
	}
}

#requestDemo,
#requestDemoOk {
	.pl-container { padding-top: 30px; }
}

body.login {
	height: 100vh;
	background-color: white;
}

body.register {
	height: 100vh;
	background-color: white;
} 

