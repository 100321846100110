.toolbox {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	padding-top: 25px;
	margin-bottom: 30px;

	.tb-btn {
		display: flex;
		height: 30px;
		width: 30px;
		border: 2px solid $grey;
		border-radius: 15px;
		margin-right: 7px;
		font-size: 15px;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		color: $grey;
		background-color: transparent;

		&:last-child { margin-right: 0; }
		&:hover { background-color: white; }

		&.active,
		&:hover {
			border-color: $blue;
			color: $blue;
		}

		&.dt-group {
			width: auto;
			min-width: 30px;
			i{
				font-size: 13px;
				width: 10px;
				margin-left: 1px;
			}
			span {
				margin-left: 5px;

				&:empty { margin-left: 0px; }
			}
		}
	}

	.tb-state {
		display: flex;
		align-items: center;
		
		.tb-btn {
			@extend .state;

			width: auto;
			height: auto;
			margin-right: 0;

			&:hover,
			&:focus { color: white; }

			.fa { margin-left: 5px; }
		}

		margin-right: 7px;
	}

	.tb-state-check {
		margin-right: 7px;

		.tb-check {
			@extend .state;

			margin-right: 0;
		}

		.fa { margin-left: 5px; }
	}

	.tb-check {
		width: auto;
		margin-right: 7px;
		cursor: pointer;

		input {
			position: absolute;
			opacity: 0;
			cursor: inherit;
		}

		label {
			margin-bottom: 0;
			cursor: inherit;
		}
	}

	.tb-color {
		height: auto;
		.tb-btn {
			margin-right: 0;

			span {
				width: 12px;
				height: 12px;
				border-radius: 50%;

				+ i { display: none; }
			}
		}
	}

	.dropdown {
		margin-right: 7px;

		&:last-child { margin-right: 0; }

		.btn {
			padding: 0;

			.avatar {
				> * { margin-right: 0 ; }

				@media #{$max-xs} {
					img {
						height: 35px;
						width: 35px;
					}
				}
			}
		}
	}

	@media #{$max-sm} {
		margin-bottom: 15px;

		.tb-btn,
		.tb-state,
		.tb-state-check,
		.dropdown { margin-bottom: 5px; }
	}
}

.tb-item {
	display: none;

	&.tb-preselected {
		* { display: none; }

		.tb-alert { display: inline-block; }
	}

	.tb-alert {
		@extend .help-block;

		display: none;
		color: $red;
	}
}

.bg-light {
	.toolbox {
		border-top: 0;
		padding-top:0;
	}
}

.bloque-toolbox{
	.item-icon{

	}
}