/* selector emergente con tabs (en kanban y funnel) */
.select-tabs {
	display: inline-block;

	.select-tabs-content { display:none; }
}

.selectorTabs {
	.arrow { display:none; }

	&.popover {
		border-radius:0;
		background-color:#fff;
		width:350px;
		z-index:80;
		
		&.bottom { margin-top: 0; }

		.popover-content { padding: 0; }
	}

	.select-tabs-inner {
		.tab-content{ 
			border:none;
			height:300px;
			margin-bottom:0;
		}

		.tab-pane {
			overflow-y:scroll;
			height:300px;
			
			ul li {
				a, .item {
					color: $text;
					line-height:24px;
					display:block;
					width:100%;
					padding:5px 15px;
					cursor:pointer;
					
					&:hover,&:active, &:focus{
						color: $blue1;
						background:#f2f2f2;
					}
				}
			}

			.dropdown-menu {
				display: block;
				position: initial;
				width: 100%;
				border: 0;
				box-shadow: none;
			}
		}

		.nav-tabs {
			border:none;
			
			> li {
				width: 50%;
				border-bottom: 1px solid  $blue;

				&:first-child { border-right:1px solid  $blue !important; }
				&:last-child > a { margin-right: 0; }

				& > a {
					border:none !important;
					font-weight:bold;
					background-color:#f2f2f2;
					border-radius:0 !important;
					text-align: center;
				}

				&.active {
					border-bottom:none;
					
					& > a {
						background-color: white !important;
						color: $blue1;
					}
				}
			}
		}
	}

	.tabs-favoritos {
		background: white;
		border-bottom: 1px solid $blue;
		padding-top: 10px;
		padding-bottom: 5px;
		margin-bottom: 5px;

		h3 {
			font-size: 14px;
			margin: 0 15px;
			font-weight: bold;
			padding: 5px 0 7px 0;

			img {
				height: 16px;
				margin-right: 10px;
				float: left;
			}

			span { display: inline-block; }
		}
	}
}


.nav-tabs {
	> li.inactive {
	
		a, a:hover, a:focus, a:active{
			color: $grey-2;
		}
	
	}
}


ul.nav-billage {
	margin-bottom: 30px;
	font-size: 1em;
	border: none;
	border-bottom: 3px solid $primary-100;

	@media #{$max-sm} {
		margin-bottom: 15px;
		font-size: 0.95em;
	}

	@media #{$max-xs} {
		font-size: 0.85em;
	}

	li {
		//text-transform: uppercase;
		
		a {
			padding: 15px 0 10px 0;
			border: 0;
			margin-left: 15px;
			margin-right: 15px;
			color: $basic-700;
			background-color: transparent;
			margin-bottom: -2px;

			@media #{$max-sm} {
				margin-left: 8px;
				margin-right: 8px;
			}

			@media #{$max-xs} {
				margin-left: 5px;
				margin-right: 5px;
			}
			
			&:hover,
			&:focus {
				background-color: transparent;
				color: $blue;
				outline: none;
			}
		}

		&:first-of-type a { margin-left: 0; }
		&:last-of-type a { margin-right: 0; }

		&.active {
			a {
				font-weight: 600;
				padding-bottom: 8px;
				border: 0;
				border-bottom: 3px solid $blue;
				color: $blue;
				background-color: transparent;

				&:hover,
				&:focus {
					border: 0;
					border-bottom: 3px solid $blue;
					color: $blue;
					background-color: transparent;
				}
			}
		}
		
		&.tab-buttons{
			float: right;
			display: block;
			margin-top: 7px;
		}
		
	}
}

.popup .nav-billage {
	padding-top: 10px;
	padding-left: 30px;
	padding-right: 30px;
	margin: 0 -30px;
}

.bloqueNavTraducciones{
	border: 1px dashed $grey-2;
	padding-left: 15px;
	padding-right: 15px;
	margin-bottom: 20px;
	margin-top: -10px;

	ul.nav-billage{
		padding-top: 0;
		border-bottom: 0;
		display: flex;
		justify-content: flex-end;
		
		li {
			a{
				margin-right: 0;
				padding: 10px 0 0 0;
				text-transform: uppercase;
			}
			
			&.active a{
				border-width: 2px;
				padding-bottom: 0;
			}
		}
	}

}