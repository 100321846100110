$menu-width: 250px;
$vertical-margin: 15px;
$input-height: 34px;
$input-height-large: 50px;
$font-size-normal: 16px;

/* ---------- DATE RANGE PICKER */
$daterangepicker-ranges-hover-border-size: 0;
$daterangepicker-ranges-border-size: 0;

$f1: "Source Sans Pro", sans-serif;
$f-icons: "BillageIcons", "FontAwesome", "icomoon";