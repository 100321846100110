#imports {
	.media {
		margin-bottom: 10px;

		&:last-of-type { margin-bottom: 0; }

		.media-left {
			padding-right: 15px;

			.media-object {
				width: 40px;
				height: 40px;
				padding: 10px;
				border-radius: 50%;
				line-height: 20px;
				font-size: 20px;
				font-weight: 600;
				text-align: center;
				color: white;
				background-color: $green;
			}
		}

		.media-body {
			.media-heading {
				margin-top: 5px;
				margin-bottom: 10px;
				line-height: 32px;

				.btn { margin-left: 30px; }
			}
		}
	}

	textarea { resize: vertical; }

	.form-btn {
		display: flex;
		align-items: center;
		
		.btn{
			margin-right: 30px;
		}
	}
}

#importPreview {
	.well {
		border-color: white;
		border-radius: 0;
		background-color: white;

		.btn {
			padding: 8px 15px;

			&:not(:first-of-type) { margin-left: 5px; }
		}
	}
}