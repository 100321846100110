.color-red { color: $red !important; }
.color-green { color: $green !important; }
.d-block { display: block !important; }
.d-inline-block { display: inline-block !important; }
.ui-widget{ font-size: 1em;}
.tolower { text-transform: lowercase; }
.hide {display: none;}
.aright{ text-align: right !important }
.aleft{ text-align: left !important }
.acenter { text-align: center !important }
.space-between{ display: flex !important;  justify-content: space-between; align-items: center}
.flex-start{ display: flex; justify-content: flex-start; align-items: center}
#divError { display: none; }
.divFormButton{ margin-top: 15px;}
.line-through{text-decoration: line-through;}
p:empty{
	margin-bottom:0;
}

.info-box{
	border:1px solid $primary-100;
	padding: 15px;
	background: white;

	.title-info{
		display: flex;
		font-weight: bold;
		font-size: 15px;
		margin-top: 0;
		margin-bottom: 5px;
		.badgeHelp{
			float: none;
			margin-right: 5px;
			padding: 0;
		}
	}
}

.flex-column{
	display: flex;
	flex-direction: column;
}

.light-text { color: $grey1; }

.visuallyhidden {
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
}

/*
.log-container{
	.listbox{
		[data-type]{
			display: none !important;
		}
	}
}
*/
.texto-intro{
	color: $grey2;
}
 
.texto-ayuda{
	display: block;
	width: 100%;
	box-sizing: border-box;
	color: #777;
	margin-bottom: 30px;
	
 }

.img-user {
    width: 35px;
    max-width: 35px;
    height: 35px;
    overflow: hidden;
	border-radius: 100%;
    background-color: #efefef;
    margin-right:10px;
}

.a-text {
	@extend .a;

	color: inherit;
}

.copy {
	@extend .a;

	cursor: pointer;
}

.click-me,
.pointer { cursor:pointer; }

.upper{ text-transform: uppercase; }

.clearfix {
	&:before,
	&:after {
		content: "";
		display: block;
		clear: both;
	}
}

.i-icon{height: 17px;}
.bigIcon{height: 26px;}
.icon-text{height: 16px;}


.subinfo {
  font-size: 0.9em;
  margin-top: 2px;
  color: $form-subinfo-color; 

}
  
.help-block {
	display: inline-block;
	font-weight: normal;
	font-size: .9em;
	color: $form-subinfo-color;
	
	&.help-alert{
		color: $red;
	}
	
	&.a {
		display: inherit;
		color: $blue;
	}
	
	> p { margin-bottom: 0; }
	
	.list-helps {
		padding-left: 40px;
		margin-bottom: 0;
		list-style: initial;
	}
	
	label &{
		margin-top:0;
		margin-bottom:0;
		margin-left:5px;
	}
}

.box{
	display: inline-block;
	padding: 15px 0;
	
	&.boxAlert{
		background-color: $primary-100;
		color: $basic-700;
		padding: 15px;
		p{
			margin-top: 10px;
			margin-bottom: 0;
		}
		.form-group{ 
			margin-bottom: 0; 
		} 
	}
	
	.boxIcon{
		top: 10px;
		
		img{
			max-width: 100%;
			width: 30px;
			margin: 0 auto;
			display: block;
		}		
	}	
}


.well {
	.page-header + & {
		background-color: white;
		border: 0;
		border-radius: 0;
	}

	.form-group:last-child { margin-bottom: 0; }
}

.substring {
	display: inline-block;
	max-width: 100%;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}

.social-icon-middle { height: 45px; }

.no-row {
	margin-left: 0;
	margin-right: 0;
}

#mapCanvas {
	width: 100%;
	height: 100%;
	min-height: 200px;
	padding: 30px;
	text-align: center;
	color: $grey;
	//background-color: $grey-3;

	.no-map {
		height: 80px;
		padding: 15px;
		background-position: center;
		background-image: url(/saas/img/icons/backgrounds/no-map.svg);
		background-size: auto 90%;
		background-repeat: no-repeat;
	}
}

.linear-box {
	border: 1px solid #d6d1db;
	padding: 30px;
	margin: 20px auto;
}

.hr-line {
	border-color:$table-border;
	margin-top:25px;
	margin-bottom:25px;
}

.two-cols {
	-moz-column-count: 2;
    -moz-column-gap: 20px;
    -webkit-column-count: 2;
    -webkit-column-gap: 20px;
    column-count: 2;
    column-gap: 20px;
}

.updating{
	position: relative;
}

.spinner {
	position: relative;
	display: block;
	width: 20px;
	height: 20px;
	z-index: 3;

	&:before {
		content: "";
    	display: block;
    	width: 100%;
    	height: 100%;
    	border: 3px solid $grey-4;
		border-top-color: $blue;
		border-radius: 50%;
		animation: spinner 2s linear infinite;
	}
	
	.updating &{
		position: absolute;
		top: 0;
		left: 0;
	}
}

.rowButtons {
	text-align:center;
	margin:25px 0;
}

.c-color {
	display: inline-block;
	width: 10px;
	height: 10px;
	vertical-align: middle;
	border-radius: 50%;
	background-color: transparent;

	&.color-trigger { cursor: pointer; }
}

.line-color {
	display: block;
	width: 4px;
	height: 100%;
	background-color: transparent;
	position: absolute;
	left: -1px;
	top: 0;	
}

.title {
	.c-color {
		background-color: white;
		
		&.color-none { border:1px solid $grey-1; }
	}
}

.progress {
	height: 3px;
	border-radius: 10px;
	box-shadow: none; 
	
	.progress-bar { 
		box-shadow: none; 

		&.transparentBar {background-color: transparent;}
		&.greyBar {background-color: $grey;}
		&.greenBar {background-color: $green;}
		&.orangeBar {background-color: $color-orange;}
		&.redBar {background-color: $red;}
		&.blueBar {background-color: $blue;} 
	} 
}

.bloque-progreso {
	.help-block {
		display: flex;
		justify-content: space-between;
				
		.progreso-restante { text-align:right; }

		@media #{$max-xs} {
			display: block;

			.progreso-main,
			.progreso-restante {
				display: block;
				text-align: left;
			}
		}
	}
}

.line-loading {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	margin: 0;
	background-color: transparent;
	box-shadow: none;
	border-radius: 0;
	z-index: 1;

	.progress-bar {
		height: 5px;
		width: 20%;
		background-color: $blue-1;

		animation-name: progressBar;
		animation-duration: 5s;
		animation-iteration-count: infinite;
	}
}

/* Cajas */
.box {
    padding: 5px 10px;
    width: 100%;
    line-height: 1.3em;
    margin-right: 10px;
    box-sizing: border-box;
    color:white;
    margin-bottom:10px;

	&.box-grey {
	    background: #f7f7f7;
	    color:$color-input;
	}
}

.caja {
	width:100%;
	display:block;
	border:1px solid $grey-2;
	padding:20px;
}

/* items con comas */
.items-commas .item {
	&:after { content: ","; }
	&:last-child:after { content: ""; }
}

/* Mensajes */
.bloque-mensajes {
	.mensaje{	
		background: $bg-grey;
		border: 1px solid #f2f2f2;
		margin-left:55px;
		padding:10px;
		
		&::after {
		    content: "";
		    position: absolute;
		    left: 45px;
		    right: -20px;
			top: 25px;
			width: 0;
			height: 0;
			border-style: solid;
		    border-width: 10px 10px 10px 0px;
		    border-color: transparent #f2f2f2 transparent transparent;
		}

		&::before {
		    content: "";
		    left: 46px;
		    z-index:2;
		    position: absolute;
		    top: 24px;
		    border-style: solid;
		    border-width: 11px 11px 11px 0px;
		    border-color: transparent $bg-grey transparent transparent;
		}		
	}
	
	.mensaje-autor{
		font-weight:bold;
		color:#999;
		
		&:hover { color: $blue1; }
	}
	
	.mensaje-header {
		margin-bottom: 5px;

		.btn-alink {
			display: none;
			margin-left: 5px;

			img { height:15px; }
		}
	}

	.comentario { margin-left: 25px; }

	.mensaje-editable {
		background: #fefefe;
		border:1px solid #ddd;
		padding:5px;
		margin-bottom:10px;
		display:none;
		position:relative;
		
		.combiControl {
			width: calc(100% - 180px);
			display: inline-block;
		}
		
		.combiButton {
			position:absolute;
			right:5px;
			bottom:5px;
		}
	}
	
	.mensaje-texto {
		margin:10px 0;

		br:first-of-type { display:none; }
	}

	.link-doc {
		padding:6px 0 6px 25px;
		background: url(/saas/img/icons/icons/blue/documentos.svg) 0 15px no-repeat;
		background-size: auto 18px;
		outline:none;
		max-width: calc(100% - 120px);
		height: 45px;
		overflow: hidden;
		
		&:hover{
			color:#888;
			background-image: url(/saas/img/icons/icons/grey/documentos.svg);
		}
	}
	
	.btn-remove{
		min-width:15px;
		padding:0;
		float:right;
		margin-left:10px;
	}
	
	.addComment{
		position:relative;
	
		.combiControl{
			width: calc(100% - 180px);
			display:inline-block;
		}

		.tabulado{
			margin-left:25px;
			width:calc(100% - 25px);
		}
	}
	
	.btn-addComment{
		margin-top:10px;
		
		&.combiButton{
			position:absolute;
			right:0;
			bottom:0;
			margin-top:0;
		}
	}	
	

	.imgProfileMini{
		display: inline-block;
		height:20px;
		width:20px;
		border-radius:50%;
		background:white;
		margin-right:5px;
	}
	
	.vTimeline {
	  margin-top: 2em;
	  margin-bottom: 2em;
	  padding: 20px 0;
  	}

}

/* Filtros buscador */
.filtros {
	width: 100%;
	
	@media #{$min-lg} { 
		width: 300px;
	}
		
	.btn-filter-undo {
		text-transform:initial;
		padding-left:10px;
		padding-right:10px;
	}
	
	.form-control { height:35px;}
	
	.input-group-addon{
		border-color:#f2f2f2 !important;
		background:white;
		cursor:pointer;
		
		&:hover { background: #f2f2f2; }
	}
}


/* Submenus general */
.menu-desplegable {
	top: 22px;
	z-index:5;

	> li {
		@extend .menu-desplegable-option;
	}
}
	
.menu-desplegable-option {
	position: relative;
	padding: 5px 15px;
	color: $dropDown-line-color;
	background-color: $dropDown-line-background;
	cursor: pointer;

	&.menu-option-icon {
		position: relative;
		.menu-option-text { width:calc(100% - 25px); } 
		.menu-icon {
			position: absolute;
			top: 6px;
			left: auto;
			right: 10px;
			max-width: 20px;
		} 
	}
	
	&:hover {
		color: $dropDown-line-hover-color;
		background-color: $dropDown-line-hover-background;
	}	
}

/* popover conceptos  */ 
.tag-importe{
	background:white;
	color: $blue;
	border:1px solid $blue;
	padding:1px 4px;
	border-radius:3px;
	white-space:nowrap;
	
	&:hover, &:focus, &:active{
		background: $blue;
		color:white;
		cursor:pointer;
	}
}

.tt-title{
	color: $blue1;
	font-size: 27px;
	font-weight: 300;
	margin-bottom: 10px;
	margin-top:0; 
}


/* Color select */
	
.pickColor{
	background-image: url("/saas/img/icons/icons/blue/paint.svg");
	background-repeat:no-repeat;
	background-size:20px 20px;
	background-position:50% 50%;
	background-color:transparent;
	width: 20px;
	height: 20px;
	border:none;
	display:inline-block;
	cursor:pointer;	
	
	&:hover{
		background-image: url("/saas/img/icons/icons/grey/paint.svg");
	}
}

.pickColor-wrap{
	.color-display{
		padding:5px;
		display:inline-block;
		height:20px;
		width:20px;
		border-radius: 50%;
		margin-right: 5px;
		float:left;
		cursor:pointer;
		
		&.color-none{
			background-image: url("/saas/img/icons/icons/red/close.svg");
			background-repeat:no-repeat;
			background-size:20px 20px;
			background-position:50% 50%;
			background-color:transparent;
			border:1px solid #ddd;
		}
	}
	
	.inline-box{
		color: $color-input;
		font-size:14px;
		font-weight:300;
		float:left;
		margin-right:10px;
		text-transform:initial;
		line-height:initial;
	}
	
	.label-ficha{
		margin-bottom:10px;
	}

	&.bucket-picker{
		margin-top:7px; 
		margin-right:10px;
		float:left;
	}
}




.menu-color{
	.pickColor{
		position:absolute;
		right:0;
		top:-3px;
	}
	&:hover{
		.pickColor{
			background-image: url("/saas/img/icons/icons/grey/paint.svg");
		}
	} 
}

.popover-pickColor{
	border:1px solid #ccc;
	background:#F2F2F2;
	padding:10px 0 10px 10px !important;
	width:160px !important;
	
	.color-sq{
		width:20px;
		height:20px;
		border-radius:10px;
		display:inline-block;
		//border:1px solid #ccc;
		margin-right:5px;
		cursor:pointer;
		&:hover{
			opacity:0.5;
		}
		
		&.color-none{
			background-image: url("/saas/img/icons/icons/red/close.svg");
			background-repeat:no-repeat;
			background-size:20px 20px;
			background-position:50% 50%;
			background-color:transparent;
		}
	}
	
	.popover-content{
		padding:0;
	}
}

// colors nou

.colores-display{
	white-space:nowrap;
	padding-top:7px;
	
	.color-circle { 
		width: 24px; 
		height: 24px; 
		-moz-border-radius: 12px; 
		-webkit-border-radius: 12px; 
		border-radius: 12px; 
		margin-right:-3px;
		display:inline-block;
		cursor:pointer;
		border:3px solid transparent;
		-webkit-background-clip: content-box !important;
		background-clip: content-box !important;
		position:relative;
		
		&.active{
			margin-right:0;		
			margin-left:3px;
			
			&:after{
				color:white;
				font-family: $f-icons;
				content:"\f00c";
				position:absolute;
				left:2px;
				top:-1px;
			}
		}
		
		&:first-child{
			.active{
				margin-left:0;
			}
		}
			
		&:hover{
			opacity:0.5;
		}
		
		&.color-none{
			background-image: url("/saas/img/icons/icons/red/close.svg");
			background-repeat:no-repeat;
			background-size:20px 20px;
			background-position:50% 50%;
			background-color:transparent;
			
			&.active{
				border-color:#ddd;	
				
				&:after{
					content:"";
				}
			}
		}
	}
}

/* TAGS */
.tag {
	position: relative;
	display: inline-block;
	max-width: 100%;
	line-height: 1em;
	font-size: 14px;
	padding: 6px 8px;
	margin-right: 2px;
	margin-bottom: 2px;
	border-radius: 2px;
	color: $primary-800; //$grey2
	background-color: $primary-100; //$grey-3
	&:last-of-type { margin-right: 0; }
}

.tag-more {
	padding:0;
	
	.tag i {
		font-size:4px;
		margin-right:1px;

		&:last-child { margin-right: 0; }
	}
}

ul.tagit-custom {

	li {
		&.tagit-choice {
			color: $primary-800; //$grey2
			background-color: $primary-100; //$grey-3
	
			&:hover { background-color: $primary-50; }
	
			.tagit-label { color: $primary-800 !important; }
	
			.tagit-close .text-icon {
				color: $primary-800;
				font-weight: bold;
				line-height: 18px;
			}
		}
	
		&.tagit-error{
			background: $color-error;
	
			.tagit-label,
			.tagit-close .text-icon { color: white !important; }
		}
	
		&.tagit-new {
			width: auto;
			clear: none;
		}
	}
}


/* Varios */
.opacity0 {opacity: 0 !important; }

.img-icon {
	height:18px;
	width:auto;
} 

.dropdown-backdrop { z-index: 4; } 


[aria-expanded="true"] .fa.fa-angle-down {
	transform: rotate(-180deg);
	transition: all 1s ease;
}

[aria-expanded="false"] .fa.fa-angle-down {
	transform: rotate(0deg);
	transition: all 1s ease;
}

/* AVATAR */
.avatar {
	span {
		display: inline-flex;
		height: 40px;
		width: 40px;
		border-radius: 4px; //50%

		&.avatar-image {
			overflow: hidden;
			vertical-align: middle;
			background-color: white;

			img {
				object-fit: cover;
				width: 100%;
				height: 100%;
				vertical-align: initial;
			}
		}

		&.avatar-no-image {
			padding: 0;
			font-size: 14px;
			line-height: 20px;
			text-transform: uppercase;
			background-color: $grey-2 !important;
			color: $grey2 !important;
			justify-content: center;
			align-items: center;
		}
	}

	@include avatar(20);
	@include avatar(25);
	@include avatar(30);
	@include avatar(35);
	@include avatar(40);
	@include avatar(50);
	@include avatar(70);
	@include avatar(90);
	@include avatar(140);
	@include avatar(150);
}

.media-upload-img {
	&:first-of-type { margin-top: 0; }

	.media-left .btn { padding: 5px 19px; }
	.media-body p { margin: 5px 0; }
}

.social {
	display: inline-flex;
	justify-content: center;
	align-items: center;
	width: 25px;
	height: 25px;
	border: 1px solid transparent;
	margin: 0 5px;
	border-radius: 50%;
	text-align: center;
	color: white;

	&:first-of-type { margin-left: 0; }
	&:last-of-type { margin-right: 0; }

	@media #{$max-sm} { margin: 0 3px; }

	&:focus,
	&:hover { color: white; }

	&.skype {
		background-color: #00aff0;

		&:hover {
			border-color: #00aff0;
			color: #00aff0;
			background-color: transparent;
		}
	}
	&.linkedin {
		background-color: #0077B5;

		&:hover {
			border-color: #0077B5;
			color: #0077B5;
			background-color: transparent;
		}
	}
	&.facebook {
		background-color: #3B5998;

		&:hover {
			border-color: #3B5998;
			color: #3B5998;
			background-color: transparent;
		}
	}
	&.twitter {
		background-color: #1DA1F2;

		&:hover {
			border-color: #1DA1F2;
			color: #1DA1F2;
			background-color: transparent;
		}
	}
	&.google {
		background-color: #DE4F42;

		&:hover {
			border-color: #DE4F42;
			color: #DE4F42;
			background-color: transparent;
		}
	}	
}

.block-group {
	display: flex;
	margin-bottom: 25px;

	@media #{$max-xs} { flex-direction: column; }

	.block {
		flex-grow: 1;
		flex-basis: 100%;
		padding: 15px 20px;
		margin-left: 25px;
		margin-bottom: 10px;
		background-color: $primary-50;

		&:first-of-type { margin-left: 0; }

		@media #{$max-xs} {
			padding: 15px 10px;
			margin-left: 0;
			margin-bottom: 10px;

			&:last-of-type { margin-bottom: 0; }
		}
	}
}

.block-full-group {
	@extend .block-group;
	flex-direction: column;

	.block {
		margin-left: 0;

		&:last-of-type { margin-bottom: 0; }
	}
}

.stadistic {
	h4 {
		&:first-of-type { margin-top: 0px; }
		font-weight:700;

		small {
			display: block;
			margin-top: 5px;
		}
	}

	.stadistic-icon {
		float: right;
		height: 45px;
	}

	.stadistic-graf {
		height: 100px;
		margin: 0 auto;
	}

	@media #{$max-sm} {
		.stadistic-icon { display: none; }

		.row {
			margin-left: 0;
			margin-right: 0;
		}
	}
}


.stadistic-big {
	font-size: 40px;
	white-space: nowrap;
	font-weight:700;

	@media #{$max-xs} { font-size: 30px; }
}

.stadistic-small {
	font-size: 16px;
	color: $grey1;
}

.stadistic-graph {
	@extend .clearfix;

	.progress {
		height: 5px;
		width: calc(100% - 40px);
		border-radius: 3px;
		margin-top: 20px;
		margin-bottom: 0;
		float: left;
		background-color: $grey-1;
		box-shadow: none;

		@media #{$max-xs} { margin-top: 15px; }

		.progress-bar { border-radius: 3px; }

		+ .help-block {
			margin-top: 5px;
			margin-bottom: 0;
		}
	}

	.progress-percent {
		display: block;
		line-height: 5px;
		width: 35px;
		margin-top: 20px;
		margin-left: 5px;
		float: left;
		text-align: right;
		color: $grey-1;

		+ .help-block {
			margin-top: 5px;
			margin-bottom: 0;
		}

		@media #{$max-xs} { margin-top: 15px; }
	}

	.stadistic-subtext { padding-right: 40px; }
}

.stadistic-subtext {
	p {
		@extend .help-block;

		display: inline-block;
		margin-top: 5px;
		margin-bottom: 0;
	}
}


.operation {
	display: flex;
	flex-direction: row;
	align-items: center;
	font-size: 1em;
	
	.operation-operand {
		flex-grow: 2;
		flex-shrink: 1;
		flex-basis: 17.5%;
	}

	.operation-operator {
		flex-grow: 1;
		flex-shrink: 2;
		flex-basis: 10%;
		padding: 6px 0;
		margin-top: 15px;
		text-align: center;
	}

	p {
		margin-bottom: 0;
		color: $grey1;
	}

	.num {
		font-size: 2em;
		color: inherit;
		font-weight:700;
	}
	
	
	@media #{$max-xs} {
		flex-wrap: wrap;
		font-size: 0.8em;

		.operation-operand,
		.operation-operator {
			&:empty { display: none; }
		}

		.operation-operand {
			flex-basis: 26.5%;

			&.operation-result {
				flex-basis: 100%;
				flex-grow: 8;
				text-align: center;
			}
		}

		.operation-operator {
			text-align: left;

			&.operation-result { display: none; }
		}

		img { height: 15px; }
	}
}

.alert {
	border: none;
	border-radius: 0;
	
	.btn-alert {
		color: inherit;
		font-weight: 600;
		text-transform: uppercase;
	}

	.i-icon {
		height: 30px;
		margin-right: 15px;
	}
	
	&.alert-danger {
		background: $red;
		color: white;

		.btn-alert {
			background-color: $red2;
			margin-left: 15px;

			@include hover-focus {
				background-color: $red3;
			}	
		}
	}

	&.alert-warning {
		border: 1px solid #f5e79e; //$grey-4
		//color:$grey3;
	}

	&.alert-info {
		border: 1px solid $grey-4;
		color:$grey3;
		background-color: #fffadc;
		background-image: url(/saas/img/icons/icons/grey/info.svg);
		background-repeat: no-repeat;
		background-size: 30px 30px;
		background-position: 10px 50%;
		padding-left: 55px;

		ul{
			list-style: initial;
			margin-left: 15px;
			margin-top: 10px;
		}
	}
}

.media-block {
	display: flex;

	.media-left,
	.media-body { display: block; }
}

.form-fase {
	.progress {
		position: relative;
		background-color: $grey-2;

		.progress-bar {
			&.fase-perdida { background-color: #ed1c24; }
			&.fase-hibernando { background-color: #d83c12; }
			&.fase-inicio { background-color: #f15a24; }
			&.fase-presentacion { background-color: #f15a24; }
			&.fase-ofertada { background-color: #f7931e; }
			&.fase-valoracion { background-color: #c9d805; }
			&.fase-ganada { background-color: #39b54a; }
			&.fase-new { background-color: #e2c516; }
		}
	}

	.click-table {
		position: absolute;
		width: 100%;
		height: 100%;
		table-layout: fixed;
		direction: rtl;

		td {
			border-left: 1px solid white;
			border-right: 1px solid white;
			cursor: pointer;

			&:first-child { border-left: 0; }
			&:last-child { border-right: 0; }

			&:hover,
			&.hover { background-color: rgba(0, 0, 0, 0.31); }
		}
	}
}

// add clear

.add-clear-span { 
	position: relative; 
}

.add-clear-x {
	color: #cdcdcd !important;
	
	&:hover { 
		color: $red !important; 
	}
}

.autocompleter-content.add-clear-span{
	.add-clear-x {
		margin-right:25px;
	}
}

.input-group.inputImg.autocompleter-content{
	.add-clear-x {
		margin-right:55px;
	}
}

// input fecha seguido de addClear:
.inpFecha + .add-clear-x{
	right: 32px !important;
}

.inpAviso + .add-clear-x, .inpAviso + .tooltip + .add-clear-x{
	right: 27px !important;
}

.inpAviso + .tooltip{
	width: 170px;
}


.inputSelectGroup .add-clear-x{
	right:85px !important;
}


// bglight
.bg-light {
	background: $primary-50;

	.btn-group {
		.btn {
			&.btn-check-none,
			&.btn-check,
			&.btn-radio {
				background-color: $primary-100;
			}

			&.active {
				background-color: $blue;
			}
			&:hover {
				background-color: $primary-300;
			}
		}

	}
}

// ok / error
.text-error { color: $color-error; }
.text-ok { color: #24b75b; }

.media-heading{
	font-size: 16px;
	color: $primary-800;
	font-weight: 600;
	margin-top: 0;
	margin-bottom: 3px;

	a{
		color: $primary-800;
	}
}

//list users - permisos

.permisos-usuario-container{
	.permisos-multiple{
		display: none;
		margin-bottom: 15px;
		margin-top: 10px;
	}
}
	
.permisos-usuario {

	li {
		padding: 3px 0;
		overflow: hidden;
		display: flex;
		align-items: center
	}
	
	.checkbox{
		margin-right: 7px;
		label{
			background: white;
		}
	}

	.permisos-usuario-img { 
	}

	.permisos-usuario-nombre {
		@extend .substring;

		width: calc(100% - 220px);
		margin-left: 5px;
		margin-right: 10px;
	}

	.permisos-usuario-select {
		width: 160px;
	}
}

.breadcrumb {
	background-color: $grey-3;

	li{
		font-size: 14px;
	}

	> li + li:before {
		content: "\f101";
		font-size: 0.7em;
		color: $grey;
		font-family: FontAwesome;
	}

	.breadcrumb-item {
		color: $grey2;
		font-size: 18px;

		&.active { color: $color-black; }

		&:hover:not(.active) { color: $blue1; }

		a { color: inherit; }
	}

	&.breadcrumb-dark{
		background: $primary-800;

		.breadcrumb-item {
			color: white;
			font-size: 16px;

			&.active {
				color: white;
				font-weight: 600;
			}

			&:hover:not(.active) { color: $blue; }

			a { color: inherit; }
		}
	}
}


.folder-tree {
	.ui-fancytree {
		padding: 0;
		border: 0;
		line-height: 2;
		font-size: 14px;
		color: inherit;
		font-family: inherit;
		overflow-x: hidden;

		> li > .fancytree-node {

			.fancytree-icon { display: none; }

			.fancytree-title {
				margin-left: 0;
				font-size: 15px;
				font-weight: 600;
			}
		}

		ul li {
			position: relative;

			&:before {
				content: "";
				position: absolute;
				top: 0;
				left: 5px;
				height: 100%;
				border: 1px dashed $grey2;
			}

			&:last-child {
				&:before { display: none;}

				.fancytree-expander {
					&:before {
						content: "";
						position: absolute;
						top: 0;
						left: 5px;
						height: 50%;
						border: 1px dashed $grey2;
					}
				}
			}
		}

		.fancytree-node {
			margin-top: 0;

			&:hover { background-color: $blue-4; }

			.fancytree-expander {
				position: relative;
				background: none;
				vertical-align: middle;

				&:after {
					content: "";
					position: absolute;
					top: 50%;
					left: 5px;
					width: 12px;
					border: 1px dashed $grey2;
				}
			}

			.fancytree-icon {
				height: 15px;
				width: 25px;
				margin-left: 5px;
				vertical-align: middle;
				background-image: url(/saas/img/icons/icons/grey/folder.svg);
				background-position: center;

				&:hover { background-position: center; }
			}

			.fancytree-title {
				font-weight: normal;
				padding: 0;
				margin-left: 5px;
				color: $color-black;
				outline: none !important;
				background-color: transparent !important;
			}

			&.fancytree-expanded {
				.fancytree-icon {
					background-image: url(/saas/img/icons/icons/grey/folder-open.svg);
				}
			}

			&.fancytree-active {
				.fancytree-icon { background-image: url(/saas/img/icons/icons/blue/folder.svg); }
				&.fancytree-expanded .fancytree-icon { background-image: url(/saas/img/icons/icons/blue/folder-open.svg); }

				.fancytree-title {
					font-weight: bold !important;
					color: inherit !important;
					background-color: transparent !important;
				}
			}
		}
	}
}

@media #{$max-xs} {
	.show-xs-ib{
		display: inline-block;
		opacity: 1;
	}
	
	.show-xs-b{
		display: block;
		opacity: 1;
	}	
}

/* columnas con padding estrecho */
@media #{$max-sm} {
	.row-narrow-xs{
		margin-left: -5px;
		margin-right: -5px;
		
		[class*="col-"]{
			padding-left:5px;
			padding-right:5px;
		}
	}
}

@media #{$bs-small-only} {
	.row-narrow-sm{
		margin-left: -5px;
		margin-right: -5px;
		
		[class*="col-"]{
			padding-left:5px;
			padding-right:5px;
		}
	}
}

@media #{$bs-medium-only} {
	.row-narrow-md{
		margin-left: -5px;
		margin-right: -5px;
		
		[class*="col-"]{
			padding-left:5px;
			padding-right:5px;
		}
	}
}

@media #{$min-lg} {
	.row-narrow-lg{
		margin-left: -5px;
		margin-right: -5px;
		
		[class*="col-"] {
			padding-left: 5px;
			padding-right: 5px;
		}
	}
}


/* menu acciones superior en tablas */

.helper-multi-actions{
	display: none;
	background: $blue;
	color: white;
	align-items: center;
	justify-content: space-between;
	padding: 12px 20px;
	position: relative;
	
	.btn-more{
		background-image: url(/saas/img/icons/icons/white/more.svg);
	}
	
	> ul{
		margin-bottom: 0;
		li{
			font-weight: 600;
			display: inline-block;
			margin-left: 20px;
			cursor: pointer;
			
			&:hover{
				opacity:0.7;
			}
		}
	}
	
	&:before{
		content: "";
		position: absolute;
		bottom: -8px;
		left: 20px;
		border-left: 9px solid transparent;
		border-right: 9px solid transparent;
		border-top: 8px solid $blue;
		z-index: 1;
	}
}


/* Campos personalizados */

.custom-wrapper {
	position: relative;
}
	
.custom-fields{

	.btn-custom-config{
		position: absolute;
		top: 5px;
		right: 0;
	}
	
	.custom-buttons {
		position: absolute;
		top: 55;
		right: 0;
		z-index: 1;

		.btn:not(.btn-edit) {
			padding: 6px 12px;
			margin-left: 10px;

			&:first-of-type { margin-left: 0; }
		}
	}

	&.tab-pane{ 
		min-height: 500px;	
	}

	.page-content{
		position: relative;
		min-height: 500px;
		
		.btn-custom-config{
			top: -60px;
			right: 0;
		}
		
		.custom-buttons {
			top: 30px;
			right: 30px;
		}		
	}
	

	
	.row-campos {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		padding-top: 30px;

		.custom-field {
			display: flex;
			justify-content: space-between;
			flex-basis: auto;
			flex-grow: 1;
			min-width: 33%;
			padding-bottom: 20px;
			border-bottom: 1px solid $table-border;
			margin-bottom: 20px;

			.field-name {
				flex-grow: 1;
				max-width: 150px;

				label {
					margin-bottom: 0;
					font-weight: normal;
					color: $primary-800;
					line-height: 1.2em;
				}
			}

			.field-value {
				flex-grow: 2;

				p{
					margin: 0;
				}
			}
			
			&.custom-textarea{
				display: block;
				
				.field-name{
					margin-bottom: 5px;
					max-width: 100%;
				}
			}
		}
	}

	pre {
		padding: 0;
		margin-bottom: 0;
		border: 0;
		border-radius: 0;
		background-color: transparent;
		white-space: pre-wrap;
	}	
}


.img-cargando{
	margin: 0 auto;
	display: block;	
}


/* bloques con lineas dashed arriba y abajo */

.bloque-dashed{
	position: relative;
	padding-top: 40px !important;
	padding-bottom: 40px !important;
	
	&:after, &:before{
		content: '';
		position: absolute;
		width: 100%;
		height: 2px;
		left:0;
		background-image: linear-gradient(to right, transparent 50%, $table-border 50%);
    	background-size: 10px 100%;
	}
	
	&:before{
		top: 10px;
	}
	
	&:after{
		bottom: 10px;
	}
}

.row-dashed{
	margin-top: 20px;
	margin-bottom: 20px;
	height: 2px;
	background-image: linear-gradient(to right, transparent 50%, $table-border 50%);
	background-size: 10px 100%;
    border: 0;
    
    .page-content &{
    	margin-left: -30px;
    	margin-right: -30px;
    }
}

.notrans{ transition: none !important; }
.inline-block{display: inline-block !important;}

.radio-colors{
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: 10px;
	
	.radio{
		margin: 0;
		
		label{
			width: 24px;
			height: 24px;
			
			&:after{
				background-color: transparent !important;		
			}
						
			&:before{
				width: 24px;
				height: 24px;		
				border:none;
				//opacity: 0.7;	
			}
			&.color-red:before{
				background-color: $red !important;
			}
			&.color-green:before{
				background-color: $green !important;
			}
			&.color-orange:before{
				background-color: $color-orange !important;
			}
			&.color-lima:before{
				background-color: #c9d805 !important;
			}									
		}
		
		input[type="radio"]:checked + label::before{
			border-width: 3px;
			//opacity: 1;
		}
	}
}

/* panels */

.panel-group .panel{
	border-color: $color-border;
	
	& + .panel {
		margin-top: 5px;
	}
	
	.panel-collapse .panel-body{
		border-color: $color-border;
	}
}


/* box multi-select */
.box-multi-select{
	display: flex;
	justify-content: space-beween;
	
	.list-items{
		display:none;
		width: 50%;
		max-height: 200px;
		overflow-y: auto;
		border: 1px solid $form-input-border;
		padding: 0 10px;
		scrollbar-width: thin;
		scrollbar-color: $grey-1 $grey-3; // 1 barra 2 fondo (firefox)	
		margin-left: 10px;
		min-width: 220px;
		
		label{
			font-weight: 400;
		}
	}
}


/* listbox option switch */

.listbox-options{
	border-top: 0;

	.list-element{
		background: white;
		border: 1px solid $grey-3;
		padding:30px 20px;
		margin-bottom: 30px;
		flex-direction: column;
		height: 100%;

		.option-image{
			max-width: 60px;
			height: auto;
		}

		.option-title{
			font-weight: 600;
			font-size: 17px;
			display: flex;
			align-items: center;
			margin-bottom: 10px;
		}

		.option-footer{
			border-top: 1px solid $grey-3;
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding-top: 10px;
		}

		.btn{
			margin-top: 5px;
		}

		&:hover, &:focus, &:active, &.active{
			outline: 2px solid $blue;
			background: white;
		}
	}
}


 
/* margins y paddings */
.p0{padding:0 !important;}
.pb0{padding-bottom:0 !important;}
.pb20{padding-bottom:20px !important;}
.pt0{padding-top:0 !important;}
.pt15{padding-top:15px !important;}
.m0{margin:0 !important;}
.mb0{margin-bottom:0 !important;}
.mb5{margin-bottom:5px !important;}
.mb10{margin-bottom:10px !important;}
.mb15{margin-bottom:15px !important;}
.mb20{margin-bottom:20px !important;}
.mb25{margin-bottom:25px !important;}
.mb30{margin-bottom:30px !important;}
.mb40{margin-bottom:40px !important;}
.mt0{margin-top:0 !important;}
.mt5{margin-top:5px !important;}
.mt15{margin-top:15px !important;}
.mt20{margin-top:20px !important;}
.mt25{margin-top:25px !important;}
.mt30{margin-top:30px !important;}
.ml5{margin-left:5px !important;}
.mr5{margin-right:5px !important;}
.mt10{margin-top:10px !important;}
.mt15{margin-top:15px !important;}
.ml10{margin-left:10px !important;}
.ml15{margin-left:15px !important;}
.ml20{margin-left:20px !important;}
.mr0{margin-right:0px !important;}
.mr10{margin-right:10px !important;}
.mr20{margin-right:20px !important;}
.mt30{margin-top:30px!important}
.mt-10{margin-top:-10px!important}
.mt-30{margin-top:-30px!important}