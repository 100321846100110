
.table {
	background-color: white;

	.popup & { background-color: transparent; }
	.help-block { margin-bottom: 0 }
	
	.table-empty-message{
		display: none;
	}
			
	&.table-empty{
		.table-empty-message{
			display: table-row; 
		}
	}

	tbody tr.active td { background-color: $primary-50 !important; }
}



.table-list {
	thead {
		tr {
			th {
				height: 50px;
				border-bottom: 0;
				font-weight: 700;
				color: $blue1;
				vertical-align: middle;

				@media #{$min-sm} {
					&.only-check:not(.list-bc) .checkbox { margin-left: 3px; }
				}

				&.list-carets {
					text-align: right;

					.btn {
						background-color:transparent;
						padding: 20px 5px;
						outline: none;
						box-shadow: none;
			
						&.left { padding: 0 9px 0 0; }
						&.right { padding: 0 0px 0 9px; }

						img { max-width: 15px; }
					}
				}

				.help-block {
					display: block;
					margin-top: -2px;
					margin-bottom: 0;
				}
			}
		}
	}
	
	// OJO! no treure ">"
	> tbody {
		> tr {
			> td {
				height: 50px;
				border-color: $table-border;
				vertical-align: middle;
				color: $grey2;
	
				&:first-child:not(.list-bc) { border-left: 1px solid transparent; }

				&:last-child { border-right: 1px solid transparent; }

				&.only-check:not(.list-bc) .checkbox { margin-left: 3px; }

				&.list-money { text-align: right; }


				&.list-actions {
					min-width: 45px;
					text-align: right;
	
					.dropdown-menu {
						top: 20px;
						overflow: initial;
					}
	
					> .btn,
					> .btn-group > .btn {
						background-color: transparent;
						opacity: 0.5;

						&.btn-icon {
							padding: 4px 5px;

							img { height: 25px; }
						}

						&.btn-del { padding: 11px; }

						&:hover { opacity: 1; }
					}

					.hoverAction {
						opacity: 0;

						@media #{$max-sm} { opacity: 1; }
					}

					.spinner { margin: 0 auto; }

					@media #{$max-xs} {
						min-width: unset;
					}
				}
	
				.td-icon {
					&.btn { padding: 6px 9px; }

					img { height: 17px; }
	
					&.pull-left { margin-right: 10px; }
				}

				a,
				.a {
					color: inherit;

					&:focus,
					&:hover { color: $blue1; }
				}
				
				.link { color: $blue}

				p:last-of-type { margin-bottom: 0; }
				/*
				.estado {
					display: inline-block;
					color: white;
				}*/
	
				.media-left { padding-right: 5px; }

				.media-right { padding-left: 5px; }

				@media #{$max-sm} {
					padding: 5px;
				}
			}

			&:last-child > td { border-bottom: 1px solid transparent; }
	
			&.row-selected,	&:focus,	
			&:hover:not(.no-hover) {
				> td {
					border: 1px solid $blue-2;
					background-color: $blue-5;
	
					&:first-child:not(.list-bc) { border-left: 1px solid $blue-2; }
					&:last-child { border-right: 1px solid $blue-2; }
					
					&.list-bc { border-left-width: 4px; }
					
					&:not(:first-child) { border-left: 0; }
					&:not(:last-child) { border-right: 0; }
	
					&.list-actions .hoverAction {
						opacity: 0.5;

						&:hover { opacity: 1; }
					}
				}

				&:last-child > td { border-bottom: 1px solid $blue-2; }
			}
			
			&.comments-list{
				&:hover { background-color: transparent; }
	
				td a { color: $blue; }
			}
		}
	}
	
	.only-radio,
	.only-check { 
		.checkbox,
		.radio {
			width: 20px;
			text-align: left;
			padding-left: 5px;
			line-height: 1em;

			@media #{$max-sm} {
				width: auto;
				padding: 0;
				margin: 0;
			}
		}
	}

	/* TABLE REPORTS */
	&.table-report {
		margin-bottom: 30px;
		background: white;

		thead {
			tr {
				th {
					font-weight: 600;
					color: $blue1;
				}
			}
		}

		tbody {
			tr {
				td { height: auto; }

				&.tr-total {
					&:hover td,
					td {
						font-weight: 600;
						background-color: $grey-2;

						&:first-child { text-transform: uppercase; }
					}
				}

				&.tr-user {
					&:hover td,
					td {
						border-top: 1px solid $grey-3;
						border-bottom: 1px solid $grey-3;
						border-left: 0;
						border-right: 0;
						font-weight: 600;
						background-color: white;

						&:first-child { border-left: 0; }
					}
				}

				&.tr-list td { background: $grey-2; }

				&.tr-task td { background: $grey-3; }

				&.tr-total,
				&.tr-list,
				&.tr-task {
					&:hover td,
					td {
						border: 0;

						&:first-child { border-left: 0; }
					}
				}
			}
		}
	}
}

.table-no-list {
	position: relative;

	.avatar .avatar-no-image { background-color: $grey-2; }

	.table-list {
		.td-empty {
			display: block;
			width: 100%;
			padding: 7px;
			background-color: $grey-2;
		}
	}

	.btn-link { padding: 10px 25px; }

	&.no-list-tasks {
		.table-content {
			background-color: white;

			.title-empty {
				display: inline-block;
				height: 20px;
				width: 200px;
				max-width: 20%;
				min-width: 10%;
				margin: 20px;
				background-color: $grey-2;
			}
		}

		.task-center {
			text-align: right;

			.progress {
				display: inline-block;
				width: 70%;
			}
		}

		.name-empty { width: 35%; }

		.date-empty {
			display: inline-block;
			max-width: 35%;
			padding: 5px;
		}

		.state-empty {
			display: inline-block;
			width: 50%;
		}
	}

	&.no-list-accounts {
		figure .cuenta-logo {
			display: block;
			height: 50px;
			width: 80%;
			background-color: $grey-2;
		}

		.cuenta-nombre,
		.cuenta-label,
		.cuenta-last {
			height: 15px;
			background-color: $grey-2;
		}

		.cuenta-nombre { width: 30%; }
		.cuenta-label { width: 40%; }
		.cuenta-last { width: 30%; }

		.cuenta-saldo,
		.cuenta-last {
			display: block;
			height: 15px;
			width: 25%;
			margin: 5px 0;
			background-color: $grey-2;
		}

		.cuenta-footer .btn { background-color: $grey-2; }
	}

	&.no-list-contacts {
		.media {
			.media-heading {
				display: inline-block;
				height: 20px;
				width: 65%;
				background-color: $grey-2;
			}

			p {
				height: 15px;
				width: 45%;
				background-color: $grey-2;
			}
		}

		.contacts-bloc2,
		.contacts-bloc3 {
			span {
				display: inline-block;
				height: 20px;
				width: 65%;
				background-color: $grey-2;
			}
		}

		.social {
			color: $grey-2;
			background-color: $grey-2;
		}
	}

	.table-degraded {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: $background;
		background: linear-gradient(to bottom, rgba($background, 0) 0%, rgba($background, 1) 55%, rgba($background, 1) 100%);

		.wrapper-degraded {
			width: 60%;
			max-width: 600px;
			margin: 150px auto;
			text-align: center
		}

		h2 { margin-bottom: 30px; }

		.btn-submit{
			height: auto;
		}
	}

	@media #{$max-xs} {
		.table-degraded .wrapper-degraded {
			width: 80%;
			margin: 100px auto;
		}
	}
}

#divMail {
	.table-list {
		table-layout: fixed;

		.date { text-align: right; }
	}
}



/* Tabla listado */
.table-lst {
	border: 1px solid $table-border;
	background: white;
	
	&.table > thead > tr > th, &.table > thead > tr > td, &.table > tbody > tr > td{
		border:0;
		vertical-align:middle;
		border-right:1px solid $table-border;
	}
		
	tr{
		border-bottom: 1px solid $table-border;

		&:last-child { border-bottom: 0; }
	}
	

	
	th {
		background:$primary-50;
		font-weight:600;

		&:last-child { border-right:0 !important; }
	}

	td {
		&:last-child { border-right:0 !important; }

		&.color {
			border-left: 4px solid transparent !important;
			padding-left: 5px;
			margin-left: 5px;
		}
	}

	.divUserMini{
		float: none;
	    margin: 0px auto;
	}
	
	.empty { text-align: center; }
}

/* Tabla ordenable */
.table-sort {
	.order,
	.order_asc,
	.order_desc {
		cursor:pointer;
		white-space: nowrap;
		
		&:after {
			content: "";
			background-image:url(/saas/img/icons/icons/grey/caret_up_down.svg); 
			background-size: 12px 12px;
			background-repeat: no-repeat;
			background-position: 100% 0;
			width: 12px;
			height: 12px;
			margin-left: 4px;
			display: inline-block;
			margin-bottom: -2px;
		}
	}

	.order_asc,
	.order_desc { color: $blue; }

	.order_asc:after { background-image:url(/saas/img/icons/icons/blue/caret_up.svg); }
	.order_desc:after { background-image:url(/saas/img/icons/icons/blue/caret_down.svg); }
}

.table-list, .table-lst, .table-sort{
	td.col-total{
		font-weight: 600;
		color: $primary-800;

		a,.a, .btn-link{
			color: $primary-800;
			font-weight: 600;
		}
	}
}

/* Scrollable tables */

.table-scrollable {
	width: auto;
	overflow-x: auto;
	overflow-y: hidden;
	margin: 0 0 10px 0;
	z-index: 1;

	.dropdown-menu{
		z-index: 99999;
	}

	@media #{$min-sm} {
		.table-fixed-cell {
			position: absolute;
			margin-top: -1px;
			display: flex;
			align-items: center;
			background: $grey-5;
		}
		.table{
			margin-bottom: 0;

			th, td{
				white-space: nowrap;
			}

			tr:hover{
				.table-fixed-cell{
					z-index: 1000;
				}

			}
		}
	}
}

// fix
#divCentral .table-scrollable .popover{
	z-index: 2000 !important;
}

// double scroll
.double-scroll-horizontal{
	scrollbar-width: thin;
	scrollbar-color: $grey-1 $grey-3; // 1 barra 2 fondo (firefox)
}

#fakeScroll{
	overflow-x:auto;
	div{
		height: 1px;
	}
}

@media #{$max-sm} {
	.table-scrollable{
		margin-left: 0 !important;
		margin-right: 0 !important;
	}
}








/* Varios */
.checkbox-out-table { margin-left: 9px; }

#tableSaleBills,
#tableSpending {
	.col-base { text-align: right; }
}


/* Media queries */

@media #{$max-sm} {
	#tableReceiptsPayments,
	#tableBudgets,
	#tableRemittances,
	#tableSaleBills,
	#tableTimes,
	#tableCampaigns,
	#tableActivities {
		.main-menu.toggled & {
			.col-icons,
			.col-description,
			.col-base,
			.col-pedido,
			.col-fecha,
			.list-actions .btn-icon,
			.breadcrumb li:not(:last-child),
			.breadcrumb li:before { display: none; }

			.bloque-progreso .help-block { display: block; }
		}
	}
}

@media #{$max-lg} {
	#tableSaleBills,
	#tableTimes {
		.main-menu.toggled & {
			.col-category,
			.col-tags,
			.breadcrumb li:not(:last-child),
			.breadcrumb li:before { display: none; }

			.col-description .substring { max-width: 250px; }
		}
	}
}

@media #{$min-md} {
	#tableSpending,
	#tableBudgets,
	#tableOrders,
	#tableRemittances,
	#tableSaleBills,
	#tableTimes {
		.list-actions { min-width: 90px; }
	}
}