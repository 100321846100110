#popupStore{
	width: 750px; 
	top: 10px !important;
	
	figure{
		width: calc(100% + 60px);
		margin-bottom: 25px;
		min-height: 200px;
		margin-left: -30px;
		margin-right: -30px;
		margin-top: -15px;

		img{
			width: 100%;
			height: auto;
		}
	}
}

#onboarding.store-list{
	.module-heading{
		margin-bottom: 20px;
		
		img{
			height: 50px;
		}
	}
}