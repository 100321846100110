
/*
//	Font Icons Billage 0.1
@import "variables";
@import "mixins";
@import "path";
@import "core";
@import "larger";
@import "fixed-width";
@import "list";
@import "bordered-pulled";
@import "animated";
@import "rotated-flipped";
@import "stacked";
@import "icons";
@import "screen-reader";
*/

[class^="icono-"], [class*=" icono-"] {
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: 17px 17px;
  width: 17px;
  height: 17px;
}

.icono-user{
  background-image: url(/saas/img/icons/filters/user.svg);
}
a:hover{
  .icono-user{
    background-image: url(/saas/img/icons/filters/hover/user.svg);
  }
}


.icono-filter{
  background-image: url(/saas/img/icons/filters/filter.svg);
}
a:hover{
  .icono-filter{
    background-image: url(/saas/img/icons/filters/hover/filter.svg);
  }
}


.icono-order{
  background-image: url(/saas/img/icons/filters/order.svg);
}
a:hover{
  .icono-order{
    background-image: url(/saas/img/icons/filters/hover/order.svg);
  }
}


.icono-tags{
  background-image: url(/saas/img/icons/filters/tag.svg);
}
a:hover{
  .icono-tags{
    background-image: url(/saas/img/icons/filters/hover/tag.svg);
  }
}


.icono-state{
  background-image: url(/saas/img/icons/filters/state.svg);
}
a:hover{
  .icono-state{
    background-image: url(/saas/img/icons/filters/hover/state.svg);
  }
}


.icono-payment{
  background-image: url(/saas/img/icons/filters/payment.svg);
}
a:hover{
  .icono-payment{
    background-image: url(/saas/img/icons/filters/hover/payment.svg);
  }
}



.icono-project{
  background-image: url(/saas/img/icons/filters/project.svg);
}
a:hover{
  .icono-project{
    background-image: url(/saas/img/icons/filters/hover/project.svg);
  }
}



.icono-date{
  background-image: url(/saas/img/icons/filters/calendar.svg);
}
a:hover{
  .icono-date{
    background-image: url(/saas/img/icons/filters/hover/calendar.svg);
  }
}


.icono-interval{
  background-image: url(/saas/img/icons/filters/interval.svg);
}
a:hover{
  .icono-interval{
    background-image: url(/saas/img/icons/filters/hover/interval.svg);
  }
}



.icono-type{
  background-image: url(/saas/img/icons/filters/type.svg);
}
a:hover{
  .icono-type{
    background-image: url(/saas/img/icons/filters/hover/type.svg);
  }
}



.icono-tasks{
  background-image: url(/saas/img/icons/filters/tasks.svg);
}
a:hover{
  .icono-tasks{
    background-image: url(/saas/img/icons/filters/hover/tasks.svg);
  }
}


.icono-clock{
  background-image: url(/saas/img/icons/icons/blue-dark/clock.svg);
}
a:hover{
  .icono-clock{
    background-image: url(/saas/img/icons/icons/blue/clock.svg);
  }
}


.icono-cuenta{
  background-image: url(/saas/img/icons/icons/blue-dark/cuenta.svg);
}
a:hover{
  .icono-cuenta{
    background-image: url(/saas/img/icons/icons/blue/cuenta.svg);
  }
}

.icono-contacto{
  background-image: url(/saas/img/icons/icons/blue-dark/contacto.svg);
}
a:hover{
  .icono-contacto{
    background-image: url(/saas/img/icons/icons/blue/contacto.svg);
  }
}

.icono-speed{
  background-image: url(/saas/img/icons/icons/blue-dark/speed.svg);
}
a:hover{
  .icono-speed{
    background-image: url(/saas/img/icons/icons/blue/speed.svg);
  }
}

.icono-oportunidad{
  background-image: url(/saas/img/icons/icons/blue-dark/oportunidad.svg);
}
a:hover{
  .icono-oportunidad{
    background-image: url(/saas/img/icons/icons/blue/oportunidad.svg);
  }
}

.icono-campanya{
  background-image: url(/saas/img/icons/icons/blue-dark/campanya.svg);
}
a:hover{
  .icono-campanya{
    background-image: url(/saas/img/icons/icons/blue/campanya.svg);
  }
}

.icono-comment{
  background-image: url(/saas/img/icons/icons/blue-dark/comment.svg);
}
a:hover{
  .icono-comment{
    background-image: url(/saas/img/icons/icons/blue/comment.svg);
  }
}

.icono-phone{
  background-image: url(/saas/img/icons/icons/blue-dark/phone.svg);
}
a:hover{
  .icono-phone{
    background-image: url(/saas/img/icons/icons/blue/phone.svg);
  }
}

.icono-color{
  background-image: url(/saas/img/icons/icons/blue-dark/paint.svg);
}
a:hover{
  .icono-color{
    background-image: url(/saas/img/icons/icons/blue/paint.svg);
  }
}

.icono-fax{
  background-image: url(/saas/img/icons/icons/blue-dark/fax.svg);
}
a:hover{
  .icono-fax{
    background-image: url(/saas/img/icons/icons/blue/fax.svg);
  }
}


.icono-mail{
  background-image: url(/saas/img/icons/icons/blue-dark/mail.svg);
}
a:hover{
  .icono-mail{
    background-image: url(/saas/img/icons/icons/blue/mail.svg);
  }
}

.icono-web{
  background-image: url(/saas/img/icons/icons/blue-dark/web.svg);
}
a:hover{
  .icono-web{
    background-image: url(/saas/img/icons/icons/blue/web.svg);
  }
}


.icono-linkedin{
  background-image: url(/saas/img/icons/icons/blue-dark/linkedin.svg);
}
a:hover{
  .icono-linkedin{
    background-image: url(/saas/img/icons/icons/blue/linkedin.svg);
  }
}