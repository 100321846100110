#viewProject {
	.tab-header {
		.estado {
			display: inline-block;
			margin-right: 15px;

			@media #{$max-xs} { margin-bottom: 10px; }
		}

		.info-project {
			display: inline-block;

			@media #{$max-xs} { padding-right: 60px; }
		}

		.pickColor-wrap {
			display: inline-block;
			float: none;
			line-height: 27px;
			margin: 0;

			.color-display {
				display: inline-block;
				vertical-align: middle;
				float: none;
				margin-left: 2px;
				margin-right: 0;
			}
		}

		.info-account {
			a {
				margin-right: 15px;

				&:last-of-type { margin-right: 0; }
			}
		}

		@media #{$max-xs} { margin-top: 5px; }
	}	

	.stadistic-member {
		position: relative;
		overflow: hidden;

		> * {
			float: left;
			padding: 0 10px;

			&:first-child { padding-left: 0; }
			&:last-child { padding-right: 0; }
		}

		.media {
			.media-left {
				display: block;
				float: left;
			}

			.media-body {
				display: flex;
				flex-direction: column;
				width: calc(100% - 40px);

				.media-heading{
					font-weight: 600;
				}
			}
		}

		.member-info {
			width: 26%;
			cursor: pointer;
		}

		.member-profile { width: 13%; }

		.member-graph {
			@extend .stadistic-graph;

			&.graph-long { width: 34%; }
			&.graph-short { width: 23%; }

			.progress {
				width: 100%;
				margin-top: 0;
			}
		}

		.member-del {
			width: 2%;
			margin-left: 5px;
		}

		@media #{$max-sm} {
			.member-info {
				width: 100%;
				padding-right: 0;
			}

			.member-profile {
				width: 100%;
				padding-left: 40px;
				padding-right: 0;
			}

			.member-graph {
				&.graph-long {
					width: 62%;
					padding-left: 40px;
					padding-right: 5px;
					margin-top: 5px;
				}

				&.graph-short {
					width: 38%;
					margin-top: 5px;
					padding-right: 0;
					padding-left: 5px;
				}
			}

			.member-del {
				position: absolute;
				top: 0;
				right: 0;
			}
		}

		@media #{$max-xs} {
			.media .media-body { display: inline-block; }

			.member-profile br { display: none; }

			.member-graph {
				&.graph-long,
				&.graph-short {
					width: 100%;
					padding: 0;
				}
			}
		}
	}	
	
	.block-alert {
		@extend .stadistic;
		display: flex;

		[class^="alert-"] {
			padding: 0 15px;

			&:first-child { padding-left: 0; }
			&:last-child { padding-right: 0; }

			&.alert-icon {
				position: relative;
				width: 8%;

				> img {
					position: absolute;
					top: 50%;
					transform: translateY(-50%);
					height: 35px;
				}
			}

			&.alert-element { width: 30%; }

			&.alert-graph {
				@extend .stadistic-graph;
				width: 32%;
				.progress {
					width: 100%;
					margin-top: 10px;
				}
			}

			.form-group {
				margin-bottom: 0;

				label {
					margin-bottom: 0;
					color: $grey1;
				}

				.form-control {
					padding: 0;
					border: 0;
					font-size: 20px;
					color: $color-black;
				}
			}
		}
		
		@media #{$max-xs} {
			display: block;
			
			.alert-icon { display: none; }
			
			[class^="alert-"] {
				padding: 0 !important;
				width: 100% !important;
			}

		}
		
	}	
	
	.media .media-body {
		vertical-align: middle;

		.media-heading {
			margin-bottom: 0;
		}

		p:last-child { margin-bottom: 0; }
	}

	.btn-close { float: right; }

	.block-full-group {	
		margin-top: 15px;

		.block { margin-bottom: 10px; }

		@media #{$max-xs} {
			.block:last-child { margin-bottom: 0; }
		}
	}

	.log-list {
		max-height: none;

		.log-container { min-height: 615px; }
	}

}

/* Alta proyectos */ 
#alta-proyecto{
	.tagit-new input[type="text"] { height: auto !important; }

	.estadoContainer {
		height: 34px;
		padding: 5px 0;
	}
	
	#tableMiembrosProyecto{
		.checkbox {
			width: 50px;

			label {
				&:before,
				&:after { margin-left: 0; }
			}
		}

		.imgProfile {
			margin-right:10px;
			display:inline-block;
		}			
	}

	.alertaIcon {
		text-align: center;

		img { margin-top: 20px; }
	}

	.radio-block {
		background-color: white;
		padding: 15px;
		border: 1px solid $color-input-border;
		border-radius: 5px;
		float: left;
		
		.radio-inline {
			line-height: 20px;
			padding-left: 25px;

			input[type="radio"] { margin-top: 4px; }
		}

		.input-group {
			margin-top: 10px;
			margin-bottom: 5px;
			margin-left: 25px;
		}
	}
}

#resumenTiempos {
	display: none;

	#masterPeriodo .filtroTiempo { display:none; }
}

#helpAlertFecha,
#helpAlertGasto,
#helpAlertHoras,
#helpAlertCoste {
	padding-left: 0;
	padding-right: 0;
}

/* INFORMES */
.reports {
	margin-top: 10px;

	.box {
		padding: 10px;
		margin-bottom: 0;
		color: $grey2;
		background: white;
	}

	.table-report-times {
		th {
			text-align: right;

			&:first-child { text-align: left; }
		}

		tr td {
			text-align: right;

			&:first-child { text-align: left; }
		}
	}
}

/* INFORMES */
#reportsIndex .caja {
	text-align: center;
}

.name-project {
	float: right;
	font-size: 15px;
	color: #828284;
	z-index: 0;
	margin-right:10px;
}

/* TIEMPOS */
#tableTimes {
	.separador-fecha .sum {
		text-align: right;
		font-weight: 600;
	}

	.sp-fin:before {
		content: " - ";
		color: $grey;
	}

	.breadcrumb { margin-bottom: 0; }

	.btn-round { display: none; }

	.col-description .substring { max-width: 350px; }

	tr:hover .btn-round {
		display: inline-block;
		width: 28px;
		height: 28px;
		color:$grey2;
		background: $grey-1;
	}

	@media #{$max-lg} {
		.col-description .substring { max-width: 230px; }
	}

	@media #{$max-md} {
		.breadcrumb li:not(:last-child),
		.breadcrumb li:before { display: none; }

		.col-description .substring { max-width: 250px; }
	}

	@media #{$max-xs} {
		.btn-round,
		tr:hover .btn-round,
		.separador-fecha td.sum,
		.sp-fin { display: none; }

		.separador-fecha td .sum {
			display: inline-block;
			float: right;
		}

		.list-actions{ min-width: unset; }
	}
}

/* listado proyectos */
.project-details {
	display: flex;
	align-items: center;
	color: $grey1;
	font-size: 14px;
	
	.project-bars{
		width: 80px;
		display: flex;
		flex-direction: column;
		align-items: center;
		
		.progress{
			height: 5px;
			
			&:nth-child(2){
				margin-top: 10px;
			}
		}
	}

	.btn-alert{
		position: absolute;
		left: 0px;
	}
}

.table-list .project-details { float: right; }

.project-info {
	white-space: nowrap;
	text-align: right;
	padding-right: 5px;
	min-width: 105px;
}

.project-bars {
	padding-left: 3px;
	width: 100%;
	
	.progress {
		width: 100%;
		margin: 0;
		background-color: $grey-2;
		box-shadow: none;

		&:last-child { margin-top: 1px; }
	}
}

.task-progress {
	white-space: nowrap;
	display: flex;
	flex-direction: column;
	text-align: right;
	
	.project-bars { width: 100px; }
}

.listado-progress { 
	margin-right: 15px; 
	text-transform: initial;
	font-weight: 400;
	font-size: 14px;
	color: #777;
	
	.project-bars { 
		margin-top: 3px;
		
		 .progress{
	 		background-color: white;
	 	}
	 }
}