
/* PLANTILLAS FACTURAS */

.custom-templates {
	.template-sidebar {
		background: white;
		padding: 30px;
		
		@media #{$max-xs} { 
			padding-left: 15px;
			padding-right: 15px;
		}		
	}
	
	.checkbox + .checkbox { margin-top: 10px; }
	
	.btn-cam {
		background: $grey-3;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		color: $grey1;
		font-size: 17px;
		width: 32px;
		height: 32px;
		position: absolute;
		bottom: 15px; 
		right: 15px;
		
		&:after {
			content: "\e909";
			font-family: 'icomoon';
			line-height: 18px;
		}
		
		&:hover {
			background: $grey-1;
			color: $grey-3;
		}
	
	}
	
	.uploadImg{
		position: relative;
		overflow: hidden;
		border: 1px solid $grey-3;
		padding: 30px;
		color: $grey1;
		background-color: transparent;
		width: 100%;
		
	
		& > input[type=file] {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			cursor: pointer;
			opacity: 0;
		}
	
		h3 {
		    margin: 0 0 10px 0;
			font-size: 20px;
			color: $grey2;
		}
		
		img {
			width: 100%;
			height: auto;
			max-width: 200px;
		}
	}	
	
	.bloque-logo {
		margin-bottom: 20px;
		position: relative;

		.help-block {
			display: block;
			text-align: right;
		}

		.btn-link { padding: 0; }
	}
	
	.svg-template {
		background: white;
		width: 100%;
		height: auto;
	}
	
	.evo-pop {
		background: white;
		border:1px solid $grey-3;
		width: auto;
		padding: 15px;
	}
	
	.evo-cp-wrap{
		position: relative;
		width: 100% !important;
		
		.form-control { width: calc(100% - 50px); }
	}
	
	.evo-colorind, .evo-colorind-ie, .evo-colorind-ff {
	    border: none;
	    width: 40px;
	    height: 40px;
	    float: none;
		position: absolute;
		right: 0;
		top: 0;
	}	
	
	.template-custom { display: none; }
	
	
	.template-container{
		svg{
			min-height: 1035px;
		}
	}
}

.upload-container {
	display: flex;

	.upload-img {
		align-self: center;
		margin-right: 20px;
		color: $blue;
	}
}

#dpopup_imagen_empresa {
	position: fixed;
	top: 50% !important;
	left: 50% !important;
	transform: translate(-50%, -50%);
	width: 500px;
}


#divSelectTemplate{
	background: transparent;
	box-shadow: none; 
	
	.popup-header {
		.popup-title{
			border: none;
		}
		.popup-btn-close{
			right: 15px;
			top: 0;
		}
	}
	article{
		background: white;
		margin-bottom: 5px;
		display: flex;
		align-items: center;
		justify-content: center;
		color: $grey;
		border: 1px solid $grey-2;
		cursor: pointer;
		
		&:hover{
			border-color: $blue-2;
		}
		
		&.active{
			border: 2px solid $blue;
		}		
	}
	
	.custom .img-plantilla {
		flex-direction: column;
		padding: 20px;
		line-height: 1.2em;
		text-align: center;
		 
		img{
			width: 50px;
			height: auto;
			margin-bottom: 5px;
		}			
	}	
	
	.svg-template{
		height: 100%;
		width: 100%;
	}
	
	h4{
		margin-bottom: 15px;
		font-size: 15px;
		color: $blue;
		text-align: center;
	}
	
	@media #{$max-xs} { 
		position: absolute !important;
	}
}