.columna-ajax-info{
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	font-size: 12px;
	min-width: 140px;
	align-items: center;
	
	img{
		margin-right: 5px;
	}
}