.dashboard {
	position: relative;
	z-index: 0;

	[data-sizex="1"] { width: calc((100% - 30px) / 4); }
	[data-sizex="2"] { width: calc((100% - 30px) / 2 + 10px); }
	[data-sizex="3"] { width: calc(((100% - 30px) /4) * 3 + 20px); }
	[data-sizex="4"] { width: 100%; }

	[data-col="1"] { left: 0; }
	[data-col="2"] { left: calc((100% - 30px) / 4 + 10px); }
	[data-col="3"] { left: calc(((100% - 30px) /2) + 20px); }
	[data-col="4"] {
		left: auto;
		right: 0;
	}

	.widget-settings .btn-more,
	.highcharts-button, .DTTT_container {
		opacity: 0.5;

		&:hover { opacity: 1; }
	}

	.widget_moving {
		opacity: 0.7;
		z-index: -10;
	}

	.widget_resizing {
		opacity: 0.7;
		z-index: -10;
	}

	.buttonInactive {
		background: #dcdcdc !important;
		cursor: none !important;
	}

	.widget-dropdown {
		top: 30px;
		right: -10px;
		min-width: 130px;
	}

	.widget {
		display: table-cell;
		padding: 30px 15px 15px 15px;
		margin: 3px;
		overflow: hidden;
		background: white;
	}

	.widget-area { margin-top: 25px; }

	.widget-title {
		position: absolute;
		top: 15px;
		padding:0;
		margin: 0 0 20px 0;
	}

	.widget-settings {
		position: absolute;
		top: 10px;
		right: 15px;
		height: 20px;
		z-index: 100;
	}

	.img-loading {
		display: block;
		width: 32px;
		margin: 60px auto 0 auto;
		opacity: 0.4;
	}

	.empty-chart-bar,
	.empty-chart-line,
	.empty-chart-pie,
	.empty-chart-number,
	.empty-chart-list {
		height: 230px;
		background-size: auto 100%;
		background-repeat: no-repeat;

		img { display: none; }
	}

	.empty-chart-bar { background-image: url(/saas/img/icons/backgrounds/dashboard/chart-bar.svg); }
	.empty-chart-line { background-image: url(/saas/img/icons/backgrounds/dashboard/chart-line.svg); }
	.empty-chart-list { background-image: url(/saas/img/icons/backgrounds/dashboard/chart-list.svg); }
	.empty-chart-number {
		height: 55px;
		background-size: auto 55px;
		background-image: url(/saas/img/icons/backgrounds/dashboard/chart-number.svg);
	}
	.empty-chart-pie {
		background-size: auto 150px;
		background-position-y: 30px;
		background-image: url(/saas/img/icons/backgrounds/dashboard/chart-pie.svg);
	}

	.caja-listado {
		li,
		.otros-contenido {
			padding-top: 5px;
			padding-bottom: 5px;

			&.otros {
				padding-top: 0;

				.otros-contenido { cursor: pointer; }

				ul { display: none; }
			}
		}
	}

	.kpi-number,
	.kpi-right-number {
		display: block;
		font-size: 3em;
		color: $color-dark;
	}

	.kpi-description,
	.kpi-right-description {
		display: block;
		margin-top: -10px;
	}

	.kpi sup {
		top: -1.2em;
		font-size: 45%;
	}

	.oWrap {
		padding-right: 10px;
		margin-right: -20px;
		overflow-y: auto;
		overflow-x: hidden;
		scrollbar-width: thin;
		scrollbar-color: $grey-1 $grey-3; // 1 barra 2 fondo (firefox)		
	}

	.dataTable {
		width: 100%;

		thead {
			th{
				padding: 13px 20px 13px 0;
				border-top: 1px solid $table-border;
				border-bottom: 1px solid $table-border;
				font-weight: 600;
				color: $color-dark;
			}

			.sorting,
			.sorting_asc,
			.sorting_desc { background-image: none; }

			.sorting_asc,
			.sorting_desc { color: $blue; }
		}

		tr {
			td {
				padding: 8px 20px 8px 0;
				border-bottom: 1px solid $table-border;
			}
		}

		&.no-footer {
			border-bottom: 1px solid $table-border;
			margin-bottom: 5px;
		}

		.col-num { text-align: right; }
	}

	.dt-buttons {
		margin-right: 10px;

		button.dt-button,
		div.dt-button,
		a.dt-button {
			padding: 0px 5px;
			border: 0;
			border-right: 1px solid #ddd;
			margin: 5px 0 0 0;
			font-size: 14px;
			color: $blue;
			background: white;

			&:last-child { border-right: 0 !important; }

			&:hover,
			&:active,
			&:focus {
				background: white;
				border: 0;
				border-right: 1px solid #ddd;
				margin: 0;
				color: $color-dark;
			}
		}
	}

	.dataTables_wrapper {
		padding: 5px;

		.dataTables_paginate {
			.paginate_button {
				border: none !important;
				color: $text;

				&.current,
				&.current:hover,
				&:hover {
					color: white !important;
					background: $blue;
				}

				&.current,
				&.current:hover { font-weight: 600; }

				&.disabled,
				&.disabled:hover,
				&.disabled:active { color: #bbb !important; }
			}
		}

		.dataTables_length,
		.dataTables_filter,
		.dataTables_info,
		.dataTables_processing,
		.dataTables_paginate { color: $text !important; }

		.wrapTop {
			margin: 0 0 20px 0;
			overflow: hidden; 

			label,
			select {
				margin-bottom: 0;
				font-weight: 600;
			}

			input,
			select {
				height: 30px;
				padding-left: 5px;
				padding-top: 4px;
				padding-bottom: 4px;
				border: 1px solid $form-input-border;
				font-weight: 400;
			}

			/*.dataTables_length { display: none; }*/
		}

		.dataTables_paginate,
		.dataTables_info { font-size: 90%; }

		/*.dataTables_info { padding-left: 10px; }*/
	}

	.gridster {
		.gs-w {
			display: table;
			border: 1px solid white;
			z-index: 1;
			background-color: white;
		}

		.preview-holder {
			width: 100%;
			border: 1px dashed $blue-3 !important;
			z-index: 0;
			opacity: 1 !important;
			background-color:#f9f9f9 !important;
		}
	}

	@media #{$max-md} {
		.gridster > ul {
			display: flex;
			flex-direction: column;
			height: auto !important;

			.gs-w {
				display: block !important;
				position: relative !important;
				top: auto;
				left: auto;
				height: auto;
				margin-bottom: 10px;

				.widget {
					display: block;
					max-width: 100%;
					margin: 0;

					.dt-buttons { float: left !important; }
				}
			}
		}

		[data-sizex="1"],
		[data-sizex="2"],
		[data-sizex="3"],
		[data-sizex="4"] { width: 100%; }
	}

	@media #{$max-xs} {
		.widget .widget-settings .dt-buttons {
			margin-right: 5px;

			.dt-button {
				padding: 0 4px 0 1px;
				font-size: 12px;
			}

			.widget-area { margin-top: 15px; }
		}

		.dataTables_wrapper {
			padding: 0;

			.wrapTop {
				margin-bottom: 15px;

				.dataTables_filter { margin-top: 0; }
			}

			.oWrap {
				padding: 0;
				margin: 0;
				overflow: auto;
				scrollbar-width: thin;
				scrollbar-color: $grey-1 $grey-3; // 1 barra 2 fondo (firefox)
			}

			.dataTables_paginate > span { display: none; }
		}

		.dataTable {
			tr th,
			tr td { padding: 5px 10px 5px 0; }
		}
	}
}

/* empty */
.dashboard-empty {
	width: 100%;
	padding: 100px 70px;
	text-align: center;
	background: white;

	.dashboard-empty-img {
		width: 130px;
		margin-bottom: 30px;
	}

	.title { margin-bottom: 20px; }

	.btn { margin-top: 20px; }

	@media #{$max-xs} {
		padding: 50px 35px;
	}

	@media #{$max-xxs} {
		.btn {
			padding: 5px 10px;
			margin-top: 0;
			white-space: initial;
		}
	}
}

/* popup selector */
#divAddWidgetPopup {
	width: 920px;

	#selectorAddWidget {
		position: relative;
		height: 560px;
		width: 100%;
		padding: 10px 0 10px 10px;
		overflow: hidden;
		background-color: $blue-4;
	}

	.ps__rail-y { opacity: 0.6; }

	.mix:after {
		content: attr(data-myorder);
		display: inline-block;
	}

	.filter {
		padding: 3px 5px;
		border: none;
		border-radius: 2px;
		margin: 5px 3px;
		cursor: pointer;
		background-color: $blue-4;

		&.filterAll {
			padding: 3px 0;
			color: inherit;
			background: transparent;
		}

		&.mixitup-control-active:not(.filterAll) {
			background: $text;
			color: white;
		}

		&:first-child { margin-left: 0; }

		&:hover { opacity: 0.7; }
	}

	.filter-divider {
		display: inline;
		width: 0px;
		height: 100%;
		border-top: 0;
		border-left-width: 1px;
		border-left-style: solid;
		margin: 0 10px;
	}

	.widget-preview {
		width: 30%;
		height: 250px;
		padding: 20px;
		border: 1px solid transparent;
		margin: 10px;
		float: left;
		background: white;
		text-align: center;
		cursor: pointer;

		.widget-preview-title {
			font-size: 16px;
			color: $color-dark;
			font-weight: 600;
			margin-bottom: 10px;
			line-height: 1.25em;
		}

		.widget-preview-img {
			height: 60px;
			width: 60px;
			margin-top: 15px;
			margin-bottom: 20px;
		}

		&.widget-preview-selected {
			border: 1px solid $blue;
			background: $blue-3;
		}
	}

	.controls {
		width: 100%;
		margin: 15px 0;
	}

	@media #{$max-md} {
		left: 5px;
		top: 5px !important;
		width: 100%;

		.widget-preview { width: 47%; }
	}

	@media #{$max-sm} {
		.widget-preview { width: calc(100% - 30px); }
	}

	@media #{$max-xs} {
		#selectorAddWidget {
			height: 475px;
		}
	}
}

// Popup Filtros
#divWidgetFiltersPopup { width: 550px; }

.page-header-dashboard{
	@media #{$max-md} {
		.main-row{
			display: block;
		}
	}
	
	.default-panel{
		display: flex;
		align-items: center;
		
		span{
			margin-right: 10px;
		}
	}
	
	.switch{
		input:checked + .slider{
			background: $green;
		}
	}
}