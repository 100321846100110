.info-blocks {
	.main-amount {
		display: block;
		font-size: 2.7em;
		line-height: 1em;
		color: $grey4;
		font-weight: 700;

		@media #{$max-sm} { font-size: 2em; }
	}

	.color-green { color: #03C85F !important; }
	.color-yellow { color: #FEE550 !important; }
	.color-red { color: #FF6D62 !important; }
	.color-blue { color: #32D8F6 !important; }
	.color-billage { color: #2C64FF !important; }

	.stateGreen { background: #03C85F !important; }
	.stateYellow { background: #FEE550 !important; }
	.stateRed { background: #FF6D62 !important; }
	.stateBlue { background: #32D8F6 !important; }
	.stateBillage { background: #2C64FF !important; }

	.highcharts-credits { display: none }

	.caja {
		border: none;
		padding: 15px;
		margin-top: 0;
		margin-bottom: 15px;
		
		h2 { 
			font-size: 18px;
			font-weight: 700;
		}
			
		h3 {
			font-weight: 400;
			color: #888;
		}
	}

	.row-resumenes {
		.caja {
			h2 { 
				margin-bottom: 0; 
			}
		}
	}

	.caja-listado {
		margin-bottom: 0;

		li {
			padding-top:2px;
			padding-bottom: 2px;
		}
	}

	.empty-box img {
		width: 100%;
		height: auto;
		max-height:180px;
	}

	.listbox article { padding: 1px 0; }
}