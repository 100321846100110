// TODO: borrar
.log-list2 {
	.log-container {
		max-height: 450px;
		margin-bottom: 30px;
		overflow-y: auto;
		overflow-x: hidden;
		scrollbar-width: thin;
		scrollbar-color: $grey-1 $grey-3; // 1 barra 2 fondo (firefox)
		margin-right: -30px;
    	padding-right: 30px;
	}

	.log-block {
		padding: 15px 0;

		&:first-of-type {
			padding-top: 0;
			border-top: 0;
		}

		&:before,
		&:after { display: none; }

		.media {
			.media-heading {
				padding-top: 0;
				margin-bottom: 0;
				font-size: 14px;
				font-weight: normal;

				a { color: inherit; }
			}
		}
	}

	.help-block {
		margin: 0;

		span {
			margin-left: 5px;

			&:before {
				content: "-";
				margin-right: 5px;
			}

			&:first-of-type {
				margin-left: 0;

				&:before { display: none; }
			}
		}
	}

	.log-block-container {
		padding: 0;
		border: 0;
		margin-left: 0;
		background-color: transparent;

		&:before,
		&:after { display: none; }
	}

	&.log-comments {
		.form-group .inpTextarea {
			.form-control { padding-bottom: 20px; }

			.dz-clickable {
				position: absolute;
				bottom: 5px;
				right: 5px;
				padding: 9px;
			}

			.dropzone {
				font-size: 0.8em;

				.btn-close {
					padding: 8px;

					&:hover { opacity: 1; }
				}
			}
		}

		.log-block.log-comment {
			.media-heading {
				margin-bottom: 10px;
				font-weight: 600;
			}

			.comment-area {
				padding: 8.5px 12px;
				border: 1px solid transparent;
				border-radius: 2px;
				margin-bottom: 0;
				font-size: 14px;
				white-space: pre-wrap; 
				//background-color: $grey-5;
				word-break: break-word;
			}

			.inpTextarea .form-control { font-size: 14px; }

			.comment-edit-btn,
			.comment-answer-btn { min-height: 40px; }		
		
			.help-block span {
				&.log-edit,
				&.log-delete,
				&.log-reply {
					position: relative;
					cursor: pointer;

					&:after {
						content: "";
						position: absolute;
						bottom: 0;
						right: 0px;
						width: calc(100% - 8px);
						border-bottom: 1px solid $grey;
						opacity: 0.7;
					}

					&:hover {
						color: $grey1;

						&:after { opacity: 1; }
					}
				}
			}

			.media {
				.media-body {
					.comment-answer-btn { display: none; }
					.row { position: relative; }
					.bottom-left {
						position: absolute;
						right: 0;
						bottom: 0;
					}
				}

				&.media-reply {
					padding-left: 40px;

					.avatar { margin-top: 3px; }

					.media-body {
						.media-heading { margin-bottom: 5px; }

						pre {
							padding: 0;
							border: 0;
							background-color: transparent;
							white-space: pre-wrap;
						}
					}
				}

				&:first-of-type .media-body .help-block span.log-delete { display: none; }
				&:only-of-type .media-body .help-block span.log-delete { display: initial; }
			}
		}
	}
}

.bg-light {
	.log-list2 {
		textarea.form-control,
		textarea.form-control:focus { background-color: white; }
	}
}
.mentions-list{
	margin-top: 5px;
	margin-bottom: -2px;
	margin-left: 40px;
	li{
		color: white;
		background: $corporative-color;
		font-weight: bold;
		border-radius: 20px;
		padding: 2px 10px;
		font-size: 13px;
		display: inline-flex;
		line-height: 1.3em;
		margin-right: 3px;
		&:last-child{
			margin-right: 0;
		}
	}
}

.comment-body{
	.mentions-list {
		margin-left: 0 !important;
	}
}

.btn-mention {
	position: absolute;
	bottom: 5px;
	right: 5px;
	padding: 3px 9px;
	line-height: 1em;
	color: $corporative-color;
	font-weight: bold;
}
 
.bloque-detalles{
	.btn-mention{
		top: 0;
		bottom: auto;
		right: 0;
	}

	.mentions-list{
		margin-left: 0;
	}
}

.log-comments{
	.log-container {
		max-height: 450px;
		margin-bottom: 30px;
		overflow-y: auto;
		overflow-x: hidden;
		scrollbar-width: thin;
		//scrollbar-color: $grey-1 $grey-3; // 1 barra 2 fondo (firefox)
		margin-right: -30px;
		padding-right: 30px;
	}

	.log-block{
		padding-top: 10px;
		padding-bottom: 10px;
	}

	.new-comment{
		padding-top: 10px;
		padding-bottom: 10px;
		display: flex;
		flex-direction: column;

		.inpTextarea {
			.form-control { padding-bottom: 20px; }

			.dz-clickable {
				position: absolute;
				bottom: 5px;
				right: 5px;
				padding: 9px;
			}

			.dz-clickable + .btn-mention {
				right: 25px;
			}

			.dropzone {
				font-size: 0.8em;

				.btn-close {
					padding: 8px;

					&:hover { opacity: 1; }
				}
			}
		}

		.btn-comment{
			margin-top: 10px;
			margin-left: auto;
			font-size: 15px;
			display: none;

			&.btn-default{
				background: white;
			}
		}
	}

	.btn-comment-edit, .btn-comment-reply{
		margin-left: 5px;
		min-height: 40px;
		font-size: 15px;
	}

	.comment{
		display: flex;

		.menu-desplegable{
			top: 15px;
		}

		.dropdown-menu{
			min-width: 140px;
		}

		.avatar{
			margin-right: 10px;
		}

		.comment-heading{
			display: flex;
			margin-bottom: 5px;
			height: 20px;

			.dropdown{
				display: flex;
			}

			.btn-more{
				height: 20px;
				background-position-x:100%;
				display: none;
			}
		}

		&:hover{
			.btn-more{
				display: block;
			}
		}

		.comment-body{
			flex:1;
		}

		.comment-date{
			color: $grey1;
			font-size: 13px;
			line-height:15px;
			flex:1;
		}

		.comment-author{
			font-weight: 700;
			margin-right: 5px;
			font-size: 16px;
			line-height:12px;

			a{
				color: $text;
				//text-decoration: underline;
			}
		}

		.comment-area {
			padding: 7px 10px;
			border: none;
			border-radius: 2px;
			margin-bottom: 0;
			font-size: 16px;
			white-space: pre-wrap;
			background-color: white;
			word-break: break-word;
		}

		&.comment-reply{
			padding-left: 40px;
			margin-top: 15px;
		}
	}

	.activity {
		display: flex;

		.log-icon{
			&:after{
				padding-right: 10px;
			}
		}
		.activity-body{
			flex: 1;
			font-size: 16px;
		}

		.activity-author{
			font-weight: 700;
		}
		.activity-content{

		}
		.activity-date{
			color: $grey1;
			font-size: 13px;
		}
	}

	// comentarios en proyectos:
	&.log-list{
		.log-container{
			margin-top: 15px;

			.log-block{
				padding-bottom: 15px;
			}

			.avatar{
				z-index: 10;
			}

			.comment-reply{
				margin-top: 15px;
			}

			.comment-area{
				background: $grey-4;
			}
		}
	}
}



/*** NOU ***/
.log-container .listbox {
	padding-bottom: 40px;
	
	.bloque:last-child { margin-right: 0; }
	
	.bloque-actions{
		min-width: 45px;
		text-align: right;
		
		.btn-more{
			opacity: 0;
		}
	}
	
	article:hover{
		.btn-more{
			opacity: 1;
		}
	}

	@media #{$max-xs} {
		article { flex-wrap: wrap; } 

		.bloque {
			flex-grow: 1;
			flex-shrink: 1;
			flex-basis: auto;

			&:empty { display: none; }
		}

		.bloque-user {
			display: none;

			+ .bloque { padding-left: 0; }
		}

		.bloque-fecha {
			max-width: 100%;

			br { display: none; }
		}
		

	}
}

.log-no-results{ display: none; margin-top: 30px}

.log-topbar{
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 10px 0;
	margin: 10px 0;
	text-align: right;

	@media #{$max-xs} {
		display: block;
		margin-top: 5px;
		margin-bottom: 5px;
	}
}

.log-filters {
	text-align: center;

	a {
		display: inline;
		padding: 5px 7px;
		border-right: 1px solid $grey-2;
		font-weight: 600;
		color: $grey;

		&:first-child { padding-left: 0; }
		&:last-child { border-right: 0; }
		&.active { color: $color-black; }
		&:hover { color: $blue; }
	}

	@media #{$max-xs} {
		margin-bottom: 10px;

		a {
			font-size: 0.8em;
			padding: 5px;
		}
	}
}

.log-note {
	border-left: 2px solid $grey1;
	padding-left: 7px;
	margin-top: 10px;
}

.log-reminder{
	color: $grey2;
	overflow: hidden;
	margin-top: 7px;

	i {
		font-size: 20px;
		float: left;
	}
}

.log-icon {
	border-radius: 50%;
	width: 40px;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content:center;
	color: $grey1;
	background: transparent;
	
	&:after{
		content: "";
		font-family: "FontAwesome";
		font-size: 20px;
	}
}

.log-icon-send:after { content: "\f1d9"; }

.log-icon-note {
	background: #444;
	color: white;

	&:after { content: "\f0f6"; }
}

.log-icon-edit:after { content: "\f040"; }
.log-icon-delete:after { content: "\f014"; }
.log-icon-new:after {
	content: "\e905";
	font-family: "icomoon";
}
.log-icon-new2:after { content: "\f0c5"; }
.log-icon-remesa:after { content: "\f016"; }
.log-icon-member:after {
	content: "\e973";
	font-family: "icomoon";
}
.log-icon-status:after {
	content: "\e906";
	font-family: "icomoon";
}
.log-icon-list:after {
	content: "\e9bb";
	font-family: "icomoon";
}
.log-icon-emails {
	background: $red;

	&:after {
		content: "\f003";
		margin-bottom: 2px;
		color: white;
	}
}
.log-icon-meetings {
	background: $yellow;

	&:after {
		content: "\e972";
		font-family: "icomoon";
		color: white;
	}
}
.log-icon-demos {
	background: $green;

	&:after {
		content: "\e907";
		font-family: "icomoon";
		margin-top: 2px;
		color: white;
	}
}
.log-icon-calls {
	background: $blue;

	&:after {
		content: "\f10b";
		margin-top: 2px;
		font-size: 26px;
		color: white;

		@media #{$max-xs} {
			font-size: 22px;
			line-height: 30px;
		}
	}
}
.log-icon-seen:after { content: "\f06e"; }
.log-icon-pay:after {
	content: "\e93c";
	font-family: "icomoon";
}