.highcharts-tooltip{
	text-align: center;
	font-size: 16px;
	color: #888;
	
	.htt-title{
		font-size: 16px;
		color: #888;
	}
	
	strong, .htt-main{
		color: $color-dark;
		font-size: 18px;
	}
}


.highcharts-root{

	@media #{$max-sm} { 
		
		.highcharts-legend{
			transform: translate(-5px,25px) !important;
		}
		
		.highcharts-legend-item{
			text{
				font-size: 13px !important;
			}
			
			rect{
				height: 10px !important;
				width: 10px !important;
			}
		}
	}		

}