#timetracking {
	padding-left: 0;
	list-style-type: none;

	.tracking-project, .tracking-task{
		display: block;
	}

	.tracking-project{
		color: $text;
		font-size: 13px;
	}
	.tracking-task{
		color: $primary-800;
		font-weight: 600;
	}

	.trakingTask,
	.trakingController {
		padding: 8px 20px 8px 34px;

		@media #{$max-sm} {
			padding-left: 15px;
		}
	}

	.trakingTask {
		padding-bottom: 0;
	}

	.trakingController {
		.media {
			overflow: initial;

			.media-left,
			.media-right {
				.media-object {
					width: 20px;
					height: 20px;
					padding: 0;
					line-height: 0;

					* { height: 100%; }
				}

				&.media-right {
					padding-left: 5px;

					.media-object * { height: 100%; }
				}
			}
		}
	}

	#timetracking-counter {
		font-size: 16px;
		font-weight: 600;
		line-height: 20px;
	}
}

#popup-tracking {
	position: fixed;
	top: inherit !important;
	bottom: 20px !important;
	left: 20px !important;
	width: 800px;
	
	.bloque-main{
		padding-bottom: 30px;
	}
	
	.bg-light{
		padding-top:0;
	}

	.btn-round{
		width: 24px;
		height: 24px;
		background: $primary-400;
		color: white !important;
		
		i {
			font-size: 11px;
			display: block;
		}
		
		&:hover { opacity: 0.8; }
	}

	.form-group {
		&.disabled{	
			.input-group.inputImg{
				.input-group-addon.addon-add{
					label{
						display:none;
					}
				}
			}
		}		
		
		&.form-error {
			> label { 
				color: #eb7c6b !important; 
			}
			> .form-control {  
				border-color: #eb7c6b !important; 
			}
			> .input-group.inputImg{
				.form-control { 
					border-color: #eb7c6b !important; 
				}
				.input-group-addon.addon-add{
					border-color:#eb7c6b !important;
				}				
			}
		}		
	}

	.botonera{
		margin-top: 10px;
		
		.btn{
			width: calc(50% - 10px);
		}
	}
	
	#btn_timetracking-start {
		padding: 10px;
		margin-right: 10px;
	}	
	
	.tracking-historial{
		overflow-y: auto;
		max-height: 410px;
		scrollbar-width: thin;
		scrollbar-color: $grey-1 $grey-3; // 1 barra 2 fondo (firefox)
		
		article{
			border-bottom: 1px solid $primary-100;
			padding: 5px 30px;
			overflow: hidden;
			margin-left: -30px;
			margin-right: -30px;
			display: flex;
			align-items: center;
			justify-content: space-between;

			&:last-child{
				border-bottom: 0;
			}
		} 
		
		.tracking-info{
			width: calc(100% - 100px);
		}
		
		.tracking-action{
			width: 45px;
			text-align: right;
			color: #999;
			padding-top: 5px;
		}		
		 
		.tracking-time{
			width: 55px;
			text-align:right;
			padding-top: 8px;
		}
	}
	
	
	
	.separador-fecha{
		padding: 5px 30px;
		margin-left: -30px;
		margin-right: -30px;
		overflow: hidden;

		.sum{
			float:right;
		}
	}
}


.separador-fecha{
	background: $primary-100;
	color: $primary-800;
	padding: 5px;
	
	.fecha{
		font-weight: 600;
	}	
	
	td{
		height: 30px !important;
		color: $primary-800 !important;
	}
}