#facturacion {
	&.container {
		height: auto;
		overflow: auto;
		scrollbar-width: thin;
		scrollbar-color: $grey-1 $grey-3; // 1 barra 2 fondo (firefox)
	}

	.row-facturacion-head { margin-bottom: 25px; }
	
	.btn-edit-cliente{
		@extend .btn-action;
		background: url(/saas/img/icons/icons/grey/edit.svg) 5px 50% no-repeat;
		background-size: auto 17px;
		padding-left: 27px !important;
		font-size: 14px;
		text-transform: lowercase;
		color: $grey;
	}

	.cuenta-direcciones {
		margin-top: 30px;
		margin-bottom: 20px;
		margin-right: -7.5px;
		margin-left: -7.5px;
		width: calc(100% + 15px);
		display: flex;

		&.cuenta-direcciones-empty {
			/*background-image:url("../img/icons/backgrounds/white-lines.svg");
			background-size:auto 80px;
			background-repeat:no-repeat;
			min-height: 100px;
			*/
			.row { display: none; }
		}

		.direccion-col{
			display: flex;
			width: 50%;
			padding-right: 7.5px;
			padding-left: 7.5px;
		}

		.direccion{
			width: 100%;
			background: $primary-50;
			padding: 15px;

			.direccion-titulo{
				color: $blue1;
				font-weight: 600;
			}
		}

		&.edit { margin-top: 0; }

		h4 { font-size: 14px; }
	}

	// BLOQUE CONCEPTOS:
	.list-conceptos { 
	
	 }

	.conceptos-head {
		display: flex;
		flex-flow: row wrap;
		border-bottom: 1px solid $table-border;
		font-weight: 600;
		color: $blue1;
		
		.col-descripcion{
			padding-left: 5px;
		}	
	}
	
	.concepto-descripcion {
		display: none;
		min-height: 40px;
		border-top: 1px solid $table-border;
		background:#feffec;
		padding: 10px;

		.obs-linea {
			padding: 0;
			padding-right: 10px;
			background: transparent;
			box-shadow: none;
			
			&:focus { 
				box-shadow: initial;
			}			
		}

		@media (max-width: 780px) {
			padding: 5px;
			font-size: 14px;

			.expanding-clone{
				min-height: 20px !important;
			}
		}
	}

	.row-concepto {
		background: white;
		display:flex;
		align-items: flex-start;
		justify-content: space-between;
		border-bottom: 1px solid $table-border;

		&:hover, &.open {
			.menu-concepto,
			.order-container:not(.order-off) { display: block; }
		}
		
		.concepto-main{
			flex: 1;
			max-width: calc(100% - 250px);
			
			@media (min-width: 1100px) {
				max-width: calc(100% - 300px);
			}	
			
			@media (min-width: 1250px) {
				max-width: calc(100% - 350px);
			}	
				
			@media (min-width: 1400px) {
				max-width: calc(100% - 380px);
			}
			
			@media (min-width: 1500px) {
				max-width: calc(100% - 480px);
			}	
		}
		.col-descripcion{
			//border-right: 1px solid $table-border;
		}				
		&.active{
			border: 1px solid $blue;
		}

		&.active,
		&.filled-desc {
			.concepto-descripcion { 
				display: block; 
			}
		}

		&.ui-draggable-helper-list {
			border: none !important;
			background-color: $grey-4; 

			.form-control-o { background-color: $grey-4; }

			.concepto-descripcion { display: none !important; }
		}
		
		.col-total{
			font-weight: 600;
			color: $blue;
		}	
		
		@media #{$max-sm} {
			display: block;
			
			.concepto-main{
				max-width: 100%;
			}
					
			.col-descripcion{
				border-right: none;
				max-width: 100%;
				min-width: 100%;
			}
		}		
		
	}

	.list-conceptos-placeholder {
		width: 100%;
		min-height: 40px;
		border: 1px dashed $grey-1;
		background-color: $grey-4; 
		transition: 0.3s;
	}

	.cell {
		position: relative;
		flex: 1 1 0;
		min-height: 40px;
		padding: 10px;
		white-space: nowrap;

		.form-control-o {
			position: absolute;
			height: 100%;
			width: 100%;
			padding: 10px;
			transition: none;
			box-shadow: none;
			
			&:focus { 
				box-shadow: initial;
			}
		}
		
		&.form-error {
			display: block;
			.form-control {
				border-bottom:1px solid $form-error-input-border;
			}
		}


		&.editable { padding: 0; }

		&:last-child { border-right: 0; }
	}

	.concepto-info {
		position: relative;
		display: flex;
		flex-flow: row wrap; 

		&:before {		// fix per obrir la icona d'ordenació en hover fora
			content: "";
			position: absolute;
			left: -240px; // -40px
			width: 40px;
			height: 40px;
			cursor: move;
			z-index: 0;
			
			@media #{$max-sm} {
				left: -40px;
				height:80px;
			}		
			
			@media (min-width: 1100px) {
				left: -340px;
			}	
			
			@media (min-width: 1250px) {
				left: -390px;
			}	
				
			@media (min-width: 1400px) {
				left: -420px;
			}
			
			@media (min-width: 1500px) {
				left: -520px;
			}				
		}

		&:after {		// fix per obrir el menu en hover fora
			content: "";
			position: absolute;
			right: -40px;
			width: 40px;
			height: 40px;
			cursor: pointer;
			z-index: 0;
		}

		.cell:last-child { border-right: 0; }

		.btn-more,
		.btn-order,
		.order-container {
			height: 40px;
			width: 35px; 
			z-index: 1;
		}

		.order-container {
			position: absolute;
			left: -280px; // -35px
			top: 0;
			display: none;

			&.order-off { cursor: initial; }
			
			@media #{$max-sm} {
				left: -35px;
				top: -20px;
			}
			
			@media (min-width: 1100px) {
				left: -330px;
			}	
			
			@media (min-width: 1250px) {
				left: -380px;
			}	
				
			@media (min-width: 1400px) {
				left: -410px;
			}
			
			@media (min-width: 1500px) {
				left: -510px;
			}				
		}

		
		.btn-more{ cursor: pointer; }

		.btn-order {
			top: 10px;
			padding: 15px;		
			background-position: 90% 50%;
			cursor:move;
		}

		.menu-concepto {
			position: absolute;
			display: none;
			right: -35px;
			top: 0;

			.dropdown-menu {
				left: -160px;
				top: 30px;
				background: white;
				font-size: 14px;

				li {
					padding: 10px 20px;
					border-bottom: 1px solid $grey-3;
					
					&:last-child{
						border-bottom: 0;
					}

					.help-block { margin: 0; }

					&.option-contable {
						.form-control {
							padding: 0;
							height: auto;
							padding-right: 18px;
							margin-left: -3px;
							margin-bottom: -2px;
						}					
					}
					
					&.option-entrega, &.option-contable, &.option-codigo-albaran{
						padding: 5px 20px 10px 20px;
					}

					&.option-retencion .checkbox {
						margin: 0;

						label { padding-left: 30px; }
					}

					&.option-eliminar { cursor: pointer; }
				}
			}
		}


	}

	.col-small {
		flex: 1;

		@media #{$max-sm} {
			flex: 0 0 23%;
			max-width: 23%;
			min-height: 45px;

			.form-control {
				height: 45px;
				padding-top: 22px;
				padding-bottom: 8px;
			}
		}
	}

	.col-medium {
		flex: 1;
		max-width: 160px;
		min-height: 45px;

		@media #{$max-sm} {
			flex: 0 0 31%;
			max-width: 31%;

			.title-mini { text-align: center; }
		}
	}
	
	// contenido tabla
	.col-descripcion {
		min-width: 250px; // fix
		max-width: 250px; // fix
		
		@media (min-width: 1100px) {
			min-width: 300px; 
			max-width: 300px; 
		}	
				
		@media (min-width: 1250px) {
			min-width: 350px; 
			max-width: 350px; 
		}	
			
		@media (min-width: 1400px) {
			min-width: 380px; 
			max-width: 380px; 
		}
		
		@media (min-width: 1500px) {
			min-width: 480px; 
			max-width: 480px; 
		}
		
		.autocompleter-content { position: initial; }
		
		.desc-linea{
			position: relative;
			height: auto;
			max-height: none;
			min-height: 0;
			line-height: normal;
			padding: 10px 0 5px 5px;
			resize: none;	
		}
		
		.autocompleter-content {
			.spinner{
				top: 12px !important;
				left: auto !important;
				right: 35px !important;
				position: absolute;
			}
			
			.caret {
			    top: 22px;
			    margin-right: 0;
			    background-position: 100% 50%;
			}		
		}
	}

	.col-ctd,
	.col-dto {
		text-align: center;

		.form-control { text-align: center; }
	}		
	
	.col-precio, .col-precio-bruto, .col-total, .col-dto{
		text-align:right;
		
		.form-control{
			text-align:right;
		}
	}
	
	.col-total{
		padding-right: 5px;
	}	
	
	.col-iva{
		.form-control{
			padding-right: 18px;
		}
		
		@media #{$bs-xsmall-only} {			
			.form-control{
				padding-top: 20px;
				padding-bottom: 5px;
				height: 45px;
			}
		}			
	}
	
	.title-mini{
		display:none;
		
		@media #{$bs-xsmall-only} {			
			display: block;
			position: absolute;
			top: 0;
			background: #f2f2f2;
			text-transform: uppercase;
			font-size: 10px;
			color: #888;
			z-index: 1;
			left: 0;
			width: 100%;
		}
	}
	
	&.no-edit{
		.form-control-o{
			pointer-events: none;
		}
		
		select{
			background: none;
		}
		
		.menu-concepto .btn-more, .btn-order{
			display: none;
		}
		
		.concepto-info{
			&:before, &:after{
				cursor: initial;
			}
		}
	}

	.row-cabecera{
		.col-ctd, .col-unidad, .col-precio, .col-precio-bruto, .col-dto, .col-iva, .col-total, .option-retencion, .option-contable, .option-entrega, .option-guardar
		{
			display: none;
		}
		
		.col-descripcion .form-control-o{
			font-weight: 600;
			color: #686868;
			padding-left: 5px;
			
			&:placeholder-shown {
				text-transform: initial;
				color: #999;
			}
		}
	}
	
	.addLinea{
		margin-top:20px;
	}
	
	.addCabecera{
		margin-top:20px;
		margin-left: 10px;
	}
	
	.hideMas{
		display:none;
	}
	
	.row-notas{
		padding-top: 25px;
	}
	

	.no-edit-error{
		margin-top:15px;
		font-size: 90%;
		color: $red;
	}
	
	textarea{
		&.form-control{
			height:initial;
			
			&.expanding{
				height: 40px;
				
				&:placeholder-shown { 
					border:none;
				}
			}			
		}	
	}
	
	.form-group {
		margin-bottom: 20px;
		 
		&.form-disabled label { color: #ccc !important; }

		@media #{$max-xs} {
			margin-bottom: 15px;
		}
	} 
	
	
	
	&.fra-periodica {
		.direccion-col{
			margin-bottom: 25px;
			
			@media #{$bs-large-up} {
				width: 100%;
			}				
		}
	}

	.form-dias-antes .form-group { margin-bottom: 0; }
}





.totalizador{
	margin-top: 20px;
	background: $primary-50;
	display: flex;
	justify-content: space-between;

	li{
		padding: 10px 30px;
		flex-grow: 1;
		display: flex;
		flex-direction: column;
		justify-content: space-between;

		.totalizador-concepto{
			font-weight:600;
			font-size: 1em;
			margin: 7px 0;
			color: $blue1;
		}
		
		.totalizador-importe{
			font-size: 30px;
			font-weight: 700;
			color: $blue;
			white-space: nowrap;
		}
		
		&:last-child{
			background: $color4;
			color: white;
			
			.totalizador-importe, .totalizador-concepto{
				color: white;
			}
		}
		
		@media #{$max-lg} {
			padding: 8px 25px;
			
			.totalizador-importe{
				font-size: 24px;
			}
		}
					
		@media #{$max-sm} {
			padding: 5px 15px;
			
			.totalizador-concepto{
				font-size: 13px;
			}
			
			.totalizador-importe{
				font-size: 18px;
			}		
			
		}
	}
	
	@media #{$max-xs} {

		flex-wrap: wrap;
		
		li{
			flex: 0 0 20%;
			padding: 5px 10px;
			

			&:last-child{
				flex-basis: 100%;
				flex-direction: row;
			}					
		}

	}	

	&.totalizador-mini{
		li {
			padding: 5px 15px;

			.totalizador-importe{
				font-size: 24px;
			}

			.totalizador-concepto{
				margin: 0;
			}
		}

		&.totalizador-right{
			width: 400px;
			margin-left: auto;
			margin-right: 0;
		}
	}
}












#divTiemposFacturables{
	background: #f9fafa; 
	padding: 20px 30px 30px 30px; 
	margin: 30px -30px 40px -30px;
	
	.help-block {
		margin-bottom: 20px;
		display: none;
	}
	
	.span-no-seleccionados { 
		display: none;
		margin: 5px 0;
		color: $red;
	}
	
	.rowButtons{
		margin-bottom: 0;
	}
	
	.table-list{
		background: transparent; 
		
		thead {
			th {
				border-top: 1px solid $table-border !important;  
				border-bottom: 1px solid $table-border !important;  
			}
		}	
	}
	
	.sum-total{
		font-weight: bold;
		width: 100%;
		text-align: right;
		padding-right: 10px;
		
		span{
			display: inline-block;
			margin-left: 50px;
		}
	}
}	

#divGastosFacturables{
	display: none;
	padding-top:25px;
}

#facturacion-ext{
	.no-results-generador{
		margin-top: 10px;
		margin-bottom: 30px;
	}
	
	.form-generador {
		.form-group {
			display: inline-block;
			margin-bottom: 0;
			vertical-align: middle;
		}

		.btn-submit-sm{
			margin-left:5px;
			display: inline-block;
		}

		.form-label{
			margin-right:10px;
			display: inline-block;
			max-width: 100%; 
		}

		.input-group{
			width: auto;
			display: inline-table;
			vertical-align: middle;

			.form-control { width: 100%; }
			
			.input-group-addon { width: auto; }
		}
		
		.form-control,
		.btn-submit-sm {
			height: 34px;
			padding-top: 6px;
			padding-bottom: 6px;
		}
		
		@media #{$min-lg} {
			text-align:right;
		}
	}
	
	.page-content .block-header {
		margin-bottom: 20px;
		padding-bottom: 0;

		
		@media #{$min-sm} {
			display: flex;
			justify-content: space-between;
			align-items: center;
		}
				
		&:not(:first-child) { margin-top: 50px; }

		.title {
			margin: 0 0 20px 0;
			font-size: 1.7em;
		} 

		.form-inline {
			display: inline-block;
/*
			.form-control { 
				height: 34px; 
			}
			*/
			.form-label{
				margin-right: 7px;
			}
			
			.input-group-addon{
				label{
					padding: 5px 4px;
				}
			}
		}
		
		.input-group{
			width: auto;
			max-width: 180px;
		}		
		
		@media #{$max-sm} { 
			display: block;
			
			.input-group{
				width: 100%;
				max-width: 100%;
			}	
			.title{
				display: block;
			}
		}		
		@media #{$max-xs} { 
			.form-inline {
				width: 100%;
			}
		}

		.block-facturas-info{
			justify-content: flex-end;
			display: flex;
			flex-direction: column;

			.checkbox{
				height: auto;
				margin: 0;
				justify-content: flex-end;

				label{
					font-weight: 400;
				}
			}
		}
	}
}

.no-guardar #facturacion,
.no-edit {
	.form-control,
	.btn.btn-radio {
		pointer-events: none;
		border-color: $table-border !important;

		&:hover,
		&:focus { border-color: $table-border; }
	}

	select { background: none; }

	.addon-add {
		border-color: $table-border;

		label {
			display:none;

			&:before { background: none; }
		}
	}

	.required { display: none; }
}

#tabActividad {
	.page-content{
		padding: 0;
	}
	.top-buttons{
		margin-left: 10px;
		text-align: right;
		padding: 10px 20px;
	}
}




/* CONTABILIDAD - CONFIGURACIÓN */
#contabilidad {
	.btn-primary.pull-right { margin-top: 12px; }

	.table-list{
		td:first-child, th:first-child{
			padding-left: 25px;
			width: 50%;
		}
	}
		
	.list-parametros{
		width: calc(100% - 50px);

		li {
			display: flex;
			padding-top:5px;
			padding-bottom:5px;
		}
		
		.list-label{
			color: $grey;
			padding-right: 20px;
			width: 40%;
		}
	}
}



#configContabilidad{
	width: 400px;
}

#idTableCuentasContables{ 
	th{
		padding-bottom: 5px;
		border-bottom: 1px solid $table-border;
		height: auto;
	}
	
	tbody{
		// chapuza
		th{	display: none;	}
	
		tr{
			td{
				padding-left:0;
				padding-right:0;
				border: 0;
				
				&:first-child{
					padding-right: 10px;
				}
			}
			
			&:first-child{
				td{
					padding-top: 15px;
				}
			}
		}
	}
}

// VIEW INVOICE

.sent-invoice{
	.navbar-alt{
		padding-top: 10px;
		padding-bottom: 10px;
		
		h1{
			margin: 0;
			font-weight: 400;
			font-size: 24px;
		}
		
		.header-inner {
			display: flex;
			justify-content: space-between;
			align-items: center;
		}
		
		.btn-transparent{
			color: white;
			opacity: 0.8;
			font-size: 24px;
			padding-top: 0;
			padding-bottom: 0;
		}
		
		.btn-cta:not(.btn-cta-multi){
			margin-left: 40px;
		} 
		
		.dropdown-cta{
			label{
				font-size: 16px;
			}
			
			.dropdown-menu{
				margin-top: 6px;
			}
		}
	}
	
	.page-content {
		background: white;
		padding: 30px;
		margin-top:100px;
		margin-bottom:30px;
	}
	
	.invoice-head{
		display: flex;
		justify-content: space-between;
	}
		
	.invoice-direcciones{
		margin-bottom: 30px;
		
		ul{
			margin-top: 10px;
			li{
				&:first-child{
					font-weight: 600;
					margin-bottom: 5px;
				}
			}
		}
	}
	
	.invoice-detalles{
		padding-right:30px;
		padding-top: 20px;
		
		.title{
			display: block;
			font-size: 32px;
			font-weight: 600;
			margin-bottom: 20px;
		}
		
		ul {
			li{
				padding-top: 5px;
				padding-bottom: 5px;
			}
		}
	}
	
	.invoice-logo{
		max-width: 150px;
		height: auto;
		max-height: 80px;
		margin-bottom: 20px;
	}
	
	.invoice-vencimiento{
		display: flex;
		justify-content: space-between;
		align-items: center;	
		
		.estado{
			margin-left: 20px;
			border-radius: 0 !important;
			font-size: 15px;
			font-weight: 600;
			padding: 2px 10px !important;
		}
	}
	
	.list-conceptos{
		margin-top: 15px;
	}
	
	.concepto-descripcion{
		padding: 10px;
	}
	
	.col-descripcion.cell{
		white-space: initial !important;
	}
	
	.row-cabecera{
		.col-descripcion{
			font-weight: 600;
			color: $grey2;
		}
	}
	
}




// presupuestos

.presupuestos-action{
	border-bottom: 1px solid $grey-3;
	margin-bottom: 35px;
	padding-bottom: 10px;
	
	.form-inline{
		margin-bottom: 20px;
	}
	
	.input-group-addon{
		border: 0;
		background: none;
		padding-left: 0;
	}
	
	.budget-flex{
		display: flex;
		
		> *{
			margin-right: 15px;
		}
		
		#aliasCliente{
			width: 310px;
		}
		
		.input-group .caret{ margin-right:5px} // fix
	
		.btn{
			height: 40px;
			align-self: flex-end;
			margin-bottom: 20px;
			margin-right: 0;
		}
	}
}


#doc-serie, #doc-ref{
	color: $primary-800;
	font-size: 20px;
	font-weight: 400;
}


// factura electrónica

#popupFacturae{
	width: 540px;
}

#tabFacturae{
	.alert.alert-info {
		background-position: 10px 20px;
	}
}

// financiar facturas santander

.step-list{
	li{
		display: flex;
		margin-bottom: 20px;
	}
	.step-circle{
		width: 40px;
		height: 40px;
		min-width: 40px;
		padding: 10px;
		border-radius: 50%;
		line-height: 20px;
		font-size: 20px;
		font-weight: 600;
		text-align: center;
		color:	#fff;
		background-color:$green;
		margin-right: 15px;
	}
	
	.step-content{
		.step-title{
			font-size: 16px;
			font-weight: 600;
			margin-bottom: 5px;
			margin-top: 0;
		}
	}
}




#popupSantanderSteps{
	width: 600px;
	.step-list{
		margin-top: 30px;
	}

	.popup-footer{
		margin-top: 60px;
	}
}

.btn-santander{
	.santander-disponible{
		display: none;
		font-weight: 400;
		color: $text;
		
		span{
			color: $color-santander;
			font-weight: 700;
		}
	}
}



#popupSantanderFinance, #popupSiiIntegration{
	.sin-seleccion{
		text-align: center;
	}
	

	
}

#popupSantanderFinance{
	width: 700px;
	
	.popup-title{
		border-bottom: 0;
	}	
		
	.santander-title{
		font-size: 20px;
		font-weight: bold;
		text-align: center;
		margin-bottom: 10px;
		margin-top: 40px;
	}
	
	.santander-intro{
		font-size: 16px;
		margin-bottom: 50px;
		text-align: center;
		
		span{
			color: $color-santander;
		}
	}	
	
	
	.popup-footer{
		margin-top: 60px;
		text-align: center; 
	}	
		
	.santander-steps{
		text-align: center;
		
		h3{
			font-weight: bold;
			margin-top: 10px;
			margin-bottom: 5px;
			height: 40px;
			display: flex;
			justify-content: center;
			align-items: center;
		}
		
		figure{
			border-radius: 50%;
			background: $blue;
			height: 68px;
			width: 68px;
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 15px; 
			margin: 0 auto;
			
			img{
				width: 100%;
				height: auto;
			}
		}
		
		.icono-done{
			color: $green;
			font-size: 30px;
			margin-left: 5px;
		}
		
		.col-conecta{
			&.active{
				.icono-done{
					display: none;
				}			
			}
		}
	}
	
	.step-text{
		font-size: 16px;
		margin-top: 50px;
	}
	
	.step-text-alert{
		color: $color-santander;
		margin-top: 20px;
		font-size: 90%;
		text-align: center;
		
		.i-icon{
			height: 17px;
			margin-right: 5px;
		}
	}	
	
	.finance-container{
		min-height: 200px;
	}	
	
}

#popupSiiIntegration{
	width: calc(100% - 30px);
	top: 50px !important;
	max-width: 1300px;
	
	.btn-primary-outline.popup-btn-close{
		margin: 0 auto;
		display: block;
	}
	
	.popup-content{
		padding: 30px 30px 0 30px;
	}
	
	.validacion{
		font-weight: normal;
		margin-top: -30px;
		width: 100%;
		padding: 0;
	}
	
	.sii-details{
		display: none;
		&.active{
			display: block;
		}
	}
	
	.row-bloques{
		margin-top: -45px;
		display: flex;
		justify-content: space-between;
		border-bottom: 1px solid $grey-3;
		
		.bloque{
			padding-top: 30px;
			padding-bottom: 30px;
			overflow-y: auto;
			scrollbar-width: thin;
			scrollbar-color: $grey-1 $grey-3; // 1 barra 2 fondo (firefox);
			max-height: 680px;			
		}		
		
		.bloque-main{
			flex-grow: 2;

		}
		
		.bg-light{
			width: 0%;
			display: none;
			transition: all 1s ease-in;
		}
		
		&.open{
			.bg-light{
				width: 60%;
				display: block;
			}		
		}
	}
	
	.input-group{
		.add-clear-x{
			right: 45px !important;
		}
		
		input{
			border-color:$color4;
			padding-right: 8px;
		}
		
		.input-group-addon{
			background: $color4; // $grey-2
			color: white;
			border: 1px solid $color4;
			font-weight: 700;
			padding: 7px;
		}			
	}
	
	.table-sii{
		border: 1px solid $color-border;
		border-collapse: initial;
		margin: 30px 0 50px 0;
		
		.col-factura{
			width: 150px;
		}
		.col-fecha{
			width: 150px;
		}		
		tr:hover{
			td{
				cursor: pointer;
			}
		}
		
		.sii-item.has-error td{
			border-color: $red;
		}
	}
	
	.table-sii-bases{
		th{
			border-bottom: 1px solid $color-border;
			padding-bottom:0;
		}
		
		td, th{
			&:first-child{
				padding-left:0;
			}
			&:last-child{
				padding-right:0;
			}			
		}
		tbody{
			td{
			
			}
		}
		
	}
	
	.totalizador{
		li{
			&.totalizador-cuota, &.totalizador-retencion{
				background: white;
			}
		}
		.totalizador-concepto{
			margin-bottom: 0;
		}		
		
		.totalizador-total{
			position: relative;
			
			.btn-edit-icon{
				position: absolute;
				color: white;
				right: 0;
				top: 0;
				width: 40px;
				height: 40px;
				
				&:hover{
					color: $blue;
				}
			}
			
			.total-input{
				display: none;
				border: none;
				border-bottom: 1px solid white;
				background: $color4;
				color: white;
				font-size: 30px;
				font-weight: 700;
				width: 100px;
			}
		}
	}
	
	.sii-historial{
		margin-top: 40px;
		font-size: 14px;
		
		h4{
			font-weight: 700;
			font-size: 18px;
		}
	}

}


#popupSantanderPaymentsIntegration{
	width:800px;
	
	.popup-content{
		min-height: 200px;
	}
	
	.panel-errores{
		padding-top:30px;
		
		.list-helps{
			margin-left: 20px;
			li{
				list-style-type: initial;
				padding-top:3px;
				padding-bottom:3px;
			}
		}
	}
}



.ayuda-titulos-content{
	display: none;
}