#navbarTop > .navbar {
	position: fixed;
	width: 100%;
}

.navbar-billage {
	margin-bottom: 0;
	background-color: $navbar;
	z-index: 101;

	.avatar {
		padding: 12px 25px 12px 5px; //17px 25px 17px 0;
		background: transparent !important;
		color: white;

		.avatar-no-image,
		.avatar-image {
			width: 40px; // 32px
			height: 40px; // 32px
			line-height: 10px;
		}

		@media #{$max-sm} {
			padding: 0 4px 0 0;

			.avatar-no-image,
			.avatar-image {
				width: 32px;
				height: 32px;
			}
		}
	}

	.btn-navbar{
		//border: 1px solid white;
		border-radius: 2px;
		background: transparent; //$blue2
		height: 32px;
		width: 40px;
		display: flex;
		align-items: center;
		justify-content: center;
		color: white;
		padding:5px;

		&:hover, &:focus, &:active{
			//background: hsla(0,0%,100%,.2); // $navy
			//background: $primary-800;
			background: rgba(7, 30, 95,0.5);
		}

		img{
			width: 18px;
			min-width: 18px;
			height: auto;
			display: block;
		}
		&.btn-acceso-directo{
			width: 32px;
			padding: 0;
			img{
				width: 25px;
			}
		}

		&.btn-navbar-ayuda{
			img{
              height: 22px;
              width: 25px;
			}
		}
		&.btn-navbar-mensajes{
			 img{
				 width: 23px;
			 }
		}
		&.btn-navbar-calendar{
			img{
				width: 22px;
			}
		}
	}

	.navbar-header {
		position: relative;

		@media #{$min-sm} { min-width: 300px; }
		
		.navbar-left{
			display: flex;
			align-items: center;

			@media #{$min-lg} {
				body.menu-toggled &{
					width: $menu-width;
				}
			}
		}

		.navbar-info{
			float: left;
			display: flex;
			align-items: center;
			height: 65px;
			color: white;
			padding-left: 30px;
			padding-right: 10px;

			.navbar-title{
				font-weight: 700;
				font-size: 24px;
				margin-right: 15px;
			}

			.navbar-totals{
				font-size: 14px;
				color: white;
				display: flex;

				.value{
					margin-left: 20px;
					line-height: 1.3em;
					text-align: right;
					text-transform: uppercase;

					strong{
						display: block;
						font-size: 18px;
					}
				}
			}

			@media #{$max-md} {
				padding-left: 10px;
				.navbar-title{
					margin-right: 5px;
				}
				.navbar-totals{
					display: none;
				}
			}

			@media #{$max-sm} { display: none }
		}

		.navbar-right-mobile{
			display: flex;
			justify-content: flex-end;
			margin-bottom: 0;
			position: absolute;
			top: 15px;
			right: 0;

			li {
				margin-right: 8px;
				display: flex;
				align-items: center;

				&:last-child{
					margin-right: 0;
				}
			}

			@media #{$min-sm} { display: none; }
			@media #{$max-xs} { top: 10px; }
		}

		.navbar-brand, .navbar-menu-toggle {
			height: 65px;
			padding: 5px;

			@media #{$max-xs} {
				height: 50px;
				padding: 12px 12px 12px 15px;
			}
		}

		.navbar-brand {
			margin-left: 15px;

			img {
				height: 100%;
				min-width: 25px;
				width: auto;
				max-width: 170px;
				padding: 0px;
				margin: 0px;
			}

			@media #{$max-sm} {
				margin-left: 0;
			}
		}

		.navbar-menu-toggle {
			background-image: url(/saas/img/icons/icons/white/burger.svg);
			background-position: 50% 50%;
			background-repeat: no-repeat;
			opacity: 1;
			width: 25px;

			&:focus,
			&:hover { opacity: 0.8; }

			@media #{$max-xs} {
				background-position: 50% 75%;
			}
		}
/*
		.navbar-toggle {
			color: white;
			margin-bottom: 0;
			.icon-bar { background-color: $blue2; }
			@media #{$min-xs} { margin-top: 8px; }
		}*/
	}

	.navbar-collapse {
		max-height: 430px;
		padding-left: 15px;
		padding-right: 15px;

		@media #{$max-xs} {
			background-color: $blue2;
			box-shadow: inset 0 1px 0 $blue2;
		}

		.navbar-nav {
			> li {
				color: white;

				> a {
					.fa {
						font-size: 15px;
						color: white;
					}

					&.toggle-ajustes{
						&:focus, &:hover{
							background: transparent;
						}
					}

					.badge {
						position: absolute;
						top: 16px;
						right: -5px;
						padding: 0 5px;
						border-radius: 50%;
						font-size: 11px;
						display: flex;
						align-items: center;
						min-height: 18px;
						min-width: 18px;
						padding: 0 3px;
						justify-content: center;						
						background-color: $red;

						&:empty { display: none; }
					}
				}

				&.active a { background-color: rgba(255, 255, 255, 0.2); }
			}

			&.navbar-right {
				display: flex;
				li {
					margin-right: 3px;
					display: flex;
					align-items: center;

					&:last-child{
						margin-right: 10px;
					}
				}
				@media #{$max-xs} { display: none; }
			}
		}

		&.in {
			.navbar-nav {
				li{
					a{
						color: white;

						&:hover, &:focus, &:active{
							background: $primary-800;
						}
					}

				}
			}
		}

	}
}

.navbar-recompensas-link{
	padding: 6px 12px !important;
	height: 32px;
	line-height: 20px;
	margin-right: 5px;

	strong{
		font-weight: 700;
	}

	&:hover{
		background-color: $btn-third-hover-background !important;
	}
}


#bNavbar{
	.dropdown-menu{
		> li{
			&.divider{
				margin: 5px 0;
			}
		}
	}
}

#bNavPerfil {
	@media #{$min-sm} { 
		display: none !important; 
	}

	.navbar-nav {
		li {
			&.divider {
				height: 1px;
				margin: 5px 0 0 0;
				background-color: rgba(255,255,255,.3);
			}
			
			a { 
				padding-left: 55px; 
				background-repeat: no-repeat;
				background-size: auto 20px;
				background-position: 15px 50%;
				padding-top: 10px;
				padding-bottom: 10px;
			}
			
			&.nav-help a { background-image: url(/saas/img/icons/menu/ayuda.svg); }
			&.nav-perfil a { background-image: url(/saas/img/icons/menu-config/perfil.svg); }
			&.nav-ajustes a { background-image: url(/saas/img/icons/menu-config/ajustes.svg); }
			&.nav-mail a { background-image: url(/saas/img/icons/menu-config/mail.svg); }
			&.nav-empresa a { background-image: url(/saas/img/icons/menu-config/datos.svg); }
			&.nav-usuarios a { background-image: url(/saas/img/icons/menu-config/users.svg); }
			&.nav-contratacion a { background-image: url(/saas/img/icons/menu-config/carrito.svg); }
			&.nav-integraciones a { background-image: url(/saas/img/icons/menu-config/integraciones.svg); background-size: auto 18px; }
			&.nav-store a { background-image: url(/saas/img/icons/menu-config/store.svg); }
			&.nav-logout a { background-image: url(/saas/img/icons/menu-config/salir.svg); background-size: auto 18px; }

			&.nav-company-switch{
				a{
					display: block;
					padding-top: 5px;
					padding-bottom: 5px;
				}
			}
		}
	}
}

.alert-partner {
	padding: 7px;
	padding-right: 35px;
	margin-bottom: 0;
	border-radius: 0;
	text-align: center;
	color: white; 
	background-color: $color4; 


	.btn-trial {
		margin-left: 5px;
		color: white;
		background-color: $blue;
		padding: 4px 10px;

		&:hover,
		&:focus,
		&:active,
		&:active:focus {
			color: white;
			background-color: $blue2;
		}
	}
	
	.close{
		top: 2px;
	}
	
	.texto-quedan{
		line-height: 1.8em;
	}
	
	#im_contratar{
		margin-right: 5px;
		
		@media #{$max-xs} { 
			display: none;
		}		
	}

}

// barra nav sin menu 

.navbar-alt {
	position: fixed;
	width: 100%;	
	margin-bottom: 0;
	background-color: $navbar;
	font-size: 16px;
	z-index: 81;
	color: white;
}


// Buscador
.btn-search {
	padding: 5px;
	background-color: transparent;
	img {
		height: 20px;
	}
}
	
.search-input {
	input {
		border: 0;
		color: white;
		background-color: rgba(0, 0, 0, 0.15);
	}

	.input-group-addon {
		padding: 0;
		border: 0;
		background-color: transparent;
	}

	.btn-search {
		border-radius: 50%;
		
		&:hover {
			background-color: rgba(0, 0, 0, 0.15);
		}		
	}

	&.focus {
		max-width: 350px;

		.btn-search {
			border-top-left-radius: 0px;
			border-bottom-left-radius: 0px;
			border-top-right-radius: 2px;
			border-bottom-right-radius: 2px;
			background-color: rgba(0, 0, 0, 0.15);
		}
	}
}


// popover buscador

.popover.popover-topBuscador{
	border-radius: 0;
	top: 55px !important;
	background: $color4;
	width: 100%;
	max-width: 100%;
	border: none;
	position: fixed;
	z-index: 106;
	
	.popover-content{
		padding: 10px;
		width: 100%;
		display: block;
		
		.search-input input{
			background: rgba(255,255,255,0.2) !important;
			border: none;
			color: white;
		}
	}

	@media #{$max-xs} {
		top: 41px !important;
	}
	

	// fix 
	.arrow{
		display: none;
	}


}