/* ---------- RESPONSIVE UTILITIES */
@include responsive-invisibility('.visible-xxs');

.visible-xxs-block,
.visible-xxs-inline,
.visible-xxs-inline-block {
	display: none !important;
}

@media #{$max-xxs} {
	@include responsive-visibility('.visible-xxs');
	.visible-xxs-block { display: block !important; }
	.visible-xxs-inline { display: inline !important; }
	.visible-xxs-inline-block { display: inline-block !important; }
}

@media #{$max-xs} {
	@include responsive-invisibility('.hidden-xxs');
}

.text-muted { color: $grey1; }

$warning: $yellow !default;
$danger: $red !default;
$info: white !default;

$theme-colors: () !default;
$theme-colors: map-merge((
	//"primary":    $primary,
	// "secondary":  $secondary,
	"info":		$info,
	"warning":	$warning,
	"danger":	$danger,
), $theme-colors);

$theme-color-interval: 8% !default;
$alert-bg-level: -10 !default;
$alert-border-level: -9 !default;
$alert-color-level: 6 !default;
$yiq-contrasted-threshold:  150 !default;

/* ---------- TEXT COLORS */
@each $color, $value in $theme-colors {
	.text-#{$color} { color: $value !important; }

	a.text-#{$color} {
		@include hover-focus {
			color: darken($value, 10%) !important;
		}
	}
}

/* ---------- BACKGROUNDS COLORS */
@each $color, $value in $theme-colors {
	.bg-#{$color} { background-color: $value !important; }

	a.bg-#{$color},
	button.bg-#{$color} {
		@include hover-focus { 
			background-color: darken($value, 10%) !important;
		}
	}
}

/* ---------- TABLES COLORS */
@each $color, $value in $theme-colors {
	$color-level: theme-color-level($color, -9);

	.table {
		> thead,
		> tbody,
		> tfoot {
			> tr {
				&.#{$color} > td,
				> td.#{$color} { background-color: $color-level; }
			}
		}
	}
	.table-hover {
		> tbody {
			> tr {
				&:hover .#{$color},
				&.#{$color}:hover > td,
				> td.#{$color}:hover { background-color: darken($color-level, 5%); }
			}
		}
	}

	// bootstrap 4.0
	.table-#{$color} {
		&,
		> th,
		> td { background-color: $color-level; }
	}
	.table-hover {
		.table-#{$color} {
			&:hover {
				background-color: darken($color-level, 5%);

				> th,
				> td { background-color: darken($color-level, 5%); }
			}
		}
	}
}

/* ---------- INPUTS COLORS */
@each $color, $value in $theme-colors {
	.has-#{$color} {
		.help-block,
		.control-label,
		.radio,
		.checkbox,
		.radio-inline,
		.checkbox-inline,
		&.radio label,
		&.checkbox label,
		&.radio-inline label,
		&.checkbox-inline label  { color: $value; }

		.form-control {
			border-color: $value;

			&:focus { border-color: darken($value, 10%); }
		}

		.input-group-addon {
			color: $value;
			border-color: $value;
			background-color: theme-color-level($color, -9);
		}

		.form-control-feedback { color: $value; }
	}
}

/* ---------- BUTTONS COLORS */
@each $color, $value in $theme-colors {
	.btn-#{$color} {
		@include button-variant($value, $value);
	}
}

/* ---------- BADGE COLORS */
@each $color, $value in $theme-colors {
	.badge-#{$color} {
		color: color-yiq($value);
		background-color: $value;

		&[href] {
			@include hover-focus {
				color: color-yiq($value);
				text-decoration: none;
				background-color: darken($value, 10%);
			}
		}
	}
}

/* ---------- ALERTS COLORS */
@each $color, $value in $theme-colors {
	$alert-theme-background: theme-color-level($color, $alert-bg-level);
	$alert-theme-border: theme-color-level($color, $alert-border-level);
	$alert-theme-color: theme-color-level($color, $alert-color-level);

	.alert-#{$color} {
		background-color: $alert-theme-background;
		border-color: $alert-theme-border;
		color: $alert-theme-color;

		hr { border-top-color: darken($alert-theme-border, 5%); }

		.alert-link { color: darken($alert-theme-color, 10%); }
	}
}

/* ---------- PROGRESS COLORS */
@each $color, $value in $theme-colors {
	.progress-bar-#{$color} {
		background-color: $value;

		.progress-striped & {
			background-image: -webkit-linear-gradient(45deg, rgba(255,255,255,.15) 25%, transparent 25%, transparent 50%, rgba(255,255,255,.15) 50%, rgba(255,255,255,.15) 75%, transparent 75%, transparent);
			background-image: -o-linear-gradient(45deg, rgba(255,255,255,.15) 25%, transparent 25%, transparent 50%, rgba(255,255,255,.15) 50%, rgba(255,255,255,.15) 75%, transparent 75%, transparent);
			background-image: linear-gradient(45deg, rgba(255,255,255,.15) 25%, transparent 25%, transparent 50%, rgba(255,255,255,.15) 50%, rgba(255,255,255,.15) 75%, transparent 75%, transparent);
		}
	}
}

/* ---------- LIST GROUP COLORS */
@each $color, $value in $theme-colors {
	$listGroup-theme-background: theme-color-level($color, -9);
	$listGroup-theme-color: theme-color-level($color, 6);

	.list-group-item-#{$color} {
		color: $listGroup-theme-color;
		background-color: $listGroup-theme-background;

		&.list-group-item-action {
			@include hover-focus {
				color: $listGroup-theme-color;
				background-color: darken($listGroup-theme-background, 5%);
			}

			&.active {
				color: white;
				background-color: $listGroup-theme-color;
				border-color: $listGroup-theme-color;
			}
		}
	}

	a.list-group-item-#{$color},
	button.list-group-item-#{$color} {
		color: $listGroup-theme-color;

		@include hover-focus {
			color: $listGroup-theme-color;
			background-color: darken($listGroup-theme-background, 5%);
		}

		&.active,
		&.active:hover,
		&.active:focus {
			color: white;
			background-color: $listGroup-theme-color;
			border-color: $listGroup-theme-color;
		}

		.list-group-item-heading { color: inherit; }
	}
}