.log-list {
	.log-container {
		max-height: 500px;
		overflow-y: auto;
		scrollbar-width: thin;
		scrollbar-color: $grey-1 $grey-3; // 1 barra 2 fondo (firefox)		
	}

	.log-block-container {
		position: relative;
		padding: 20px 15px;
		border: 1px solid $timeline-block-border;
		margin-left: 50px;
		color: $timeline-block-color;
		background-color: $timeline-block-background;

		&:before {
			content: "";
			position: absolute;
			top: 25px;
			left: -8px;
			width: 15px;
			height: 15px;
			background-color: inherit;
			border-left: 1px solid;
			border-bottom: 1px solid;
			border-color: inherit;
			transform: rotate(45deg);
		}

		.comment-area {
			white-space: pre-wrap;
		}

		.media {
			overflow: visible;

			small { color: $grey; }

			pre {
				padding: 0;
				border: 0;
				margin-bottom: 5px;
				font-size: 13px;
				font-family: inherit;
				color: inherit;
				background-color: transparent;
				white-space: pre-line;

				&:last-of-type { margin-bottom: 0; }
			}

			.row { position: relative; }

			.bottom-left {
				position: absolute;
				right: 0;
				bottom: 0;
			}
		}
		
		.media-heading {
			font-size: 15px;
			margin-bottom: 5px !important;

			.btn-icon {
				padding: 0;
				margin-left: 10px;
				opacity: 0;

				> img { height: 20px; }
			}

			&:hover > .btn-icon { opacity: 1; }
		}
		
		.media-body {
			overflow: visible;

			.media {
				position: relative;
				margin-bottom: 15px;

				&:not(.media-reply):last-of-type { margin-bottom: 0; }

				.log-block-menu {
					top: 0;
					right: -5px;
				}
			}

			.add-btn-answer { display: none; }
		}			

	}

	.log-block-menu {
		position: absolute;
		top: 5px;
		right: 10px;
		opacity: 0;

		.btn { padding: 10px; }
	}
	
	.log-block {
		position: relative;
		padding-top: 10px;
		padding-bottom: 10px;

		&:before,
		&:after {
			content: "";
			position: absolute;
			left: 15px;
			transform: translateX(-50%);
			width: 3px;
			background-color: $timeline-line;
		}

		&:before {
			top: 0;
			height: 46px;
		}

		&:after {
			top: 46px;
			bottom: 0;
		}

		&:first-of-type {
			padding-top: 0;

			&:before { display: none; }

			.log-block-block-icon { top: 20px; }
		}

		&:last-of-type { padding-bottom: 0; }

		&:hover {
			 .log-block-container .media .log-block-menu { opacity: 1; }
		}


		&.log-block2 {
			.log-block-container {
				border: 1px solid $timeline-block2-border;
				color: $timeline-block2-color;
				background-color: $timeline-block2-background;
			}
		}
		
		.log-block-icon {
			position: absolute;
			top: 30px;
			left: 0;
			width: 30px;
			height: 30px;
			padding: 5px;
			border: 2px solid $timeline-line-icon-border;
			border-radius: 100%;
			background-color: $timeline-line-icon-background;
			box-shadow: 0 0 0 3px white;
			z-index: 1;

			img {
				width: 100%;
				vertical-align: initial;
			}
		}
	}

	@media #{$max-xs} {
		.log-block{
			&:before,
			&:after { display: none; }

			.log-block-icon { display: none; }
		}
		
		.log-block-container {
			margin-left: 0;

			&:before { display:none; }
		}
	}
}