#viewOportunity {
	.oportunity-info {
		margin-bottom: 15px;

		a { @extend .a; }

		.o-info {
			display: inline-block;
			padding-left: 2px;

			&:before {
				content: "|";
				color: inherit;
			}

		}
	}

	.tab-container-wrapper {
		margin-bottom: 40px;

		.map-wrapper {
			@media #{$max-xs} {
				margin-top: 15px;
				margin-right: -65px;
			}
		}
	}

	.contacts-list {
		.contact {
			background-color: $primary-50;
		}
	}

	.div-comment { margin-top: 40px; }

	.tab-content .tab-content { min-height: 350px; }

	.head-actions {
		text-align: right;
		margin-bottom: 15px;

		> .form-group {
			@media #{$min-sm} {
				display: inline-block;
				margin-right: 15px;
				margin-bottom: 0;
				vertical-align: middle;

				label { display: inline-block; }

				.input-group {
					display: inline-table;
					width: 200px;
					vertical-align: middle;
				}

				> .form-control {
					display: inline-table;
					width: 200px;
				}
			}
		}

		.popover-addSerie { left: 20px !important; }
	}
	
	.progress { height: 5px; }
}

#tableOportunities {
	th.col-oportunidad { padding-left: 33px; }
	
	.td-icon {
		position: absolute;
		left: 0;
	}	

	.col-oportunidad-contenido {
		position: relative;
		padding-left: 27px;
	}

	.lista-actividades { margin: 0; }
	
	.label-actividades { color: $grey; }
	
	.col-tags{
		max-width:350px;
	}
	
	.formFase {
		label {
			width: 100%;
			margin-bottom: 0;
			line-height: 12px;
		}
		
		.progress { height: 5px; }
		
		.progress-fase {
			color: $grey !important;
			font-weight: 400;
			line-height: 1em;

			.substring { vertical-align: top; }
	
			.badge {
			    padding: 0;
			    color: $grey;
			    font-weight: 400;
			    float:right;
			    line-height: inherit;
			}	 
		}
	}
}

#divNuevaOportunidad {
	width: 870px;
	
	.combo-visibilidad {
		.fake-select {			
			&.selectPrivada .selectPrivada-texto,
			&.selectPublica .selectPublica-texto { display: inline-block; }
		}
		
		.dropdown-menu {		
			li {			
				&.visibilidad-publica { background-image: url("/saas/img/icons/icons/blue/public.svg"); }
				&.visibilidad-privada { background-image: url("/saas/img/icons/icons/blue/privat.svg"); }
			}
		}
	}
 
	.formFase {
		.progress-fase {
		    text-transform: initial;
		    display: block;
		    margin-top: 5px;
		    text-align: center;
		}		
		
		.progress{
			margin-top: 20px;
		}
	}

}

@media screen and (max-width: 425px) {
	#divNuevaOportunidad {
		.bloque {
			height: initial !important;

			&.bg-light {
				padding-top: 0;
				background-color: transparent;
			}
		}
	}
}