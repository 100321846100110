
.cuentas-tesoreria{
	display: flex;
	flex-direction: column;

	.list-cuentas{
		//margin-top: 15px;
		border-top: none; 
		outline: 2px solid transparent;
	}
	
	.cuenta-logo{
		height:50px;
		width: auto;
		max-width: 100%;
	}
	
	.cuenta-nombre{
		font-weight: 600;
		font-size: 17px;
		display: flex;
		align-items: center;
	}

	.icono-img{
		width: 50px;
		height: 50px;
		margin-top: 10px;
		margin-bottom: 7px;
	}
	
	.icono{
		display: flex;
		background: $blue;
		color: white;
		align-items: center;
		justify-content: center;
		width: 24px;
		min-width: 24px;
		height: 24px;
		border-radius: 50%;
		margin-left: 5px;
		font-size: 14px;
		margin-top: -3px;
		margin-bottom: -3px;
		
		&:before{
			content: '';
			font-family: 'fontAwesome';
			font-weight: normal;
			font-style: normal;
		}
		
		&.icono-repeat{
			background: $green;
			&:before{
				content: '\f01e';
			}
		}
		
		&.icono-manual{
			&:before{
				content: '\f013';
			}
		}		
		
		&.icono-lg{
			background: $blue;
			width: 50px;
			height: 50px;
			font-size: 30px;
		}
	}

	
	.cuenta-label{
		color: $grey;
		font-size: 17px;
		margin-bottom:0;
		margin-top: 15px;
	}
	
	.cuenta-label-saldo{
		margin-bottom:-2px;
	}
	
	.cuenta-label-last{
		margin-bottom:3px;
	}
	
	.cuenta-saldo{
		font-size: 30px;
		font-weight: 600;
	}
	
	.cuenta-last{
		font-weight: 600;
		font-size: 17px;
	}	
	
	.cuenta-footer{
		margin-top: 15px;
		display: flex;
		align-items: center;
		
		.btn-primary{
			margin-right: 5px;
		}
	}
	
	.btn-remove-icon{
		padding: 3px 10px 3px 10px;
		line-height: 18px;
	}
			
	article.cuenta{
		background: white;
		border: 1px solid $grey-3;
		padding:15px;
		margin-bottom: 30px;
		display: block;
		
		figure{
			padding: 20px 0 0 0;
			height: 80px;
		}

		.cuenta-footer{
			border-top: 1px solid $grey-3;
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding-top: 10px;
		}
		
		&:hover, &:focus, &:active, &.active{
			outline: 2px solid $blue;
			background: white;
			padding: 15px !important; 
		}
	}	
	
	
	.progress-conciliado{
		margin: 10px 0 20px 0;
		
		p{
			text-transform: lowercase;
			margin-bottom: 3px;
		}
	}
} 

.tesoreria-detalle{
	padding-top: 0;
	
	.info-blocks{
		.caja:first-of-type{
			margin-top:0;
		}
	}
	
	article{
		margin-bottom: 30px;
		padding-right: 25px;
	}
	
	.progress-conciliado{
		margin: 15px 0 25px 0;
	}
		
	.cuenta-logo-container{
		margin-bottom: 20px;
	}
	
	.cuenta-nombre{
		font-size: 20px;
		margin-bottom: 3px;
	}
	
	.cuenta-titular{
		font-size: 20px;
		font-weight: 600;		
	}
	
	.cuenta-label-numero{
		margin-top:0;
		margin-bottom: 30px;
	}
	
	.table-list{
		border-top: 1px solid $table-border;
		thead tr th:first-child, tbody tr td:first-child { padding-left: 20px; }
	}
	// tabla movimientos
	#movements{
		tbody{
			td:first-child{
				padding-left: 10px;
			}
		}
		.category{
			color: $grey;
			font-size: 13px;
			display: block;
		}
		td.list-actions{
			padding-left:0;
			padding-right:0;
			width: 30px;
			min-width: 30px;
		}
	}
	
	.movimientos-salir{
		display: none;
		margin: 10px 0 0 0;
		
		&:before{
			content: "\f104";
			margin-right: 5px;
			font-family: "FontAwesome";
		}
	}
	
	.row-movimientos{
		.caja{ 		
			padding: 0;
			
			h3{
				color: $title-color;
				font-size: 18px;
				font-weight: 700;
				padding: 20px 20px 10px;
				margin: 0 0 10px 0;
			}	
		}
	}
	
	.movimientos-main{
		margin-bottom: 15px;
		
		&.active{
			.col-nota, .col-saldo{ display: none}
			
		}
	}
	
	.col-estado{
		.progress{
			margin-bottom:0;
		}
	}
	
	.movimientos-info{
		display: none;
		
		@media #{$min-md} { 
			position: -webkit-sticky;
			position: sticky;
			top: 80px;
			height: calc(100vh - 100px);
			overflow-y: auto;
			scrollbar-width: thin;
			scrollbar-color: $grey-1 $grey-3; // 1 barra 2 fondo (firefox)	
		}	
		
		.caja{ 
			&:first-child{
				margin-bottom: 20px;
			}			
			&.caja-conciliaciones{
				h3{
					display: flex;
					justify-content: space-between;
					align-items: center;
					
					span{
						font-size: 18px;
						color: $grey2;
					}
				} 
			}
		}
		
		.table-list{
			thead tr th:last-child, tbody tr td:last-child { padding-right: 20px; }
		}		
		
		.documentos-buscador{
			padding: 0 20px 30px 20px;
			display: flex;
			justify-content: space-between;

			> * {
				margin-right: 10px;
				flex-grow: 1;

				&:last-child{
					margin-right: 0;
				}
			}

			.label-wrapper{
				position: relative;
				label{
					position: absolute;
					top: 3px;
					left: 6px;
					font-size: 10px;
					color:$grey-1;
					z-index:1;
					text-transform: uppercase;
				}
			}
			
			.form-control, .btn-fecha{
				padding: 14px 6px 4px 6px;
				color: $text;
				width: 100%;
				text-align: left;
			}
			.form-control{
				padding-right: 20px !important;
			}

			.add-clear-x{
				top: 15px !important;
				right: 5px !important;
			}
			
			.btn-white{
				border-color: $grey-3;
				font-weight: 600;
				height: 40px;
				font-size: 15px;
			}
		}
		
		.conciliaciones-no-results, .documentos-no-results{ display: none}

		.caja-mensaje{
			padding: 10px 20px 30px 20px;
		}

		.documentos-head{
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding-right: 20px;
			margin-bottom: 5px;
			
			.btn{
				margin-top: -10px;
			}
		}
		
		.documentos-botones{
			white-space: nowrap;
		}
		
		.documentos-filtro{ 
			padding-left: 15px;
			font-weight: 600;
			font-size: 15px;
			
			a{
				padding: 5px 10px;
				color: $grey;
			}
			
			li{
				display: inline-block;
				border-right: 1px solid $form-input-border;
				
				&.active a{
					color: $text; 
				}
				
				&:last-child{
					border-right: 0; 
				}
			}
		}
				
		.btn-fecha{
			border-color: $form-input-border;
			height: 34px;
			border-radius: 2px;
		}
		
		.table-documentos {
			.list-element{
			
				&.tr-verd{
					td{
						background: lighten($green, 35%);
						border-color: lighten($green, 20%);
						border-bottom: 1px solid lighten($green, 20%);
					} 				
				}
			
				&:not(.no-hover){
					cursor: pointer;
				}
			}
		}
	}
	
	
	@media #{$max-sm} { 
		.cuenta-label-numero{
			margin-bottom: 20px;
		}
		
		.cuenta-logo-container{
			margin-bottom: 30px;
		}
		
		.cuenta-logo{
			max-height: 40px;
		}		
		
		.highcharts-title{
			transform: translate(0, -10px) !important;
		}
	}
				
	@media #{$min-md} { 
		article{
			border-right: 1px solid $grey-3;
			margin-bottom: 0;
		}
	}	
}

#popupBanc {
	width: 900px;
		
	.form-group-banc { margin-bottom: 40px; } 
	
	.validation-error{
		display: none;
		color: $red;
		margin-bottom: 25px;
	}	
	
	@media (min-width: 769px) {
		.group-banc { padding-left: 0; }
		
		.help-block.copy{
			float:right;
		}
		
		.help-iban{
			position: absolute; 
			white-space: nowrap;
		}		
		
	}	
}


// Afterbancs


#popupBancAfterbanks{
	width: 600px;
	
	.popup-content{
		min-height:300px;
		display: flex;
		align-items: center;
	}
	
	#mainDiv{ width: 100%;}
	
	#back i { margin-right: 5px; }
	
	.texto-intro{
		margin-bottom: 25px;
	}	
	
	.row-flex{
		display: flex;
		
		img{
			width: 128px;
			height: 128px;
			margin-right: 30px;
		}
		
		section{
			flex:1;
		}
		
		&.row-flex-results{
			justify-content: space-around;
			border: 1px solid $grey-3;
			margin-top: 20px;
			padding-bottom: 10px;
			
			div{
				text-align:center;
			}
		}
	}
	
		
	.result-titulo{
		color: $grey;
		font-size: 17px;
		margin-bottom:0;
		margin-top: 15px;	
	}
	.result-texto{
		font-size: 40px;
		font-weight: 600;
	}	
	
	

	.combo-advanced{
		li{
			display:flex;
			align-items: center;
			
			.combo-texto{
				margin-left: 20px;
			}
			
			img{
				height:30px;
			}				
			
			&.disabled{
				.combo-texto{
					margin-left: 0;
				}
				
				&:hover{
					color: $text;
				}
			}

		}
	}

}

#popupBancSwitch{
	width: 600px;
	
	.texto-intro{
		margin-top: 25px;
	}
	
	.listbox{
		border-top: 0;
		margin-top: 30px;
	}
	
	.popup-footer{
		margin-top: 10px;
	}
	
	.icono{
		margin: 10px auto 30px auto;
	}
	
	.list-element, .cuenta-nombre{
		text-align: center;
		justify-content: center;
	}
}

#popupBancAfterbanksSync{
	width: 400px;
		
	.popup-content{
		min-height:160px;
		display: flex;
		align-items: center;
	}
	
	#mainDiv{ width: 100%;}	
	
	.row-flex{
		display: flex;
		justify-content: space-around;
		border: 1px solid $grey-3;
		margin-top: 20px;
		padding-bottom: 10px;
		
		div{
			text-align:center;
		}
	}
	
	.result-titulo{
		color: $grey;
		font-size: 17px;
		margin-bottom:0;
		margin-top: 15px;	
	}
	.result-texto{
		font-size: 40px;
		font-weight: 600;
	}
}
