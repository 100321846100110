.nav-vertical-tabs {
	display: flex;

	.vertical-tabs {
		min-width: 120px;
		background-color: $primary-100;

		@media #{$max-md} { min-width: 90px; }
		@media #{$max-xs} { min-width: 40px; }

		.vertical-tab {
			border-bottom: 0;

			> li {
				float: none;

				> a {
					@extend .substring;

					width: 100%;
					padding: 25px 10px;
					border: 0;
					border-radius: 0px;
					margin: 0;
					color: $primary-800;
					text-align: center;
					background-color: transparent;

					&:hover { background-color: $primary-200; }

					&.tabIcon > img {
						height: 30px;
						max-width: 30px;
						margin-bottom: 2px;
					}

					@media #{$max-xs} {
						padding: 10px 0;

						&.tabIcon > img {
							height: 20px;
							max-width: 20px;
						}
					}
				}

				&.active {
					> a,
					> a:hover,
					> a:focus {
						color: white;
						background-color: $primary-400;
					}
				}

				&.inactive {
					cursor: not-allowed;
					user-select: none;

					> a,
					> a:hover,
					> a:focus {
						color: $primary-300;
						background-color: $primary-100;
						opacity: 0.40;
					}
				}
			}
		}
	}

	.vertical-content {
		@media #{$max-md} { max-width: calc(100% - 100px); }
		@media #{$max-xs} { max-width: calc(100% - 40px); }
		
		flex-grow: 35;
		
		.tab-content {
			min-height: 100%;
			padding: 30px;
			overflow-x: hidden;
			overflow-y: auto;
			scrollbar-width: thin;
			scrollbar-color: $grey-1 $grey-3; // 1 barra 2 fondo (firefox)

			@media #{$max-sm} { padding: 15px; }
			@media #{$max-xs} { padding: 10px; }

			.subtitle{
				margin-top: 0;
			}

			.tab-pane {
				.nav-tabs { 
					margin-top: -15px;

					li {
						a {
							padding: 5px 0 5px;
							margin-left: 0;
							margin-right: 10px;
						}
					}

					@media #{$max-xs} {
						margin-top: -10px;

						li a { margin-right: 5px}
					}
				}

				.tab-content { padding: 0; }
			}

			&.postIt {
				background-color: $color-postIt;
				border: 1px dashed $blue;
				padding: 6px 15px;
				min-height: 100px;
				max-height: 150px;
			}

			&.content-textarea {
				.tab-pane {
					position: relative;

					textarea {
						min-height: 86px;
						max-height: 136px;
						padding: 0;
						border: 0;
						background-color: transparent;

						+ label {
							position: absolute;
							bottom: 0;
							right: 0;
							margin: 5px 10px;
							font-size: 11px;
							font-weight: normal;
						}
					}
				}
			}

			.tab-container-wrapper {
				padding-right: 65px;

				@media #{$max-xs} { padding-right: 0; }
			}
		}
	}

	&.smallTabs .vertical-tab.nav-tabs > li > a { padding: 10px 5px; }

	&.singleTab .vertical-tab > li {
		min-height: 100px;
		max-height: 150px;
	}

	@media #{$max-xs} {
		.row {
			margin-right: -10px;
			margin-left: -10px;

			[class^="col-"] {
				padding-right: 10px;
				padding-left: 10px;
			}
		}
	}
}


.tab-header {
	position: relative;

	&:before,
	&:after {
		content: "";
		display: table;
		clear: both;
	}

	.tab-buttons {
		position: absolute;
		top: 0;
		right: 0;
		z-index: 1;

		.btn:not(.btn-edit) {
			//padding: 6px 12px;
			margin-left: 10px;

			&:first-of-type { margin-left: 0; }
		}
	}

	.tab-buttons-inline {
		@extend .tab-buttons;
		position: relative;
		display: flex;
		justify-content: end;
		align-items: center;
	}

	.view-mode {
		position: relative;
		float: right;

		&:after {
			content: "";
			position: absolute;
			height: 100%;
			border-left: 1px solid $grey-2;
			left: 50%;
			transform: translateX(-50%);
		}
	}

	+ .table-responsive { margin-top: 20px; }
}