.integraciones{

	.integracion-intro{
		margin-bottom: 20px;
	}

	.integracion-question{
		font-weight: bold;
		margin-bottom: 5px;
	}
	.integracion-answer{}

	.content-ticketbai{
		h4{
			font-weight: bold;
			margin-bottom: 15px;
		}

		.content-box{
			margin-top: 40px;
			margin-bottom: 40px;
			padding: 30px 25px;
			background: #f9f9f9;
			border: 1px solid #ddd;
		}
	}
}




.social-register, .social-multiple {
	max-width: 650px;
}

.social-multiple{
	.social-list{
		margin-top: 40px;
	}
}

.social-list {
	overflow: hidden;
	
	.avatar { 
		float: left; 
		margin-right: 15px;
	}
	
	.divSocialProfileEntryText {
		display: inline-block;
		margin-left: 15px;
	}

	> li {
		border-bottom: 1px solid $table-border;

		&:first-of-type { padding-top: 0; }
		&:last-of-type { border-bottom: 0; }
	}
	
	.social-button {
		width: 100%;
		border: 1px solid transparent;
		text-align: left;
		background: transparent;
		border-radius: 4px;
		padding: 15px;
		
		.avatar { 
			padding-top: 5px;
		}
		
		.social-info {
			margin-left: 90px;
			font-size: 16px;
			line-height: 24px;
		}
		
		&:hover{
			background: $blue-3;
			border-color: $blue;
		}
	}
	
	.estado-titulo, .conectado-titulo{
		display: block;
		color: $grey;
	}
	
}


.social-head{
	display: flex;
	align-items: center;
	margin-bottom: 20px;
	
	.social-icon {
		height: 50px;
	}	
	
	.provider-titulo{
		font-weight: 600;
		font-size: 18px;
		margin-left: 15px;
	}
}

.social-entry{
	.avatar img{
		max-width: 200px;
		height: auto;
	}
}


#buttons-google{
	margin-top: 25px;
	
	.btn{
		margin-right: 10px;
		
		&:last-child{
			margin-right: 0;
		}
	}
}


// A3ECO:
#popupA3ecoAlta{
	width: 400px;
}

#popupA3ecoExport{
	width: 300px;
}

#popupA3ecoExportValidate{
	width: 500px;
	
	.row-flex{
		display: flex;
		justify-content: space-around;
		border: 1px solid $grey-3;
		margin-top: 20px;
		margin-bottom: 20px;
		padding-bottom: 10px;
		
		div{
			text-align:center;
		}
	}
	
	.result-titulo{
		color: $grey;
		font-size: 17px;
		margin-bottom:0;
		margin-top: 15px;	
	}
	.result-texto{
		font-size: 40px;
		font-weight: 600;
	}
	
	.list-helps{
		list-style-type: initial;
		margin-left: 15px;
	}
}
