.mail-manager {
	display: flex;
    height: 100vh;
	max-height: calc(100vh - 200px);
	margin-bottom: 30px;
	align-items: stretch;
	background-color: white;

	.mm-sidebar-wrapper {
		width: 20%;
		border-right: 1px solid $grey-3;
	    overflow-y: auto;
    	overflow-x: hidden;
 		scrollbar-width: thin;
		scrollbar-color: $grey-1 $grey-3; // 1 barra 2 fondo (firefox)   	

		.btn-close { display: none; }

		@media #{$min-xl} {
			width: 12%;
		}

		.mm-cta { 
			padding: 25px 20px 20px 20px; 
			h3{
				color: $blue;
				font-size: 16px;
				text-transform: uppercase;
				font-weight: 600;
				margin-top:35px;
			}
			
			h4{
				color: $grey1;
				font-size: 14px;
				margin-bottom: 0;
				margin-top: 5px;
			}
		}
	}

	.mm-page-content-wrapper {
		position: relative;
		width: 80%;
		padding: 25px;

		@media #{$min-xl} {
			width: 88%;
		}

		.toggle-btn { display: none; }

		.list-wrapper {
			height: calc(100% - 50px);
			margin-left: -25px;
			margin-right: -25px;
			overflow-y: auto;
			overflow-x: hidden;
			scrollbar-width: thin;
			scrollbar-color: $grey-1 $grey-3; // 1 barra 2 fondo (firefox)			
			
			@media #{$max-sm} {
				margin-left: -15px;
				margin-right: -15px;
			}
		}

		.page-header { padding-top: 0; }
	}
	.summary { color: $grey1; }

	.filters-list {
		.filter-check {
			border: 1px solid $grey-3;
			border-radius: 2px;
			padding: 8px 6px;
			height: 34px;
		}

		.filter-refresh .btn { 
			padding: 5px 9px;
			color: $primary-400;
		}

		.filter-dropdown .btn-icon.btn-dropmore { 
			padding: 14px; 
		}

		.filter-btn-group {
			.btn {
				margin-right: 0;
				font-weight: 600;

				&:first-of-type {
					border-top-left-radius: 2px;
					border-bottom-left-radius: 2px;
				}

				&:last-of-type {
					border-top-right-radius: 2px;
					border-bottom-right-radius: 2px;
				}
			}
		}
	}
	
	.menu-list li {
		position: relative;
		padding: 8px 20px;
		border-left: 2px solid transparent;
		margin: 0;
		cursor: pointer;
		color: inherit;
		list-style: none;

		&.selected,
		&:hover {
			border-color: $blue;
			color: $blue;
			background-color: $blue-5;
		}

		&.drop-mail-hover {
			border-color: $blue;
			color: $blue;
			background-color: $blue-3;
		}

		> a {
			padding: 0;
			font-weight: 600;
			color: inherit;

			.badge {
				float: right;
				font-size: inherit;
				color: inherit;
				background-color: transparent;
			}
		}
	}	
	
	.listbox{	
		.bloque-fecha{
			text-align: right;
		}
		
		.bloque-subject{
			flex: 2;
			
			@media (min-width: 780px) and (max-width: 900px) {
				max-width: 300px;
			}	
			@media (min-width: 901px) and (max-width: 1000px) {
				max-width: 330px;
			}						
			@media (min-width: 1001px) and (max-width: 1199px) {
				max-width: 370px;
			}				
		}
		
		.bloque-main{
		
			@media (max-width: 780px) {
				max-width: 620px;
			}	
			
			@media (max-width: 600px) {
				max-width: 400px;
			}	
			@media (max-width: 550px) {
				max-width: 300px;
			}						
			@media (max-width: 480px) {
				max-width: 290px;
			}	
			
			@media (max-width: 400px) {
				max-width: 220px;
			}		
							
			@media (max-width: 360px) {
				max-width: 200px;
			}			
	
		}
		
		.list-element{
			justify-content: flex-start;
			position: relative;
			
			&:hover {
				&:before {
					content: "";
					position: absolute;
					left: -4px;
					width: 24px;
					height: 100%;
					background-image: url(/saas/img/icons/icons/grey/dragable.svg);
					background-position-y: center;
					background-repeat: no-repeat;

					cursor: move;
					cursor: grab;
					cursor: -moz-grab;
					cursor: -webkit-grab;
				}

				&:active:before {
					cursor: move;
					cursor: grabbing;
					cursor: -moz-grabbing;
					cursor: -webkit-grabbing;
				}
			}	
			
			p {
				margin-bottom: 0;
				vertical-align: middle;
			}
						
			&:hover,
			&.row-selected {
				z-index: 1;
			}			
			
			&.msg-no-headers { opacity: 0.3; }
			&.msg-no-read { font-weight: 600; }			
		}
		@media #{$max-sm} {
			.bloque-fecha{
				max-width: unset;
			}
		}			
		
	}
		
	.dragg-mail-helper {
		width: 250px;
		//min-height: 50px;
		border-radius: 5px;
		padding: 10px 20px;
		font-size: 16px;
		font-weight: 600;
		color: white;
		background-color: $blue-1;

		cursor: move;
		cursor: grabbing;
		cursor: -moz-grabbing;
		cursor: -webkit-grabbing;

		&:before {
			content: "\f003";
			padding-right: 15px;
			font-weight: 100;
			font-family: "FontAwesome";
		}

		> * { cursor: inherit; }
	}	

	.helper-drag {
		position: fixed;
		display: none;
		height: 45px;
		width: 250px;
		border-radius: 5px;
		cursor: -webkit-grabbing;
		color: white;
		background-color: $blue-1;
		z-index: 81;
	}
}

@media #{$max-sm} {
	.mail-manager {
		position: relative;
		overflow: hidden;

		&.toggled .mm-sidebar-wrapper { 
			left: 0; 
		}

		.mm-sidebar-wrapper {
			position: absolute;
			height: 100%;
			left: -55%;
			width: 55%;
			background-color: white;
			z-index: 1;
			transition: all 0.5s ease;

			.btn-close {
				display: block;
				float: right;
				padding: 15px;
				margin: 10px;
			}
		}

		.mm-page-content-wrapper {
			width: 100%;
			padding: 15px;

			.toggle-btn {
				display: block;

				.btn { padding: 5px 9px; }
			}
		}
	}
}

@media #{$max-xs} {
	.mail-manager {
		.mm-sidebar-wrapper {
			left: -100%;
			width: 100%;
		}
	}
}

#popupImapSync {
	top: 10px !important;
	width: 400px;

	#zone_select_folders {padding-left: 30px; }

	hr {
		margin-top: 25px;
		margin-bottom: 25px;
	}
}

#divImapViewMessage {
	width: 950px;

	.popup-content {
		display: flex;
		flex-direction: column;
		//height: calc(100% - 62px);
		height: calc(100vh - 120px);
		overflow: hidden;

		.row-bloques,
		.bloque { height: 100%; }
	}

	#zone_view_message {
		display: flex;
		flex-direction: column;
	}
	.msg-info {
		margin-top: -15px;
		margin-right: -30px;
		margin-left: -30px;
	}

	.msg-from,
	.msg-to,
	.msg-when,
	.msg-subject,
	.msg-attach {
		padding: 10px 30px;
		border-bottom: 1px solid $grey-3;
		margin-bottom: 0;
		overflow: hidden;
		
		label {
			display: inline-block;
			min-width: 80px;
			padding-right: 15px;
			vertical-align: top;
			font-weight: 600;
			color: $grey1;
			float: left;
		}
		
		.msg-info-content{
			width: calc(100% - 110px);
			float: left;
		}

		&.msg-attach {
			display: flex;
			max-height: 100px;
			overflow-y: auto;
			scrollbar-width: thin;
			scrollbar-color: $grey-1 $grey-3; // 1 barra 2 fondo (firefox)			

			ul {
				display: inline-flex;
				flex-wrap: wrap;
				margin: -3px;					
			}
			
			li {
				@extend .substring;

				max-width: 180px;
				padding: 5px 10px;
				border: 1px solid $grey;
				border-radius: 15px;
				margin: 3px;

				> * {
					text-decoration: none;
					color: $grey;

					&:hover { color: inherit; }
				}
			}

   		}
	}

	.msg-container {
		padding: 15px 30px;
		margin: 0 -30px;
		overflow-y: auto;
		scrollbar-width: thin;
		scrollbar-color: $grey-1 $grey-3; // 1 barra 2 fondo (firefox)		
	}
	
	.msg-firma{
		padding-top:20px;
		padding-bottom: 20px;
	}

	#rowMail {
		display: inline-block;
		position: absolute;
		top: 15px;
		width: 100%;
		margin: 0;

		.btn-group {
			margin-right: 5px;
			float: right;

			.btn {
				border: 1px solid $grey-3;
				margin-right: 0px;
				background-color: white;
			}
		}
	}
}

#zone_view_link {
	.media {
		.media-object { width: 25px; }

		.media-body {
			.media-heading { font-weight: 600; }

			p:not(:last-of-type) { margin-bottom: 0; } 
		}
	}
}






#popupServidorSwitch{
	width: 600px;

	.texto-intro{
		margin-top: 25px;
	}

	.listbox{
		border-top: 0;
		margin-top: 30px;
	}

	.popup-footer{
		margin-top: 10px;
	}

	.icono{
		margin: 10px auto 30px auto;
	}

	.list-element, .cuenta-nombre{
		text-align: center;
		justify-content: center;
	}

	#btnGoogleContainer{
		display: flex;
		align-items: center;

		.texto-legal{
			margin-left: 15px;
			font-size: 85%;
		}
	}
}