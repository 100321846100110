.page-search {
	.first-line {
		display: block;
		
		.texto-intro { margin: 10px 0; }
	}
}

.result-list {
	padding: 0 !important;

	h3 {
		width: 100%;
		padding: 10px 20px;
		margin: 0;
		font-size: 16px;
		font-weight: 600;
		background: $grey-2;
	}

	.no-results{ display: none; }

	.result-container{ padding: 30px;	}

	.spinner {
		width: 30px;
		height: 30px;
		margin: 30px auto;
	}

	.ver-mas {
		font-weight: 600;
		padding: 15px 20px;
		display: block;
	}
}

// Buscador top
.form-buscador {
	display: flex;
	margin: 30px auto 40px auto;
	border: 1px solid $grey-2;
	max-width: 400px;

	input,
	button {
		background: transparent;
		border: transparent;
		height: 34px;
	}

	input {
		width: calc(100% - 40px);
		padding-left: 10px;
		font-weight: 400;
	}
	
	button {
		width: 40px;
		padding: 5px 12px;
		font-size: 18px;
		opacity: 0.7;
	}
	
	&.form-buscador-inv{
		background: darken($blue, 10%);
		color: white;	
		border: 0;
		margin: 15px 5px 15px 0;
		
		input, button{
			color: white;
		}	
		
		input{
			&::-webkit-input-placeholder,
			&::-moz-placeholder,
			&:-moz-placeholder,
			&:-ms-input-placeholder{ 
				color: white;
			} 
		}		
	}
}

@media (max-width: 900px) {
	.navbar-billage .form-buscador-inv{
		display: none;
	}
}