/********** LLISTAT SENSE RESULTATS **********/
.listNoResult {
	position: relative;
	height: calc(100% - 100px);
	width: 100%;
	padding-top: 100px;

	@media (max-width: 1024px) { height: 330px; }
	@media #{$max-sm} {
		height: 180px;
		text-align: center;
	}

	.imgNinot { max-height: 380px; }

	.blocText {
		margin-top:10%;

		@media #{$max-sm} {
			top: inherit;
			transform: none;
		}

		> h2 {
			margin-top: 0;
			margin-bottom: 10px;
			font-size: 1.5em;
			font-weight: 600;
			color: $noResult-title;
		}

		> h3 {
			font-size: 1.5em;
			font-weight: normal;
		}

		> p {
			font-size: 1.3em;
			font-weight: 300;
			color: $noResult-subtitle;

			&.smallText { font-size: 1.1em; }
			&.subtitulo {
				line-height:28px;
			}
		}

		> small {
			display: block;
			margin-bottom: 15px;
		}

		.bigIcon { 
			padding-right: 10px; 
			float:left;

			@media (max-width: 425px) { float: none; }
		}
	}
}

/********** PRIMERS PASSOS **********/
.first-steps {
	@extend .listNoResult;

	height: 100%;
	padding-top: 160px;

	&:before {
		content: "";
		position: absolute;
		top: 0;
		right: 0;
		width: 160px;
		height: 160px;
		background-image: url(/saas/img/icons/backgrounds/sun.svg);
		background-position-x: right;
		background-position-y: top;
		background-repeat: no-repeat;
	}
	
	.blocText { margin-top:20px; }
}

/********* SUMMARY ********************/
.summary {
	text-align: right;

	.important {
		font-size: 16px;
		font-weight: 600;
	}

	.iconBig {
	    height: 30px;
	    width: 30px;
	    margin-right: 5px;
	}

	&.summary-filters{
		color: inherit; 
		background-color: transparent;
		width: 100%;
	}
	
	.value {
		display: inline-block;
		margin-left: 15px;
		color: $grey1;

		&:first-of-type { margin-left: 0; }

		strong {
			padding-right: 5px;
			font-size: 16px;
			font-weight: 600;
			color: $grey4; 
			white-space: nowrap;
		}

		@media #{$max-sm} {
			margin-left: 5px;
			font-size: 13px;

			strong {
				font-size: 13px;
				font-weight: 600;
			}
		}
	}

	.filters-list + & {
		.value { margin-bottom: 8px; }
	}
}

.list-navi {
	/*margin-left: 30px;*/

	.btn-icon{
		padding: 0 8px;
		
		&:hover{
			opacity:0.7;
		}
	}
}

.summary-small {
	padding-top: 10px;
	padding-bottom: 10px;
}

.summary-periodo {
	float:left;
	font-size: 18px;
}

.summary-icono {
	float: left;
	margin-right: 10px;
}

/********** LLISTATS **********/
.list {
	min-height: calc(100% - 30px);
	box-sizing: border-box;

	.summary {
		.media {
			.media-body{
				h4 { color: white; }
			}
		}
		
		.summary-totales {
			text-align:right;
			color: inherit;
		}	
	}

	.table-responsive .table {
		&:first-of-type:not(:last-of-type) { margin-bottom: 0; }
	}
		

}

.listTable {
	float: none !important;

	.listColLeft {
		padding: 20px 15px;
		text-align: left;

		@media screen and (max-width: 425px) {
			padding-left: 10px;
			padding-right: 10px;
		}
	}

	.listHeader {
		background: white;
		border-bottom: 1px solid $table-border;

		h4 {
			margin: 0;
			font-size: 14px;
			font-weight: 600;
			color: $color-dark;
		}
		.dobleLine [class^="col-"]:not(:last-child) { min-height: 50px; }
	}

	.list-element{
		position: relative;
		font-size: 14px;
		background-color: white;
		border: 1px solid transparent;
		border-bottom: 1px solid $table-border;
		
		&.list-bc {
			border-left-width: 4px;
			border-left-style: solid;
			border-left-color: transparent;
			padding-left: 16px;
		}
		
		&:hover { 
			background-color: $hover-background;
			border:1px solid $hover-border;
			margin-top: -1px;
			padding-top: 1px;
			
			&.list-bc {
				border-left-width: 4px;
				border-left-style: solid;
			}
		}

		a,
		.a {
			&:not(.estado) {
				@extend .a;
				color: $grey2;

				&:focus,
				&:hover { color: $blue; }
			}
		}

		.listInput,
		.listBtn {
			padding-top: 3px;
			padding-bottom: 3px;
			text-align: center;

			.btn { margin: 0;}
		}
		.listProgres {
			padding: 10px 15px;
		}

		.listActivities {
			display: block;
			height: 21px;
			text-align: center;
			cursor: pointer;

			> img { height: 100%; }
		}
	}	

	@media #{$max-sm} { 
		.listNumero { font-size: 85%; }
	}

	@media screen and (max-width: 425px) {
		.estado { padding: 4px; }
	}
}

.list-element {
	.list-bc { border-left: 4px solid transparent; }
}


/**************************************/



/* PAGINADOR LISTADOS */
.paginador{
	display: flex;
	width: 100%;
	justify-content: space-between;
	margin-top: 20px;
	align-items: center;
	margin-bottom: 80px; // fix para que intercom no tape boton 'next'
	
	strong{
		color: black;
	}
		
	.page-block{
		padding-left: 10px;
		padding-right: 10px;
		
		&:first-child{
			padding-left: 0;
		}
		
		&:last-child{
			padding-right: 0;
		}		
	}

	.paginador-select {
		margin: 0 5px;
		padding: 0;
		border: 0;
		width: 45px;
		background-color:transparent;
	}	

	.btn {
		background-color:transparent;
		padding: 0;
		outline: none;
		box-shadow: none;

		img { max-width: 15px; }

		&.left { padding: 0 9px 0 0; }
		&.right { padding: 0 0px 0 9px; }
	}	
	
	@media #{$max-xs} {
		font-size: 13px;
		
		.page-block{
			padding-left: 5px;
			padding-right: 5px;
		}	
		
		.page-block-elementos{
			text-align: center;
		}			
		
		.page-block-paginas, .page-block-resultados{
			span{
				display: block;
			}
		}
		
		.paginador-select {
			margin: 5px 0;
			width: 100%;
			display: block;
		}
	}
}

/* NUEVO TIPO DE LISTADO */
.listbox {
	border-top: 1px solid $table-border;

	&:empty { border-top: 0 !important; }

	i {
		color: $grey-1;
		margin-right: 5px;
	}

	article {
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-wrap: wrap;
		padding: 10px;
		border: 1px solid transparent;
		border-bottom: 1px solid $table-border;
		background: white;

		&:not(.no-hover) {
			cursor: pointer;

			&:hover {
				border: 1px solid $blue-2;
				background-color: $grey-4;
			}

			&.list-bc:hover { border-left-width: 4px !important; }
		}

		@media #{$max-xs} { padding: 5px; }
	}

	.bloque {
		flex: 1;
		padding-left: 5px;

		&:first-child { padding-left: 0; }
		&:last-child { padding-right: 10px; }
	}

	.bloque-cut {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
	
	.bloque-check {
		flex: 0.5;
		max-width: 35px;
		text-align: center;
	}
	
	.bloque-user {
		flex: 0.5;
		max-width: 55px;
		text-align: center;
	}

	.bloque-alias { white-space: nowrap; }
	.bloque-doc{
		max-width: 40px;
	}
	.bloque-estado { text-align: center; }

	.bloque-estado-right {
		text-align: right;
		max-width: 150px;
	}

	.bloque-importe,
	.bloque-recurrente,
	.bloque-fecha { text-align: right; }

	.bloque-ref { flex: 1 0 30%; }

	.bloque30 { flex: 1 0 20%; }
	
	.bloque-fecha { max-width: 120px; }

	.ref {
		display: block;
		font-weight: bold;
	}

	.ref-sub { font-weight: normal; }

	.activa { color: $green; }

	.inactiva { color: $red; }

	.activity-info{
		&:before{
			content: '·';
			margin-left: 5px;
			margin-right: 5px;
			color: $grey1;
		}
		label{
			color: $grey1;
			margin: 0 2px 0 0;
		}

		&:first-of-type{
			&:before{
				display: none;
			}
		}
	}

	.list-actions {
		flex: 0.5;
		text-align: right;

		.btn { margin-right: 0; }
	}
}

/* NUEVAS LISTAS VERSIÓN MÓVIL */
.table-list-xs {
	@extend .listbox;
	
	.list-element { cursor: pointer; }
	
	article {
		border-left: 4px solid transparent;
		padding: 10px 5px;
	}

	.bloque-actividades {
		color: $grey;
		text-align: right;
		
		img {
			height: 25px;
			width: auto;
		}
	}
	
	.bloque-campanas{
		color: $grey;
		text-align: right;
		
		div { white-space: nowrap; }

		img {
			height: 20px;
			width: auto;
		}
		
		@media #{$max-xxs} {
			img { display: none; }
		}		
	}
	
	a:not(.estado), .a {
		color: inherit;
	}			
	
}


// custom lists

.custom-list-config{
	.custom-list-title-columns{
		font-size: 16px;
		font-weight: 700;
	}

	.connected-lists{
		border: 1px solid #eee;
		min-height: 20px;
		list-style-type: none;
		margin: 0;
		padding: 7px 7px 4px 7px;
		background: $bg-grey;

		li{
			padding: 5px 7px;
			cursor: move;
			position: relative;
			border: 1px solid transparent;
			box-shadow: 0 2px 2px 0 rgba(13,10,44,.05);
			color: $text;
			list-style: none;
			background: white;
			margin-bottom:4px;

			&:hover,
			&:focus {
				border-color: $hover-border;
				background-color: $hover-background;
			}

			&.ui-sortable-placeholder {
				border: 2px dashed $blue;
				background-color: $blue-4;
				visibility: visible !important;
			}
		}
	}
}