.main-menu {
	height: 100%;
	padding-top: 66px;
	padding-left: 0;
	transition: all 0.2s ease;


	&.toggled {
		padding-left: $menu-width;

		.sidebar-wrapper { left: $menu-width; }

		@media #{$max-xs} {
			padding-left: 0;
		}
	}

	&.ajustes {
		padding-right: $menu-width;

		@media #{$max-sm} {
			padding-right: 0;
		}
	}
}

.sidebar-wrapper {
	position: fixed;
	left: 0;
	height: calc(100% - 65px);
	width: $menu-width;
	padding: 0; //0 23px 0 0;
	border-right: 1px solid $menu-border;
	margin-left: -$menu-width;
	color: $menu-color;
	background-color: $menu-background;
	font-size: 16px; //15
	overflow-y: auto;
	overflow-x: hidden;
	scrollbar-width: thin;
	scrollbar-color: $grey-1 $grey-3; // 1 barra 2 fondo (firefox)
	transition: all 0.2s ease;
	z-index: 100; // 40
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	.row-switch{
		margin-bottom: 20px;
		margin-left: 30px;
		margin-top: 15px;
		.label-switch{
			color: $text;
			margin-left: 8px !important;
		}
	}
}

.menu-list {
	margin-bottom: 0;

	li {
		position: relative;
		color: inherit;
		font-weight: 100;

		&.menu-header {
			border: 0;
			background-color: transparent;
			background-repeat: no-repeat;
			background-position: 28px 50%;
			background-size: 20px 20px;
			padding-left: 48px;
			font-weight: 700;
			color: $blue1;

			@media #{$max-sm} {
				background-position: 18px 50%;
				padding-left: 30px;
			}

			&:hover, &.active {
				a{ color: $blue}
			}

			&.menu-management{
				display: inline-block;
				padding-right: 30px;
				background-image: url(/saas/img/icons/menu/grey/general.svg);

				&:hover, &.active{
					background-image: url(/saas/img/icons/menu/blue/general.svg);
				}
			}

			&.menu-analisis{
				display: inline-block;
				padding-right: 30px;
				background-image: url(/saas/img/icons/menu/grey/analisis.svg);

				&:hover, &.active{
					background-image: url(/saas/img/icons/menu/blue/analisis.svg);
				}
			}

			&.menu-comercial{
				background-image: url(/saas/img/icons/menu/grey/comercial.svg);

				&:hover, &.active{
					background-image: url(/saas/img/icons/menu/blue/comercial.svg);
				}
			}

			&.menu-proyectos{
				background-image: url(/saas/img/icons/menu/grey/proyectos.svg);

				&:hover, &.active{
					background-image: url(/saas/img/icons/menu/blue/proyectos.svg);
				}
			}

			&.menu-facturacion{
				background-image: url(/saas/img/icons/menu/grey/facturacion.svg);

				&:hover, &.active{
					background-image: url(/saas/img/icons/menu/blue/facturacion.svg);
				}
			}

			&.menu-tesoreria{
				background-image: url(/saas/img/icons/menu/grey/tesoreria.svg);

				&:hover, &.active{
					background-image: url(/saas/img/icons/menu/blue/tesoreria.svg);
				}
			}
		}

		> a {
			display: block;
			padding: 3px 30px 3px 10px;
			color: inherit;
		}

		&.menu-analisis > a{
			padding-top: 0;
		}

		.btn-add {
			position: absolute;
			right: 15px;
			top: 50%;
			padding: 7px !important;
			transform: translateY(-50%);
			opacity: 0;
		}

	}
}

.menu-tree {
	li {
		border-left: 2px solid transparent;
		color: $text;

		a {
			padding-top: 4px;
			padding-bottom: 4px;
			line-height: 18px;
		}

		&.menu-header {
			&:hover { color: inherit; }

			a {
				padding-top: 8px;
				padding-bottom: 8px;
			}
		}

		&.tree-lv1{
			padding-left: 42px;
			//font-size: 15px;

			&:hover,
			&.active,
			&.selected {
				border-color: $menu-color-hover;
				color: $menu-color-hover;
			}

			&.active{
				font-weight: 500;
			}

			&:hover .btn-add{
				opacity: 0.6;

				&:hover { opacity: 1; }
			}

			@media #{$max-sm} {
				padding-left: 28px;
			}
		}

		.menu-grupo{
			margin-bottom: 8px;
			a{
				padding-top: 2px;
				padding-bottom: 2px;
			}
		}

	}

	.panel {
		border: 0;
		margin-bottom: 0;
		background-color: transparent;
		box-shadow: none;
	}

	.menu-settings {
		.menu-header {
			padding-left: 20px;
			padding-bottom: 10px;
			border: 0;
		}

		li a { padding-right: 20px; }
	}
}

.favoritos-list {
	margin-top: 30px;
	margin-bottom: 30px;

	li {
		border-top: 1px solid transparent;
		border-bottom: 1px solid transparent;

		&.menu-header {
			padding-left: 20px;
			padding-right: 20px;
		}

		a { padding-left: 57px; }

		.btn-fav {
			position: absolute;
			padding: 6px 4px;
			margin: 0 5px 0 30px;
			font-size: 10px;
			background-color: transparent;
			background-image: none;
			border: none;

			&:after {
				content: "\f005";
				font-family: "fontAwesome";
			}

			&:hover:after { content: "\f006"; }


		}

		&.show-more {
			border: 0;
			font-weight: 400;
			margin-top: 5px;

			a{
				padding-left: 35px;
			}

			&:hover {
				color: $menu-more-hover;

				a { background-color: transparent; }
			}
		}

		@media #{$max-sm} {
			a { padding-left: 40px; }

			.btn-fav{
				margin-left: 15px;
			}

			&.show-more a{
				padding-left: 19px;
			}
		}

		&:hover,
		&.selected {
			color: $blue;
			border-color: $hover-border;

			a {
				background-color: $hover-background;
				color: $blue;
			}
		}

		&:hover .btn-add{
			opacity: 0.6;
			background-image: url(/saas/img/icons/icons/blue/add.svg);

			&:hover { opacity: 1; }
		}
	}

	.menu-submenu {
		.btn-fav {
			&:after { content: "\f006"; }
			&:hover:after { content: "\f005"; }
		}

		+ .show-more {
			&[aria-expanded="false"] {
				.text-less { display: none; }
			}

			&[aria-expanded="true"] {
				.text-more { display: none; }
			}
		}
	}
}



#backgroundSidebar { display: none; }

@media #{$max-xs} {
	.main-menu {
		padding-top: 51px;

		.sidebar-wrapper {
			left: $menu-width;
			top: 51px;
			height: calc(100% - 51px);
			padding: 5px 0;
		}

		li.separador { margin: 20px; }

		.page-content-wrapper {
			left: 0;
			min-height: calc(100% - 51px);
		}

		&.toggled {
			.sidebar-wrapper { left: 0; }

			#backgroundSidebar { display: none; }
		}
	}

	#backgroundSidebar {
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: transparent;
		z-index: 1;
	}
}

@media #{$min-sm} {
	.main-menu.toggled .page-content-wrapper { position: relative; }
}



.settings-container{
	position: fixed;
	top: 0 !important;
	right: 0 !important;
	width: $menu-width;
	height: 100%;
	background: white;
	overflow-y: auto;
	scrollbar-width: thin;
	scrollbar-color: $grey-1 $grey-3; // 1 barra 2 fondo (firefox)
	transition: all 0.2s ease;
	border-left: 1px solid $menu-border;
	margin-left: -$menu-width;
	color: $menu-color;

	.separador{
		display: none;
	}

	.menu-list{
		//margin-top: 45px;
		.tree-lv0 > a{ padding-left: 20px}
		.tree-lv1{
			padding-left: 30px
		}
	}
}

#sidebarAjustes{
	z-index: 3;
	padding: 100px 0 23px 0;

	@media #{$max-sm} {
		display: none;
	}
}

#divMenuAjustes{
	@extend .settings-container;
	left: auto !important;

	.popup-header{
		margin: 0;
		.popup-title{
			border-bottom: 0;

			.popup-btn-close{
				top: 10px;
				right: 15px;

				img{
					height: 12px;
				}
			}
		}
	}

	.popup-content{
		padding: 60px 0 0 0;
	}

	@media #{$max-xs} {
		left: auto !important;
		right: 5px !important;

		.popup-content{
			padding: 20px 0 0 0;
		}
	}
}


.company-switch{
	height: 64px;
	padding: 15px 25px;
	display: block;
	border-bottom: 1px solid $menu-border;
	margin-bottom: 20px;
	width: $menu-width;

	.btn.dropdown-toggle{
		position: relative;
		background-color: transparent;
		color: $text;
		border: 1px solid $menu-border;
		font-size: 14px;
		font-weight: 600;
		text-align: left;
		width: 100%;
		background-image: url(/saas/img/icons/menu/negocio.svg);
		background-position: 8px 50%;
		background-repeat: no-repeat;
		background-size: 15px 15px;
		padding-left: 30px;
		padding-right: 21px;
		display: flex;
		align-items: center;
		border-radius: 2px;

		&:hover{
			border-color: $hover-border;
			background-color: $hover-background;
		}

		.icon-arrows{
			width: 10px;
			position: absolute;
			right: 7px;
			top: 12px;
		}
	}

	.dropdown-menu{
		min-width: 220px;
		margin-top: 0;
		left:15px;
		top: 55px;

		.fa{
			width: 17px;
		}
	}

	@media #{$max-sm} {
		padding: 15px;
	}
}