.board-main-content {
	display: -webkit-box;
	display: flex;
	flex-direction: column;
	height: calc(100vh - 160px);
	padding-bottom:50px;
	min-height: 420px;
	width: calc(100% + 30px); 
	margin-right: 0;
	transition: margin .1s ease-in;
	
	&.board-funnels{
		height: 200px;
		min-height: 200px;
	}
}

.board {
	position: relative;
	flex-grow: 1;

	.board-sort {
		display: flex;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		padding-bottom: 10px;
		overflow-x: auto;
		overflow-y: hidden;
		white-space: nowrap;
		user-select: none;
		scrollbar-width: thin;
		scrollbar-color: $grey-1 $grey-3; // 1 barra 2 fondo (firefox)
		
		.board-sort-reverse-container{
			display: flex;
			flex-direction: row-reverse;
			justify-content: flex-end;
		}
	}
	
	.board-list-header {
		flex: 0 0 auto;
		position: relative;
		padding: 10px 25px;
		color: $grey2;
		background-color: $grey-2;

		h3 {
			margin-top: 0;
			margin-bottom: 0;
			line-height: 1em;
			font-size: 15px;
			text-transform: uppercase;
			font-weight: 600;
		}

		p { margin-bottom: 0; }

		.dropdown {
			position: absolute;
			top: 0;
			right: 0; 
		}
	}			
	
	.board-card {
		min-height: 80px;
		padding: 8px 12px 10px 12px;
		border: 1px solid transparent;
		border-bottom:1px solid $grey-3;
		border-left-width: 4px;
		cursor: pointer;

		.listActivities {
			display: block;
			width: 20px;
			height: 17px;
			float: right;
			text-align: center;
			cursor: pointer;

			img { 
				height: 100%; 
				vertical-align: initial;
			}
		}				

		&:last-of-type { margin-bottom: 0; }

		&.hasColor:hover {
			border-top-color: $blue-2;
			border-right-color: $blue-2;
			border-bottom-color: $blue-2;
		}
		
		&:not(.hasColor):hover {
			border-color: $blue-2;
			border-left-width: 1px;
			padding-left: 15px;
		}

		.dropdown-menu { overflow: visible; }
		
		&:hover .btn-more { display: block; }
	}	
	
	.board-card-dragging { 
		cursor: move; 
		background:white;
		border: none;
		box-shadow: rgba(10, 10, 10, 0.2) 0px 2px 5px 0px; 
	}

	.board-card-header {
		overflow: hidden;

		.btn-more {
			background-position: 100% 50%; 
			width: 20px;
			display: none;
			
			&[aria-describedby] { display: block; }
		}
	}
	
	.board-card-footer{
		overflow: hidden;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	
	.board-card-footer-left{
		display: flex;
		align-items: center;
		justify-content: space-between;
		color: $grey2;
		//font-size: 14px;
		
		.board-icon{
			width: 18px;
			height: auto;
			margin-left: 12px;
			margin-right: 6px;
		}
	}	
	
	.board-presupuesto{
		display: flex;
		align-items: center;
		justify-content: space-between;	
	}
	
	.board-card-title{
		font-weight: 600;
		margin-top: 5px;
		color: $grey2;
		
		a{
			color: inherit;
		}
		
		&:not(.fw){
			width: calc(100% - 20px);
			float:left;
		}
	}
	
	.board-card-placeholder {
		min-height: 55px;
		padding: 8px;
		border:2px dashed transparent;
		margin: 3px 0;				
		border-color: $blue !important;
		background-color: $blue-3;
	}
					
	.board-list-cards {
		flex: 1 1 auto;
		overflow-y: auto;
		min-height: 10px;
		margin: 0 1px 0 1px;
		scrollbar-width: thin;
		scrollbar-color: $grey-1 $grey-3; // 1 barra 2 fondo (firefox)
	}

	.board-list-footer {
		position: relative;
		flex: 0 0 auto;
		user-select: none;
		background: transparent url(/saas/img/icons/icons/blue/add.svg) 10px center no-repeat;
		background-size: auto 25px;

		.btn {
			text-align: left;
			padding: 15px 25px 15px 40px;
			&:hover { color: $grey; }
		}
	}	
					
	.board-list-container {
		display: flex;
		flex-direction: column;
		position: relative;
		max-height: 100%;
		white-space: normal;
		background: white;
	}
	

	.board-list-wrapper {
		min-width: 260px;
		margin-left: 20px;
		
		&:first-of-type { 
			margin-left: 0; 
		}

		@media #{$min-xxl} { width: 300px; }
	}
	
	.board-sort-reverse{
		.board-list-wrapper {
			margin-left: 0;
			margin-right: 20px;
			
			&:first-of-type { 
				margin-right: 0; 
			}
		}
	}

	.board-list-placeholder {
		@extend .board-list-wrapper;
		border: 2px dashed $blue;
		background-color: $blue-3;
	}

	.ui-sortable-helper { transform: rotate(3deg); }
}

// solo funnel oportunidades 

.board-funnel {
	.board-list-header {
		padding: 19px 15px 19px 25px;

		&:before, &:after {
			content: "";
			position: absolute;				
			transform: rotate(45deg) skew(25deg, 25deg);
			top: 19px;
			width: 38px;
			height: 38px;	
			z-index: 1;		
		}
				
		&:before {
			left: -19px;
			background-color: $background;				
		}

		&:after {
			right: -19px;
			background-color: inherit;
			z-index: 2;	
		}
		
		.oportunidad-titulo{
			display: flex;
			justify-content: space-between;
			margin-bottom: 3px;
		}
		
		.oportunidades-info{
			text-transform: lowercase;
		}			
	}
	
	.fase-bgcolor{
		height: 4px;
		min-height:4px;
		opacity:0.5;
	}
	
	.board-sort-normal{
		.board-list-wrapper {
			&:first-of-type { 
				.board-list-header{
					padding-left: 15px;
					
					&:before{ 
						display: none; 
					} 
				}
			}
		}	
	}

	.board-sort-reverse{
		.board-list-wrapper {
			&:last-of-type { 
				.board-list-header{
					padding-left: 15px;
					
					&:before{ 
						display: none; 
					} 
				}
			}
		}	
	}	
	
}



.popover.popover-listActivities {
	left: initial !important;
	right: 0;
	width: 100%;
	cursor: initial;

	.popover-title{
		margin-bottom: 0;
		border-bottom: 0;
	}

	.arrow {
		right: 20px;
		left: auto !important;
	}

	.popover-content {
		padding-top: 0;
	}
	
	.listTable {
		margin-bottom: 0;
		cursor: pointer;

		.list-element {
			&:nth-child(1) {
				border-top-color: $grey-3;

				&:hover { border-top-color: $blue-2; }
			}

			> *:nth-child(1) { padding-top: 5px; font-weight: 600;}
			> *:nth-child(2) {
				padding-right: 5px;
				padding-bottom: 5px;
				padding-top: 5px;
				img{
					width: 17px;
					height: auto;
				}
			}
			> *:nth-child(3) {
				padding-left: 5px;
				padding-bottom: 5px;
				padding-top: 5px;
			}

			.owner {
				@extend .substring;
				width: calc(100% - 69px);
				font-style: italic;
				vertical-align: bottom;
			}
		}
	}
}


// solo funnel de funnels


.board-funnels{
	.board{
		.board-list-wrapper{
			min-width: 200px;
		}
		
		.board-list-header {
			.dropdown{
				bottom: 9px;
				top: auto;
				
				.dropdown-menu{
					min-width: 160px;
				}
			}
		}
	}
}