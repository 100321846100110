.board.board-kanban {
	@extend .board;
	
	.board-list-header {
		padding: 0 0 0 17px;
		height: 34px;
		display: flex;
		align-items: center;
		
		h3{
			line-height: initial;
		}
	}
			
	.board-card {				
		.project-details {
			margin-left: 5px;
			display: inline-block;
			font-size: 13px;
			color: $grey1;
			
			.img-icon-calendar{margin-left: 3px}
			.timetraking { float: right; }
			.fecha-fin, .project-comments{margin-right:3px;}
		}						
		
		.img-icon {
			height: 16px;
			width: auto;
			margin-bottom: 3.5px;
			margin-right: 2px;
			
			&.img-icon-calendar{
				width: 16px;
			}
			
			&.img-icon-time{
				margin-left: 3.5px;
			}
			
			&.img-icon-comments{
				margin-left: 3.5px;
			}
		}

		.popover-pickDT{
			left: 7px !important;

			.arrow{
				left:50px !important;
			}
		}
	}

	.state-container-kanban {
		.estado{
			font-size: 13px;
		}
	}
		
	.project-timetracker{
		display: none;
	}

	.board-list-footer {
		textarea {
			width: 100%;
			padding: 15px 20px 15px 40px;
			border: 1px solid transparent;
			background-color: transparent;

			&.inp-error {
				border-color: $color-error;
				background-color: $color-error-light;
			} 
		}
	}		

	.board-list-wrapper {
		min-width: 280px;
		max-width: 300px;
		width: 22%;

		&.board-list-wrapper-edit,
		&.board-list-wrapper-add {
			.board-list-header {
				padding: 0;
			}
			 .add-quick {
			 	height: 40px;
				width: 100%;
				padding: 9px;
				padding-left: 30px;
				border: 1px solid transparent;

				font-size: 16px;
				background: transparent url(/saas/img/icons/icons/grey/add.svg) left 5px center no-repeat;
				background-size: auto 25px;

				&::placeholder { color: $grey2; }

				&.inp-error {
					background-color: $color-error-light;
					border-color: $color-error;
				}
			}

			.edit-quick {
				@extend .add-quick;
				background: transparent url(/saas/img/icons/icons/grey/edit.svg) left 10px center no-repeat;
				background-size: auto 15px;
			}

		}
		
		.kanbanList-add-quick{
			color: $grey2;
			&::placeholder { color: $grey2 !important; }
		}
	}
}

.kanban-list-wrapper {
	width: 270px;
	height: 100%;
	margin-left: 1px;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	display: inline-block;
	vertical-align: top;
	white-space: nowrap;
	background-color: #b3b3b3;
}
.kanban-list{
	-webkit-box-flex: 1;
	-webkit-flex: 1 1 auto;
	-ms-flex: 1 1 auto;
	flex: 1 1 auto;
	overflow-y: scroll;
	overflow-x: hidden;
	padding: 0 10px;
	z-index: 1;
	min-height: 12px;
	scrollbar-width: thin;
	scrollbar-color: $grey-1 $grey-3; // 1 barra 2 fondo (firefox)	
}
.kanban-list-container {
	border-radius: 7px;
	box-sizing: border-box;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	max-height: 100%;
	position: relative;
	white-space: normal;
	padding: 10px 0 10px 10px;
}

.main-row-kanban{
	.pickColor-wrap{
		margin-right: 0;
		margin-left: 10px;
		
		.color-display{
			margin-right: 0;
		}
	}
}