.btn {
	border: 0;
	box-shadow: none;
	//border-radius: 2px;
	font-size: 16px;
	//line-height: 1em;

	// material
	//height: 40px;
	padding: 6px 20px;
	border-radius: 25px;
 
	&:hover,
	&.hover,
	&:focus,
	&.focus,
	&:active,
	&.active,
	&:active:focus {
		outline: none !important;
		box-shadow: none !important; 
	}

	@media #{$max-xs} { padding: 6px 10px; }

	&.btn-xs { padding: 4px 5px; }

	.caret {
		position: relative;
		border: 0;
		margin-right: 5px;
		margin-left: 3px;

		&:before {
			content: "\f107";
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			font-family: FontAwesome;
			font-size: 18px;
			color: $blue;
			font-weight: bold;
		}
	}

	/* ------------------- BTN DEFAULT */
	&.btn-default {
		font-weight: 600;
		color: $btn-def-color;
		background-color: $btn-def-background;
		border: 2px solid $btn-def-color;

		&:hover,
		&.focus,
		&:focus,
		&.active,
		&:active,
		&:active:focus {
			color: $btn-def-hover-color;
			background-color: $btn-def-hover-background;
		}

		&.disabled,
		&.disabled.focus,
		&.disabled:focus,
		&.disabled:hover,
		&[disabled],
		&[disabled].focus,
		&[disabled]:focus,
		&[disabled]:hover {
			color: $btn-def-disabled-color;
			background-color: $btn-def-disabled-background;
		}
	}

	/* ------------------- BTN INVERT */
	&.btn-invert{
		font-weight: 600;
		color: white;
		background-color: $btn-primary-background;
		border:2px solid white;

		&:hover,
		&.focus,
		&:focus,
		&.active,
		&:active,
		&:active:focus {
			color: $btn-primary-hover-color;
			background-color: $btn-primary-hover-background;
		}
		&.disabled,
		&.disabled.focus,
		&.disabled:focus,
		&.disabled:hover,
		&[disabled],
		&[disabled].focus,
		&[disabled]:focus,
		&[disabled]:hover{
			color: $btn-primary-disabled-color;
			background-color: $btn-primary-disabled-background;
		}
	}	

	/* ------------------- BTN PRIMARY */
	&.btn-primary, &.btn-submit, &.btn-submit-sm, #submit {
		font-weight: 600;
		color: $btn-primary-color;
		background-color: $btn-primary-background;

		&:hover,
		&.focus,
		&:focus,
		&.active,
		&:active,
		&:active:focus {
			color: $btn-primary-hover-color;
			background-color: $btn-primary-hover-background;
		}
		&.disabled,
		&.disabled.focus,
		&.disabled:focus,
		&.disabled:hover,
		&[disabled],
		&[disabled].focus,
		&[disabled]:focus,
		&[disabled]:hover{
			color: $btn-primary-disabled-color;
			background-color: $btn-primary-disabled-background;
		}

	}

	&.btn-submit, #submit{
		height: 40px;
	}
	
	&.btn-primary-outline {
		font-weight: 600;
		background-color: white;
		border: 1px solid $btn-primary-background;
		color: $btn-primary-background;
		
		&:hover,
		&.focus,
		&:focus,
		&.active,
		&:active,
		&:active:focus {
			color: $btn-primary-background;
			background-color: #d1daf4;
		}
		&.disabled,
		&.disabled.focus,
		&.disabled:focus,
		&.disabled:hover,
		&[disabled],
		&[disabled].focus,
		&[disabled]:focus,
		&[disabled]:hover{
			color: $btn-primary-background;
			background-color: #d1daf4;
		}

	}
	

	/* ------------------- BTN SECONDARY */
	&.btn-secondary {
		color: $btn-secondary-color;
		background-color: $btn-secondary-background;
		font-weight: 600;
		
		&:hover,
		&.focus,
		&:focus,
		&.active,
		&:active,
		&:active:focus {
			color: $btn-secondary-hover-color;
			background-color: $btn-secondary-hover-background;
		}

		&.disabled,
		&.disabled.focus,
		&.disabled:focus,
		&.disabled:hover,
		&[disabled],
		&[disabled].focus,
		&[disabled]:focus,
		&[disabled]:hover{
			color: $btn-secondary-disabled-color;
			background-color: $btn-secondary-disabled-background;
			font-weight: bold;
		}
	}

	/* ------------------- BTN THIRD */
	&.btn-third {
		color: $btn-third-color;
		background-color: $btn-third-background;

		&:hover,
		&.focus,
		&:focus,
		&.active,
		&:active,
		&:active:focus {
			color: $btn-third-hover-color;
			background-color: $btn-third-hover-background;
		}

		&.disabled,
		&.disabled.focus,
		&.disabled:focus,
		&.disabled:hover,
		&[disabled],
		&[disabled].focus,
		&[disabled]:focus,
		&[disabled]:hover {
			color: $btn-third-disabled-color;
			background-color: $btn-third-disabled-background;
		}
	}
	
	/* ------------------- BTN FOURTH */
	&.btn-fourth, &.btn-white {
		color: $btn-fourth-color;
		font-weight: 600;
		background-color: $btn-fourth-background;
		border:1px solid $btn-fourth-border-color; 
		
		&:hover,
		&.focus,
		&:focus,
		&.active,
		&:active,
		&:active:focus {
			color: $btn-fourth-hover-color;
			background-color: $btn-fourth-hover-background;
		}

		&.disabled,
		&.disabled.focus,
		&.disabled:focus,
		&.disabled:hover,
		&[disabled],
		&[disabled].focus,
		&[disabled]:focus,
		&[disabled]:hover {
			color: $btn-fourth-disabled-color;
			background-color: $btn-fourth-disabled-background;
		} 
	}

	/* ------------------- BTN CANCEL */
	&.btn-cancel {
		color: $btn-cancel-color;
		background-color: $btn-cancel-background; 
		
		&:hover,
		&:focus, 
		&:active {
			color: $btn-cancel-hover-color;
			background-color: $btn-cancel-hover-background; 
		}	
	}
	
	&.btn-cancel-link {
		color: $red;
		background-color: transparent; 
		padding-left: 15px;
		padding-right: 15px;
		font-weight: 600;
		
		&:hover,
		&:focus, 
		&:active {
			color: $btn-cancel-hover-background;
			background-color: transparent; 
		}	
	}	

	
	
	/* ------------------- BTN cta (rojo) */
	&.btn-cta {
		color: $btn-cta-color;
		background-color: $btn-cta-background;
		
		&:hover,
		&.focus,
		&:focus,
		&.active,
		&:active,
		&:active:focus{
			color: $btn-cta-hover-color;
			background-color: $btn-cta-hover-background;
		}
		&.disabled,
		&.disabled.focus,
		&.disabled:focus,
		&.disabled:hover,
		&[disabled],
		&[disabled].focus,
		&[disabled]:focus,
		&[disabled]:hover {
			color: $btn-cta-disabled-color;
			background-color: $btn-cta-disabled-background;
			text-transform: uppercase;
		}
		
		&.btn-md{
			font-size: 16px;
			font-weight: 600;
			padding-top: 8px;
			padding-bottom: 8px;
		}
	}
	
	/* ------------------- BTN GRIS */
	&.btn-gris {
		color: $btn-gris-color;
		background-color: $btn-gris-background;

		&:hover,
		&.focus,
		&:focus,
		&.active,
		&:active,
		&:active:focus {
			color: $btn-gris-hover-color;
			background-color: $btn-gris-hover-background;
		}

		&.disabled,
		&.disabled.focus,
		&.disabled:focus,
		&.disabled:hover,
		&[disabled],
		&[disabled].focus,
		&[disabled]:focus,
		&[disabled]:hover {
			color: $btn-gris-disabled-color;
			background-color: $btn-gris-disabled-background;
		}
	}

	/* ------------------- BTN UPLOAD */
	&.btn-upload {
		padding: 10px 20px;
		border: 1px solid $btn-upLoad-border;
		color: $btn-upLoad-color;
		background-color: $btn-upLoad-background;

		&:hover,
		&.focus,
		&:focus,
		&.active,
		&:active,
		&:active:focus {
			border: 1px solid $btn-upLoad-hover-border;
			color: $btn-upLoad-hover-color;
			background-color: $btn-upLoad-hover-background;
		}

		&.disabled,
		&.disabled.focus,
		&.disabled:focus,
		&.disabled:hover,
		&[disabled],
		&[disabled]:focus,
		&[disabled]:hover {
			border: 1px solid $btn-upLoad-disabled-border;
			color: $btn-upLoad-disabled-color;
			background-color: $btn-upLoad-disabled-background;
		}

		img,
		small { display: block; }

		img { width: 30px; }
		small {
			margin-top: 1px;
			font-size: 0.8em;
			line-height: 2em;
		}
	}
	
	/* ------------------- BTN CANCEL */
	&.btn-cancel {
		color: $btn-cancel-color;
		background-color: $btn-cancel-background; 
		
		&:hover,
		&:focus, 
		&:active {
			color: $btn-cancel-hover-color;
			background-color: $btn-cancel-hover-background; 
		}	
	}
	
	/* ------------------- BTN REGISTER */
	&.btn-register {
		font-size: 16px;
		color: $btn-register-color;
		background-color: $btn-register-background;
		font-weight: 600;
		padding-top: 10px;
		padding-bottom: 10px;
		
		&:hover,
		&:focus, 
		&:active {
			color: $btn-register-hover-color;
			background-color: $btn-register-hover-background;
		}	
	}

	/* ------------------- BTN SOCIAL */
	&.btn-social {
		position: relative;
		padding: 10px 30px;
		border: 1px solid;

		> img {
			position: absolute;
			left: 0;
			top: 50%;
			transform: translateY(-50%);
			height: 100%;
			padding: 10px 30px;
		}

		// Google
		&.btn-google {
			//height: 40px !important;
			//padding: 8px !important;
			padding-left: 50px !important;
			font-size: 14px;
			text-align: left;
			font-family: "Roboto", sans-serif;
			font-weight: 500;
			border-color: white;
			color: rgba(0,0,0,0.54);
			background-image: url('/saas/img/icons/icons/social/google.svg');
			background-size: 18px 18px;
			background-repeat: no-repeat;
			background-position: 15px center;
			background-color: white;
			box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.25);
			transition: background-color .218s, border-color .218s, box-shadow .218s;

			&:hover,
			&.focus,
			&:focus,
			&.active,
			&:active,
			&:active:focus {
				box-shadow: 0 0 1px 2px rgba(0,123,255,.25) !important;
				color: rgba(0,0,0,0.54);
				background-color: white;
			}
		}
	}
	
	
	/* ------------------- BTN SANTANDER */
	
	
	&.btn-santander-submit-outline {
		color: $color-santander;
		font-weight: 600;
		background-color: white;
		border:1px solid $color-santander; 
		
		&:hover,&.focus,&:focus,&.active,&:active,&:active:focus {
			color: $color-santander;
			background-color: #faedee;
		}

		&.disabled,	&.disabled.focus,&.disabled:focus,&.disabled:hover,	&[disabled],&[disabled].focus,	&[disabled]:focus,	&[disabled]:hover {
			color: #f09ca0;
			background-color: #faedee;
		} 
		
		/*
		&.btn-santander-multiline{
			height: 50px !important;
			font-size: 14px; 
			max-width: 205px;
			white-space: normal;
			text-align: left;
			line-height: 1.2em;
		}
		*/
	}	
	&.btn-santander {
		/*background-image: url(/saas/img/bancos/santander-iso.svg);
		background-size: 17px;
		background-repeat: no-repeat;
		background-position: 10px 50%;
		padding-left:35px;*/
		
		@extend .btn-third;
		
	}
	&.btn-santander-submit {
		color: white;
		font-weight: 600;
		background-color: $color-santander;
		border:1px solid $color-santander; 
		
		&:hover,&.focus,&:focus,&.active,&:active,&:active:focus {
			color: $color-santander;
			background-color: #faedee;
			border:1px solid $color-santander; 
		}

		&.disabled,	&.disabled.focus,&.disabled:focus,	&.disabled:hover,	&[disabled],	&[disabled].focus,	&[disabled]:focus,	&[disabled]:hover {
			color: #f09ca0;
			background-color: #faedee;
		} 
	}


	&.btn-link{
		height: auto;
	}
}

.link-santander{
	/*color: $color-santander !important;
	background-image: url(/saas/img/bancos/santander-iso.svg);
	background-size: 17px;
	background-repeat: no-repeat;
	background-position: 100% 50%;	*/
}

.fakeBtn {
	> input[type=file] {
		position: absolute;
		width: 100%;
		height: 40px;
		z-index: 3;
		opacity: 0;
		cursor: pointer;

		&:hover + .input-group .btn-primary { background-color: $blue1; }
	}
}

.btn-round {
	border-radius: 50%;
	height: 34px;
	width: 34px;
	padding: 0;
}

/* ------------------- DROPDOWN DEFAULT */
@mixin divider {
	height: 1px;
	padding: 0;
	margin: 10px 15px;
	background-color: $dropDown-divider;
	overflow: hidden;

	&:hover { background-color: $dropDown-divider; }
}

.dropdown-menu {
	font-size: 15px;
	min-width: 240px;
	max-height: 90vh;
	border-color: $dropDown-menu-border;
	background-color: $dropDown-menu-background;
	overflow-y: auto;
	scrollbar-width: thin;
	scrollbar-color: $grey-1 $grey-3; // 1 barra 2 fondo (firefox)
	overflow-x: hidden;
	z-index: 75;
	margin-top:10px;
	font-weight: 400;

	@media #{$max-xs} {
		max-width: 100%;
	}

	> li {
		&.dropdown-header {
			padding: 3px 15px;
			font-size: 14px;
			color: $grey;
		}

		&:not(.dropdown-header) {
			position: relative;
			padding: 5px 15px;
			color: $dropDown-line-color;
			background-color: $dropDown-line-background;
			cursor: pointer;

			> a,
			> .a {
				padding: 0;
				white-space: unset;
				color: inherit;
				background-color: transparent;
			}

			> * :not(.state-edit) {
				margin: 0;
				color: inherit;
			}

			&:hover {
				color: $dropDown-line-hover-color;
				background-color: $dropDown-line-hover-background;

				&.active a:hover,
				> * :not(.state-edit):not(.avatar span) {
					color: inherit;
					background-color: transparent;
				}

			}
		}

		&.dropdown-title {
			font-weight: 600;
			color:#999;
			background:#f2f2f2;
		}
		
		&.divider {
			@include divider;
		}

		&.dropCollapse {
			&:hover {
				color: inherit;
				background-color: transparent;
			}

			.panel-group {
				margin-bottom: 0;

				.panel {
					box-shadow: none;

					.panel-heading {
						position: relative;
						min-height: 100%;
						padding: 5px 20px;
						border: 0;
						margin: -5px -20px;
						background-color: transparent;

						&:hover {
							color:  $dropDown-line-hover-color;
							background-color: $dropDown-line-hover-background;
						}

						a, .a {
							display: block;
							color: inherit;
							background-color: transparent;
						}
					}

					.panel-collapse {
						margin: 5px -20px 0;

						&:before {
							content: "";
							display: block;
							border-bottom: 1px solid $dropDown-menu-border;
							margin: 0 20px;
						}

						.panel-body {
							padding: 0;
							border-top: 0;

							> a,
							> .a {
								display: block;
								padding: 5px 20px;
								margin: 0;
								color: inherit;

								&:hover {
									color:  $dropDown-line-hover-color;
									background-color: $dropDown-line-hover-background;
								}
							}
						}
					}
				}
			}
		}

		.radio,
		.checkbox { margin: 0; }				
		
		.btn-link{
			color: inherit;
			text-decoration: none;
			text-align: left;
			height: auto !important;
		}
	}

	&.dropdown-years {
		min-width: 105px;
		max-height: 150px;
	}


	.filter-dropdown & {
		.dropdown-menu {
			display: block;
			position: initial;
			max-height: 45vh;
			border: 0;
			border-radius: 0;
			margin-top: 0;
			box-shadow: none;
			float: none;
		}
	}

	.dropdown-footer {
		width: 100%;
		padding: 0 15px;
		margin-bottom: 5px;

		hr {
			margin-top: 5px;
			margin-bottom: 10px;
		}

		.btn-submit { color: white; }
	}


}


.dropdown-arrow {
	overflow: initial;
	box-shadow: 0 3px 5px 0 rgba(2,9,14,.12);

	&:before {
		content: "";
		position: absolute;
		top: -8px;
		left: 21px;
		border-left: 9px solid transparent;
		border-right: 9px solid transparent;
		border-bottom: 8px solid $dropDown-menu-border;
	}

	&:after {
		content: "";
		position: absolute;
		top: -7px;
		left: 23px;
		border-left: 7px solid transparent;
		border-right: 7px solid transparent;
		border-bottom: 7px solid $dropDown-menu-background;
	}
}

.dropdown-menu-right {
	&.dropdown-arrow {
		overflow: initial;
		box-shadow: 0 3px 5px 0 rgba(2,9,14,.12);

		&:before {
			left: auto;
			right: 21px;
		}

		&:after {
			left: auto;
			right: 23px;
		}
	}
}

.dropup {
	.dropdown-menu {
		margin-top: 0;
		margin-bottom: 10px;
	}

	.dropdown-arrow {
		&:before {
			top: auto;
			bottom: -8px;
			left: 21px;
			right: auto;
			border-top: 8px solid $dropDown-menu-border;
			border-bottom: 0;
		}

		&:after {
			top: auto;
			bottom: -7px;
			left: 23px;
			right: auto;
			border-top: 7px solid $dropDown-menu-background;
			border-bottom: 0;
		}
	}
}

.btn-group {
	.btn {
		padding: 5px 10px;
		margin-right: 2px;

		&:last-child { margin-right: 0; }

		&:hover,
		&.active {
			> img { 
				opacity: 1; 
			}
		}

		&.btn-primary {
			border: 1px solid $grey-2;
			color: $grey;
			background-color: transparent;
			
			&:hover,
			&.focus,
			&:focus,
			&.active,
			&:active,
			&:active:focus {
				border: 1px solid $btn-primary-hover-background;
				color: $btn-primary-hover-color;
				background-color: $btn-primary-hover-background;
			}

			&.active {
				border: 1px solid $btn-primary-background;
				color: $btn-primary-color;
				background-color: $btn-primary-background;

			}
		}

		> input {
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;
			width: 100%;
			margin: 0;
			opacity: 0;
			cursor: pointer;
		}

		> img {
			opacity: 0.4;
			cursor: pointer;
			max-width: 100%;
		}
	}
}



.btn-icon {
	padding: 10px;
	border: 0;
	background-color: transparent;
	background-repeat: no-repeat;
	background-size: 100%;
	background-position: center;
	height: auto;

	&.btn-add { background-image: url('/saas/img/icons/icons/grey/add.svg'); }
	&.btn-alert { background-image: url('/saas/img/icons/icons/red/alert.svg'); }
	&.btn-fav { background-image: url('/saas/img/icons/icons/white/like.svg'); } 
	&.btn-close {
		padding: 8px;
		background-image: url('/saas/img/icons/icons/blue/close.svg');
		background-size: 50%;
	}
	&.btn-attach { background-image: url('/saas/img/icons/icons/blue/clip.svg'); }
	&.btn-photo { background-image: url('/saas/img/icons/icons/blue/photo.svg'); }
	&.btn-dropmore {
		padding: 15px;
		background-size: 60%;
		background-color: transparent;
		background-image: url('/saas/img/icons/icons/blue/more.svg');

		&:focus,
		&:hover { background-image: url('/saas/img/icons/icons/grey/more.svg'); }
	}
}

.btn-del {
	@extend .btn-icon;
	padding: 11px;
	background-image: url('/saas/img/icons/icons/blue/delete.svg');
}

.btn-menu {
	@extend .btn-icon;
	padding: 11px;
	background-image: url('/saas/img/icons/icons/blue/more.svg');

	&:active { background-image: url('/saas/img/icons/icons/grey/more.svg'); }
}

.btn-left, .btn-right  {
	border: 0;
	background-color: transparent;
	background-repeat: no-repeat;
	background-position: center;
	padding: 10px 12px;
	background-size: 95%;
}

/*-------------------------------------------*/
.open>.dropdown-toggle.btn-default:focus, 
.open>.dropdown-toggle.btn-default:hover,
.open>.dropdown-toggle.btn-default,
.nav > li > a.btn-default:focus,
.nav > li > a.btn-default:hover {
	color: $btn-def-hover-color;
	background-color: $btn-def-hover-background;
}
.open>.dropdown-toggle.btn-primary:focus, 
.open>.dropdown-toggle.btn-primary:hover,
.open>.dropdown-toggle.btn-primary,
.nav > li > a.btn-primary:focus,
.nav > li > a.btn-primary:hover {
	color: $btn-primary-hover-color;
	background-color: $btn-primary-hover-background;
}
.open>.dropdown-toggle.btn-secondary:focus, 
.open>.dropdown-toggle.btn-secondary:hover,
.open>.dropdown-toggle.btn-secondary,
.nav > li > a.btn-secondary:focus,
.nav > li > a.btn-secondary:hover {
	color: $btn-secondary-hover-color;
	background-color: $btn-secondary-hover-background;
}

.open>.dropdown-toggle.btn-gris:focus, 
.open>.dropdown-toggle.btn-gris:hover,
.open>.dropdown-toggle.btn-gris,
.nav > li > a.btn-gris:focus,
.nav > li > a.btn-gris:hover {
	color: $grey;
	background-color: white;
}



/* ------------------- BTN NO BUTTON */
.btn-noButton{
	padding: 0;
	background:none;
	background-color:transparent;
	border:0;
	box-shadow: none !important;
}


.btn-noButton:hover,
.btn-noButton.focus,
.btn-noButton:focus,
.btn-noButton.active,
.btn-noButton:active,
.btn-noButton:active:focus,
.open>.dropdown-toggle.btn-noButton:focus, 
.open>.dropdown-toggle.btn-noButton:hover,
.open>.dropdown-toggle.btn-noButton,
.nav > li > a.btn-noButton:focus,
.nav > li > a.btn-noButton:hover {
	color: inherit;
	background-color: transparent;
}

.btn-noButton.disabled,
.btn-noButton.disabled.focus,
.btn-noButton.disabled:focus,
.btn-noButton.disabled:hover,
.btn-noButton[disabled],
.btn-noButton[disabled].focus,
.btn-noButton[disabled]:focus,
.btn-noButton[disabled]:hover {
	color: inherit;
	background-color: transparent;
}

/* ------------------- BTN DROPDOWN IMG */
.btn-group.open .dropdown-toggle {
	outline: none;
	box-shadow: none;
}

.dropdown {
	display: inline-block;

	.dropdownImg { height: 20px; }
}



.btn-transparent{
	background:transparent;
	color: #949090;
	border:0;
	box-shadow: none !important;
	-webkit-box-shadow: none !important;
	padding:10px;
	cursor: pointer; 
}

.btn-transparent-dd{
	@extend .btn-transparent;
	background: transparent $bgCaretSelect;
	min-width: 25px;
}

.btn-transparent-image {
	@extend .btn-transparent;

	padding: 10px 5px;

	img {
		height:20px;
		width:auto;
	}
}

.btn-alink {
	@extend .btn-transparent;

	margin: 0px;
	padding: 0;
	font-weight: 500;	
	color: $blue1;
	font-size: 14px;
	
	img {
		height: 20px;
		width: auto;
	}
	
	&:hover { color: $grey; }
}

.btn-action{
	@extend .btn-transparent;

	cursor:pointer;
	position:relative;
	min-width:30px;
	min-height:20px;
}

.btn-edit {
	@extend .btn-action;

	background: url(/saas/img/icons/icons/blue/edit.svg) 50% 50% no-repeat;
	background-size: auto 24px;
}


.btn-new {
	@extend .btn-action;

	background: url(/saas/img/icons/icons/blue/add.svg) 50% 50% no-repeat;
	background-size: auto 18px;
}

.btn-remove{
	@extend .btn-action;

	background: url(/saas/img/icons/icons/grey/close.svg) 50% 50% no-repeat;
	background-size: auto 9px;
}

.btn-config{
	@extend .btn-action;

	background: url(/saas/img/icons/icons/blue/config.svg) 50% 50% no-repeat !important;
	background-size: auto 16px;
}

.btn-edit-icon {
	@extend .btn-action;
	position: relative;

	background: url(/saas/img/icons/icons/blue/edit.svg) 50% 50% no-repeat;
	background-size: auto 24px;
}

.btn-remove-icon{
	@extend .btn-action;
	position: relative;

	background: url(/saas/img/icons/icons/blue/delete.svg) 50% 50% no-repeat;
	background-size: auto 24px;
}

.btn-fav-project {
	@extend .btn-action;

	background: url(/saas/img/icons/icons/grey/star-o.svg) 50% 50% no-repeat;
	background-size: auto 14px;
	
	&.btn-fav-active{
		background-image: url(/saas/img/icons/icons/grey/star.svg);
	}
}

.btn-more, .btn-order { 
	@extend .btn-transparent;

	background-image:url(/saas/img/icons/icons/blue/more.svg);
	background-size: auto 18px;
	background-repeat:no-repeat;
	background-position:50% 50%;
	cursor:pointer;
	position:relative;
	height:30px;
	width:30px;

	&:focus,
	&:hover,
	&.active,
	&.focus { background-image: url(/saas/img/icons/icons/grey/more.svg); }
}

.btn-order {
	background-image:url(/saas/img/icons/icons/grey/dragable.svg); 
	&:hover,
	&.active { background-image: url(/saas/img/icons/icons/blue/dragable.svg); }
}

.btn-enviar-popup {
	@extend .btn-transparent;

	position: relative;
	width: 30px;
	height: 35px;
	background-image: url(/saas/img/icons/icons/grey/mail.svg);
	background-size: auto 15px;
	background-repeat: no-repeat;
	background-position: 2px 50%;

	sub {
		position: absolute;
		right: 0;
		bottom: 10px;
	}

	@media #{$max-md} {
		display: none;
	}
}

.btn-link {
	color: $blue;
	padding: 0;

	&.verMas { margin-bottom: 30px; }
	&:hover { color: $blue1; }
}

.btn-backlink {
	// NOTA: no fer extend de btn-link perque genera milers de linies adicionals
	color: $corporative-color;
	padding: 0;

	&:before{
		content: "\ea40";
		font-family: "icomoon";
		font-size: 16px;
	}
}


.btn-plus{
	background: url(/saas/img/icons/icons/white/add.svg) no-repeat 13px 50%;
	background-size: 18px auto;
	padding-left:33px;
}

.icon-plus{
	display: inline-block;
	background-color: transparent;
	background-image: url(/saas/img/icons/icons/white/add.svg);
	background-size: 17px;
	background-repeat: no-repeat;
	background-position: 50% 50%;
	width: 17px;
	height: 17px;
	margin-left: -2px;
}

.icon-edit-white{
	display: inline-block;
	background-color: transparent;
	background-image: url(/saas/img/icons/icons/white/edit.svg);
	background-size: 18px;
	background-repeat: no-repeat;
	background-position: 50% 50%;
	width: 18px;
	height: 18px;
	margin-left: -2px;
	margin-bottom: -3px;
	margin-right: 2px;
}

.btn-link.btn-add{
	background: url(/saas/img/icons/icons/blue/add.svg) no-repeat 5px 50%;
	background-size: 18px auto;
	padding-left: 25px;

	&:hover{
		opacity: 0.8;
		text-decoration: none;
	}
}


/*********************** SWITCH ***********/
.switch {
	position: relative;
	display: inline-block;
	width: 50px;
	height: 26px;

	input{
		display:none;

		&:checked + .slider { background-color: $primary-800; }
		&:focus + .slider { box-shadow: 0 0 1px $primary-800; }
		&:checked + .slider:before {
			-webkit-transform: translateX(20px);
			-ms-transform: translateX(20px);
			transform: translateX(20px);
		}
	}  
	
	.slider {
		position: absolute;
		cursor: pointer;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: $primary-100;
		-webkit-transition: .4s;
		transition: .4s;
	  
		 &:before {
		  position: absolute;
		  content: "";
		  height: 21px;
		  width: 21px;
		  left: 5px;
		  bottom: 3px;
		  background-color: white;
		  -webkit-transition: .4s;
		  transition: .4s;
		}
		
		/* Rounded sliders */
		&.round {
			border-radius: 26px;
		}
		
		&.round:before {
			border-radius: 50%;
		} 	
	}	
	
	&.switch-mini{
	    width: 36px;
	    margin-bottom: 0;
	    height: 22px;
	    
		.slider:before {
		    height: 18px;
		    width: 18px;
		    left: 3px;
		    bottom: 2px;
		}	   
		 
	    input:checked + .slider {
		    background-color: $primary-800;
		    
		    &:before {
			    -webkit-transform: translateX(12px);
			    -ms-transform: translateX(12px);
			    transform: translateX(12px);
			}
		}
	}	
}

.row-switch{
	overflow:hidden;
	display:flex;
	align-items: center;
	
	.label-switch{
		padding: 6px 0;
		height: 34px;
		color: $primary-100;
		
		&:first-of-type{
			margin-right: 5px;
		}
		
		&:last-of-type{
			margin-left: 5px;
		}
		
		&.active{
			color: $primary-800;
			font-weight: 600;
		}
		
		a{
			color: inherit;
		}
	}
}