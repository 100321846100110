
// colores de contratación
$color-solo: $basic-500;
$color-sidecar: $yellow;
$color-team: $blue;
$color-ultimate: #000000;

.intro-billage30{
	text-align: center;
	margin: 10px auto 40px auto;
	font-size: 28px;

	strong{
		font-weight: 900;
	}
}

.contratacion{
	
	padding-top: 30px;
	padding-bottom: 30px;

	.intro-billage30{
		display: none;
	}

	.sin-iva{
		color: $grey1;
		margin-top: 15px;
		text-align: center;
	}
	
	.contratacion-container{
		width:100%;
		max-width:1400px;
		margin: 0 auto;
	}
	
	.flex-row{
		display: -webkit-flex;
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		margin: 0 -5px;
	}
	
	.plan-titulo{
		text-align: center;
		color: white;
		padding: 10px 15px;
		font-size: 27px;
		font-weight: 900;
		margin: 0 -10px 10px -10px;
		text-transform: uppercase;
	}
	
	.plan-price-wrap{
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: center;
		height:100px;	
	}
	
	.plan-usuarios{
		color: #888;
		margin-bottom: 10px;
		display: flex;
		align-items: center;
		justify-content: center;
		height:42px;
		font-weight: 400;
	}
	
	.plan-mensual{
		display:none;
	}	
	
	.plan-box{
		background: white;
		text-align:center;
		padding: 0 10px 30px 10px;
		box-shadow: 0 0 15px #ddd;
		margin-bottom: 30px;
		position: relative;
		margin: 0px 5px;
		flex: 2;
		display: flex; 
		flex-direction: column;
		justify-content: space-between;

		@media #{$min-md} {
			max-width: 40%;
		}
	}

	.plan-price{
		font-size: 60px;
		font-weight:700;
		margin-top:0;
		margin-bottom: -15px;

		sup{
			font-size: 35px;
			margin-left: 5px;
			top: -18px;
		}

		.price-month{
			font-size: 16px;
			margin-left: -28px;
			color: $text;
			&:before{
				content:'/';
			}
		}
	}

	.plan-price-sub, .plan-info{
		color: #444;
	}
	
	.plan-price-sub{
		font-weight: 600;
		display: block;
		width: 100%;
		font-size: 17px;

	}

	.btn-contratar{
		padding: 12px 25px;
		text-transform: uppercase;
		color: #fff;
		border:0;
		font-weight: 600;
		display: block;
		max-width: 250px;
		margin: 15px auto 20px auto;
		height: 46px;
		
		&:hover{
			opacity: 0.8;
			text-decoration: none;
		}
	}

	.plan-info{
		text-align: center;
		margin-top: 12px;
		font-weight: 600;
		
		li{
			padding-top: 6px; 
			padding-bottom: 6px;

			&.no-ampliable, &.line-through{
				font-weight: 400;
				color: $basic-500;
			}
		}		
		
		sup{
			top: -.1em;
			font-size: 14px;
		}
		
		&.plan-info-features{
			border-top: 1px solid #dedede;
			margin-top: 12px;
			padding-top: 12px;
			font-weight: 400;
		}
	}
	 

	
	.plan-solo{
		.plan-titulo{
			background: $color-solo;
		}
		.plan-price{
			color: $color-solo;
		}
		.btn-contratar{background: $color-solo;}
	}
	
	.plan-sidecar{
		.plan-titulo{
			background:$color-sidecar;
		}
		.plan-price{
			color: $color-sidecar;
		}
		.btn-contratar{
			background: $color-sidecar;
		}
	}

	.plan-team{
		.plan-titulo{
			background:$color-team;
		}
		.plan-price{
			color: $color-team;
		}
		.btn-contratar{
			background: $color-team;
		}
	}
	
	.plan-ultimate{
		.plan-titulo{
			background: $color-ultimate;
		}
		.btn-contratar{background: $color-ultimate;}
		
		.plan-price{
			color: $color-ultimate;
		}
	}
	
	.plan-actual{
		display: inline-block;
		padding: 12px 25px;
		margin: 30px auto;
		text-transform:uppercase;
		font-weight: 600;
	}
	
		
	.row-switch{
		margin: 5px auto 30px auto;
		overflow:hidden;

		.switch{
			float:left;
			margin: 0 15px;

			input{
				background-color:  $primary-800;
				box-shadow: 0 0 1px  $primary-800;
			}
			.slider {
				background-color:  $primary-800;
			}
		}

		.label-switch{
			display: block;
			float: left;
			font-size:16px;
			font-weight:600;
			color:  $grey;

			&.active{
				color: $primary-800;
				font-weight: 600;
			}
		}

		.label-mensual{
			text-align: right;
		}
		
		.oferta{
			color: $green;
		}

		@media #{$min-md} {
			width: 580px;

			.label-switch{
				width: 240px;
			}
		}
	}

	.plan-price-discount{
		display: none;

		.plan-price-old{
			text-decoration: line-through;
			font-size: 36px;
			color: $grey1;
		}
	}

	.extra-modulo-discount, .oferta-discount{
		display: none;
	}

	&.billage30{
		.intro-billage30{ display: block; }
		.plan-price-regular{ display: none;	}
		.plan-price-discount{ display: inline-block;	}
		.extra-modulo-regular{ display: none;	}
		.extra-modulo-discount{ display: block;	}
		.oferta-regular{display: none}

		.plan-price-sub{
			.plan-price-discount{
				span{
					text-decoration: line-through;
					color: $grey1;
				}
			}
		}

		.oferta-discount{
			display: inline-block;
			span{
				text-decoration: line-through;
				color: $grey1;
			}
		}
	}

	.intro-old-plan{
		margin: 30px auto 35px auto;
		text-align: center;
		font-size: 24px;
		font-weight: 600;
	}

}

.popup .contratacion { 
	#usuarios{
		text-align:center;
		
		option{
			text-align:center;
		}
	}

	.plan-modulo{
		width: 320px;
	}

	.form-group-usuarios{
		margin-top: 30px;
		margin-bottom: 30px;
	}

	.row-switch{
		margin: 10px 0;
		
		.label-mensual{
			text-align: left;
			width: auto;
		}		
	}

	.plan-price-wrap{
		height: 120px;
		width: 150px;
	}
	
	.plan{
		margin-top:15px;	
		margin-bottom:30px;	
		display: flex;
		justify-content: space-between;		
		
		.plan-titulo{
			display:none;
		}
		
		.plan-price{
			margin-bottom: -5px;
		}

		.plan-price-sub{
			font-weight: normal;
			font-size: 13px;
			margin-top: 15px;
			color: $grey1;
		}
	}

	&.billage30{
		.plan-price{
			.plan-price-discount{
				display: flex;
				flex-direction: column;
				.plan-price-old{
					font-size: 26px;
					margin-bottom: -10px;
				}
			}
		}

		.plan-price-sub{
			.plan-price-discount{
				display: inline-block !important;
			}
		}
	}
}

// popup

.contratar-titulo{
	display:none;
	
	&.active{
		display:inline-block;
	}
}

#divContratarDatos{

	#cuentaBancaria{
		margin-top:10px;
	}
	
	.combo-menu{
		border: 1px solid #d6dadb;
	}
	
	.combo-texto p {
		margin-bottom:15px;
		
		strong{
			font-weight: 600;
		}
	}

}

#divContratar{
	width:575px;
}

#divContratarOK{
	width:400px;
	
	p{
		margin: 40px 0;
	}
}

#divBillage30{
	width: 650px;

	.popup-title{
		border: 0;
		margin-bottom: 50px;
	}

	.popup-footer{
		display: flex;
		justify-content: center;
		padding-bottom: 30px;

		.btn-submit{
			font-size: 18px;
			padding: 10px 25px;
		}
	}

	.intro-billage30{
		font-size: 26px;
	}
}