#onboarding {
	height: 100%;
	padding-top: 50px;

	.onboarding-container {
		width: 90%;
		max-width: 1100px;
		padding: 40px;
		margin: 0 auto;
		background-color: white;
	}

	h1 {
		margin-top: 0;
		margin-bottom: 25px;
		font-size: 2.5em;
		font-weight: 400;
		color: $title-color;
		text-align: center;
	}

	.page-header-hidden{ padding: 0;	}

	.main-row{
		justify-content: center;
		h1{
			margin-bottom: 0;
		}
	}
	
	.texto-intro{
		text-align: center;
		max-width: 650px;
		margin-left: auto;
		margin-right: auto;
	}
	
	.module-heading {
		padding: 20px;
		margin: -1px;
		color: white;

		* { color: inherit; }

		img { height: 35px; }

		h2 {
			font-size: 1.7em;
			font-weight: 600;
			margin-top: 10px;
			margin-bottom: 0;
			vertical-align: middle;
		}
	}

	.module-content {
		padding: 20px;

		.btn {
			padding: 10px 20px;
			color: white;
			text-transform: uppercase;
			font-weight: 600;
		}
	}		

	.module {
		border: 1px solid $grey-2;
		text-align: center;
		margin-bottom: 30px;

		&.default {
			.module-heading {
				padding-bottom: 0;
				color: $grey2;

				h2 {
					margin-top: 15px;
					font-size: 20px;
					font-weight: 600;
					color: $color-black;
				}
			}

			.module-content { padding-top: 0; }
		}

		&.vender {
			.module-heading,
			.btn { background-color: $color-vender; }
		}

		&.organizar {
			.module-heading,
			.btn { background-color: $color-organizar; }
		}

		&.facturar {
			.module-heading,
			.btn { background-color: $color-cobrar; }
		}
	}

	@media #{$min-sm} {
		.module-content {
			p { margin: 5px 0 20px; }

			.btn { font-size: 16px; }
		}
	}

	@media #{$max-md} {
		padding-top: 30px;

		.onboarding-container { padding: 50px; }
	}

	@media #{$max-sm} {
			padding-top: 20px;

			.onboarding-container { padding: 30px; }
			
			h1 { font-size: 1.8em; }

			.module-heading {
				padding: 10px 20px;

				img { height: 25px; }

				h2 {
					margin-top: 10px;
					margin-bottom: 0px;
					font-size: 1.2em;
				}
			}

			.module-content { padding: 10px 20px; }
	}

	@media #{$max-xs} {
		.onboarding-container {
			width: 100%;
			padding: 15px;
		}

		h1 { margin-bottom: 20px; }

		.form-buscador { margin: 20px auto 30px; }

		.module {
			margin-bottom: 25px;

			.module-heading {
				img {
					display: inline-block;
					height: 20px;
					margin-right: 10px;
				}

				h2 {
					display: inline-block;
					margin-top: 0;
				}
			}

			.module-content .btn { padding: 5px 15px; }
		}
	}
}