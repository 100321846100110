
.progress-bar {
	float: none;
	width:100%;
}
	
.formFase {
	.progress-fase {
		font-size: 13px;
		font-weight: bold;
		text-transform: uppercase;

		.substring {
			max-width: calc(100% - 33px);
			vertical-align: middle;
		}

		.badge {
			min-width: 15px;
			max-width: 30px;
		    margin-top: -2px;
		    padding: 0;
		    padding-bottom: 2px;
		    color: inherit;
		    background-color: transparent;
		}	    
	}
	
	.progress-bar {
		cursor: pointer;
		color: transparent;
		font-weight: bold;
	    border-right: 2px solid white;
		background-color: $grey-2;
		-webkit-box-shadow: none;
		-moz-box-shadow: none;
		box-shadow: none;
		
		&:first-child{border-right: 0;}
		
		&:hover + .progress-bar + .progress-bar + .progress-bar + .progress-bar + .progress-bar + .progress-bar,
		&:hover + .progress-bar + .progress-bar + .progress-bar + .progress-bar + .progress-bar,
		&:hover + .progress-bar + .progress-bar + .progress-bar + .progress-bar,
		&:hover + .progress-bar + .progress-bar + .progress-bar,
		&:hover + .progress-bar + .progress-bar,
		&:hover + .progress-bar,
		&:hover { background-color: $grey-2; }
		

		&.fase-perdida.active { background-color: $grey-2 !important; }
		&.fase-perdida2.active { background-color: #ed1c24 !important; }
		&.fase-hibernando.active { background-color: #d83c12 !important; }
		&.fase-inicio.active { background-color: #f15a24 !important; }
		&.fase-presentacion.active { background-color: #f15a24 !important; }
		&.fase-ofertada.active { background-color: #f7931e !important; }
		&.fase-valoracion.active { background-color: #c9d805 !important; }
		&.fase-ganada.active { background-color: #39b54a !important; }
		&.fase-new.active { background-color: #e2c516 !important; }
		&:last-of-type.active { background-color: $grey-2 !important; }
	}

	.fase-perdida { color: #ed1c24; }
	.fase-perdida2 { color: #ed1c24; }
	.fase-hibernando { color: #d83c12; }
	.fase-inicio { color: #f15a24; }
	.fase-presentacion { color: #f15a24; }
	.fase-ofertada { color: #f7931e; }
	.fase-valoracion { color: #c9d805; }
	.fase-ganada { color: #39b54a; }
	.fase-new { color: #e2c516; }
}

.viewFase .progress,
.formFase .progress {
	height:5px;
	border: 2px solid;
	margin-bottom: 0;
	border-radius: 20px;
	background-color: transparent;
	display: flex;
	justify-content:space-between;
	flex-direction: row-reverse; 
}

.formFase .progress { border: 0; }

.viewFase .progress {
	border: 0;
	box-shadow: none;
	height: 40px;
}

.viewFase .progress-bar{
	position: relative;
	cursor: pointer;
	font-weight: bold;
    border-right: 2px solid white;
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	box-shadow: none;
	color: white;
	background-color: transparent;
	display: flex;
	align-items: center;
	justify-content: center;
	line-height: 1.2em;
}
.viewFase .progress-bar>div:hover {text-decoration: underline;}

.viewFase .progress-bar:first-child,
.btn.radio-color.fase-perdida,
.fase-bgcolor.fase-perdida,
.viewFase .progress-bar.fase-perdida{
	background-color: #ed1c24;
}
.fase-bgcolor.fase-perdida2,
.btn.radio-color.fase-perdida2,
.viewFase .progress-bar.fase-perdida2{
	background-color: #ed1c24;
}
.fase-bgcolor.fase-hibernando,
.btn.radio-color.fase-hibernando,
.viewFase .progress-bar.fase-hibernando{
	background-color: #d83c12;
}
.fase-bgcolor.fase-inicio,
.btn.radio-color.fase-inicio,
.viewFase .progress-bar.fase-inicio{
	background-color: #f15a24;
}
.fase-bgcolor.fase-presentacion,
.btn.radio-color.fase-presentacion,
.viewFase .progress-bar.fase-presentacion{
	background-color: #f15a24;
}
.fase-bgcolor.fase-ofertada,
.btn.radio-color.fase-ofertada,
.viewFase .progress-bar.fase-ofertada{
	background-color: #f7931e;
}
.fase-bgcolor.fase-valoracion,
.btn.radio-color.fase-valoracion,
.viewFase .progress-bar.fase-valoracion{
	background-color: #c9d805;
}
.fase-bgcolor.fase-ganada,
.btn.radio-color.fase-ganada,
.viewFase .progress-bar.fase-ganada{
	background-color: #39b54a;
}
.fase-bgcolor.fase-new,
.btn.radio-color.fase-new,
.viewFase .progress-bar.fase-new{
	background-color: #e2c516;
}

.radio-color {
	position: relative;
	float: left;
	width: 40px;
	height: 40px;
	border: 2px solid transparent;
	margin: 0 5px 5px 0;

	&.active:before,
	&:hover:before {
		content: '';
		position: absolute;
		left: 0;
		top: 0;
		width: 36px;
		height: 36px;
		border: 2px solid white;
		border-radius: 4px;
	}

	&.radio-color:last-child { margin-right: 0; }

	input[type="radio"] { opacity: 0; }
}

.popover.popover-edit .form-group.group-colors { display: inline-block !important; }

.viewFase .progress {
	.btn {
		position: absolute;
		right: 0;
		top: 10px;
		width: initial;
		padding: 10px;
		opacity: 0;

		> img { height: 17px; }
	}

	.progress-bar:hover .btn { opacity: 1; }
}

.oportunidad-perdida {
	position: relative;
	padding-top: 15px;
	padding-bottom: 15px;
	transition: all 0.5s ease-in-out;

	.btn { white-space: normal; }
}