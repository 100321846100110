.footer-billage{
	.logo{
		max-width: 150px;
		height: auto;
		margin-bottom: 20px;
	}
	
	.footer-slogan{
		color: $grey1;
	}
	
	.list-links{
		display: flex;
		justify-content: center;
		
		li{
			padding-right: 5px;
			padding-left: 5px;
			border-right: 2px solid $grey-2;

			&:last-of-type{
				border-right: 0;
			}
		}
	}
}


.footer-noclient{
	text-align: center;
}