// bloque detalles en popup

.pre-title-row{
  margin-bottom: 2px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .breadcrumb{
    margin: 0;
    padding: 0;
    max-width: calc(100% - 65px);

    > li + li::before{
      padding: 0 1px 0 4px;
    }

    li.bc-edit{
      width: 17px;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .btn-icon{
        padding: 0;
      }
    }
  }

  .dropdown-color{
    height: auto;

    .dropdown-toggle{
      .color{
        width: 20px;
        height: 20px;

        &.color-none{
          position: relative;
          border: 1px solid $form-input-border;

          &:after{
            content:' color';
            color: $text-grey;
            font-size: 14px;
            position: absolute;
            left: 25px;
            //top: -2px;
          }
        }
      }
    }
  }
}

.detalles{
  .breadcrumb-form{
    justify-content: flex-end;
    padding: 0;
    margin-bottom: 0;
  }
}





.form-group-title{
  margin-bottom: 10px;

  .add-clear-x{
    right: 0 !important;
  }

  .form-control-title{
    border: 0;
    border-bottom: 1px dashed transparent;
    padding-left: 0;
    padding-top: 0;
    padding-bottom: 0;
    font-size: 26px;
    font-weight: 700;
    &:focus {
      width: calc(100% + 10px);
      margin-left:-5px;
      padding-left:5px;
      margin-right: -5px;
      padding-right:5px;
    }
  }
}

.form-group-textarea{
  margin-bottom: 25px;

  .form-control{
    border: 0;
    background: $grey-4;
  }
}

.form-group-flex {
  display: flex;
  margin-bottom: 7px !important;
  min-height: 34px;

  .item-icon {
    margin-right: 8px;
    width: 17px;
  }

  > .form-label {
    min-width: 200px;
    padding-top: 2px;
    display: flex;
    align-items: center;
    color: $primary-800; //$grey-label
    font-weight: 400 !important;

    &.form-label-top {
      align-self: self-start;
      padding-top: 5px;
    }
  }

  .form-icon-text{
    display: flex;
    align-items: center;

    img{
      margin-right: 5px;
      height: 20px;
      width: auto;
    }
  }

  // hora
  > .input-group {
    width: auto;
    margin-left: -3px;

    &.duration-group {
      width: 100%;
      max-width: 100px;

      &:before {
        padding: 6px 0;
        left: 30px;
        transform: none;
      }

      .add-clear-x{
        top: 10px;
      }
    }

    .inpHora {
      background: white;
      text-align: center;
      max-width: 28px;
    }

    .inpMinuts {
      padding-left: 5px;
      text-align: center;
      max-width: 60px;
      margin-right: 20px;
    }

    &.inputImg .input-group-addon:last-child{
      border-right:0;
    }

    &.linkedin{
      margin-left: 0;

      .input-group-addon{
        background: transparent;
        color: $primary-800;
        border: 0;
        font-size: 13px;
        padding: 0;
      }
    }
  }

  // usuario
  .dropdown-toggle {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .avatar {
      margin-right: 8px;
    }
  }

  // color
  .dropdown-color{
    padding-top: 0;
    height: auto;

    .dropdown{
      display: flex;
      align-items: center;
    }

    .color{
      width: 20px;
      height: 20px;
      border: 0;
    }

    .btn{
      padding: 0;
    }
  }

  // estado
  .state-container {
    display: flex;
    align-items: center;

    .state {
      font-weight: 400;
      //font-size: 15px;
    }

    // estado checkbox (actividades)
    .state-check {
      width: auto;
      margin-right: 7px;
      cursor: pointer;

      input {
        position: absolute;
        opacity: 0;
        cursor: inherit;
      }

      label {
        margin-bottom: 0;
        cursor: inherit;
      }
    }
  }



  // fecha
  .dt-group {
    background: none;
    border: none;
    width: 100%;
    text-align: left;
    padding: 0;
    border: 1px solid transparent;
  }

  // etiquetas
  .tagit-custom {
    width: 100%;
    padding: 0;
    height: auto !important;
    min-height: 34px;

    li{
      margin: 0px 5px 4px 0;

      &.tagit-new{
        input{
          font-size: 16px;
          color: $grey1;
        }
      }
    }
  }


  // empresa
  .autocompleter-content {
    width: 100%;
  }

  .form-control-clean{
    border: 0;
    padding-left: 0;
    padding-top: 2px;
    padding-bottom: 2px;
    background: transparent;
    height: 34px;
    font-size: 16px;

    & + .input-group-addon {
      border: 0;
    }

    &.form-time-container{
      padding: 0;

      .time-container{
        margin-left:0;
        margin-right: 0;
        width: 50px;
      }
    }
  }


  // actividades comerciales

  #productsActivities{
    width: 100%;
    ul.tagit-custom li.tagit-new{
      width: 100%;
      > .ui-widget-content{
        width: 100%;
      }
    }
  }

  // combo
  &.combo-advanced{
    .dropdown.dropdown-img{
      width: 100%;

      .fake-select{
        border: 0 !important;
        padding-left: 0 !important;
        padding-top: 2px !important;
        padding-bottom: 2px !important;
        display: flex;
        align-items: center;
      }

      ul.dropdown-menu{
        margin-left: -10px;
        border-top: 1px solid $color-border;

        li{
          padding: 5px 10px;
          display: flex;
          align-items: center;
          font-size: 15px;
        }
      }
    }
  }

  // seleccionar contacto
  .input-group-select{
    width: 100%;
  }
}