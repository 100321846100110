/* OLD */

.doc-preview {
    height: 150px;
    width: 120px;
    right: 50px;

}.doc-preview-widget {
     height: 150px;
     width: 120px;
     position: absolute;
    margin-top: 45px;     
}
.doc-buttons {
    width: 100%;
    height: 40px;
    list-style-type: none;
    position: absolute;
    bottom: 0;
}
.doc-buttons li {
    float: left;
    width: 30px;
    height: 30px;
    margin-left: 6px;
    /*border: solid 1px #ccc;*/
    -moz-border-radius: 4px;
    border-top-left-radius:4px;
    border-top-right-radius:4px;
    border-bottom-left-radius:4px;
    border-bottom-right-radius:4px;
    
    .img {
	    float:  none;
	    width: 20px;
	    height: 20px;
	    display: block;
	    margin-left: auto;
	    margin-right: auto;
	    transform: translate(0, 5px);
	}
}

.doc-button-upload {
    width: 100%;
    text-align: center;
    margin-top: 40px;
}

.divLinkViewDoc{
	position: absolute;
	right: 57px;
	top: 155px;
	text-align: center;
	width: 105px;
	font-size: 14px;
}

.doc-wrap{
	position:relative;
	overflow:hidden;
	height:210px;
	
	.doc-preview{
		right:auto;
		left:0;
	}	
	.doc-preview-widget { margin-top:15px; }
	
	.divLinkViewDoc{
		right: auto;
		left:7px;
		top: 110px;
		text-align: center;
	}		
}

#tableDocuments {
	tbody tr td .td-icon img {
		height: 20px;
	}
}

#viewDocs{
	padding-top: 15px;

	.btn.init-curtain{
		position: absolute;
		top: 25px;
		right: 25px;
	}
}

// document list

.docs-list {
	.document {
		display: table;
		position: relative;
		width: 100%;
		padding: 5px 0;
		margin-bottom: 10px;
		background-color: white;
		border-bottom: 1px solid $color-border;

		&:last-of-type{
			border-bottom: none;
		}



		.breadcrumb-form{
			margin-top:0;
			margin-bottom: 0;
			padding-top: 0;
		}

		[class^="documents-bloc"],
		.list-actions {
			display: table-cell;
			vertical-align: top;
			color: $grey2;

			@media #{$max-sm} {
				display: block;
				float: left;
			}

			&.documents-bloc1 {
				width: 35%;
				padding-right: 5px;

				@media #{$max-sm} {
					width: 100%;
					padding-right: 30px;
				}

				.media-left{
					img{
						max-height: 24px;
						margin-right: 5px;
					}
				}
			}

			&.documents-bloc2 {
				width: 35%;
				padding-left: 5px;
				padding-right: 5px;
				text-align: center;

				@media #{$max-sm} {
					width: 100%;
					text-align: left;
				}
			}

			&.documents-bloc3 {
				width: 30%;
				padding-left: 5px;
				text-align: right;

				@media #{$max-sm} {
					width: 100%;
					text-align: left;
				}
			}
		}
	}
}

#tabDocuments{
	.docs-list{
		padding-top: 45px;
	}

	.no-results{
		padding-top: 75px;
	}
}