.popover .divFormButton{
	margin-top:10px;
	padding:0 15px;
}

/**************************************************/
#divCentral .popover { z-index:75 !important; }

.popover {
	padding: 0;
	border-radius: 2px;
	border:1px solid $color-border;
	z-index: 70;
	font-family: "Source Sans Pro", sans-serif;
	background: rgba(255,255,255,0.9);
	color: $text;
	.has-popup &{
		//position: fixed;
	}
	
	.popover-title {
		border: 0;
		border-radius: 4px 4px 0 0;
		background-color: $grey-3;
	}

	&.popover-xs {
		max-width: 100vh;

		.popover-content {
			display: inline-block;
			width: 300px;
			padding: 10px 5px;
		}
	}

	&.popover-sm {
		width: 50%;
		max-width: 800px;
	}

	&.popover-lg {
		width: 70%;
		max-width: 450px;
	}

	&.popover-edit {
		z-index: 125; // no baixar
		background: white;

		.popover-header { position: relative; }

		.popover-btn-close {
			position: absolute;
			right: 5px;
			top: 5px;

			img {
				height: 15px;
				width: auto;
			}
		}

		.popover-title{
			background: white;
			border-bottom: 1px solid #eee;
			margin: 0 15px 0 15px;
			padding: 17px 0;	
			font-size: 16px;	
		}

		.popover-content {
			max-width: 100%;
			padding: 10px 0;
		}

		.form-group{
			margin-bottom: 15px !important;
			display: block !important;
			
			/* NO BORRAR: */
			.form-control{
				display: block;
				float: none;
				width: 100%;
			}
		}
	}

	&.popover-divided {
		.bg-light {
			background-color: $primary-50;
		}
		.popover-content { padding: 0; }
		.bloque {
			padding-top: 15px;
			padding-bottom: 15px;
		}
	}	

	&.popover-options {
		padding: 0;
		overflow: hidden;

		.popover-content { padding:0; }
	}

	.popover-template { display: none; }

	&[role="tooltip"] .popover-content { min-width: 250px; }
	
	> .arrow.arrow-dark{
		border-bottom-color: $color4;
		
		&:after{
			border-bottom-color: $color4;
		}
	}
}

/* popover historial emails */
.popover-historial-envios{
	width: 500px;
	max-width: 500px;
	
	.popover-content {
		max-height: 500px;
		padding: 15px;
		overflow-y: auto;
		scrollbar-width: thin;
		scrollbar-color: $grey-1 $grey-3; // 1 barra 2 fondo (firefox)
	}

	ul {
		list-style: none;
		
		li {
			line-height: 1.5em;
			padding: 5px 0;
			border-bottom: 1px solid $grey-3;
			
			strong { font-weight: 600; }

			&:last-child { border-bottom: 0; }
		}
	}
}


/* popover ayuda emails */
.popover-ayuda {
	width: 500px;
	max-width: 500px;
	
	.popover-content {
		position: relative;
		padding: 20px 30px 20px 55px;
	}
	
	img.help {
		position: absolute;
		width: 20px;
		height: 20px;
		left: 20px;
		top: 22px;
	}

	ul {
		margin-left: 15px;
		margin-top:10px;
		list-style: initial;

		li {
			line-height: 1.5em;
			padding: 3px 0;
			
			strong { font-weight: 600; }
		}
	}
}


/* popover seleccion contactos emails */
.popover-contactos-cuenta {
	width: 260px;
	max-width: 260px;
	left: 20px !important;

	.arrow { left: 50px !important; }
	
	.popover-content{
		max-height: 200px;
		overflow-y: auto;
	}
	
	li { line-height: 1.7em; }
}

/* popover seleccion direccion */
.popover-changeDireccion {
	left:0 !important;

	.btn-footer{
		border-top: 1px solid $form-input-border;
		width:100%;
		padding:10px 20px;
		text-align:left;

		&:hover { background-color: $grey-2; }
		
		i { margin-right:5px; }
	}
}

/* conceptos factura */
.popover-conceptos,
.popover-expiraciones {
	min-width: 700px;

	.popover-content { padding: 20px 15px; }

	.table-lst { margin-bottom: 0; }
}

.popover-conceptos {
	right: 0;
	left: auto !important;

	.arrow {
		left: auto !important;
		right:30px !important;
	}
}

.popover-movimientos{
	min-width: 500px;	
	.popover-title{
		background-color: white;
		padding-bottom: 0;
		font-size: 18px;
		margin-top: 5px;
	}
	
	.popover-content{
		padding: 15px;
	}
	.table-lst { 
		margin-bottom: 0; 
		thead tr th {
			height: auto;
		}
	}
}

.popover-comment {
	width: 800px;
	max-width: 800px;
	left: initial !important;
	right: 0;

	.arrow { transform: translateX(25px); }

	.popover-content { padding: 20px; }
}

.popover.popover-dropdown {
	min-width: 200px;
	max-width: 100%;
	margin-top: -5px;
	border: 0;
	background-color: transparent;
	box-shadow: none;
	margin-left: -55px;

	.popover-content { padding: 0; }	
	
	.dropdown-menu {
		position: inherit;
		display: block;
		margin-top: 0;
	}
}

.popover.popover-estados {
	min-width: 140px;
	max-width: 100%;
	border-radius: 4px;
	cursor: initial;
	
	.popover-content { padding: 15px; }

	.dropdown-estados {
		margin-bottom: 0;

		li { padding: 3px 0; }
		.icon-estado { margin-right: 5px; }
	}

	.state-edit {
		cursor: pointer;
	}		

	&.bottom { margin-top: 15px; }
}

.popover.popover-usuarios {
	border: none  !important;
	left:0 !important;
	width: 230px;

	.popover-content { padding: 0; }

	.arrow {
		left: 40px !important;
		border: none !important;
	}

	.dropdown-menu-usuario {
		margin-bottom: 0;
		padding: 10px;
		height: 225px;
		overflow-y: auto;
		
		li {
			overflow: hidden;
			padding-top: 6px;
			padding-bottom: 6px;

			&:hover {
				color: $blue;
				cursor: pointer;
			}
		}

		abbr { display: none; }
	}
}

.popover.popover-ayuda-iban {
	min-width: 350px;

	.result {
		display: none;

		&.result-error { color: $red; }
	}

	[disabled] {
		cursor: default;
		background-color: white;
		pointer-events: none;
	}

	.input-group-addon { width: 85px; }
	.btn-to-form { display: none; }
}

.dropdown-popover { top:-2px; }

/* popover seleccion fecha y hora */
.popover-pickDT {
	background: white;
	
	.pika-single {
		border: none;
		
		.pika-lendar {
			float: none;
			width: 100%;
			margin: 0 1px 0 0;
		}
	}

	.hora-group { margin-top: 15px; }

	.btn-update {
		display: none;
		width: 100%;
		margin-top: 15px;
	}

	.btn-delete {
		width: 100%;
		margin-top: 10px;
		margin-bottom: -5px;
	} 
} 

/* popover fecha y hora tareas - fechas relativas*/
.popover-task-calendar{
	.radio{
		label{
			font-weight: normal;
		}
	}
	
	.container-fluid{
		padding-left:15px !important;
		padding-right:15px !important;
	}
}
   
  
.popover-addFamilia,
.popover-addFabricante {  
	.item-descripcion { display: none; }
}



.popover.popover-alerts{

	ul {
		margin-bottom:0;
		margin-left: 15px;
		list-style: initial;

		li {
			line-height: 1.5em;
			padding: 3px 0;
		}
	}
}

.popover-mentions{
	min-width: 280px;

	.popover-header{
		background: $basic-300;

		.popover-title{
			background: transparent;
		}
	}
	&.bottom > .arrow::after{
		border-bottom-color: $basic-200;
	}

	.popover-content{
		padding: 0 !important;
		max-height: 300px;
		overflow-y: auto;
		scrollbar-width: thin;
		scrollbar-color: $grey-1 $grey-3; // 1 barra 2 fondo (firefox)

		ul{
			margin-bottom: 0;
		}
	}

	.item-user{
		padding: 5px 15px;
		border-top: 1px solid $color-border;
		border-bottom: 1px solid $color-border;
		&:hover{
			background: $basic-200;
			cursor: pointer;
		}

		span{
			display: block;
			&.usuario-nombre{
				font-weight: 500;
			}
			&.usuario.email{
				color: $grey-label;
			}
		}
	}
}

.tooltip {
	&.top .tooltip-arrow { border-top-color: $grey4; }
	&.bottom .tooltip-arrow { border-bottom-color: $grey4; }
	&.left .tooltip-arrow { border-left-color: $grey4; }
	&.right .tooltip-arrow { border-right-color: $grey4; }

	.tooltip-inner {
		max-width: 100%;
		padding: 5px;
		background-color: $grey4;
	}
		
	@media #{$min-md} {
		.tooltip-inner { width: 300px; }
	}
}

@media #{$max-xs} {
	.popover {
		left: 0 !important;

		&.popover-dropdown { margin-left: 0; }
		&.popover-sm { width: 80%; }
	}
}