.msg-error {
	margin-top: -8px;
	display: none;
}

.form-error .msg-error {
	display: block;
	color: $form-error-label;
}

// popups de envios de email
.envio-email {
	.row-bloques{
		display: block;
	}
	.btn-cc, .btn-cco {
		text-decoration: underline;
		padding: 0;

		&:hover { text-decoration: none; }
	}
	
	.btn-cco{
		margin-left: 10px;
	}
	
	.btn-cc-hide, .btn-cco-hide { padding: 0; }	

	.box-cc, .box-cco { display: none; }
	
	.tagitBox {
		border: 1px solid $form-input-border;
		border-radius: 2px;
		padding-bottom: 25px;
		padding-left: 40px;
		background-image: url(/saas/img/icons/icons/grey/users.svg); 
		background-repeat: no-repeat;
		background-position: 8px 8px;
		background-size: 24px auto; 

		ul.tagit {
			border: none;
			padding-left: 0;
			background: none;
			
			li.tagit-new > .ui-widget-content{
				max-width: 125px;
			}
		}
		
		.text-icon .fa{
			font-size: 75%;
			margin-top: 2px;
			margin-right: -1px;
		}
	
	}
	
	.cc-anadir, .cco-anadir {
		position: absolute;
		bottom: 8px;
		left: 10px;
	}
	
	.cc-anadir-texto, .cco-anadir-texto{
		text-decoration: underline;
		cursor: pointer; 
	}
	
	.contacto-cargo{
		color: $grey-bold;
	}
	
	.inpTextarea .infoTextarea {
		right:15px;
		bottom: initial;
		top: 40px;
	}

	.email-actions{
		background: #f9f9f9;
		padding: 5px 10px;
		border: 1px solid $form-input-border;
		border-top:0;
	}
	
	.btn-icon{
		background-size: 20px auto;
		background-position: 0 50%;
	}
	
	.btn-firma{
		background-image: url(/saas/img/icons/icons/grey/firma.svg);
		padding-left: 25px;
		margin-right: 20px;
	}
	
	.btn-template{
		background-image: url(/saas/img/icons/icons/grey/template.svg);
		padding-left: 28px;
	}
	
	.btn-attach{
		background-image: url(/saas/img/icons/icons/grey/clip.svg);
		padding-left: 28px;
		margin-left: 20px;
	}	
	
	#firma, #adjuntos{
		border-left: 1px solid $form-input-border;
		border-right: 1px solid $form-input-border;
		padding: 0 20px;
	}
	
	#adjuntos{
		padding: 5px 20px 20px 20px;
	}
	
	// no m'ho borris, gracies
	.trumbowyg-box{
		border-bottom: 0; 
		margin-bottom: 0;
	}
}



// emails actividad
.envio-email-normal{
	#body { padding-bottom: 40px; }
}

.envio-email-imap{
	.tagitBox {
		padding-bottom: 0;
		
		ul.tagit{
			margin: 0;
		}
	}
}



// popovers de emails
.firmas-content, .plantillas-content, .historial-envios-content,.ayuda-envios-content,	.contactos-cuenta-content {
	display: none;
}
.popover-firmas, .popover-plantillas{
	background: white;
}