.partner-panel{
	
	padding-top: 30px;
	padding-bottom: 30px;
	
	h2.titulo{
		margin-bottom: 20px;
		font-size: 20px;
		font-weight: 600;
		line-height: 140%;
		
		&.titulo-trophy{
			background: url(/saas/img/icons/icons/blue/trophy.svg) no-repeat; 
			background-size: 20px; 
			background-position: 0 50%; 
			padding-left: 30px;
			margin-top: 0;
		}
	}
		
	.bloque-content{
		background: white;
		padding: 30px;
	}
	
	.row-compartir{
		display: flex;
		align-items: center;
	}
	
	.input-compartir{
		.form-control{
			background: white;
			min-width: 315px;
		}
		.btn{
			color: $blue;
			border-left: none;
		}
	}
	
	ul.tagit {
	    background: #fff url(/saas/img/icons/icons/grey/mail.svg) no-repeat;
	    background-position: calc(100% - 8px) 8px;
	    background-size: 30px auto;
	}	
		
	.list-social{
		margin-top: 15px;
		margin-left: 10px;
		white-space: nowrap;
		
		li{
			display: inline;
			margin-left: 2px;
		}
		
		.social{
			width: 30px;
			height: 30px;
			
			i{
				font-size: 17px;
			}
		}
	}
	
	#mensaje{

	}
	
	.row-enviar{
		display: flex;
		align-items: center;
		margin-top: 25px;
		margin-bottom: 50px;
		
		.btn-submit{
			margin-right: 20px;
		}
		
		.btn-link{
			text-decoration: underline;
			font-weight: 600;
		}
		
		.text-ok {
			margin-bottom: 0; 
		}
	}
	
	.copiar-result{
		display: none;
		margin: -15px 0 30px 0;
	}
		
	
	.row-stats, .row-movs{
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding-top: 3px;
		padding-bottom: 3px;
		border-bottom: 1px solid $grey-3;
	}
	
	.stats-text{
		font-size: 17px;
		color: $grey;
	}
	.stats-value{
		font-size: 30px;
	}	
		
	.row-stats{	
		&:last-of-type{
			border-bottom: none;				
		}
	}	
	

	
	.row-movs{
		padding-top: 12px;
		padding-bottom: 12px;
		&:first-of-type{
			border-top: 1px solid $grey-3;
		}		
	}	
	
	.movs-date{
		width: 120px;
		display: inline-block;
	}
	
	.movs-value{
		align-self: flex-end;
	}
	

	
	.row-button{
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-top: 20px;
		margin-bottom: 20px;
		div{
			display: flex;
			align-items: center;
		}
		.stats-text{
			color: $text;
			font-weight: 600;
			font-size: 18px;
			margin-right:10px;
		}
		.stats-value{
			font-weight: 600;
		}			
	}
	
	@media #{$max-sm} {
		.row-compartir { 
			display: block;
			
			.form-control{
				min-width: initial;
			}
		}
		
		.row-enviar{
			display: block;
			
			.btn{
				margin-bottom: 15px;
			}
		}
	
	}
	
}

#feedbackOK, #feedbackKO{
	display: none;
}

.partner-no-interesa{

	max-width: 550px;
	
	.page-content{
		line-height: 1.7em;
		padding: 100px 30px 80px 30px;
		background:#ffffff url("/saas/img/icons/backgrounds/sun.svg");
		background-repeat: no-repeat;
		background-position: 100% 0;
		background-size: auto 150px;
		
		p{
			font-size: 16px;
		}
	}
	
	.preLogin-header{
		padding: 50px 0 30px 0;
	}
	
	.preLogin-icon{
		height: 70px;
		width: auto;
	}
	
	footer{
		p{
			margin-bottom: 3px;
		}
	}
}


#partnerCobrar{
	width: 500px;
	
	.popup-title{
		border: none;
	}
	.popup-content{
		line-height: 1.5em;
		font-size: 16px;
	}
		
	.popup-content, .popup-footer{
		text-align: center;
	}
	
	.popup-footer{
		padding-bottom: 20px;
	}	
	
	.img-cobrar{
		max-width: 100px;
		display: block;
		margin: 0 auto;
	}

}
