.panel-group {   
	margin-bottom: 0;
	.panel {
		border-radius: 2px;
		border-color: $color-input-border-hover;

		+.panel { margin-top: 15px; }

		.panel-heading { 
			padding: 0;
			border-top-left-radius: 2px;
			border-top-right-radius: 2px;
			background-color: transparent;

			.panel-title {
				> a {
					&,
					&:focus,
					&:hover {
						display: block;
						padding: 10px 15px;
						color: inherit;
						font-weight: 600;
						outline: none;
					}
				}
			}
		}

		.panel-collapse {
			.panel-body {
				border-color: $color-input-border-hover;

				h3 {
					padding-bottom: 5px;
					border-bottom: 1px solid $color-input-border-hover;
					margin-top: 5px;
					margin-bottom: 15px;
					font-size: 1.5em;
					font-weight: 400;
					color: $color-input-border-hover;
				}
			}
		}
	}
}