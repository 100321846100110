
.filters-list {
	@extend .clearfix;
	color: $grey2;

	@media #{$max-sm} { font-size: 14px; }

	.list-navi-top{
		padding-top: 6px;
		padding-bottom: 6px;
		float: right; 
	}
	
	> * {
		float: left;
		margin-bottom: 6px;

		&:not(&:last-child){ margin-right: 7px; }
	}

	.filter-label { margin-right: 5px; }

	.btn{
		height: 34px;

		
		&:not(.fake-select):not(.btn-filter-reset) {
			border: 1px solid $filter-border;
			border-radius: 2px;
			//text-transform: uppercase;
			color: $primary-800; //$grey2
			font-size: 14px;
			font-weight: 600;
			padding: 4px 8px;

			img { height: 16px; }
	
			&:hover,
			&:focus,
			&:active {
				background-color: $hover-background !important;
				border:1px solid $hover-border !important;
				color: $primary-400 !important;
			}
		}

		&.btn.btn-dropmore{
			padding: 15px;
		}
	}

	.btn-filter{
		background-position: 8px 50%;
		background-color: $primary-50 !important; //$grey-4
		background-repeat: no-repeat;
		background-size: 15px 15px;
		padding-left: 27px !important;
		padding-right: 10px !important;
		border-color: $primary-100 !important;
		color: $primary-800 !important;

		&:hover {
			background-color: $hover-background !important;
			border:1px solid $hover-border !important;
			color: $primary-400 !important;
		}

		&.by-user{
			background-image: url(/saas/img/icons/filters/user.svg);

			&:hover{
				background-image: url(/saas/img/icons/filters/hover/user.svg);
			}
		}
		&.by-filter{
			background-image: url(/saas/img/icons/filters/filter.svg);
			&:hover{
				background-image: url(/saas/img/icons/filters/hover/filter.svg);
			}
		}

		&.by-order{
			background-image: url(/saas/img/icons/filters/order.svg);
			&:hover{
				background-image: url(/saas/img/icons/filters/hover/order.svg);
			}
		}
		&.by-tags{
			background-image: url(/saas/img/icons/filters/tag.svg);
			&:hover{
				background-image: url(/saas/img/icons/filters/hover/tag.svg);
			}
		}
		&.by-state{
			background-image: url(/saas/img/icons/filters/state.svg);
			&:hover{
				background-image: url(/saas/img/icons/filters/hover/state.svg);
			}
		}
		&.by-payment{
			background-image: url(/saas/img/icons/filters/payment.svg);
			&:hover{
				background-image: url(/saas/img/icons/filters/hover/payment.svg);
			}
		}

		&.by-project, &.by-funnel, &.by-campaign{
			padding-left: 8px !important;
			/*
			background-image: url(/saas/img/icons/filters/project.svg);
			&:hover{
				background-image: url(/saas/img/icons/filters/hover/project.svg);
			}
			*/
		}
		&.by-date{
			background-image: url(/saas/img/icons/filters/calendar.svg);
			&:hover{
				background-image: url(/saas/img/icons/filters/hover/calendar.svg);
			}
		}
		&.by-interval{
			background-image: url(/saas/img/icons/filters/interval.svg);
			&:hover{
				background-image: url(/saas/img/icons/filters/hover/interval.svg);
			}
		}
		&.by-type{
			background-image: url(/saas/img/icons/filters/type.svg);
			&:hover{
				background-image: url(/saas/img/icons/filters/hover/type.svg);
			}
		}

		&.by-tasks{
			background-image: url(/saas/img/icons/filters/tasks.svg);
			&:hover{
				background-image: url(/saas/img/icons/filters/hover/tasks.svg);
			}
		}

		&.btn-arrows {
			position: relative;
			padding-right: 25px !important;

			.icon-arrows{
				width: 10px !important;
				height: auto !important;
				position: absolute;
				right: 7px;
				top: 12px;
			}
		}
	}


	.filter-tags-container{

		.dropdown{
			> .dropdown-menu{
				scrollbar-width: auto;
				overflow: hidden;
				.dropdown-menu{
					max-height: calc(65vh - 65px);
					max-width: 62vw;
					scrollbar-width: auto !important;

					@media #{$max-lg} {
						max-height: calc(65vh - 110px);
					}
					&::-webkit-scrollbar{
						width: 12px;
						height: 12px;
					}

				}
			}

			::-webkit-scrollbar{
				width: 12px;
				height: 12px;
			}
		}	
	
		@media #{$min-lg} {
			&.c1{
				.dropdown{
					> .dropdown-menu{
						.dropdown-menu{
							overflow-x: auto;
							max-height: calc(65vh - 110px);
						}
					}
				}
			}
			&.c2{
				.dropdown{
					> .dropdown-menu{
						left: -100px;

						.dropdown-menu{
							overflow-x: auto;
							max-height: calc(65vh - 110px);
							columns: 2;
						}
					}
				}
			}

			&.c3{
				.dropdown{
					> .dropdown-menu{
						left: -100px;

						.dropdown-menu{
							overflow-x: auto;
							columns: 3;
						}
					}
				}
			}

			&.c4{
				.dropdown{
					> .dropdown-menu{
						left: -100px;

						.dropdown-menu{
							columns: 4;
							overflow-x: auto;
						}
					}
				}
			}
		}

		@media #{$max-xs} {
			display: none;
		}

		&.tags-with-tabs{
			.dropdown {
				> .dropdown-menu {
					.dropdown-menu {
						max-height: calc(65vh - 155px);
					}
				}
			}
		}

	}



	.filter-dropdown .dropdown,
	.filter-btn,
	.filter-check,
	.filter-popover {
		> .btn {
			background-color: white;

			@media #{$max-sm} { line-height: 18px; }

			.filter-responsive { display: none; }

			abbr { border-bottom: 0; }
		}
	}

	.filter-dropdown {
		.panel { border: 0; }

		.dropdown-menu {
			max-height: 65vh;

			li p {
				margin-bottom: 0;

				abbr { display: none; }
			}

			> .nav-tabs {
				margin-top: -5px;

				li {
					display: table-cell;
					width: 1%;

					&:first-child {
						&.active > a { border-left: 0; }
					}

					a {
						border-top: 0;
						border-top-left-radius: 0;
						border-top-right-radius: 0;
					}
				}
			}
		}
	}

	.filter-btn .btn-link {
		padding-top: 6px;
		padding-bottom: 6px;
		background-color: transparent;
	}

	.filter-check {
		padding: 7px;
		line-height: 0;

		.checkbox { margin: 0; }
	}

	.filter-popover {
		position: relative;

		&.filter-first{
			.popover.filterPopover{
				left: 0 !important;
				transform: initial;
			}
		}
	}

	.filter-text{
		position: relative;
		.filter-text-icon{
			position: absolute;
			top: 8px;
			left: 7px;
			z-index: 1;
			color: $primary-800;
		}
		.filter-text-input{
			height: 34px;
			padding-left: 25px;
			border-color: $filter-border;
			font-size: 14px;
		}
		.add-clear-x{
			top: 9px;
		}
	}

	.filter-abc {
		display: flex;

		a {
			position: relative;
			padding: 6px 4px;
			color: inherit;
			display: block;

			&:hover,
			&.selected {
				color: $blue;
				text-decoration: underline;
			}
		}

		@media #{$max-lg} {
			font-size:13px;
			a{
				padding-left: 2px;
				padding-right: 2px;
			}
		}
		@media #{$max-md} { display: none }
	}

	.filter-search {
		width: auto;
		.form-control {
			height: 32px;
			padding-top: 0;
			padding-bottom: 0;

			&:not(:required) { width: 200px; }

			&:invalid {
				width: 0;
				padding: 0;
				border-width: 0px;
			}

			&:focus {
				padding: 6px 12px;
				border-width: 1px;

				@media #{$min-sm} {
					width: 300px;
				}
			}
		}

		.add-clear-x {
			position: initial !important;
			float: left;
			padding: 8px;
			border: 1px solid $grey-3;
			border-left: 0;
			line-height: 14px;
			background-color: white;
		}

		.input-group-btn {
			display: block;

			.btn {
				height: auto;
				padding: 5px 8.5px;
				font-size: 14px;
				background-color: white;
			}
		}
	}

	.filter-refresh .btn {
		.fa {
			transform: rotate(0deg);
			transition: all 0.5s;
		}

		&:hover .fa { transform: rotate(180deg); }
	}
	
	.btn-filter-reset {
		border: none;
		margin-left: 5px;
		text-decoration: underline;
		display: flex;
		align-items: center;
		background-repeat: no-repeat;
		background-size: 20px 20px;
		background-position: 50% 50%;
		background-image: url(/saas/img/icons/filters/hover/remove-filter.svg);
		width: 20px;
		height: 34px;

		&:hover,
		&:active,
		&:focus {
			background-image: url(/saas/img/icons/filters/remove-filter.svg);
		}

		span{
			display: none;
		}
	}
	
	.label-switch{
		height: auto;
	}

	.filter-range-group{
		.filter-inputs{
			display: flex;

			.form-control{
				background-color: white !important;
			}

			.form-control:first-of-type, .add-clear-span:first-of-type{
				margin-right: 5px;
			}
		}
	}

	&.filters-list-nobg{
		.btn-filter{
			background-color: #ffffff !important;
		}
	}
}

.popover.filterPopover {
	left: 50% !important;
	transform: translateX(-50%);
	min-width: 250px;
	max-width: 80%;
	max-height: 75vh;
	margin-top: 5px;
	overflow-y: auto;
	scrollbar-width: thin;
	scrollbar-color: $grey-1 $grey-3; // 1 barra 2 fondo (firefox)


	.form-group{
		margin-bottom: 15px !important;
	}
	.form-control {
		@extend .input-sm;

		font-size: 14px;
	}

	.dropdown-color {
		padding-top: 0;
		height: 34px;

		.btn {
			padding: 3.5px 3px !important;
			.color{
				margin: 0;
			}
		}

		.dropdown-menu{
			min-width: 190px;
			padding: 10px 0 10px 5px;
			left: 32px;
			top: -30px;
		}
	}

	.btn-default:hover { color: $btn-def-hover-color; }
}

// DATE RANGE PICKER
.daterangepicker {
	padding: 5px 10px;

	&:after,
	&:before { content: none !important; }

	.ranges {
		margin: 0;
		margin-left: -10px;

		@media #{$min-sm} {
			ul {
				width: auto;
				max-width: 160px;
			}
		}

		li {
			padding: 5px 15px;
			font-size: 13px;
			border: 0;
			border-radius: 0;
			margin-bottom: 0;

			&:last-child {
				margin-bottom: 15px;

				@media #{$max-xs} { margin-bottom: 0; }
			}

			&.active,
			&:hover {
				border-top: 0;
				border-left: 0;
				border-right: 0;
				border-bottom: 0;
			
				&:last-child { border-bottom: 0; }
			}
		}

		.range_inputs {
			padding-top: 15px;
			margin-left: 15px;

			.cancelBtn { display: none; }
		}
	}

	.calendar {
		//margin: 5px 15px;

		&.left {
			margin-right: 7.5px !important;

			.daterangepicker_input,
			.calendar-table  { padding-right: 0 !important; }
		}

		&.right {
			margin-left: 7.5px !important;
			margin-right: 0px;
		}

		.form-control.active { border-color: $form-input-border-focus; }
		.calendar-table {
			padding: 0;
			padding-top: 5px;

			th {
				font-weight: normal;

				&.available {
					font-size: 12px;

					&:hover {
						background-color: transparent;
						color: $daterangepicker-ranges-hover-color;
					}
				}

				select {
					padding: 0;
					padding-left: 5px;
					border: 0;
					margin: 0;
					font-size: 14px;
					-webkit-appearance: none;
					-moz-appearance: none;
					cursor: pointer;

					&::-ms-expand { display: none; }

					&.monthselect { text-align-last: right; }
					&.yearselect { text-align-last: left; }
				}
			}

			th,
			td { padding: 3px 1px; }
		}
	}

	/* CUSTOM */
	.dt-filtrar {
		label { font-weight: normal; }
		
		.dt-filtrar-lista{
			display: inline-block;
			margin-left: 20px;

			li {
				display: inline-block;
				margin-right: 20px;

				&:last-child { margin-right: 0; }
			}
		}

		.radio label { padding-left: 20px; }
	}
}

@media #{$max-xs} {
	.daterangepicker {
		left: 50% !important;
		transform: translateX(-50%);
		padding-left: 0 !important;
		padding-right: 0 !important;

		.ranges {
			margin: 0;

			li {
				font-size: 13px;
				border-bottom: 0;
				padding: 5px 15px;

				&.active,
				&:hover {
					border-bottom: 0;
					background-color: transparent;
					color: $blue;
				}
			}
		}

		.range_inputs {
			margin: 0;
			text-align: center;
		}

		&.show-calendar {
			.range_inputs,
			.calendar,
			.daterangepicker_input { display: none; }

			.calendar { float: unset; }
		}

		.dt-filtrar { display: none; }
	}	
	
	.label-switch{
		font-size: 13px;
	}
}

@media #{$max-sm} {
	.main-menu.toggled ~ .daterangepicker .calendar {
		&.left { margin-right: 0 !important; }

		&.right { display: none; }
	}
}