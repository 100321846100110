
#productView {
	.product-img {
		position: relative;
		background-color: $grey-3;

		> img { width: 100%; } 

		.btn {
			position: absolute;
			bottom: 10px;
			right: 10px;
			padding: 16px;
			border-radius: 50%;
			background-size: 65%;
			background-position: center;
			background-color: white;

			&:hover:after {
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				height: 32px;
				width: 32px;
				border-radius: 50%;
				background-color: rgba(white, 0.6);
			}
		}
	}

	.productImg {
		position: relative;
		width: 15%;
		padding: 15px;
		background-color: white;

		> img { width: 100%; }

		.editBtn {
			position: absolute;
			top: 5px;
			right: 5px;
			margin: 0;
		}
	}

	.summary {
		padding: 20px;
		margin-right: -20px;
		background-color: $summary-background;

		.state { line-height: 2; }
	}

	.media {
		.media-body { padding-left: 20px; }
	}
	
	.lista-tarifas{
		width: 50%;
		li{
			display: flex;
			justify-content: space-between;
			border-bottom: 1px solid $table-border;
			margin-bottom: 10px;
			
			&:last-child{
				border: none;
			}
		}
		
		.info-text{
			margin-left: 10px;
			font-size: 85%;
		}
	}
}

#divNuevoProducto {
	width: 1175px;
	
	#productProps { display: none; }

	#condiciones, #tarifas{
		min-height: 450px;
	}
	
	.divCompra,
	.divVenta { display: none; }
	
	.subtitle{
		margin-top: 40px;
	}
	
	.popup-footer {
		.checkbox {
			display: inline-block;
			margin-left: 20px;
		}
	}		
}

#divNuevoServicio {
	width: 1130px;
}

// popup tarifas de producto
#popupTarifaProducto{
	width: 520px;
	
	.form-group-importe{
		max-width: 120px;
	}
	
	.btn-group-justified{
		.btn{
			white-space: initial;
		}
	}
}